/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { Task } from '../../models/task';
import {
  MatCheckboxClickAction,
  MatCheckboxChange,
} from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { RecurringTasksService } from '../../services/recurring-tasks.service';
import { SubTheme } from '../../models/subtheme';
import { SharedService } from '../../services/shared.service';

import * as moment from 'moment';

/**
 *  Add/edit task dialog.
 *
 * @export
 * @class AddEditTaskComponent
 * @extends {AddEditAbstractDialog<Task>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-task',
  templateUrl: './add-edit-task.component.html',
  styleUrls: ['./add-edit-task.component.scss'],
})
export class AddEditTaskComponent
  extends AddEditAbstractDialog<Task>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditTaskComponent
   */
  formErrors = {
    responsibleMember: '',
    plannedOn: '',
    remarks: '',
    status: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditTaskComponent
   */
  validationMessages = {
    responsibleMember: {
      required: 'Voer a.u.b. een Verantwoordelijke in.',
    },
    plannedOn: {
      matDatepickerParse: 'Foutieve datum',
    },
  };

  /**
   *  The subTheme of task
   *
   * @type                    {SubTheme}
   * @memberof                AddEditTaskComponent
   */
  subTheme: SubTheme;

  /**
   *  Current date
   *
   * @memberof                AddEditTaskComponent
   */
  today = new Date();

  /**
   * Creates an instance of AddEditTaskComponent.
   *
   * @param        {MatDialogRef<AddEditTaskComponent>}          dialogRefA              The instance of MatDialogRef
   * @param        {*}                                           data                    The data passed from parent component
   * @param        {RecurringTasksService}                       recurringTasksService
   * @param        {FormBuilder}                                 fbA
   * @param        {SharedService}                               sharedService           The instance of sharedService
   * @memberof     AddEditTaskComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private recurringTasksService: RecurringTasksService,
    private fbA: UntypedFormBuilder,
    public sharedService: SharedService,
  ) {
    super(dialogRefA, data, recurringTasksService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditTaskComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.subTheme = this.data.subTheme;

    this.createForm(this.initialFormValues);
  }

  /**
   *  Respond to the check/uncheck the checkbox
   *
   * @param       {*}                 event
   * @memberof    AddEditTaskComponent
   */
  onCheckBoxChange(event: MatCheckboxChange) {
    if (event instanceof Event) {
      event.stopImmediatePropagation();
    } else {
      const status = event.checked ? 2 : 0;

      this.newFormGroup.patchValue({ status: status });
    }
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @memberof    AddEditTaskComponent
   */
  onSubmit() {
    if (this.newFormGroup.get('plannedOn').value) {
      const plannedDate = moment(
        this.newFormGroup.get('plannedOn').value,
      ).format('YYYY-MM-DD');
      this.newFormGroup.patchValue({ plannedOn: plannedDate });
    }

    this.recurringTasksService.put(this.newFormGroup.value).subscribe(
      (newtask) => {
        this.formSubmitted = true;
        this.dialogStateChanged = true;

        this.dialogRef.close({ task: newtask });
      },

      () => {
        this.formSubmitted = false;
      },
    );
  }
}
