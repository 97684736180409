<!-- @format -->

<h4
  class="mat-subheading-1"
  *ngIf="selectedTeamGroup.selected[0]"
  i18n="teams-list list-title"
>
  Praktijken in groep {{ selectedTeamGroup.selected[0].name }}
</h4>
<h4
  class="mat-subheading-1"
  *ngIf="!selectedTeamGroup.selected[0]"
  i18n="teams-list list-title"
>
  Alle praktijken in de organisatie
</h4>

<div class="flex-rows filters-container">
  <div [formGroup]="filterForm">
    <mat-form-field
      appearance="fill"
      class="team-filter"
    >
      <mat-label>Zoek praktijk</mat-label>
      <input
        type="text"
        matInput
        formControlName="name"
      />
    </mat-form-field>
  </div>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="teams-list button add-new-team"
  >
    <mat-icon>add</mat-icon>
    Nieuwe praktijk
  </button>
</div>

<div class="teams-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="index">
      <mat-header-cell
        *matHeaderCellDef
        class="index-cell"
        i18n="teams-list list-header"
        >No.1</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row; let i = index"
        class="index-cell"
      >
        {{ i + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        i18n="teams-list list-header"
        >Praktijknaam</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="group">
      <mat-header-cell
        *matHeaderCellDef
        i18n="teams-list list-header"
        >Groep</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.group }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons extended-more"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons extended-more"
      >
        <button
          *ngIf="!isUserACoCoach"
          mat-icon-button
          color="accent"
          matTooltip="Gegevens vernieuwen voor innovatie grafiek"
          (click)="triggerGraphDataRefresh(row, 'innovatie')"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>autorenew</mat-icon>
        </button>
        <button
          *ngIf="!isUserACoCoach"
          mat-icon-button
          color="accent"
          matTooltip="Gegevens vernieuwen voor kwaliteit grafiek"
          (click)="triggerGraphDataRefresh(row, 'quality')"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>download</mat-icon>
        </button>
        <button
          *ngIf="!isUserACoCoach"
          mat-icon-button
          color="accent"
          matTooltip="Gegevens vernieuwen voor compliancy grafiek"
          (click)="triggerGraphDataRefresh(row, 'compliance')"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>check</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
