/** @format */

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { EmbeddedChild } from '../../shared/embedded-child';
import { YearPlanningSession } from '../../models/year-planning';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import * as moment from 'moment';

/**
 *  Show single planned session of the yearplan
 *
 * @export
 * @class PlannedSessionComponent
 * @extends {EmbeddedChild<YearPlanningSession>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-planned-session',
  templateUrl: './planned-session.component.html',
  styleUrls: ['./planned-session.component.scss'],
})
export class PlannedSessionComponent
  extends EmbeddedChild<YearPlanningSession>
  implements OnInit, OnChanges
{
  /**
   *  The year passed from the parent component
   *
   * @type {number}
   * @memberof PlannedSessionComponent
   */
  @Input() year: number;

  /**
   *  The min date of the session
   *
   * @type {string}
   * @memberof PlannedSessionComponent
   */
  minDate: string;

  /**
   *  The max date of the session
   *
   * @type {string}
   * @memberof PlannedSessionComponent
   */
  maxDate: string;

  /**
   *  The object of form validation errors.
   *
   * @memberof                PlannedSessionComponent
   */
  formErrors = {
    plannedDate: '',
  };

  /**
   *  The default values of the new Form.
   *
   * @memberof                PlannedSessionComponent
   */
  initialFormValues = <any>{
    plannedDate: ['', Validators.required],
    ready: false,
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                PlannedSessionComponent
   */
  validationMessages = {
    plannedDate: {
      required: 'Geplande datum is een verplicht veld.',
      matDatepickerParse: 'De waarde is niet correct',
      matDatepickerMax: 'De waarde is niet correct',
      matDatepickerMin: 'De waarde is niet correct',
    },
  };

  constructor(private fBuilder: UntypedFormBuilder) {
    super(fBuilder);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof PlannedSessionComponent
   */
  ngOnInit() {
    const itemCopy = Object.assign({}, this.editedItem);

    if (itemCopy) {
      this.initialFormValues = {
        plannedDate: [
          moment(itemCopy.plannedDate).format('YYYY-MM-DD HH:mm'),
          Validators.required,
        ],
        ready: itemCopy.ready,
        temporaryFields: {
          changed: itemCopy.temporaryFields
            ? itemCopy.temporaryFields.changed
            : false,
          removed: itemCopy.temporaryFields
            ? itemCopy.temporaryFields.removed
            : false,
          invalid: itemCopy.temporaryFields
            ? itemCopy.temporaryFields.invalid
            : false,
        },
      };
    }

    if (itemCopy.id) {
      this.initialFormValues.id = itemCopy.id;
    } else {
      this.initialFormValues.temporaryFields.isNew = true;
    }

    this.createForm(this.initialFormValues);
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof PlannedSessionComponent
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fBuilder.group(data);

    this.newFormGroup.valueChanges.debounceTime(500).subscribe((changes) => {
      if (
        this.newFormGroup.get('plannedDate') &&
        this.newFormGroup.get('plannedDate').value instanceof moment
      ) {
        this.newFormGroup.patchValue({
          plannedDate: this.newFormGroup
            .get('plannedDate')
            .value.format('YYYY-MM-DD HH:mm'),
        });
      }
      this.onFormValueChanged(changes);
    });
  }

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof PlannedSessionComponent
   */
  ngOnChanges(): void {
    if (this.year) {
      this.minDate = `${this.year}-01-01`;
      this.maxDate = `${this.year}-12-31`;
    }
  }
}
