<!-- @format -->

<mat-card
  class="mat-elevation-z0 example-card"
  *ngIf="improvementGroup"
  (click)="onGroupSelect()"
>
  <div class="vertical-line">
    {{ improvementGroup.prio }}
  </div>
  <div class="group-content status-{{ improvementGroup.status }}">
    <mat-card-header>
      <mat-card-title>
        <span class="group-name">{{ improvementGroup.scoreItemName }}</span>
        <span class="group-deadline">
          <mat-icon>calendar_month</mat-icon>
          {{ improvementGroup.deadline | date: 'dd-MM-yyyy' }}</span
        >
      </mat-card-title>
    </mat-card-header>
    <mat-card-content #content>
      {{ improvementGroup.reason }}
      <div
        class="ribbon"
        *ngIf="improvementGroup.status === 2"
        (click)="addToBestPractice($event)"
      >
        <div
          *ngIf="improvementGroup.hasBestPractice"
          matTooltip="Best practice"
          matTooltipPosition="left"
          class="is-best-practice"
        >
          <mat-icon aria-label="Best practice">stars</mat-icon>
        </div>
        <div
          *ngIf="!improvementGroup.hasBestPractice"
          i18-matTooltip="improvement-group button add-to-best-practice-button"
          matTooltip="Add to best practice"
          [matTooltipDisabled]="currentUserRights.organisationAdmin"
          matTooltipPosition="left"
          class="add-to-best-practice"
        >
          <mat-icon aria-label="Best practice">stars</mat-icon>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
