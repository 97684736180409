/** @format */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { malAuthService, malSetPasswordPageComponent } from '@iq/ng-auth';

/**
 *  Set password page
 *
 * @export
 * @class KmsSetPasswordPageComponent
 * @extends {malSetPasswordPageComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'kms-auth-set-password-page',
  templateUrl: './set-password-page.component.html',
  styleUrls: ['./set-password-page.component.scss'],
})
export class KmsSetPasswordPageComponent
  extends malSetPasswordPageComponent
  implements OnInit
{
  /**
   * Creates an instance of KmsSetPasswordPageComponent.
   *
   * @param       {Router}                    _router
   * @param       {malAuthService}            _auth
   * @param       {ActivatedRoute}            _rout
   * @memberof    KmsSetPasswordPageComponent
   */
  constructor(
    private _router: Router,
    private _auth: malAuthService,
    private _rout: ActivatedRoute,
  ) {
    super(_router, _auth, _rout);
  }
}
