<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-improvement-item-dialog dialog-title"
>
  Wijzig verbeteractie
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <div class="impr-item-container">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Verbeteractie</mat-label>
        <textarea
          matInput
          formControlName="description"
          required
          matTextareaAutosize
          matAutosizeMinRows="1"
          resizeToFitContent
        ></textarea>
        <mat-error [hidden]="!formErrors.description">{{
          formErrors.description
        }}</mat-error>
      </mat-form-field>
    </form>

    <div class="flex-improvement-item flex-rows">
      <form
        [formGroup]="newFormGroup"
        class="left-field"
      >
        <mat-form-field appearance="fill">
          <mat-label>Actiehouder</mat-label>
          <mat-select
            formControlName="responsibleMember"
            [compareWith]="compareFn"
            required
          >
            <mat-option
              *ngFor="
                let member of sharedService.currentTeamMembersList$ | async
              "
              [value]="member"
            >
              {{ member.memberName }}
            </mat-option>
          </mat-select>
          <mat-error [hidden]="!formErrors.responsibleMember">{{
            formErrors.responsibleMember
          }}</mat-error>
        </mat-form-field>
      </form>
    </div>

    <div class="flex-improvement-item flex-rows">
      <form
        [formGroup]="newFormGroup"
        class="left-field"
      >
        <mat-form-field appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select
            formControlName="status"
            required
          >
            <mat-option
              *ngFor="let status of improvementStatuses; let i = index"
              [value]="i"
            >
              {{ status }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Deadline</mat-label>
          <input
            type="text"
            matInput
            formControlName="periodEndDate"
            [matDatepicker]="picker"
            required
          />
          <mat-datepicker #picker></mat-datepicker>
          <mat-error [hidden]="!formErrors.periodEndDate">{{
            formErrors.periodEndDate
          }}</mat-error>
          <mat-icon (click)="picker.open()">calendar_month</mat-icon>
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
