/** @format */

import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
  Input,
} from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';

import {
  Incident,
  INCIDENT_ORIGIN,
  INCIDENT_STATUS,
  INCIDENT_RESPONSE_STATUS,
} from '../../models/incident';
import { IncidentsService } from '../../services/incidents.service';
import { Theme } from '../../models/theme';
import { Improvement } from '../../models/improvement';
import { Attachment } from '../../models/attachment';

/**
 *  Add/edit incident dialog.
 *
 * @export
 * @class AddEditIncidentComponent
 * @extends {AddEditAbstractDialog<Task>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-incident',
  templateUrl: './add-edit-incident.component.html',
  styleUrls: ['./add-edit-incident.component.scss'],
})
export class AddEditIncidentComponent
  extends AddEditAbstractDialog<Incident>
  implements OnInit, OnDestroy
{
  /**
   *  The list of attachment ids.
   *
   * @type {string[]}
   * @memberof AddEditIncidentComponent
   */
  attachmentIdsList: string[];

  /**
   *  The list of related attachments
   *
   * @type                  {Attachment[]}
   * @memberof              AddEditIncidentComponent
   */
  attachmentsList: Attachment[];

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditIncidentComponent
   */
  formErrors = {
    subject: '',
    description: '',
    theme: '',
    subTheme: '',
    internal: '',
    improvementIds: '',
    newLinkedAttachmentIds: '',
    newUnLinkedAttachmentIds: '',
    origin: '',
    reporterName: '',
    reporterPhone: '',
    reporterEmail: '',
    occurredOn: '',
    registeredOn: '',
    plannedOn: '',
    executioner: '',
    guard: '',
    conclusion: '',
    status: '',
    appealCommittee: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditIncidentComponent
   */
  validationMessages = {
    subject: {
      required: 'Onderwerp is een verplicht veld.',
    },
    reporterName: {
      required: 'Afzender is een verplicht veld.',
    },
    reporterEmail: {
      email: 'Geen geldig e-mailadres',
    },
    occurredOn: {
      required: 'Datum is een verplicht veld.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    registeredOn: {
      required: 'Ontvangstdatum is een verplicht veld.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    plannedOn: {
      required: 'Plandatum afgehandeld is een verplicht veld.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    executioner: {
      required: 'Uitvoerder is een verplicht veld.',
    },
  };

  /**
   *  The created incident
   *
   * @type                    {Incident}
   * @memberof                AddEditIncidentComponent
   */
  createdIncident: Incident;

  /**
   *  The Theme list of incident
   *
   * @type                    {Theme[]}
   * @memberof                AddEditIncidentComponent
   */
  currentThemesList: Theme[];

  /**
   *  The list of improvementIds linked to the parent component
   *
   * @type {string[]}
   * @memberof AddEditIncidentComponent
   */
  improvementIdsList: string[];

  /**
   * The list of attached improvements
   *
   * @type {Improvement[]}
   * @memberof AddEditIncidentComponent
   */
  improvementsList: Improvement[];

  /**
   *  The list of attachments linked to the parent object in the current session
   *
   * @type {string[]}
   * @memberof AddEditIncidentComponent
   */
  newLinkedAttachmentsList: string[];

  /**
   *  The list of attachments unlinked from the parent object in the current session
   *
   * @type {string[]}
   * @memberof AddEditIncidentComponent
   */
  newUnlinkedAttachmentsList: string[];

  /**
   *  The Theme list of incident with ids as keys
   *
   * @type                    {}
   * @memberof                AddEditIncidentComponent
   */
  themesListWithIds: {};

  /**
   *  The expansion panel step.
   *
   * @memberof                AddEditIncidentComponent
   */
  step = 0;

  /**
   *  Current date
   *
   * @memberof                AddEditIncidentComponent
   */
  today = new Date();

  /**
   *  The list of incident origins
   *
   * @memberof                AddEditIncidentComponent
   */
  incidentOrigins = INCIDENT_ORIGIN;

  /**
   *  The list of incident statuses
   *
   * @memberof                AddEditIncidentComponent
   */
  incidentStatuses = INCIDENT_STATUS;

  /**
   *  The list of incident response statuses
   *
   * @memberof                AddEditIncidentComponent
   */
  incidentResponseStatuses = INCIDENT_RESPONSE_STATUS;

  /**
   * Creates an instance of AddEditIncidentComponent.
   *
   * @param        {MatDialogRef<AddEditIncidentComponent>}      dialogRefA              The instance of MatDialogRef
   * @param        {*}                                           data                    The data passed from parent component
   * @param        {IncidentsService}                            incidentsService
   * @param        {FormBuilder}                                 fbA
   * @param        {SharedService}                               sharedService           The instance of sharedService
   * @memberof     AddEditIncidentComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditIncidentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private incidentsService: IncidentsService,
    private ref: ChangeDetectorRef,
    private fbA: UntypedFormBuilder,
    private mdialog: MatDialog,
    public sharedService: SharedService,
  ) {
    super(dialogRefA, data, incidentsService, fbA, mdialog);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditUserComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.warningDialogParameters.data.message = `Er zijn nog niet opgeslagen wijzigingen. Weet je zeker dat je wilt annuleren?`;

    this.currentThemesList = this.sharedService.currentThemesList.getValue();
    this.themesListWithIds = this.sharedService.currentThemesWithIds.getValue();

    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));

    this.newLinkedAttachmentsList = [];
    this.newUnlinkedAttachmentsList = [];

    if (!this.formIsNew && this.data.incident) {
      this.createdIncident = this.data.incident;

      this.incidentsService
        .getSingleItem(
          this.createdIncident.id,
          this.currentUserRights.currentTeamId,
        )
        .subscribe((response) => {
          this.improvementIdsList = response.improvementIds
            ? response.improvementIds.slice()
            : [];
          this.attachmentIdsList = response.attachmentIds
            ? response.attachmentIds.slice()
            : [];

          this.initialFormValues.improvementIds[0] = response.improvementIds;

          if (response._embedded && response._embedded.improvement) {
            this.improvementsList = response._embedded.improvement;
          } else {
            this.improvementsList = [];
          }

          if (response._embedded && response._embedded.attachment) {
            this.attachmentsList = response._embedded.attachment;
          } else {
            this.attachmentsList = [];
          }

          this.createForm(this.initialFormValues);

          if (this.data.formDisabled) {
            this.newFormGroup.disable();
          }
        });
    } else {
      this.improvementIdsList = [];
      this.attachmentIdsList = [];
      this.attachmentsList = [];
      this.createForm(this.initialFormValues);

      if (this.data.formDisabled) {
        this.newFormGroup.disable();
      }
    }
  }

  /**
   *  Set the step of the expansion panel.
   *
   * @param     {number}                    index
   * @memberof  AddEditIncidentComponent
   */
  setStep(index: number) {
    this.step = index;
  }

  /**
   *  Set the next step of the expansion panel.
   *
   * @memberof AddEditIncidentComponent
   */
  nextStep() {
    this.step++;
  }

  /**
   *  Set the previous step of the expansion panel.
   *
   * @memberof AddEditIncidentComponent
   */
  prevStep() {
    this.step--;
  }

  /**
   *  Respond to the unlink of the child item
   *
   * @param {*} item
   * @memberof AddEditIncidentComponent
   */
  onItemUnlink(item: Improvement) {
    if (item && item.id) {
      for (let i = 0; i < this.improvementIdsList.length; i++) {
        if (this.improvementIdsList[i] === item.id) {
          this.improvementIdsList.splice(i, 1);

          this.newFormGroup.patchValue({
            improvementIds: this.improvementIdsList,
          });

          this.childChanged = true;
          const index = this.improvementsList.indexOf(item);

          if (index > -1) {
            this.improvementsList.splice(index, 1);

            //  Workaround to make change detector working in the child component
            this.improvementsList = this.improvementsList.slice();
          }

          break;
        }
      }
    }
  }

  /**
   *  Link the item to the parent component
   *
   * @param {*} item
   * @memberof AddEditIncidentComponent
   */
  onItemLink(item: any) {
    if (item && item.id) {
      this.improvementIdsList.push(item.id);

      this.newFormGroup.patchValue({
        improvementIds: this.improvementIdsList,
      });

      this.childChanged = true;

      if (!this.improvementsList) {
        this.improvementsList = [item];
      } else {
        this.improvementsList.push(item);
      }

      //  Workaround to make change detector working in the child component
      this.improvementsList = this.improvementsList.slice();
    }
  }

  /**
   *  Link the item to the parent component
   *
   * @param {*} item
   * @memberof AddEditIncidentComponent
   */
  onAttachmentLink(item: any) {
    if (item && item.id) {
      this.newLinkedAttachmentsList.push(item.id);

      this.newFormGroup.patchValue({
        newLinkedAttachmentIds: this.newLinkedAttachmentsList,
      });

      this.childChanged = true;

      if (!this.attachmentsList) {
        this.attachmentsList = [item];
      } else {
        this.attachmentsList.push(item);
      }

      //  Workaround to make change detector working in the child component
      this.attachmentsList = this.attachmentsList.slice();
    }
  }

  /**
   *  Respond to the unlink of the child item
   *
   * @param {*} item
   * @memberof AddEditIncidentComponent
   */
  onAttachmentUnlink(item: Attachment) {
    if (item && item.id) {
      this.childChanged = true;

      this.newUnlinkedAttachmentsList.push(item.id);

      if (this.newFormGroup.get('newUnLinkedAttachmentIds')) {
        this.newFormGroup
          .get('newUnLinkedAttachmentIds')
          .patchValue(this.newUnlinkedAttachmentsList);
      }

      const index = this.attachmentsList.indexOf(item);

      if (index > -1) {
        this.attachmentsList.splice(index, 1);

        //  Workaround to make change detector working in the child component
        this.attachmentsList = this.attachmentsList.slice();
      }
    }
  }

  /**
   *  Respond to the change of subTheme in dropdown list
   *
   * @param {*} change
   * @memberof AddEditIncidentComponent
   */
  onSubThemeChange(change: any) {
    if (this.formIsNew) {
      const ownerships = this.sharedService.subThemesOwnershipList.getValue();

      if (change.value && change.value.id && ownerships[change.value.id]) {
        if (ownerships[change.value.id]._embedded.member) {
          this.newFormGroup.patchValue({
            guard: ownerships[change.value.id]._embedded.member,
          });
        } else if (ownerships[change.value.id]._embedded.members) {
          this.newFormGroup.patchValue({
            executioner: ownerships[change.value.id]._embedded.members[1],
            guard: ownerships[change.value.id]._embedded.members[0],
          });
        }

        this.ref.detectChanges();
      }
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof AddEditIncidentComponent
   */
  ngOnDestroy() {
    if (this.ref) {
      this.ref.detach();
    }
  }
}
