/** @format */

import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import {
  PMRITEM_STATUSES,
  PMRITEM_CONCLUSIONS,
  PMRItem,
} from '../../models/pmr-item';
import { PmrItemsService } from '../../services/pmr-items.service';
import { PMRImprovement } from '../../models/pmr-improvement';
import { UserValidatorService } from '../../services/user-validator.service';
import { Attachment } from '../../models/attachment';

/**
 *  The dialog for add/edit PMR item
 *
 * @export
 * @class AddEditPmrItemComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-pmr-item',
  templateUrl: './add-edit-pmr-item.component.html',
  styleUrls: ['./add-edit-pmr-item.component.scss'],
})
export class AddEditPmrItemComponent
  extends AddEditAbstractDialog<PMRItem>
  implements OnInit, OnDestroy {
  /**
   *  The list of attachment ids.
   *
   * @type {string[]}
   * @memberof AddEditPmrItemComponent
   */
  attachmentIdsList: string[];

  /**
   *  The list of related attachments
   *
   * @type                  {Attachment[]}
   * @memberof              AddEditPmrItemComponent
   */
  attachmentsList: Attachment[];

  /**
   *  True/false if the form should be disabled
   *
   * @memberof AddEditPmrItemComponent
   */
  formDisabled = false;

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditPmrItemComponent
   */
  formErrors = {
    title: '',
    description: '',
    improvementIds: '',
    newLinkedAttachmentIds: '',
    newUnLinkedAttachmentIds: '',
    coRemark: '',
    conclusion: '',
    status: '',
  };

  /**
   *  The list of improvementIds linked to the parent component
   *
   * @type                  {string[]}
   * @memberof              AddEditPmrItemComponent
   */
  improvementIdsList: string[];

  /**
   *  The list of attachments linked to the parent object in the current session
   *
   * @type {string[]}
   * @memberof AddEditPmrItemComponent
   */
  newLinkedAttachmentsList: string[];

  /**
   *  The list of attachments unlinked from the parent object in the current session
   *
   * @type {string[]}
   * @memberof AddEditPmrItemComponent
   */
  newUnlinkedAttachmentsList: string[];

  /**
   *  The translations of pmr item statuses
   *
   * @memberof              AddEditPmrItemComponent
   */
  pmrItemStatuses = PMRITEM_STATUSES;

  /**
   *  The translations of pmr item conclusions
   *
   * @memberof                AddEditPmrItemComponent
   */
  pmrItemConclusions = PMRITEM_CONCLUSIONS;

  /**
   * The list of attached pmr improvements
   *
   * @type                    {PMRImprovement[]}
   * @memberof                AddEditPmrItemComponent
   */
  pmrImprovementsList: PMRImprovement[];

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditPmrItemComponent
   */
  validationMessages = {
    title: {
      required: 'Onderwerp is een verplicht veld.',
    },
  };

  /**
   * Creates an instance of AddEditPmrItemComponent.
   *
   * @param    {MatDialogRef<AddEditPmrItemComponent>} dialogRefA
   * @param    {*}                                     data
   * @param    {PmrItemsDefaultService}                pmrItemsService
   * @param    {FormBuilder}                           fbA
   * @memberof AddEditPmrItemComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditPmrItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pmrItemsService: PmrItemsService,
    private fbA: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private userValidatorService: UserValidatorService,
    private mdialog: MatDialog,
  ) {
    super(dialogRefA, data, pmrItemsService, fbA, mdialog);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditPmrItemComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.warningDialogParameters.data.message = `Er zijn nog niet opgeslagen wijzigingen. Weet je zeker dat je wilt annuleren?`;

    this.newLinkedAttachmentsList = [];
    this.newUnlinkedAttachmentsList = [];

    if (!this.formIsNew && this.data.pmrItem) {
      this.pmrItemsService
        .getSingleItem(
          this.data.pmrItem.id,
          this.data.pmrItem.teamId
            ? this.data.pmrItem.teamId
            : this.currentUserRights.currentTeamId,
        )
        .subscribe((pmritem) => {
          this.improvementIdsList = pmritem.improvementIds
            ? pmritem.improvementIds.slice()
            : [];
          this.attachmentIdsList = pmritem.attachmentIds
            ? pmritem.attachmentIds.slice()
            : [];

          this.initialFormValues.improvementIds[0] = pmritem.improvementIds;

          if (pmritem._embedded && pmritem._embedded.pmrimprovement) {
            this.pmrImprovementsList = pmritem._embedded.pmrimprovement;
          } else {
            this.pmrImprovementsList = [];
          }

          if (pmritem._embedded && pmritem._embedded.attachment) {
            this.attachmentsList = pmritem._embedded.attachment;
          } else {
            this.attachmentsList = [];
          }

          this.createPmrItemForm();
        });
    } else {
      this.improvementIdsList = [];
      this.attachmentIdsList = [];

      this.createPmrItemForm();
    }
  }

  #handleCheckboxAndCoRemark(): void {
    const currentMember = this.currentUserRights.currentMember;
    const isUserACoCoachOrCoAdmin = currentMember.teamRole.name === 'TEAM_GROUP_ADMIN' || currentMember.teamRole.name === 'ORG_COACH' || currentMember.teamRole.name === 'ORG_ADMIN' // ORG_COACH taken from jira, TEAM_GROUP_ADMIN taken from application storage
    isUserACoCoachOrCoAdmin ? this.newFormGroup.get('checkbox').enable() : this.newFormGroup.get('checkbox').disable()
    isUserACoCoachOrCoAdmin ? this.newFormGroup.get('coRemark').enable() : this.newFormGroup.get('coRemark').disable()
  }

  //hotfix - if the controls are disabled, form does not have the value and error with missing values comes from BE.
  enableCheckboxAndCoRemarks() {
    this.newFormGroup.get('coRemark').enable();
    this.newFormGroup.get('checkbox').enable()
  }

  /**
   *  Create the pmr item form and check the rights
   *
   * @memberof AddEditPmrItemComponent
   */
  createPmrItemForm() {
    this.createForm(this.initialFormValues);

    this.#handleCheckboxAndCoRemark();

    if (
      this.data.pmrArchived ||
      this.currentUserRights.organisationAdmin ||
      this.data.pmrItem.status === 3
    ) {
      this.formDisabled = true;
      this.newFormGroup.get('title').disable();
      this.newFormGroup.get('description').disable();
      this.newFormGroup.get('conclusion').disable();
      this.newFormGroup.get('coRemark').disable();
      this.newFormGroup.get('status').disable();
    }

    if (!this.currentUserRights.organisationAdmin) {
      this.newFormGroup.get('coRemark').disable();

      if (this.data.pmrItem.status === 2) {
        this.newFormGroup.get('status').enable();
      }
    } else if (
      this.currentUserRights.organisationAdmin &&
      this.data.pmrItem.status === 1
    ) {
      this.newFormGroup.get('coRemark').enable();
      this.newFormGroup.get('status').enable();
    }
  }

  /**
   *  Respond to the unlink of the child item
   *
   * @param {*} item
   * @memberof AddEditPmrItemComponent
   */
  onItemUnlink(
    item: any,
    compareField: string,
    idsList: string[],
    idsField: string,
  ) {
    if (item && item[compareField]) {
      if (compareField === 'improvementId') {
        for (let i = 0; i < idsList.length; i++) {
          if (idsList[i] === item[compareField]) {
            this.childChanged = true;

            idsList.splice(i, 1);

            if (this.newFormGroup.get(idsField)) {
              this.newFormGroup.get(idsField).patchValue(idsList);
            }

            const index = this.pmrImprovementsList.indexOf(item);

            if (index > -1) {
              this.pmrImprovementsList.splice(index, 1);

              //  Workaround to make change detector working in the child component
              this.pmrImprovementsList = this.pmrImprovementsList.slice();
            }
            break;
          }
        }
      } else {
        idsList.push(item[compareField]);

        if (this.newFormGroup.get(idsField)) {
          this.newFormGroup.get(idsField).patchValue(idsList);
        }

        const index = this.attachmentsList.indexOf(item);

        if (index > -1) {
          this.attachmentsList.splice(index, 1);

          //  Workaround to make change detector working in the child component
          this.attachmentsList = this.attachmentsList.slice();
        }
      }
    }
  }

  /**
   *  Link the item to the parent component
   *
   * @param {*} item
   * @memberof AddEditPmrItemComponent
   */
  onItemLink(item: any, idsList: string[], idsField: string) {
    if (item && item.id) {
      idsList.push(item.id);

      if (this.newFormGroup.get(idsField)) {
        this.newFormGroup.get(idsField).patchValue(idsList);
      }

      let tempItem = item;

      //  Improvements
      if (item && item.subject) {
        tempItem = {
          improvementId: item.id,
          improvementSubject: item.subject,
          responseStatus: item.responseStatus,
          copied: false,
          pmrTag: '',
          copiedArchived: false,
        };

        if (!this.pmrImprovementsList) {
          this.pmrImprovementsList = [tempItem];
        } else {
          this.pmrImprovementsList.push(tempItem);
        }

        this.pmrImprovementsList = this.pmrImprovementsList.slice();
      } else {
        if (!this.attachmentsList) {
          this.attachmentsList = [tempItem];
        } else {
          this.attachmentsList.push(tempItem);
        }

        //  Workaround to make change detector working in the child component
        this.attachmentsList = this.attachmentsList.slice();
      }
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof AddEditPmrItemComponent
   */
  ngOnDestroy() {
    if (this.ref) {
      this.ref.detach();
    }
  }
}
