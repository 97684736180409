<!-- @format -->

<mat-expansion-panel
  class="pmr-panel mat-elevation-z0"
  *ngIf="crudR"
>
  <mat-expansion-panel-header class="pmr-header">
    Periodieke Management Reviews
  </mat-expansion-panel-header>
  <app-pmr-list
    (selectedPmr)="onPmrChange($event)"
    [pmrItemChanged]="updatedPmrItem"
  ></app-pmr-list>
</mat-expansion-panel>

<div *ngIf="crudR">
  <h4 class="mat-subheading-1">PMR items</h4>

  <ng-container *ngIf="!pmrSelection?.hasValue()">
    Geen PMR geselecteerd, selecteer een PMR om items te zien.
  </ng-container>

  <ng-container *ngIf="pmrSelection?.hasValue()">
    <dl>
      <dt>PMR Titel:</dt>
      <dd>{{ pmrSelection.selected[0]?.title }}</dd>

      <dt>Periode:</dt>
      <dd>
        {{ pmrSelection.selected[0]?.beginTime | date: 'dd-MM-yyyy' }} tot
        {{ pmrSelection.selected[0]?.endTime | date: 'dd-MM-yyyy' }}
      </dd>

      <dt>Omschrijving:</dt>
      <dd>
        {{
          pmrSelection.selected[0]?.note ? pmrSelection.selected[0]?.note : ''
        }}
      </dd>
    </dl>

    <app-pmr-items-list
      [pmr]="pmrSelection.selected[0]"
      (pmrItemChanged)="onPmrItemChange($event)"
    >
    </app-pmr-items-list>
  </ng-container>
</div>
