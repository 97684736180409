<!-- @format -->

<div
  class="kms-list improvements-list improvements-list-panel"
  *ngIf="crudR"
>
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="subject">
      <mat-header-cell
        *matHeaderCellDef
        i18n="users-list list-header"
        >Titel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.subject }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        i18n="users-list list-header"
        class="role-cell"
        >Status</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-chip-list
          selectable="false"
          *ngIf="row.responseStatus || row.responseStatus === 0"
        >
          <mat-chip
            color="{{
              ['accent', 'warn', 'accent', 'warn'][row.responseStatus]
            }}"
            defaultColor="primary"
            disableRipple="true"
            selected="true"
            value="{{ row.responseStatus }}"
            class="improvement-response-status improvement-response-status-{{
              row.responseStatus
            }}"
          >
            {{ improvementResponseStatuses[row.responseStatus] }}
          </mat-chip>
        </mat-chip-list>
        <p *ngIf="!row.responseStatus && row.responseStatus !== 0">---</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>

        <button
          mat-icon-button
          color="warn"
          (click)="onUnlinkIntent(row, $event, deleteDialog)"
          *ngIf="crudD && !formDisabled"
        >
          <mat-icon>link_off</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
</div>

<ng-container *ngIf="!dataSource?.data?.length">
  <div class="table-placeholder">Geen verbetermaatregelen te tonen.</div>
</ng-container>

<div
  class="flex-rows"
  *ngIf="crudC && !formDisabled"
>
  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="incidents-list button add-new-incident"
  >
    <mat-icon>add</mat-icon>
    Nieuwe verbetermaatregel
  </button>
</div>

<div
  class="link-form-container"
  *ngIf="crudC && !formDisabled"
>
  <h4 class="mat-subheading-1 link-form-title">
    Bestaande verbetermaatregel toevoegen
  </h4>

  <form class="flex-rows">
    <mat-form-field
      appearance="fill"
      class="select-member"
    >
      <mat-label>Verbetermaatregel</mat-label>
      <input
        type="text"
        aria-label="Verbetermaatregel"
        matInput
        [matAutocomplete]="auto"
        [formControl]="filterControl"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let item of linkableItems | async"
          [value]="item"
          (click)="onOptionSelect(item)"
        >
          {{ item.subject }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button
      mat-flat-button
      color="accent"
      class="no-grow impr-item-button"
      (click)="onLinkIntent(selectedItem, $event)"
      [disabled]="!selectedItem"
      i18n="save button @@buttonSave"
    >
      <mat-icon>add_link</mat-icon>
      Toevoegen
    </button>
  </form>
</div>
