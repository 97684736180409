<!-- @format -->

<h1
  class="mat-h1"
  i18n="agenda page-title"
>
  Niet voldoende rechten
</h1>
<div class="main-content change-on-mobile">
  <div class="no-rights-info">
    Je hebt niet voldoende rechten om de inhoud te bekijken, neem contact op met
    de applicatiebeheerder.
  </div>
</div>
