/** @format */

import { Pipe, PipeTransform } from '@angular/core';

/**
 *  Transform the byte file size to the MB.
 *
 * @export
 * @class FileSizePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    if (size) {
      let sizeComputed = size;

      if (typeof sizeComputed !== 'number') {
        sizeComputed = parseInt(sizeComputed, 10);
      }

      let extension = 'B';

      if (sizeComputed > 1024) {
        sizeComputed = size / 1024;
        extension = 'KB';

        if (sizeComputed > 1024) {
          sizeComputed = sizeComputed / 1024;
          extension = 'MB';

          if (sizeComputed > 1024) {
            sizeComputed = sizeComputed / 1024;
            extension = 'GB';
          }
        }
      }

      return sizeComputed.toFixed(1) + extension;
    } else {
      return '--';
    }
  }
}
