/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UserValidatorService } from '../../services/user-validator.service';
import { Subscription } from 'rxjs';
import { UserRights } from '../../models/user-rights';
import { MatTabChangeEvent } from '@angular/material/tabs';

/**
 *  Main component for managing users
 *
 * @export
 * @class         UsersComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  /**
   *  The current user rights.
   *
   * @type            {UserRights}
   * @memberof        UsersComponent
   */
  userRights: UserRights;

  /**
   *  The list of subTabs shown within the component.
   *
   * @type            {Array}
   * @memberof        UsersComponent
   */
  subTabs: Array<any>;

  /**
   *  The subscription of currentUserRights$
   *
   * @type            {Subscription}
   * @memberof        UsersComponent
   */
  userRightsSubs: Subscription;

  /**
   *  The index of selected tab.
   *
   * @memberof        UsersComponent
   */
  selectedTabInd = 0;

  /**
   *  The index of shown Tab.
   *
   * @memberof        UsersComponent
   */
  shownTabInd = 0;

  /**
   *  Types of swipe actions.
   *
   * @memberof        UsersComponent
   */
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  isUserOfficeManagerOrQualityManager: boolean;

  /**
   * Creates an instance of UsersComponent.
   *
   * @param           {UserValidatorService}      userValidatorService      The instance of the UserValidatorService
   * @memberof        UsersComponent
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private ref: ChangeDetectorRef,
  ) { }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof UsersComponent
   */
  ngOnInit() {
    this.userRights = JSON.parse(localStorage.getItem('userRights'));

    this.isUserOfficeManagerOrQualityManager = this.userRights.currentMember.teamRole.name === 'OFFICE_MANAGER' || this.userRights.currentMember.teamRole.name === 'QUALITY_MANAGER';
    if (
      (!this.userRights.organisationAdmin &&
        this.userRights.isCurrentTeamAdmin) || this.isUserOfficeManagerOrQualityManager
    ) {
      this.shownTabInd = 1;
    }
    this.ref.detectChanges();

    this.userRightsSubs =
      this.userValidatorService.currentUserRights$.subscribe((userrights) => {
        this.userRights = userrights;

        if (
          (!this.userRights.organisationAdmin &&
            this.userRights.isCurrentTeamAdmin) || this.isUserOfficeManagerOrQualityManager
        ) {
          this.shownTabInd = 1;
        }
        this.ref.detectChanges();
      });
  }

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    UsersComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent) {
    this.selectedTabInd = event.index;
    this.shownTabInd = event.index;

    if (
      (!this.userRights.organisationAdmin &&
        this.userRights.isCurrentTeamAdmin) || this.isUserOfficeManagerOrQualityManager
    ) {
      this.shownTabInd = this.selectedTabInd + 1;
    }

    this.ref.detectChanges();
  }

  /**
   *  Respond to the swipe event on the content
   *
   * @param         {string}          eventType
   * @memberof      UsersComponent
   */
  onSwipe(eventType: string) {
    if (this.userRights.organisationAdmin || this.isUserOfficeManagerOrQualityManager) {
      if (eventType === this.SWIPE_ACTION.LEFT && this.selectedTabInd > 0) {
        this.selectedTabInd--;
        this.shownTabInd = this.selectedTabInd;
      } else if (
        eventType === this.SWIPE_ACTION.RIGHT &&
        this.selectedTabInd < 2
      ) {
        this.selectedTabInd++;
        this.shownTabInd = this.selectedTabInd;
      }
    } else if (
      (!this.userRights.organisationAdmin &&
        this.userRights.isCurrentTeamAdmin) || this.isUserOfficeManagerOrQualityManager
    ) {
      if (eventType === this.SWIPE_ACTION.LEFT && this.selectedTabInd === 1) {
        this.selectedTabInd--;
        this.shownTabInd = this.selectedTabInd + 1;
      } else if (
        eventType === this.SWIPE_ACTION.RIGHT &&
        this.selectedTabInd === 0
      ) {
        this.selectedTabInd++;
        this.shownTabInd = this.selectedTabInd + 1;
      }
    }

    this.ref.detectChanges();
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof UsersComponent
   */
  ngOnDestroy() {
    this.ref.detach();
    this.userRightsSubs.unsubscribe();
  }
}
