/** @format */

import { Member } from './member';
import { SubTheme } from './subtheme';
import { Theme } from './theme';
import { Executioner } from './executioner';
import { Team } from './team';
import { Attachment } from './attachment';

/**
 *  Default model for the Improvement
 *
 * @export
 * @class Improvement
 */
export class Improvement {
  /**
   *  The list of attachment ids attached to the parent
   *
   * @type            {string[]}
   * @memberof        Improvement
   */
  attachmentIds: string[];

  /**
   *  The internal id of the Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  id: string;

  /**
   *  The completed date of Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  completedOn: string;

  /**
   *  The conclusion
   *
   * @type            {string}
   * @memberof        Improvement
   */
  conclusion: string;

  /**
   *  The member who created the Improvement
   *
   * @type            {Member}
   * @memberof        Improvement
   */
  createdBy: Member;

  /**
   *  The descrpition of the Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  description: string;

  /**
   *  True/false if the improvement was efficient
   *
   * @type            {boolean}
   * @memberof        Improvement
   */
  efficient: boolean;

  /**
   *  The list of executioners
   *
   * @type {Executioner[]}
   * @memberof Improvement
   */
  executionerMembers: Executioner[];

  /**
   *  The guard member
   *
   * @type            {Member}
   * @memberof        Improvement
   */
  guard: Member;

  /**
   *  The comment of the guard
   *
   * @type            {string}
   * @memberof        Improvement
   */
  guardComment: string;

  /**
   *  The implementation date of Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  implementedOn: string;

  /**
   *  The measurement of Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  measurement: string;

  /**
   *  The list of new linked attachments to the parent
   *
   * @type            {string[]}
   * @memberof        Improvement
   */
  newLinkedAttachmentIds: string[];

  /**
   *  The list of new unlikend attachments from the parent
   *
   * @type                {string[]}
   * @memberof            Improvement
   */
  newUnLinkedAttachmentIds: string[];

  /**
   *  The planned date of Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  plannedOn: string;

  /**
   *  The registered date of Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  registeredOn: string;

  /**
   *  The status of the response ['0', '1', '2', '3']
   *
   * @type            {number}
   * @memberof        Improvement
   */
  responseStatus: number;

  /**
   *  The root cause of the improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  rootCause: string;

  /**
   *  The status of the Improvement ['0', '1', '2']
   *
   * @type            {number}
   * @memberof        Improvement
   */
  status: number;

  /**
   *  The subTheme of the Improvement
   *
   * @type            {SubTheme}
   * @memberof        Improvement
   */
  subTheme: SubTheme;

  /**
   *  The subject of the Improvement
   *
   * @type            {string}
   * @memberof        Improvement
   */
  subject: string;

  /**
   *  The Theme of the Improvement
   *
   * @type            {Theme}
   * @memberof        Improvement
   */
  theme: Theme;

  /**
   *  The _embedded of improvement
   *
   * @memberof        Improvement
   */
  _embedded: {
    /**
     *  The list of linked attachments
     *
     * @type {Attachment[]}
     */
    attachment?: Attachment[];

    /**
     *  The member who created improvement
     *
     * @type {Member}
     */
    createdBy: Member;

    /**
     *  The member who guards the improvement
     *
     * @type {Member}
     */
    guard: Member;

    /**
     *  The team
     *
     * @type {Team}
     */
    team: Team;
    // TODO this might not be needed? check
    checkbox: boolean;
    coRemarks: string;
    sunburstId: string;
  };
}

export const IMPROVEMENT_RESPONSE_STATUS = [
  'Normaal',
  'Overtijd',
  'Gesloten',
  'Te laat afgehandeld',
];

// export const IMPROVEMENT_STATUS = ['Gerapporteerd', 'Review CO', 'Review ready', 'Geannuleerd', 'Gesloten'];
// ['Gerapporteerd', 'Review CO', 'Review ready', 'Geannuleerd', 'Gesloten']; //new order
// ['Gerapporteerd', 'Geannuleerd', 'Gesloten', 'Review CO', 'Review ready']; //old order

export const NEW_ORDERED_IMPROVEMENT_STATUSES = [
  { statusName: 'Gerapporteerd', order: 0 },
  { statusName: 'Review CO', order: 3 },
  { statusName: 'Review ready', order: 4 },
  { statusName: 'Geannuleerd', order: 1 },
  { statusName: 'Gesloten', order: 2 },
]
