/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserValidatorService } from '../../services/user-validator.service';
import { Member } from '../../models/member';
import { User } from '../../models/user';
import { Subscription } from 'rxjs';
import { PracticeChangeService } from '../../services/practice-change.service';

/**
 *  The choosing team dialog component
 *
 * @export
 * @class ChooseTeamPanelComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-choose-team-panel',
  templateUrl: './choose-team-panel.component.html',
  styleUrls: ['./choose-team-panel.component.scss'],
})
export class ChooseTeamPanelComponent implements OnInit, OnDestroy {
  /**
   *  Array of members assigned to the currentUser.
   *
   * @type          {Array<Member>}
   * @memberof      ChooseTeamPanelComponent
   */
  membersList: Array<Member>;

  /**
   *  The currently selected user.
   *
   * @type          {User}
   * @memberof      ChooseTeamPanelComponent
   */
  currentUser: User;

  /**
   *  The subscription of currentUser.
   *
   * @type          {Subscription}
   * @memberof      ChooseTeamPanelComponent
   */
  currentUserSubscription: Subscription;

  selectedMember: Member;

  /**
   * Creates an instance of ChooseTeamPanelComponent.
   *
   * @param         {UserValidatorService}  userValidatorService  The instance of the userValidatorService
   * @param         {Router}                router                The instance of the Router object
   * @param         {ActivatedRoute}        route                 The instance of ActivatedRouter
   * @memberof      ChooseTeamPanelComponent
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private practiceChangeService: PracticeChangeService
  ) { }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      ChooseTeamPanelComponent
   */
  ngOnInit() {
    this.currentUserSubscription =
      this.userValidatorService.currentUser$.subscribe((user) => {
        if (user._embedded) {
          this.currentUser = user;
          this.membersList = user._embedded.members;
        }
      });
  }

  onMemberChoose($event) {
    this.selectedMember = $event;
  }

  /**
   *  Respond to the user submit intent.
   *
   * @memberof      ChooseTeamPanelComponent
   */
  onMemberSubmit(memberSelect: any) {
    this.practiceChangeService.onMemberSubmit(memberSelect);
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof    ChooseTeamPanelComponent
   */
  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }
}
