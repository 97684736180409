<!-- @format -->

<div
  class="current-pmr-container"
  *ngIf="crudC && !currentUserRights.organisationAdmin"
>
  <div
    class="no-pmr-container flex-rows"
    *ngIf="currentPMR === undefined"
  >
    <div>
      Er is geen actieve PMR aanwezig. Selecteer gearchiveerd, of maak een
      nieuwe PMR aan
    </div>
    <button
      mat-flat-button
      color="accent"
      class="no-grow"
      (click)="onEditIntent(undefined, $event)"
      i18n="pmr-list button add-new-pmr"
    >
      <mat-icon>add</mat-icon>
      Nieuwe PMR
    </button>
  </div>
</div>

<div class="flex-rows filters-container">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngIf="crudR"
  >
    <mat-expansion-panel-header class="filters-header">
      <mat-panel-title
        class="filters-title"
        i18n="pmr-list expansion-panel-title"
      >
        Filters
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div
        class="flex-rows"
        [formGroup]="filterForm"
      >
        <mat-form-field appearance="fill">
          <mat-label>Titel</mat-label>
          <input
            type="text"
            matInput
            formControlName="title"
          />
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          [ngClass]="{ 'constraint-violation': filterFormErrors.notValidDates }"
        >
          <mat-label>Begindatum</mat-label>
          <input
            type="text"
            matInput
            formControlName="beginDate"
            [matDatepicker]="pickerBegin"
            readonly
          />
          <mat-datepicker #pickerBegin></mat-datepicker>
          <mat-error [hidden]="!filterFormErrors.beginDate">{{
            filterFormErrors.beginDate
          }}</mat-error>
          <mat-icon (click)="pickerBegin.open()">calendar_month</mat-icon>
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          [ngClass]="{ 'constraint-violation': filterFormErrors.notValidDates }"
        >
          <mat-label>Einddatum</mat-label>
          <input
            type="text"
            matInput
            formControlName="endDate"
            [matDatepicker]="pickerEnd"
            readonly
          />
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-error [hidden]="!filterFormErrors.endDate">{{
            filterFormErrors.endDate
          }}</mat-error>
          <mat-icon (click)="pickerEnd.open()">calendar_month</mat-icon>
        </mat-form-field>
      </div>

      <div class="flex-rows">
        <mat-error [hidden]="!filterFormErrors.notValidDates"
          >{{ filterFormErrors.notValidDates }}
        </mat-error>

        <button
          mat-flat-button
          type="button"
          color="warn"
          class="no-grow"
          matBadge="8"
          matBadgePosition="after"
          matBadgeColor="accent"
          (click)="onFiltersClear()"
        >
          Reset filter
        </button>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>

<div
  class="pmrs-container"
  *ngIf="crudR"
>
  <div class="pmr-list kms-list">
    <mat-table
      [dataSource]="dataSource"
      matSort
    >
      <ng-container matColumnDef="title">
        <mat-header-cell
          *matHeaderCellDef
          i18n="pmrs-list list-header"
          >Titel</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.title }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="period">
        <mat-header-cell
          *matHeaderCellDef
          i18n="pmrs-list list-header"
          >Periode</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.beginTime | date: 'dd-MM-yyyy' }} -
          {{ row.endTime | date: 'dd-MM-yyyy' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="note">
        <mat-header-cell
          *matHeaderCellDef
          i18n="pmrs-list list-header"
          >Omschrijving</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="pmr-description"
        >
          {{ row.note }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="coEnabled">
        <mat-header-cell
          *matHeaderCellDef
          i18n="pmrs-list list-header"
          class="co-enabled-cell"
          >CO</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="co-enabled-cell"
        >
          <mat-icon *ngIf="row.coEnabled">done</mat-icon>
          <mat-icon *ngIf="!row.coEnabled">clear</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="archived">
        <mat-header-cell
          *matHeaderCellDef
          i18n="pmrs-list list-header"
          class="archived-cell"
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="archived-cell"
        >
          {{ row.archived ? '' : 'Actief' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CUD">
        <mat-header-cell
          *matHeaderCellDef
          class="cud-icons"
        >
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="cud-icons"
        >
          <button
            mat-icon-button
            color="accent"
            *ngIf="
              !row.archived && crudU && !currentUserRights.organisationAdmin
            "
            (click)="onEditIntent(row, $event)"
            [ngClass]="{ disabled: tableInEditMode.isEditMode }"
          >
            <mat-icon>create</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: columnsToDisplay; let i = index"
        (click)="onRowClick(row)"
        [ngClass]="{
          'selected': selection.isSelected(row),
          'has-error': row.hasError,
          'edit-mode': row.editMode,
          'active': !row.archived
        }"
      ></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [pageSize]="usersPageSetting.size"
      [length]="usersPageSetting.totalElements"
      (page)="pageRows($event)"
    ></mat-paginator>
  </div>
</div>
