<!-- @format -->

<h1
  class="mat-h1"
  i18n="improvements page-title"
>
  Verbetermaatregelen
</h1>

<div class="main-content">
  <nav
    mat-tab-nav-bar
    mat-stretch-tabs
    [disablePagination]="true"
    [tabPanel]="tabPanelImprovements"
  >
    <a
      mat-tab-link
      routerLink="improvements-overview"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      >Verbetermaatregelen</a
    >

    <a
      *ngIf="isUserACoCoachOrCoAdmin"
      mat-tab-link
      routerLink="improvements-review"
      routerLinkActive
      #rlc="routerLinkActive"
      [active]="rlc.isActive"
      >Overzicht verbetermaatregelen</a
    >

    <a
      mat-tab-link
      *ngIf="allowedToSee"
      routerLink="improvements-analysis"
      routerLinkActive
      #rlb="routerLinkActive"
      [active]="rlb.isActive"
      >Analyse verbetermaatregelen</a
    >
  </nav>

  <mat-tab-nav-panel
    #tabPanelImprovements
    [@routeAnimations]="prepareRoute(outlet)"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-tab-nav-panel>
</div>
