/** @format */

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { TeamGroupsListService } from '../../services/team-groups-list.service';
import { Subscription } from 'rxjs';
import { TeamGroup } from '../../models/team-group';
import { SelectionModel } from '@angular/cdk/collections';
import { UserValidatorService } from '../../services/user-validator.service';
import { MenuTogglerService } from '../../services/menu-toggler.service';

/**
 *  Show the tile list of team groups in the overview.
 *
 * @export
 * @class         TeamGroupsTileListComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-team-groups-tile-list',
  templateUrl: './team-groups-tile-list.component.html',
  styleUrls: ['./team-groups-tile-list.component.scss'],
})
export class TeamGroupsTileListComponent implements OnInit, OnDestroy {
  /**
   *  True/false if the team-groups tiles are visible
   *
   * @memberof        TeamGroupsTileListComponent
   */
  groupsVisible = true;

  /**
   *  The index of selected tab.
   *
   * @memberof        TeamGroupsTileListComponent
   */
  selectedTabInd = 0;

  /**
   *  The subscription of teamGroups
   *
   * @type                    {Subscription}
   * @memberof                TeamGroupsTileListComponent
   */
  teamGroupsSubscription: Subscription;

  /**
   *  The list of teamGroups
   *
   * @type                    {TeamGroup[]}
   * @memberof                TeamGroupsTileListComponent
   */
  teamGroupsList: TeamGroup[];

  /**
   *  The currently selected row in the TeamGroup table.
   *
   * @type                    {SelectionModel<TeamGroup>}
   * @memberof                TeamGroupsTileListComponent
   */
  selectedTeamGroup: SelectionModel<TeamGroup>;

  /**
   *  The subscription of selectedTeamGroup
   *
   * @type                    {Subscription}
   * @memberof                TeamGroupsTileListComponent
   */
  selectedTeamGroupSubs: Subscription;

  /**
   *  Creates an instance of TeamGroupsTileListComponent.
   *
   * @param         {SharedService}         sharedService
   * @param         {TeamGroupsListService} teamGroupsService
   * @memberof      TeamGroupsTileListComponent
   */

  headerTitle: TeamGroup;
  isFullView: boolean;

  constructor(
    private sharedService: SharedService,
    private teamGroupsService: TeamGroupsListService,
    private userValidatorService: UserValidatorService,
    private menuTogglerrService: MenuTogglerService,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TeamGroupsTileListComponent
   */
  ngOnInit() {
    this.teamGroupsSubscription = this.teamGroupsService
      .getAll(undefined, undefined, { name: 'asc' })
      .subscribe(
        (teamgroups) => {
          this.teamGroupsList = teamgroups.payload;
          this.sharedService.newSelectedTeamGroupsList(this.teamGroupsList);

          const teamGroupID =
            this.userValidatorService.currentUserRights.getValue().currentMember
              .team.teamGroupId;

          for (const teamgroup of this.teamGroupsList) {
            if (teamgroup.id === teamGroupID) {
              this.teamGroupsList.splice(
                0,
                0,
                this.teamGroupsList.splice(
                  this.teamGroupsList.indexOf(teamgroup),
                  1,
                )[0],
              );

              break;
            }
          }
        },

        () => {
          this.teamGroupsList = [];
          this.sharedService.newSelectedTeamGroupsList(this.teamGroupsList);
        },
      );

    this.selectedTeamGroup = this.sharedService.currentTeamGroup.getValue();
    this.selectedTeamGroupSubs = this.sharedService.currentTeamGroup$.subscribe(
      (teamgroup) => {
        this.selectedTeamGroup = teamgroup;
      },
    );
  }

  /**
   *  Responds to the user click event on the team group.
   *
   * @param       {any}                   row   The current row in the data table
   * @memberof    TeamGroupsTileListComponent
   */
  onGroupClick(group: any) {
    //  Change the title of the header
    if (this.headerTitle !== group) {
      this.headerTitle = group;
      this.toggleMenusForce();
    } else {
      this.headerTitle = null;
    }

    this.selectedTeamGroup.toggle(group);
    this.sharedService.newSelectedScoreTile(
      new SelectionModel<any>(true, null),
    );
    this.sharedService.newSelectedTeamGroup(this.selectedTeamGroup);
  }

  /**
   * Full view toggler
   *
   * @param group
   * @param change
   */
  toggleMenusForce(group?: any, change?: boolean) {
    if (change || !this.isFullView) {
      this.isFullView = !this.isFullView;

      this.menuTogglerrService.toggle();

      if (change) {
        this.toggleGroups();
      }
    }
  }

  /**
   *  Toggle groups bar visibility
   *
   * @memberof TeamGroupsTileListComponent
   */
  toggleGroups() {
    this.groupsVisible = !this.groupsVisible;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof TeamGroupsTileListComponent
   */
  ngOnDestroy() {
    this.teamGroupsSubscription.unsubscribe();
    this.selectedTeamGroupSubs.unsubscribe();
    this.selectedTeamGroup.clear();
    this.ref.detach();

    this.sharedService.newSelectedTeamGroup(this.selectedTeamGroup);
  }
}
