/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Main container for managing agendas
 *
 * @export
 * @class YearPlanningComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-year-planning',
  templateUrl: './year-planning.component.html',
  styleUrls: ['./year-planning.component.scss'],
})
export class YearPlanningComponent implements OnInit {
  /**
   *  Creates an instance of YearPlanningComponent.
   *
   * @memberof YearPlanningComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof YearPlanningComponent
   */
  ngOnInit() {}
}
