/** @format */

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { Theme } from '../../models/theme';
import { SubTheme } from '../../models/subtheme';
import { TeamGroup } from '../../models/team-group';
import { Team } from '../../models/team';
import { SelectionModel } from '@angular/cdk/collections';
import { SharedService } from '../../services/shared.service';
import { ScoreItemsListService } from '../../services/score-items-list.service';
import { Subscription } from 'rxjs';
import { ScoreItem } from '../../models/scoreitem';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { QueryList } from '@angular/core';
import * as _ from 'lodash';

/**
 *  Shows the average scores for specific content.
 *
 * @export
 * @class OverviewAverageScoresBlockComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-overview-average-scores-block',
  templateUrl: './overview-average-scores-block.component.html',
  styleUrls: ['./overview-average-scores-block.component.scss'],
})
export class OverviewAverageScoresBlockComponent
  implements OnChanges, OnInit, OnDestroy
{
  /**
   *  The teamGroup object sent by parent component.
   *
   * @type                {TeamGroup}
   * @memberof            OverviewAverageScoresBlockComponent
   */
  @Input() teamGroup: TeamGroup;

  /**
   *  The Team object sent by parent component.
   *
   * @type                {Team}
   * @memberof            OverviewAverageScoresBlockComponent
   */
  @Input() team: Team;

  /**
   *  The themes list sent by parent component.
   *
   * @type            {Theme[]}
   * @memberof        OverviewAverageScoresBlockComponent
   */
  @Input() themes: Theme[];

  /**
   *  The subThemes list sent by parent component.
   *
   * @type                {SubTheme[]}
   * @memberof            OverviewAverageScoresBlockComponent
   */
  @Input() subThemes: SubTheme[];
  @Input() arrTeam: SubTheme[];

  /**
   *  The scores sent by parent component.
   *
   * @memberof            OverviewAverageScoresBlockComponent
   */
  @Input() scores = {};

  /**
   *  The scores for scoreItems in the TeamGroup.
   *
   * @memberof            OverviewAverageScoresBlockComponent
   */
  @Input() scoreItemsScores: any;

  /**
   *  The list of scoreItems;
   *
   * @type                {ScoreItem[]}
   * @memberof            OverviewAverageScoresBlockComponent
   */
  scoreItemsList: ScoreItem[];

  /**
   *  The subscription of the score items list.
   *
   * @type                {Subscription}
   * @memberof            OverviewAverageScoresBlockComponent
   */
  scoreItemsListSubs: Subscription;

  /**
   *  The selected tile
   *
   * @memberof            OverviewAverageScoresBlockComponent
   */
  selectedTile: SelectionModel<any>;

  /**
   *  The selected tile subscirption
   *
   * @memberof            OverviewAverageScoresBlockComponent
   */
  selectedTileSubs: Subscription;

  /**
   *  The subThemes list from shared Service
   *
   * @memberof            OverviewAverageScoresBlockComponent
   */
  subThemesList: SubTheme[];

  /**
   *  The name of the block
   *
   * @type                {string}
   * @memberof            OverviewAverageScoresBlockComponent
   */
  blockName: string;

  /**
   *  The list of tiles within the block
   *
   * @memberof            OverviewAverageScoresBlockComponent
   */
  blockTiles = [];

  /**
   *  The position of current scroll
   *
   * @memberof OverviewAverageScoresBlockComponent
   */
  currentScrollPosition = 0;

  /**
   *  Mat tabs children view
   *
   * @type              {QueryList<MatTab>}
   * @memberof          OverviewAverageScoresBlockComponent
   */
  @ViewChildren(MatTab) matTabs: QueryList<MatTab>;

  /**
   * Creates an instance of OverviewAverageScoresBlockComponent.
   *
   * @param      {SharedService}                         sharedService           The instance of shared Service
   * @param      {ScoreItemsListService}                 scoreItemsListService
   * @param      {ElementRef}                            elRef
   * @param      {Renderer2}                             renderer
   * @memberof   OverviewAverageScoresBlockComponent
   */
  constructor(
    public sharedService: SharedService,
    private scoreItemsListService: ScoreItemsListService,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof OverviewAverageScoresBlockComponent
   */
  ngOnInit() {
    this.subThemesList = this.sharedService.subThemesList.getValue();
    this.selectedTile = new SelectionModel<any>(false, null);

    if (this.team && this.subThemes) {
      this.selectedTileSubs = this.sharedService.selectedScoreTile$.subscribe(
        (selectedtile) => {
          if (
            selectedtile.hasValue() &&
            selectedtile.selected[0].objectId === this.team.id
          ) {
            this.onTileClick(this.subThemes[0]);
          }
        },
      );
    }
  }

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof OverviewAverageScoresBlockComponent
   */
  ngOnChanges() {
    this.selectedTile = new SelectionModel<any>(false, null);

    if (this.teamGroup) {
      this.blockName = this.teamGroup.name;
    } else if (this.team) {
      this.blockName = this.team.name;
    }

    if (this.themes) {
      this.blockTiles = this.themes;
    } else if (this.subThemes) {
      this.blockTiles = [];

      for (const val of this.subThemes) {
        for (const item of val._embedded.subThemes) {
          this.blockTiles.push(item);
        }
      }
    }

    if (!this.scores) {
      this.scores = {};
    } else if (this.scores && this.blockTiles[0].themeId) {
      for (const tile of this.blockTiles) {
        this.scores[tile.id] = '';

        if (this.scores['themes']) {
          if (this.scores['themes'][tile.themeId]) {
            this.scores[tile.id] =
              this.scores['themes'][tile.themeId]['subThemes'][tile.id];
          }
        }
      }
    }

    this.scoreItemsList = [];
  }

  /**
   *  Responds to the user click event on the tile
   *
   * @param       {any}                   tile   The current tile
   * @memberof    OverviewAverageScoresBlockComponent
   */
  onTileClick(tile: any) {
    const objectId = () => {
      if (this.teamGroup) {
        return this.teamGroup.id;
      } else if (this.team) {
        return this.team.id;
      }
    };

    tile.objectId = objectId();

    this.selectedTile.toggle(tile);

    if (this.teamGroup && this.themes && this.selectedTile.hasValue()) {
      this.sharedService.newSelectedTeamGroup(
        new SelectionModel<TeamGroup>(false, [this.teamGroup]),
      );
      this.sharedService.newSelectedTheme(
        new SelectionModel<Theme>(
          true,
          this.themes.filter((theme) => theme.id === tile.id),
        ),
      );

      this.selectedTile.clear();
    } else if (this.team && this.subThemes && this.selectedTile.hasValue()) {
      this.scoreItemsListSubs = this.scoreItemsListService
        .getAll(undefined, undefined, undefined, [
          { name: 'subThemeId', value: tile.id },
          { name: 'teamId', value: this.team.id },
        ])
        .subscribe((scoreitems) => {
          this.scoreItemsList = scoreitems.payload;
        });
    } else if (this.team && this.themes && this.selectedTile.hasValue()) {
      this.sharedService.newSelectedScoreTile(this.selectedTile);

      this.sharedService.newSelectedTheme(
        new SelectionModel<Theme>(
          true,
          this.themes.filter((theme) => theme.id === tile.id),
        ),
      );
    } else if (
      this.teamGroup &&
      this.subThemes &&
      this.selectedTile.hasValue()
    ) {
      this.scoreItemsList = this.subThemesList[tile.id]._embedded.scoreItems;
    } else if (!this.selectedTile.hasValue()) {
      this.scoreItemsList = [];
      this.sharedService.newSelectedScoreTile(
        new SelectionModel<any>(false, null),
      );
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof OverviewAverageScoresBlockComponent
   */
  ngOnDestroy() {
    if (this.scoreItemsListSubs) {
      this.scoreItemsListSubs.unsubscribe();
    }
    if (this.selectedTileSubs) {
      this.selectedTileSubs.unsubscribe();
    }
  }
}
