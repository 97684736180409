/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Manage the tasks templates
 *
 * @export
 * @class TasksManagementComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-tasks-management',
  templateUrl: './tasks-management.component.html',
  styleUrls: ['./tasks-management.component.scss'],
})
export class TasksManagementComponent implements OnInit {
  /**
   * Creates an instance of TasksManagementComponent.
   *
   * @memberof TasksManagementComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TasksManagementComponent
   */
  ngOnInit() {}
}
