<!-- @format -->
<dl>
  <dt i18n="subtheme-owners-list list-header">Praktijk:</dt>
  <dd>
    {{ selectedTeam ? selectedTeam.name : currentUserRights.currentTeamName }}
  </dd>

  <dt i18n="subtheme-owners-list list-header">Praktijkgroep:</dt>
  <dd>
    {{
      selectedTeam
        ? selectedTeam.group
        : currentUserRights.currentMember.team.group
    }}
  </dd>
</dl>

<div class="flex-rows filters-container">
  <div class="actions-container">
    <ng-container
      *ngIf="
        (currentUserRights.organisationAdmin ||
          isUserOfficeManagerOrQualityManager) &&
        currentUserTeams
      "
    >
      <mat-form-field
        appearance="fill"
        class="team-select"
      >
        <mat-label>Verander praktijk</mat-label>
        <input
          type="text"
          aria-label="Change team"
          matInput
          [matAutocomplete]="auto"
          [formControl]="filterControl"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let team of filteredTeams | async"
            [value]="team.name"
            (click)="onTeamSelect(team)"
          >
            {{ team.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <button
      mat-flat-button
      color="accent"
      class="no-grow create-button"
      (click)="onEditIntent(undefined, $event)"
      i18n="yearplan-list button add-new-incident"
    >
      <mat-icon>add</mat-icon>
      Toevoegen nieuw thema
    </button>
  </div>
</div>

<div
  class="subtheme-owners-list-panel kms-list"
  *ngIf="dataSource.data.length"
>
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="themeName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="subtheme-owners-list list-header"
        class="hide-mobile"
        >Dienst</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        {{ row.theme.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subThemeName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="subtheme-owners-list list-header"
        >Thema</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <div
          class="mobile-text"
          *ngIf="mobileQuery.matches"
        >
          {{ row.themeName }}
        </div>
        <div>{{ row.subTheme?.name }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner1">
      <mat-header-cell
        *matHeaderCellDef
        i18n="subtheme-owners-list list-header"
      >
        <ng-container *ngIf="mobileQuery.matches"> Ownership </ng-container>
        <ng-container *ngIf="!mobileQuery.matches"> Bewaker </ng-container>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div
          class="owner1"
          i18n="subtheme-owners-list list-header"
        >
          <span *ngIf="mobileQuery.matches">1: </span>
          <span>{{ row.owner1?.id ? row.owner1.memberName : '-' }}</span>
        </div>
        <div
          *ngIf="mobileQuery.matches"
          class="owner2"
          i18n="subtheme-owners-list list-header"
        >
          2: {{ row.owner2?.id ? row.owner2.memberName : '-' }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner2">
      <mat-header-cell
        *matHeaderCellDef
        i18n="subtheme-owners-list list-header"
        class="hide-mobile"
        >Uitvoerder</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        {{ row.owner2?.id ? row.owner2.memberName : '-' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons ownership-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons ownership-icons"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
</div>
