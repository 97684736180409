/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Theme } from '../../models/theme';
import { Subscription } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';

/**
 * The lamp container.
 *
 * @export
 * @class       TheLampComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-the-lamp',
  templateUrl: './the-lamp.component.html',
  styleUrls: ['./the-lamp.component.scss'],
})
export class TheLampComponent implements OnInit, OnDestroy {
  /**
   *  The index of selected tab.
   *
   * @memberof        TheLampComponent
   */
  selectedTabInd = 0;

  /**
   *  Types of swipe actions.
   *
   * @memberof        TheLampComponent
   */
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  /**
   *  The list of themes returned from the API
   *
   * @type            {Array<Theme>}
   * @memberof        TheLampComponent
   */
  themesList: Array<Theme>;

  /**
   *  The subscription of the themesList
   *
   * @type            {Subscription}
   * @memberof        TheLampComponent
   */
  themesListSubs: Subscription;

  /**
   * Creates an instance of TheLampComponent.
   *
   * @param     {ActivatedRoute}        route           The activated route
   * @memberof  TheLampComponent
   */
  constructor(
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TheLampComponent
   */
  ngOnInit() {
    this.themesListSubs = this.route.data.subscribe(
      (data: { themes: Theme[] }) => {
        this.themesList = data.themes;
      },
    );
  }

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    TheLampComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent) {
    this.selectedTabInd = event.index;
  }

  /**
   *  Respond to the swipe event on the content
   *
   * @param         {string}          eventType
   * @memberof      TheLampComponent
   */
  onSwipe(eventType: string) {
    if (eventType === this.SWIPE_ACTION.LEFT && this.selectedTabInd > 0) {
      this.selectedTabInd--;
    } else if (
      eventType === this.SWIPE_ACTION.RIGHT &&
      this.selectedTabInd < this.themesList.length - 1
    ) {
      this.selectedTabInd++;
    }

    this.ref.detectChanges();
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof TheLampComponent
   */
  ngOnDestroy(): void {
    this.themesListSubs.unsubscribe();
    console.log('Destroying the lamp...');
  }
}
