/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserRights } from '../../models/user-rights';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SharedService } from '../../services/shared.service';
import { TasksTemplateService } from '../../services/tasks-template.service';
import { UserValidatorService } from '../../services/user-validator.service';
import { navTabAnimation } from '../../functions/nav-tabs-animation';
import { RouterOutlet, Router } from '@angular/router';

/**
 *  Main component for managing tasks list
 *
 * @export
 * @class ActionsListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss'],
  animations: [navTabAnimation],
})
export class ActionsListComponent implements OnInit, OnDestroy {
  /**
   *  The current user rights.
   *
   * @type            {UserRights}
   * @memberof        ActionsListComponent
   */
  userRights: UserRights;

  /**
   *  The subscription of frequencies
   *
   * @type            {Subscription}
   * @memberof        ActionsListComponent
   */
  frequenciesSubs: Subscription;

  /**
   *  The index of selected tab.
   *
   * @memberof        ActionsListComponent
   */
  selectedTabInd = 0;

  /**
   * Creates an instance of ActionsListComponent.
   *
   * @param     {SharedService}        sharedService
   * @param     {TasksTemplateService} tasksTemplatesService
   * @memberof  ActionsListComponent
   */
  constructor(
    private sharedService: SharedService,
    private tasksTemplatesService: TasksTemplateService,
    private userValidatorService: UserValidatorService,
    private router: Router,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ActionsListComponent
   */
  ngOnInit() {
    this.userRights = this.userValidatorService.currentUserRights.getValue();

    if (this.userRights.organisationAdmin) {
      if (this.router.url === '/tasks-list/tasks') {
        this.router.navigate(['/tasks-list/task-templates']);
      }
    }

    this.frequenciesSubs = this.tasksTemplatesService
      .getFrequencies()
      .subscribe((frequencies) => {
        this.sharedService.newFrequenciesList(frequencies);
      });
  }

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    ActionsListComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent) {
    this.selectedTabInd = event.index;
  }

  /**
   * Check the animation name in the route data
   *
   * @param    {RouterOutlet} outlet
   * @returns
   * @memberof ActionsListComponent
   */
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof ActionsListComponent
   */
  ngOnDestroy(): void {
    this.frequenciesSubs.unsubscribe();
  }
}
