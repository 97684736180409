/** @format */

import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { RouterOutlet } from '@angular/router';
import { navTabAnimation } from '../../functions/nav-tabs-animation';

/**
 *  The main container for the improvements
 *
 * @export
 * @class ImprovementsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-improvements',
  templateUrl: './improvements.component.html',
  styleUrls: ['./improvements.component.scss'],
  animations: [navTabAnimation],
})
export class ImprovementsComponent implements OnInit {
  /**
   *  True/false if the user is allowed to see the analysis content
   *
   * @memberof ImprovementsComponent
   */
  allowedToSee = false;

  /**
   *  The rights of currently loggedin user
   *
   * @type {UserRights}
   * @memberof ImprovementsComponent
   */
  currentUserRights: UserRights;

  /**
   *  The index of selected tab.
   *
   * @memberof        ImprovementsComponent
   */
  selectedTabInd = 0;

  /**
   * Creates an instance of ImprovementsComponent.
   *
   * @memberof ImprovementsComponent
   */

  isUserACoCoachOrCoAdmin: boolean;

  constructor(private userValidatorService: UserValidatorService) { }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprovementsComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.isUserACoCoachOrCoAdmin = this.currentUserRights.currentMember.teamRole.name === 'TEAM_GROUP_ADMIN' || this.currentUserRights.currentMember.teamRole.name === 'ORG_COACH' || this.currentUserRights.currentMember.teamRole.name === 'ORG_ADMIN' // ORG_COACH taken from jira, TEAM_GROUP_ADMIN taken from application storage

    if (
      this.currentUserRights.currentMember &&
      this.currentUserRights.currentMember.teamRole
    ) {
      const perm = this.currentUserRights.currentMember.teamRole.permissions;

      if (perm && perm.indexOf('READ_ANALYSIS') > -1) {
        this.allowedToSee = true;
      }
    }
  }

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    ImprovementsComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent) {
    this.selectedTabInd = event.index;
  }

  /**
   * Check the animation name in the route data
   *
   * @param    {RouterOutlet} outlet
   * @returns
   * @memberof ImprovementsComponent
   */
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
