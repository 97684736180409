<!-- @format -->

<mat-card
  class="mat-elevation-z0"
  fxLayout="row"
  fxLayoutAlign="start none"
>
  <div
    class="kms-auth"
    fxFlex="50%"
  >
    <div
      class="kms-auth-form"
      *ngIf="form"
    >
      <h3
        class="mat-subheading-2"
        i18n="login-form form-title window@@auth.loginWindowTitle"
      >
        Login
      </h3>

      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label i18n="login-form form-field-label">E-mailadres</mat-label>
          <input
            type="email"
            matInput
            i18n-placeholder="login-form form-field-placeholder"
            placeholder="e-mail@domein.nl"
            formControlName="username"
            required
          />
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          class="mat-form-field-password"
        >
          <mat-label i18n="login-form form-field-label">Wachtwoord</mat-label>
          <input
            type="password"
            matInput
            i18n-placeholder="login-form form-field-placeholder"
            placeholder=""
            formControlName="password"
            required
          />
        </mat-form-field>

        <a
          class="gn-link gn-link-forgot"
          routerLink="/auth/password/forgot"
        >
          Wachtwoord vergeten?
        </a>

        <button
          type="submit"
          mat-flat-button
          color="primary"
          class="kms-btn-login"
          (click)="submit()"
          [disabled]="form.invalid || form.disabled"
          i18n="login button @@buttonLogin"
        >
          Login
        </button>
      </form>
    </div>
  </div>
</mat-card>
