/** @format */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 *  General component for error message dialog.
 *
 * @export
 * @class   ErrorDialogComponent
 */
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  /**
   * Creates an instance of ErrorDialogComponent.
   *
   * @param         {MatDialogRef<ErrorDialogComponent>}    dialogRef The MatDialogRef instance
   * @param         {*}                                             data      The data passed through the dialog
   * @memberof      ErrorDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}
