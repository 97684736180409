<!-- @format -->

<kms-auth-forgot-password-form
  (submitted)="onSubmit($event)"
  *ngIf="!requestSent"
>
</kms-auth-forgot-password-form>

<div
  class="request-password-confirmation-container"
  *ngIf="requestSent"
>
  <mat-card
    class="mat-elevation-z0"
    fxLayout="row"
    fxLayoutAlign="start none"
  >
    <div
      class="kms-auth"
      fxFlex="50%"
    >
      <a
        mat-flat-button
        color="primary"
        routerLink="/"
        class="action-retreat attached north-east"
      >
        Home
      </a>
      <div class="kms-auth-form">
        <h3
          class="mat-subheading-2"
          i18n="
            forgot-password-page page-title
            window@@auth.forgotPasswordWindowTitle"
        >
          Wachtwoord vergeten
        </h3>
        <div>
          <p
            i18n="
              forgot-password-page confirmation-message
              window@@auth.requestPasswordConfirmation"
            class="confirmation-text"
          >
            Indien het ingevulde e-mailadres bekend is, dan is daar een
            wachtwoord-reset-link naartoe verzonden.
          </p>

          <button
            mat-flat-button
            color="primary"
            routerLink="/auth/login"
            i18n="button go-back-to-login"
          >
            Ga terug naar login
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
