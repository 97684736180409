/** @format */

import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 *  Add range label to the paginator
 *
 * @param   {number}        page
 * @param   {number}        pageSize
 * @param   {number}        length
 * @returns
 */
const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 van ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.

  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} van ${length}`;
};

/**
 * Export paginator with dutch translations.
 *
 * @export
 * @returns
 */
export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Items per pagina:';
  paginatorIntl.nextPageLabel = 'Volgende pagina';
  paginatorIntl.previousPageLabel = 'Vorige pagina';
  paginatorIntl.getRangeLabel = dutchRangeLabel;

  return paginatorIntl;
}
