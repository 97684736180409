<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-pmr-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe PMR
</h2>
<h2
  class="title-text"
  i18n="add-edit-pmr-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen PMR
</h2>

<mat-dialog-content>
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Onderwerp</mat-label>
      <input
        type="text"
        matInput
        formControlName="title"
        required
      />
      <mat-error [hidden]="!formErrors.title">{{ formErrors.title }}</mat-error>
    </mat-form-field>
  </form>

  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Omschrijving</mat-label>
      <textarea
        matInput
        formControlName="note"
        matTextareaAutosize
        matAutosizeMinRows="5"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>

  <form [formGroup]="newFormGroup">
    <mat-checkbox
      appearance="fill"
      formControlName="coEnabled"
      labelPosition="after"
      >CO mag PMR volgen</mat-checkbox
    >
  </form>

  <div class="dates-container">
    <div class="flex-rows">
      <form
        [formGroup]="newFormGroup"
        class="left-field"
      >
        <mat-form-field
          appearance="fill"
          [ngClass]="{ 'constraint-violation': formErrors.notValidDates }"
        >
          <mat-label>Begindatum</mat-label>
          <input
            type="text"
            matInput
            formControlName="beginTime"
            [matDatepicker]="pickerStart"
            required
          />
          <mat-datepicker #pickerStart></mat-datepicker>
          <mat-error [hidden]="!formErrors.beginTime">{{
            formErrors.beginTime
          }}</mat-error>
          <mat-icon (click)="pickerStart.open()">calendar_month</mat-icon>
        </mat-form-field>
      </form>

      <form [formGroup]="newFormGroup">
        <mat-form-field
          appearance="fill"
          [ngClass]="{ 'constraint-violation': formErrors.notValidDates }"
        >
          <mat-label>Einddatum</mat-label>
          <input
            type="text"
            matInput
            formControlName="endTime"
            [matDatepicker]="pickerEnd"
          />
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-error [hidden]="!formErrors.endTime">{{
            formErrors.endTime
          }}</mat-error>
          <mat-icon (click)="pickerEnd.open()">calendar_month</mat-icon>
        </mat-form-field>
      </form>
    </div>
    <mat-error [hidden]="!formErrors.notValidDates">{{
      formErrors.notValidDates
    }}</mat-error>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
