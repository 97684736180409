<!-- @format -->

<div class="group-header">
  <div class="title">
    <span>{{ subTheme ? subTheme.name : '-' }} </span>
    <span
      class="description"
      i18n="dashboard-tasks-container tasks-count"
    >
      {tasksCount, plural, =1 {1 taak } other {{{tasksCount}} taken }
    }</span>
  </div>
</div>
<div class="group-content">
  <div class="cyclic-tasks group">
    <div
      class="content-title"
      i18n="dashboard-tasks group-name"
    >
      Actiepunten
    </div>
    <div class="tasks-container">
      <ng-container *ngIf="tasks?.length > 0">
        <app-task-tile
          *ngFor="let task of tasks"
          [task]="task"
          [groupEndDate]="today"
          [groupStartDate]="today"
        ></app-task-tile>
      </ng-container>
      <div
        *ngIf="!(tasks?.length > 0)"
        class="no-tasks"
        i18n="dashboard-tasks no-tasks-to-show"
      >
        Geen taken te tonen 
      </div>
    </div>
  </div>
  <div
    class="delayed-tasks group"
    *ngIf="currentSubThemeOwner || currentUserRights.isCurrentTeamAdmin"
  >
    <div
      class="content-title"
      i18n="dashboard-tasks group-name"
    >
      Taken te laat van het subthema
    </div>
    <div class="tasks-container">
      <ng-container *ngIf="delayedTasks?.length > 0">
        <app-task-tile
          *ngFor="let task of delayedTasks"
          [task]="task"
          [groupEndDate]="today"
          [groupStartDate]="today"
        ></app-task-tile>
      </ng-container>
      <div
        *ngIf="!(delayedTasks?.length > 0)"
        class="no-tasks"
        i18n="dashboard-tasks no-tasks-to-show"
      >
        Geen taken te tonen
      </div>
    </div>
  </div>
</div>
