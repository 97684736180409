<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-team-group-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe praktijkgroep toevoegen
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-team-group-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen praktijkgroep
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <div class="team-group-name">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Praktijkgroepnaam</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          required
        />
        <mat-error [hidden]="!formErrors.name">{{ formErrors.name }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>CO Coach</mat-label>
        <mat-select
          multiple
          formControlName="admins"
          [compareWith]="objectComparisonFunction"
        >
          <mat-option
            *ngFor="let coach of listOfCOCoaches"
            [value]="{
              emailAddress: coach.emailAddress,
              firstName: coach.firstName,
              lastName: coach.lastName,
              active: coach.active,
              organisationAdmin: coach.organisationAdmin,
              id: coach.id
            }"
          >
            {{ coach.firstName }} {{ coach.lastName }}
          </mat-option>
        </mat-select>
        <mat-error [hidden]="!formErrors.admins">{{
          formErrors.admins
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="createOrEditOverride()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
