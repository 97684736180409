<!-- @format -->

<div class="dates-container">
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Geplande datum</mat-label>
      <input
        type="text"
        matInput
        [matDatepicker]="pickerBegin"
        [min]="minDate"
        [max]="maxDate"
        formControlName="plannedDate"
        required
      />
      <mat-datepicker #pickerBegin></mat-datepicker>
      <mat-error [hidden]="!formErrors.plannedDate">{{
        formErrors.plannedDate
      }}</mat-error>
      <mat-icon (click)="pickerBegin.open()">calendar_month</mat-icon>
    </mat-form-field>
  </form>
</div>
