/** @format */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UsersListService } from '../../services/users-list.service';
import { MembersService } from '../../services/members.service';
import { UserValidatorService } from '../../services/user-validator.service';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { User } from '../../models/user';
import { SharedService } from '../../services/shared.service';
import { SnackbarService } from '@iq/ng-core';
import { CacheUpdateService } from '../../services/cache-update.service';

/**
 *  Dialog for edit/add the user
 *
 * @export
 * @class       AddEditUserComponent
 * @extends     {AddEditAbstractDialog<User>}
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent
  extends AddEditAbstractDialog<User>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditUserComponent
   */
  formErrors = { emailAddress: '', teamRole: '', firstName: '', lastName: '' };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditUserComponent
   */
  validationMessages = {
    emailAddress: {
      required: 'Emailadres is een verplicht veld.',
      email: 'Geen geldig e-mailadres',
    },
    teamRole: {
      required: 'Rol is een verplicht veld.',
    },
    firstName: {
      required: 'Voornaam is een verplicht veld.',
    },
    lastName: {
      required: 'Achternaam is een verplicht veld.',
    },
  };

  /**
   *  The form group
   *
   * @type                    {FormGroup}
   * @memberof                AddEditUserComponent
   */
  newFormGroup: UntypedFormGroup;

  /**
   *Creates an instance of AddEditUserComponent.
   * @param       {MatDialogRef<AddEditUserComponent>}      dialogRefA            The instance of MatDialogRef
   * @param       {*}                                       data                  The data passed from the parent
   * @param       {UsersListService}                        usersListService
   * @param       {MembersService}                          membersService
   * @param       {UserValidatorService}                    userValidatorService
   * @param       {FormBuilder}                             fbA
   * @memberof    AddEditUserComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersListService: UsersListService,
    private membersService: MembersService,
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
    private cacheUpdateService: CacheUpdateService,
    private fbA: UntypedFormBuilder,
    private snackBarService: SnackbarService,
  ) {
    super(dialogRefA, data, usersListService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditUserComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.createForm(this.initialFormValues);
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @memberof    AddEditUserComponent
   */
  onSubmit() {
    if (this.formIsNew) {
      this.newFormGroup.patchValue({
        user: {
          organisationAdmin: false,
          emailAddress: this.newFormGroup.value.emailAddress,
          firstName: this.newFormGroup.value.firstName,
          lastName: this.newFormGroup.value.lastName,
        },
      });

      const teamId = this.newFormGroup.value.team.id;

      this.usersListService
        .postUser(this.newFormGroup.value.user, teamId)
        .subscribe(
          (newuser) => {
            this.newFormGroup.patchValue({
              user: newuser,
            });

            this.membersService.post(this.newFormGroup.value).subscribe(
              (newmember) => {
                this.formSubmitted = true;
                this.dialogStateChanged = true;
                this.formIsNew = false;

                this.dialogRef.close({ changed: this.dialogStateChanged });

                if (
                  this.userValidatorService.currentUserRights.getValue()
                    .currentTeamId === teamId
                ) {
                  this.cacheUpdateService.updateTeamMembersList();
                }
              },

              (err) => {
                this.formSubmitted = false;

                if (err && err.status === 409) {
                  this.snackBarService.showSnackbar(
                    'Deze gebruiker bestaad reeds',
                  );
                }
              },
            );
          },

          (err) => {
            this.formSubmitted = false;
          },
        );
    } else {
      this.newFormGroup.patchValue({
        user: {
          organisationAdmin: this.newFormGroup.value.user.organisationAdmin,
          id: this.newFormGroup.value.user.id,
          emailAddress: this.newFormGroup.value.emailAddress,
          firstName: this.newFormGroup.value.firstName,
          lastName: this.newFormGroup.value.lastName,
          teamId: this.newFormGroup.value.teamId,
        },
      });

      this.usersListService
        .put(this.newFormGroup.value.user)
        .subscribe((updateduser) => {
          this.newFormGroup.patchValue({
            user: updateduser,
          });

          this.membersService.put(this.newFormGroup.value).subscribe(
            (updatedgroup) => {
              this.formSubmitted = true;
              this.dialogStateChanged = true;

              this.dialogRef.close({ changed: this.dialogStateChanged });
              if (
                this.userValidatorService.currentUserRights.getValue()
                  .currentTeamId === this.newFormGroup.value.teamId
              ) {
                this.cacheUpdateService.updateTeamMembersList();
              }
            },

            (err) => {
              this.formSubmitted = false;
            },
          );
        });
    }
  }
}
