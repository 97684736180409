/** @format */

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ScoreItem } from '../../models/scoreitem';
import { ScoreItemsListService } from '../../services/score-items-list.service';
import { SubTheme } from '../../models/subtheme';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';
import { Subscription } from 'rxjs';

/**
 *  The main component for managing the scoreItems list.
 *
 * @export
 * @class         IncidentsListComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-score-items-list',
  templateUrl: './score-items-list.component.html',
  styleUrls: ['./score-items-list.component.scss'],
})
export class ScoreItemsListComponent implements OnInit, OnDestroy {
  /**
   *  The subtheme value sent by parent component.
   *
   * @type            {SubTheme}
   * @memberof        ScoreItemsListComponent
   */
  @Input() subthemeItem: SubTheme;

  /**
   *  Rights of currentUser
   *
   * @type        {UserRights}
   * @memberof    ScoreItemsListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of scoreItems;
   *
   * @type                {ScoreItem[]}
   * @memberof            ScoreItemsListComponent
   */
  scoreItemsList: ScoreItem[];

  /**
   *  The subscription of the score items list.
   *
   * @type                {Subscription}
   * @memberof            ScoreItemsListComponent
   */
  scoreItemsListSubs: Subscription;

  /**
   *  mobile Query
   *
   * @memberof    ScoreItemsListComponent
   */
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: (change: any) => void;

  /**
   * Creates an instance of ScoreItemsListComponent.
   *
   * @param           {ScoreItemsListService}       scoreItemsListService     The score item list service.
   * @memberof        ScoreItemsListComponent
   */
  constructor(
    private scoreItemsListService: ScoreItemsListService,
    private userValidatorService: UserValidatorService,
    private media: MediaMatcher,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        ScoreItemsListComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.scoreItemsListSubs = this.scoreItemsListService
      .getAll(undefined, undefined, undefined, [
        { name: 'subThemeId', value: this.subthemeItem.id },
        { name: 'teamId', value: this.currentUserRights.currentTeamId },
      ])
      .subscribe((scoreitems) => {
        this.scoreItemsList = scoreitems.payload;
      });

    //  Change the menu mode if the screen size matches the media query
    this.mobileQuery = this.media.matchMedia('( max-width: 768px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof ScoreItemsListComponent
   */
  ngOnDestroy() {
    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.scoreItemsListSubs.unsubscribe();
  }
}
