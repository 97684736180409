/** @format */

import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PageSetting, MarviqMatTableInlineCrudComponent } from '@iq/ng-core';
import { BestPractice } from '../../models/best-practice';
import { BestPracticesService } from '../../services/best-practices.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { SharedService } from '../../services/shared.service';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AddBestPracticeComponent } from '../../dialogs/add-best-practice/add-best-practice.component';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BestPracticeLikeService } from '../../services/bestpractice-like.service';
import { BestPracticeLike } from '../../models/bestpractice-like';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';

/**
 *  Display an overview of best practices.
 *
 * @export
 * @class       BestPracticesListComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-best-practices-list',
  templateUrl: './best-practices-list.component.html',
  styleUrls: ['./best-practices-list.component.scss'],
})
export class BestPracticesListComponent
  extends MarviqMatTableInlineCrudComponent<BestPractice>
  implements OnInit, OnDestroy
{
  /**
   *  Best practice like model.
   *
   * @type                    {BestPracticeLike}
   * @memberof                BestPracticesListComponent
   */
  bestPracticeLike: BestPracticeLike = new BestPracticeLike();

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                BestPracticesListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                BestPracticesListComponent
   */
  columnsToDisplay = [
    'index',
    'proposedByMemberName',
    'teamName',
    'themes',
    'proposal',
    'likes',
    'CUD',
  ];

  /**
   *  The list of columns that will be displayed in the MatTable on mobile devices
   *
   * @memberof                BestPracticesListComponent
   */
  columnsToDisplayMobile = ['proposedByMemberName', 'teamName', 'themes'];

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                ImprovementItemComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The form group storing the bestPractice
   *
   * @type                    {FormGroup}
   * @memberof                BestPracticesListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                BestPracticesListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   *  mobile Query
   *
   * @memberof    BestPracticesListComponent
   */
  mobileQuery: MediaQueryList;

  /**
   *  The listener for mobileQuery changes
   *
   * @private
   * @memberof BestPracticesListComponent
   */
  private _mobileQueryListener: (change: any) => void;

  /**
   * Creates an instance of BestPracticesListComponent.
   *
   * @param        {BestPracticesService}          bestPracticesService
   * @param        {BestPracticeLikeService}       bestPracticeLikeService
   * @param        {UserValidatorService}          userValidatorService
   * @param        {SharedService}                 sharedService             The public instance of shared service.
   * @param        {MediaMatcher}                  media
   * @param        {ChangeDetectorRef}             ref
   * @param        {MatDialog}                     dg
   * @param        {FormBuilder}                   fbuild
   * @memberof     BestPracticesListComponent
   */
  constructor(
    private bestPracticesService: BestPracticesService,
    private bestPracticeLikeService: BestPracticeLikeService,
    private userValidatorService: UserValidatorService,
    public sharedService: SharedService,
    private media: MediaMatcher,
    private ref: ChangeDetectorRef,
    private dg: MatDialog,
    private addDialog: MatDialog,
    private fbuild: UntypedFormBuilder,
  ) {
    super(bestPracticesService, fbuild, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof          BestPracticesListComponent
   */
  ngOnInit() {
    this.dialogParameters.data.message = `Weet je zeker dat je deze best practice wilt verwijderen?`;

    this.filterForm = this.fbuild.group({
      username: '',
      teamName: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          this.filterValues[field] = changes[field];
        }

        this.getRows();
      });

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.getRows();

    //  Change the menu mode if the screen size matches the media query
    this.mobileQuery = this.media.matchMedia('( max-width: 768px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  /**
   *  Respond to the change of the selected value in the MatSelect list.
   *
   * @param         {MatSelectChange}       selected
   * @memberof      BestPracticesListComponent
   */
  onSelectChange(selected: MatSelectChange) {
    const themeIdsArray = [];

    for (const select of selected.value) {
      themeIdsArray.push(select.id);
    }

    if (themeIdsArray.length > 0) {
      this.queryParams = [{ name: 'themeIds', value: themeIdsArray }];
    } else {
      this.queryParams = [];
    }

    this.getRows();
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       row       The current row in the data table.
   * @param       [event]   The (`click`) event signalling the user's intent.
   * @memberof    BestPracticesListComponent
   */
  onEditIntent(row: any, event?: Event) {
    if (event) {
      event.stopImmediatePropagation();
    }

    const dialogRef = this.addDialog.open(AddBestPracticeComponent, {
      data: { improvementGroup: row.improvementGroup, bestPractice: row },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'impr-group-details-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Toggle the like/unlike after user click.
   *
   * @param         {BestPractice}            row
   * @memberof      BestPracticesListComponent
   */
  onLikeToggle(row: BestPractice) {
    if (row.likedByUser) {
      this.bestPracticeLikeService.delete(row).subscribe((resp) => {
        this.getRows();
      });
    } else {
      this.bestPracticeLike.bestPracticeId = row.id;

      this.bestPracticeLikeService
        .post(this.bestPracticeLike)
        .subscribe((resp) => {
          this.getRows();
        });
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof BestPracticesListComponent
   */
  ngOnDestroy() {
    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
