/** @format */

import { map } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import * as moment from 'moment';
import 'moment/locale/nl';
import { Subscription } from 'rxjs';
import { RecurringTasksService } from '../../services/recurring-tasks.service';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { PagedResult } from '@iq/ng-core';
import { Task } from '../../models/task';
import { SharedService } from '../../services/shared.service';
import { QueryParam } from '@iq/ng-core/src/app/models/query-param.model';

/**
 *  Display the list of tasks assigned to the user
 *
 * @export
 * @class         TasksOverviewComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-tasks-overview',
  templateUrl: './tasks-overview.component.html',
  styleUrls: ['./tasks-overview.component.scss'],
})
export class TasksOverviewComponent implements OnInit, OnDestroy {
  /**
   *  Rights of the current user
   *
   * @memberof                TasksOverviewComponent
   */
  frequencies = <any>[
    {
      name: 'Vandaag',
      date: moment().format('dddd, DD MMMM'),
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    },
    {
      name: 'Deze week',
      date: `${moment().startOf('isoWeek').format('DD MMMM')} - ${moment().endOf('isoWeek').format('DD MMMM')}`,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    },
    {
      name: 'Deze maand',
      date: moment().format('MMMM YYYY'),
      fromDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
      toDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    {
      name: 'Overige',
      fromDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
  ];

  /**
   *  Current refreshed task.
   *
   * @type                    {Task}
   * @memberof                TasksOverviewComponent
   */
  currentRefreshedTask: Task;

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                TasksOverviewComponent
   */
  currentUserRights: UserRights;

  /**
   *  The subscription of service
   *
   * @type                    {Subscription}
   * @memberof                TasksOverviewComponent
   */
  serviceSubscription: Subscription;

  /**
   *  The list of tasks to show.
   *
   * @type                    {Task[]}
   * @memberof                TasksOverviewComponent
   */
  tasksList: Task[];

  /**
   *  The list of query params send in the call.
   *
   * @type                    {QueryParam[]}
   * @memberof                TasksOverviewComponent
   */
  queryParams: QueryParam[];

  /**
   *  mobile Query
   *
   * @memberof                TasksOverviewComponent
   */
  mobileQuery: MediaQueryList;

  /**
   *  The listener for mobileQuery changes
   *
   * @private
   * @memberof                TasksOverviewComponent
   */
  private _mobileQueryListener: (change: any) => void;

  /**
   * Creates an instance of TasksOverviewComponent.
   *
   * @param     {MediaMatcher}          media
   * @param     {ChangeDetectorRef}     ref
   * @memberof  TasksOverviewComponent
   */
  constructor(
    private media: MediaMatcher,
    private ref: ChangeDetectorRef,
    private recurringTasksService: RecurringTasksService,
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TasksOverviewComponent
   */
  ngOnInit() {
    //  Change the menu mode if the screen size matches the media query
    this.mobileQuery = this.media.matchMedia('( max-width: 768px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];

    this.getTasks();

    //  Refresh tasks list when the task has been changed
    this.sharedService.refreshTasks$.subscribe((task) => {
      if (task.id && task !== this.currentRefreshedTask) {
        this.currentRefreshedTask = task;

        this.getTasks();
      }
    });
  }

  /**
   *  Get the list of member's tasks
   *
   * @memberof TasksGroupContainerComponent
   */
  getTasks() {
    this.serviceSubscription = this.recurringTasksService
      .getAll(undefined, undefined, { privateTask: 'asc' }, this.queryParams)
      .pipe(map((resp: PagedResult<Task>) => resp))
      .subscribe((result) => {
        const results = result.payload;

        this.tasksList = results;
      });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof TasksOverviewComponent
   */
  ngOnDestroy() {
    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.serviceSubscription.unsubscribe();
  }
}
