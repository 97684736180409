/**
 * Default model for the default pmr item
 *
 * @format
 * @export
 * @class DefaultPmrItem
 */

export class DefaultPmrItem {
  /**
   *  The description of PMR item
   *
   * @type {string}
   * @memberof DefaultPmrItem
   */
  description: string;

  /**
   *  The id of default pmr item
   *
   * @type {string}
   * @memberof DefaultPmrItem
   */
  id: string;

  /**
   *  The title of default pmr item
   *
   * @type {string}
   * @memberof DefaultPmrItem
   */
  title: string;

  /**
   *  The position in the sequenced list
   *
   * @type {number}
   * @memberof DefaultPmrItem
   */
  sequence: number;
}
