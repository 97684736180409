/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { Task } from '../models/task';

const apiPath = environment.apiPath;

/**
 *  Service for managing the recurring tasks.
 *
 * @export
 * @class RecurringTasksService
 * @extends {AbstractRestService<TasksTemplate>}
 */
@Injectable()
export class RecurringTasksService extends AbstractRestService<Task> {
  /**
   * Creates an instance of RecurringTasksService
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      RecurringTasksService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}recurring-tasks`, 'recurring-tasks');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Task>}
   * @memberof        RecurringTasksService
   */
  protected mapToModel(json: any): PagedResult<Task> {
    let payload = [];

    if (json._embedded) {
      payload = <Task[]>json._embedded.recurringTasks;
    }

    return new PagedResult<Task>(payload, <PageSetting>json.page);
  }
}
