/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';

/**
 *  Show the single card with numbers
 *
 * @export
 * @class DashboardSingleCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-dashboard-single-card',
  templateUrl: './dashboard-single-card.component.html',
  styleUrls: ['./dashboard-single-card.component.scss'],
})
export class DashboardSingleCardComponent implements OnInit {
  /**
   *  The card info sent from the parent component
   *
   * @type {*}
   * @memberof DashboardSingleCardComponent
   */
  @Input() cardInfo: any;

  /**
   *  The rights of currently logged in user
   *
   * @type {UserRights}
   * @memberof DashboardSingleCardComponent
   */
  currentUserRights: UserRights;

  /**
   * Creates an instance of DashboardSingleCardComponent.
   *
   * @param    {Router} router
   * @memberof DashboardSingleCardComponent
   */
  constructor(
    private router: Router,
    private userValidatorService: UserValidatorService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof DashboardSingleCardComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    //  For the future - consider if it's ok to send the guardId in the url
    //  this.updateMemberIds();
  }

  /**
   *  Respond to the click event on the single number
   *
   * @param {*} count
   * @memberof DashboardSingleCardComponent
   */
  updateMemberIds() {
    if (this.cardInfo) {
      for (const count of this.cardInfo.counts) {
        if (count.filters) {
          if (count.filters.guardId !== undefined) {
            count.filters.guardId = this.currentUserRights.currentMember.id;
          }
          if (count.filters.executionerId !== undefined) {
            count.filters.executionerId =
              this.currentUserRights.currentMember.id;
          }
        }
      }
    }
  }
}
