<!-- @format -->

<dl>
  <dt i18n="users-list list-header">Praktijk:</dt>
  <dd>
    {{ selectedTeam ? selectedTeam.name : currentUserRights.currentTeamName }}
  </dd>

  <dt i18n="users-list list-header">Praktijkgroep:</dt>
  <dd>
    {{
      selectedTeam
        ? selectedTeam.group
        : currentUserRights.currentMember.team.group
    }}
  </dd>
</dl>

<div class="flex-rows filters-container">
  <div>
    <ng-container
      *ngIf="
        (currentUserRights.organisationAdmin ||
          isUserOfficeManagerOrQualityManager) &&
        currentUserTeams
      "
    >
      <mat-form-field
        appearance="fill"
        class="team-select"
      >
        <mat-label>Verander praktijk</mat-label>
        <input
          type="text"
          aria-label="Change team"
          matInput
          [matAutocomplete]="auto"
          [formControl]="filterControl"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let team of filteredTeams | async"
            [value]="team.name"
            (click)="onTeamSelect(team)"
          >
            {{ team.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="users-list button add-new-user"
  >
    <mat-icon>add</mat-icon>
    Nieuwe gebruiker
  </button>
</div>

<div class="users-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="emailAddress">
      <mat-header-cell
        *matHeaderCellDef
        i18n="users-list list-header"
        >Emailadres</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.user?.emailAddress }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="users-list list-header"
        >Voornaam</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.user?.firstName ? row.user.firstName : '-' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="users-list list-header"
        >Achternaam</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.user?.lastName ? row.user.lastName : '-' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="teamRole">
      <mat-header-cell
        *matHeaderCellDef
        i18n="users-list list-header"
        class="role-cell"
        >Rol</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.teamRole?.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons extended"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons extended"
      >
        <ng-container *ngIf="!row.user?.active">
          <button
            mat-icon-button
            color="primary"
            matTooltip="Opnieuw verzenden van activeringsmail"
            matTooltipPosition="left"
            (click)="onResendActivation(row, $event)"
          >
            <mat-icon>email</mat-icon>
          </button>
        </ng-container>
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
