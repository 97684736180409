/** @format */

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { SunburstGraphService } from '../../services/sunburst-graph.service';
import { Subject } from 'rxjs';
import { CHART_TYPES, SingleGraphData } from '../../models/sunburst';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sunburst-graph',
  templateUrl: './sunburst-graph.component.html',
  styleUrls: ['./sunburst-graph.component.scss'],
})
export class SunburstGraphComponent implements OnInit, OnDestroy, OnChanges {
  @Input() chartId: CHART_TYPES;
  @Input() singleGraphData: SingleGraphData;
  @Input() isPresentationalGraph = true;

  #destroyRef = new Subject<void>();

  graphData = [
    {
      type: 'sunburst',
      labels: [],
      parents: [],
      outsidetextfont: {
        size: 20,
        color: '#FFFFFF',
        opacity: 1,
      },
      texttemplate: null,
      insidetextorientation: 'radial',
      textposition: 'inside',
      textinfo: 'labels+parent',
      leaf: { opacity: 1 },
      maxdepth: 4, // 3 ?
      opacity: 1,
      marker: {
        colors: [],
        line: { width: 5 },
      },
    },
  ];

  graphLayout = {
    margin: { l: 0, r: 0, b: 0, t: 0 },
    autosize: true,
    height: 0,
    hovermode: true,
    paper_bgcolor: '#f7f7f7', // $ink-gray-lighter-er
  };

  graphConfig = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['toImage'],
  };

  constructor(
    private plotlyService: PlotlyService,
    private sunburstGraphService: SunburstGraphService,
  ) {}

  #handleChartClick(): void {
    setTimeout(() => {
      const div = this.plotlyService.getInstanceByDivId(this.chartId);
      div.on('plotly_sunburstclick', (data) => {
        const { label, parent } = data.points[0];

        if (!this.isPresentationalGraph) {
          this.sunburstGraphService.constructTooltipData(
            parent,
            label,
            this.chartId,
          );
        }
        return false;
      });
    }, 0);
  }

  ngOnInit(): void {
    this.#handleChartClick();
    this.#setGraphHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.#passDataToGraph(
      !!changes.singleGraphData?.currentValue?.parents.length
        ? changes.singleGraphData?.currentValue
        : this.sunburstGraphService.generateEmptyGraphData(this.chartId),
    );
  }

  ngOnDestroy(): void {
    this.#destroyRef.next();
    this.#destroyRef.complete();
  }

  #passDataToGraph(data: SingleGraphData): void {
    this.graphData[0].labels = data.labels;
    this.graphData[0].parents = data.parents;
    this.graphData[0].marker.colors = data.colors;
    this.graphData[0].texttemplate = data.textTemplate;
    this.graphLayout.hovermode = false;

    if (this.isPresentationalGraph) {
      this.graphData[0].texttemplate = [data.labels[0]]; // replace with correct titles
    } else {
      this.graphLayout.margin = { ...this.graphLayout.margin, t: 60, b: 60 };
    }
  }

  #setGraphHeight(): void {
    this.sunburstGraphService.graphSize$
      .pipe(takeUntil(this.#destroyRef))
      .subscribe((val) => (this.graphLayout.height = val));
  }
}
