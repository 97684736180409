<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-improvement-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe verbetermaatregel toevoegen
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-improvement-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen verbetermaatregel
</h2>

<mat-dialog-content>
  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 0"
      (opened)="setStep(0)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Onderwerp
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="subject">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label i18n="add-edit-improvement-dialog form-field-label"
              >Onderwerp</mat-label
            >
            <input
              type="text"
              matInput
              formControlName="subject"
              required
            />
            <mat-error [hidden]="!formErrors.subject">{{
              formErrors.subject
            }}</mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="description">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label i18n="add-edit-improvement-dialog form-field-label"
              >Omschrijving</mat-label
            >
            <textarea
              matInput
              formControlName="description"
              matTextareaAutosize
              matAutosizeMinRows="1"
              resizeToFitContent
            ></textarea>
          </mat-form-field>
        </form>
      </div>
      <mat-action-row>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 1"
      (opened)="setStep(1)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Dienst/Thema
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-rows">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Dienst</mat-label>
            <mat-select
              formControlName="theme"
              [compareWith]="compareFn"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let theme of currentThemesList; let i = index"
                [value]="theme"
              >
                {{ theme.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Thema</mat-label>
            <mat-select
              formControlName="subTheme"
              [compareWith]="compareFn"
              (selectionChange)="onSubThemeChange($event)"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="
                  let subTheme of themesListWithIds[
                    newFormGroup.get('theme')?.value?.id
                  ]?._embedded?.subThemes;
                  let i = index
                "
                [value]="subTheme"
              >
                {{ subTheme?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 2"
      (opened)="setStep(2)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Maatregel
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Oorzaak analyse</mat-label>
          <textarea
            matInput
            formControlName="rootCause"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Maatregel</mat-label>
          <textarea
            matInput
            formControlName="measurement"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>
      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 3"
      (opened)="setStep(3)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Bewaker
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Bewaker</mat-label>
          <mat-select
            formControlName="guard"
            [compareWith]="compareFn"
          >
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="
                let member of sharedService.currentTeamMembersList$ | async
              "
              [value]="member"
            >
              {{ member.memberName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Toelichting Bewaking</mat-label>
          <textarea
            matInput
            formControlName="guardComment"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>
      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      #executionersExpPanel
      [expanded]="step === 4"
      (opened)="setStep(4)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Uitvoerders
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div
          class="executioners"
          *ngIf="executionersExpPanel.expanded"
        >
          <div class="children-expansion-container">
            <mat-accordion
              displayMode="flat"
              multi
            >
              <ng-container *ngIf="executionersList.length">
                <mat-expansion-panel
                  class="mat-elevation-z0 insertable-item"
                  #expPanel
                  *ngFor="let executioner of executionersList; let ind = index"
                  [ngClass]="{
                    'is-new': executioner.temporaryFields?.isNew,
                    'removed': executioner.temporaryFields?.removed,
                    'has-error': executioner.temporaryFields?.invalid
                  }"
                  [disabled]="
                    executioner.temporaryFields?.removed ||
                    createdImprovement?.status === 1 ||
                    (crudUpdate !== 'ALL' &&
                      executioner.memberId !== currentMember.id)
                  "
                  [expanded]="
                    executioner.temporaryFields?.expanded &&
                    (crudUpdate === 'ALL' ||
                      executioner.memberId === currentMember.id)
                  "
                  (opened)="executioner.expanded = true"
                  (closed)="executioner.expanded = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon *ngIf="executioner.temporaryFields?.invalid"
                        >error</mat-icon
                      >
                      <span>{{
                        executioner.memberName
                          ? executioner.memberName
                          : 'Uitvoerder ' + (ind + 1)
                      }}</span>
                      <div
                        class="cud-icons"
                        *ngIf="createdImprovement?.status !== 1"
                      >
                        <button
                          mat-icon-button
                          color="accent"
                          *ngIf="
                            (crudUpdate === 'ALL' || formIsNew) &&
                            executioner.temporaryFields?.removed
                          "
                          (click)="
                            onChildItemRestore(executioner, 0, $event, ind)
                          "
                        >
                          <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <button
                          mat-icon-button
                          color="warn"
                          *ngIf="
                            (crudUpdate === 'ALL' || formIsNew) &&
                            (!executioner.temporaryFields?.removed ||
                              executioner.temporaryFields?.isNew)
                          "
                          (click)="
                            onExecutionerDelete(
                              executioner,
                              0,
                              $event,
                              expPanel,
                              ind
                            )
                          "
                        >
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-template matExpansionPanelContent>
                    <app-executioner
                      [editedItem]="executioner"
                      [crudUpdate]="crudUpdate"
                      (itemChanged)="
                        onChildItemChange($event, 0, ind, expPanel)
                      "
                    >
                    </app-executioner>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>

          <form class="flex-rows">
            <mat-form-field
              appearance="fill"
              class="select-member"
            >
              <mat-label>Uitvoerder</mat-label>
              <input
                type="text"
                aria-label="Uitvoerder"
                matInput
                [matAutocomplete]="auto"
                [formControl]="filterControl"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let member of filteredMembers | async"
                  [value]="member"
                  (click)="onOptionSelect(member)"
                >
                  {{ member.memberName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <button
              mat-flat-button
              color="accent"
              class="no-grow"
              [disabled]="crudUpdate !== 'ALL' || !this.selectedMember"
              (click)="onChildItemAdd($event, 0)"
            >
              <mat-icon>person_add</mat-icon>
              Toevoegen
            </button>
          </form>
        </div>
      </ng-template>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 5"
      (opened)="setStep(5)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Datums
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="dates-container">
        <div class="flex-rows">
          <form [formGroup]="newFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Melddatum</mat-label>
              <input
                type="text"
                matInput
                formControlName="registeredOn"
                [matDatepicker]="pickerRegistered"
                required
              />
              <mat-datepicker #pickerRegistered></mat-datepicker>
              <mat-error [hidden]="!formErrors.registeredOn">{{
                formErrors.registeredOn
              }}</mat-error>
              <mat-icon (click)="pickerRegistered.open()"
                >calendar_month</mat-icon
              >
            </mat-form-field>
          </form>

          <form [formGroup]="newFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Implementatiedatum</mat-label>
              <input
                type="text"
                matInput
                formControlName="implementedOn"
                [matDatepicker]="pickerOccured"
                required
              />
              <mat-datepicker #pickerOccured></mat-datepicker>
              <mat-error [hidden]="!formErrors.implementedOn">{{
                formErrors.implementedOn
              }}</mat-error>
              <mat-icon (click)="pickerOccured.open()">calendar_month</mat-icon>
            </mat-form-field>
          </form>
        </div>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Evaluatiedatum</mat-label>
            <input
              type="text"
              matInput
              formControlName="plannedOn"
              [matDatepicker]="pickerPlanned"
              required
            />
            <mat-datepicker #pickerPlanned></mat-datepicker>
            <mat-error [hidden]="!formErrors.plannedOn">{{
              formErrors.plannedOn
            }}</mat-error>
            <mat-icon (click)="pickerPlanned.open()">calendar_month</mat-icon>
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 6"
      (opened)="setStep(6)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Status
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Status opvolging:</mat-label>
          <mat-chip-list
            disabled="true"
            selectable="false"
          >
            <mat-chip
              color="{{
                ['accent', 'warn', 'accent', 'warn'][
                  newFormGroup.get('responseStatus').value
                ]
              }}"
              defaultColor="primary"
              disableRipple="true"
              selected="true"
              value="{{ newFormGroup.get('responseStatus').value }}"
              class="incident-response-status incident-response-status-{{
                newFormGroup.get('responseStatus').value
              }}"
              >{{
                improvementsResponseStatuses[
                  newFormGroup.get('responseStatus').value
                ]
              }}</mat-chip
            >
          </mat-chip-list>
        </mat-form-field>
      </form>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let status of improvementStatuses; let i = index"
              [value]="status.order"
              [disabled]="
                (i === 2 && !isUserACoCoachOrCoAdmin) ||
                (i === 3 && !isUserACoCoachOrCoAdmin)
              "
            >
              {{ status.statusName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox
          appearance="fill"
          formControlName="checkbox"
          labelPosition="after"
          >Verbetermaatregel volstaat</mat-checkbox
        >

        <p
          class="sunburst-link-info"
          *ngIf="linkedToSunburstInfo"
        >
          {{ linkedToSunburstInfo }}
        </p>

        <mat-form-field appearance="fill">
          <mat-label>CO opmerkingen</mat-label>
          <textarea
            matInput
            formControlName="coRemark"
            matTextareaAutosize
            matAutosizeMinRows="5"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>
      <form [formGroup]="newFormGroup">
        <mat-checkbox
          appearance="fill"
          formControlName="efficient"
          labelPosition="after"
          >Effectief</mat-checkbox
        >
      </form>
      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 7"
      (opened)="setStep(7)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Nieuwe oplossing
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Nieuwe oplossing</mat-label>
          <textarea
            matInput
            formControlName="conclusion"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>
      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 8"
      (opened)="setStep(8)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Bijlage
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-attachments-list-embedded
        *ngIf="attachmentsList !== undefined"
        [listData]="attachmentsList"
        [formDisabled]="formDisabled"
        (itemLinked)="onAttachmentLink($event)"
        (itemUnlinked)="onAttachmentUnlink($event)"
      >
      </app-attachments-list-embedded>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel(true)"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>

  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit(null, graphCellId, graphType)"
    i18n="save button @@buttonSave"
    [disabled]="!(formChanged || childChanged) || newFormGroup.invalid"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
