<!-- @format -->

<div
  class="container"
  [ngClass]="{
    'container--opened': menuOpened,
    'container__dashboard': !isPracticeChooseAfterLogin,
    'container__login': isPracticeChooseAfterLogin
  }"
>
  <div
    class="inner-container"
    (click)="triggerMenu()"
  >
    <div class="practice">
      <ng-container
        *ngIf="practiceName; then activePractice; else selectedPractice"
      ></ng-container>
    </div>
  </div>
  <mat-icon (click)="triggerMenu()">{{
    menuOpened ? 'expand_less' : 'expand_more'
  }}</mat-icon>
  <div
    class="menu"
    [ngClass]="{ 'menu--opened': menuOpened }"
  >
    <div class="practice__list__wrapper">
      <mat-form-field
        appearance="fill"
        class="practice__search"
      >
        <mat-label>Zoeken</mat-label>
        <input
          type="text"
          matInput
          [formControl]="searchValueControl"
        />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>

      <div
        class="practice__list"
        [ngClass]="{
          practice__list__dashboard: !isPracticeChooseAfterLogin,
          practice__list__login: isPracticeChooseAfterLogin
        }"
      >
        <div
          class="practice__item"
          *ngFor="let member of filteredMembers$ | async"
          (click)="selectMember(member)"
        >
          {{ member.team.name }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #activePractice>
  <span>{{ practiceName }}</span>
</ng-template>

<ng-template #selectedPractice>
  <span>{{ selectedMember ? selectedMember.team.name : 'Praktijk*' }}</span>
</ng-template>
