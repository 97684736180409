<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-pmr-item-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuw PMR item
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-pmr-item-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen PMR item
</h2>

<mat-dialog-content *ngIf="formIsNew || improvementIdsList !== undefined">
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Onderwerp</mat-label>
      <input
        type="text"
        matInput
        formControlName="title"
        required
      />
      <mat-error [hidden]="!formErrors.title">{{ formErrors.title }}</mat-error>
    </mat-form-field>
  </form>

  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Omschrijving</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMinRows="5"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>

  <div class="flex-rows">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <ng-container *ngIf="!currentUserRights.organisationAdmin">
            <mat-option
              [value]="0"
              [disabled]="newFormGroup?.get('status')?.value === 3"
              >{{ pmrItemStatuses[0] }}
            </mat-option>
            <mat-option
              [value]="1"
              [disabled]="newFormGroup?.get('status')?.value === 3"
              >{{ pmrItemStatuses[1] }}
            </mat-option>
            <mat-option
              [value]="2"
              disabled
              >{{ pmrItemStatuses[2] }}</mat-option
            >
            <mat-option [value]="3">{{ pmrItemStatuses[3] }}</mat-option>
          </ng-container>
          <ng-container *ngIf="currentUserRights.organisationAdmin">
            <mat-option
              [value]="0"
              disabled
              >{{ pmrItemStatuses[0] }}</mat-option
            >
            <mat-option [value]="1">{{ pmrItemStatuses[1] }}</mat-option>
            <mat-option [value]="2">{{ pmrItemStatuses[2] }}</mat-option>
            <mat-option
              [value]="3"
              disabled
              >{{ pmrItemStatuses[3] }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
    </form>

    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Conclusie</mat-label>
        <mat-select formControlName="conclusion">
          <mat-option
            *ngFor="let conclusion of pmrItemConclusions; let i = index"
            [value]="i"
          >
            {{ conclusion }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <form [formGroup]="newFormGroup">
    <mat-checkbox
      appearance="fill"
      formControlName="checkbox"
      labelPosition="after"
      >PMR item volstaat</mat-checkbox
    >
  </form>

  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>CO opmerkingen</mat-label>
      <textarea
        matInput
        formControlName="coRemark"
        matTextareaAutosize
        matAutosizeMinRows="5"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>

  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-pmritem-dialog expansion-panel-title">
          Verbetermaatregelen
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-pmr-improvements-list-embedded
        *ngIf="pmrImprovementsList !== undefined"
        [formDisabled]="formDisabled"
        [listData]="pmrImprovementsList"
        (itemLinked)="onItemLink($event, improvementIdsList, 'improvementIds')"
        (itemUnlinked)="
          onItemUnlink(
            $event,
            'improvementId',
            improvementIdsList,
            'improvementIds'
          )
        "
      ></app-pmr-improvements-list-embedded>
    </mat-expansion-panel>

    <mat-expansion-panel class="attachments-panel mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-pmritem-dialog expansion-panel-title">
          Bijlage
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-attachments-list-embedded
        *ngIf="attachmentsList !== undefined"
        [listData]="attachmentsList"
        [formDisabled]="formDisabled"
        (itemLinked)="
          onItemLink($event, newLinkedAttachmentsList, 'newLinkedAttachmentIds')
        "
        (itemUnlinked)="
          onItemUnlink(
            $event,
            'id',
            newUnlinkedAttachmentsList,
            'newUnLinkedAttachmentIds'
          )
        "
      ></app-attachments-list-embedded>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
  *ngIf="formIsNew || improvementIdsList !== undefined"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel(true)"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="enableCheckboxAndCoRemarks(); onSubmit()"
    [disabled]="
      !(formChanged || childChanged) || newFormGroup.invalid || childInvalid
    "
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
