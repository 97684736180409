<!-- @format -->

<h1
  class="mat-h1"
  i18n="users page-title"
>
  Beheer
</h1>

<div class="main-content">
  <mat-tab-group
    mat-stretch-tabs
    *ngIf="userRights"
    [selectedIndex]="selectedTabInd"
    (selectedTabChange)="tabSelectionChanged($event)"
    (swipeleft)="onSwipe($event.type)"
    (swiperight)="onSwipe($event.type)"
  >
    <mat-tab
      i18n-label="users tab-name"
      label="Beheer praktijken"
      *ngIf="userRights.organisationAdmin"
    >
      <app-teams-management></app-teams-management>
    </mat-tab>

    <mat-tab
      i18n-label="users tab-name"
      label="Beheer gebruikers"
      *ngIf="
        userRights.isCurrentTeamAdmin ||
        userRights.organisationAdmin ||
        isUserOfficeManagerOrQualityManager
      "
    >
      <app-users-management></app-users-management>
    </mat-tab>

    <mat-tab
      i18n-label="users tab-name"
      label="Thema verantwoordelijken"
      *ngIf="
        userRights.isCurrentTeamAdmin ||
        userRights.organisationAdmin ||
        isUserOfficeManagerOrQualityManager
      "
    >
      <app-subtheme-owners-management></app-subtheme-owners-management>
    </mat-tab>
  </mat-tab-group>
</div>
