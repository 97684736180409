<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-task-dialog dialog-title"
>
  <mat-icon
    *ngIf="newFormGroup.get('privateTask').value"
    matTooltip="Private"
    >lock</mat-icon
  >Wijzig taak
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-task-dialog expansion-panel-title">
          Status
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form>
        <mat-checkbox
          appearance="fill"
          labelPosition="after"
          (change)="onCheckBoxChange($event)"
          >Gedaan</mat-checkbox
        >
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-task-dialog expansion-panel-title">
          Algemene informatie
        </mat-panel-title>
      </mat-expansion-panel-header>
      <h4
        class="mat-subheading-1"
        *ngIf="subTheme?.name"
      >
        {{ subTheme?.name }}
      </h4>
      <p>
        <span *ngIf="newFormGroup.get('title')?.value">{{
          newFormGroup.get('title')?.value
        }}</span>
        <small *ngIf="newFormGroup.get('description')?.value">
          ( {{ newFormGroup.get('description')?.value }} )</small
        >
      </p>
      <dl *ngIf="data?.relationInfo">
        <dt>Oorsprong:</dt>
        <dd>{{ data.relationInfo }}</dd>
      </dl>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-task-dialog expansion-panel-title">
          Verantwoordelijke
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Verantwoordelijke</mat-label>
          <mat-select
            formControlName="responsibleMember"
            required
            [compareWith]="compareFn"
          >
            <mat-option
              *ngFor="
                let member of sharedService.currentTeamMembersList$ | async
              "
              [value]="member"
            >
              {{ member.memberName }}
            </mat-option>
          </mat-select>
          <mat-error [hidden]="!formErrors.responsibleMember">{{
            formErrors.responsibleMember
          }}</mat-error>
        </mat-form-field>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-task-dialog expansion-panel-title">
          Planning
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="dates-container">
        <div class="flex-rows">
          <div>
            {{
              newFormGroup.get('periodBeginDate')?.value | date: 'dd-MM-yyyy'
            }}
          </div>

          <div>
            {{ newFormGroup.get('periodEndDate')?.value | date: 'dd-MM-yyyy' }}
          </div>
        </div>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Gepland datum</mat-label>
            <input
              type="text"
              matInput
              formControlName="plannedOn"
              [min]="newFormGroup.get('plannedOn')?.value ? undefined : today"
              [matDatepicker]="pickerStart"
              [readonly]="
                data.frequency?.unit === 'DAY' &&
                data.frequency?.frequencyValue === 1
              "
            />
            <mat-datepicker
              #pickerStart
              [disabled]="
                data.frequency?.unit === 'DAY' &&
                data.frequency?.frequencyValue === 1
              "
            ></mat-datepicker>
            <mat-error [hidden]="!formErrors.plannedOn">{{
              formErrors.plannedOn
            }}</mat-error>
            <mat-icon (click)="pickerStart.open()">calendar_month</mat-icon>
          </mat-form-field>
        </form>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-task-dialog expansion-panel-title">
          Opmerkingen
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Opmerkingen</mat-label>
          <textarea
            matInput
            formControlName="remarks"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
