<!-- @format -->

<ng-container *ngIf="subthemeItem">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngFor="let scoreItem of scoreItemsList; let i = index"
    [expanded]="!mobileQuery.matches"
  >
    <mat-expansion-panel-header
      collapsedHeight="40px"
      expandedHeight="40px"
    >
      <mat-panel-title>
        {{ i + 1 }}.<ng-container *ngIf="subthemeItem.name">
          {{ subthemeItem.name.toUpperCase() }} |</ng-container
        >
        {{ scoreItem.name.toUpperCase() }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <app-score-items-list-item
        [scoreItem]="scoreItem"
        [index]="i"
      ></app-score-items-list-item>
    </ng-template>
  </mat-expansion-panel>
</ng-container>
