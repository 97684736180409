<!-- @format -->

<div class="flex-rows filters-container">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngIf="crudR"
  >
    <mat-expansion-panel-header class="filters-header">
      <mat-panel-title
        class="filters-title"
        i18n="agenda-meetings-list expansion-panel-title"
      >
        Filter
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div
        class="flex-rows"
        [formGroup]="filterForm"
      >
        <mat-form-field appearance="fill">
          <mat-label>Titel</mat-label>
          <input
            type="text"
            matInput
            formControlName="title"
          />
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          [ngClass]="{ 'constraint-violation': formErrors.notValidDates }"
        >
          <mat-label>Begindatum</mat-label>
          <input
            type="text"
            matInput
            formControlName="beginDate"
            [matDatepicker]="pickerBegin"
            readonly
          />
          <mat-datepicker #pickerBegin></mat-datepicker>
          <mat-error [hidden]="!formErrors.beginDate">{{
            formErrors.beginDate
          }}</mat-error>
          <mat-icon (click)="pickerBegin.open()">calendar_month</mat-icon>
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          [ngClass]="{ 'constraint-violation': formErrors.notValidDates }"
        >
          <mat-label>Einddatum</mat-label>
          <input
            type="text"
            matInput
            formControlName="endDate"
            [matDatepicker]="pickerEnd"
            readonly
          />
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-error [hidden]="!formErrors.endDate">{{
            formErrors.endDate
          }}</mat-error>
          <mat-icon (click)="pickerEnd.open()">calendar_month</mat-icon>
        </mat-form-field>
      </div>

      <div class="flex-rows">
        <mat-error [hidden]="!formErrors.notValidDates">{{
          formErrors.notValidDates
        }}</mat-error>

        <button
          mat-flat-button
          type="button"
          color="warn"
          class="no-grow"
          matBadge="8"
          matBadgePosition="after"
          matBadgeColor="accent"
          (click)="onFiltersClear()"
        >
          Reset filter
        </button>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="agenda-meetings-list button add-new-incident"
    *ngIf="crudC"
  >
    <mat-icon>add</mat-icon>
    Nieuw overleg
  </button>
</div>

<div
  class="agenda-meetings-list-panel kms-list"
  *ngIf="crudR"
>
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="beginTime">
      <mat-header-cell
        *matHeaderCellDef
        i18n="agenda-meetings-list list-header"
        >Datum</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.beginTime | safariDateFormat | date: 'dd/MM/yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        i18n="agenda-meetings-list list-header"
        >Titel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        i18n="agenda-meetings-list list-header"
        >Status</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-chip-list
          selectable="false"
          *ngIf="row.status || row.status === 0"
        >
          <mat-chip
            disableRipple="true"
            value="{{ row.status }}"
            class="is-status meeting-status meeting-status-{{ row.status }}"
          >
            {{ meetingStatuses[row.status] }}
          </mat-chip>
        </mat-chip-list>
        <p *ngIf="!row.status && row.status !== 0">---</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="noteExist">
      <mat-header-cell
        *matHeaderCellDef
        i18n="agenda-meetings-list list-header"
        >Notulen</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="note-exist"
      >
        <ng-container *ngIf="row.noteExist">
          <mat-icon *ngIf="row.noteExist">done</mat-icon>
          <mat-icon
            (click)="openNotes(row)"
            class="open-notes"
            >notes</mat-icon
          >
        </ng-container>
        <mat-icon *ngIf="!row.noteExist">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
          *ngIf="crudD && row.status !== 1"
        >
          <mat-icon>unpublished</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          *ngIf="crudR || checkAttenderRights(row)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
