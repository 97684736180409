/** @format */

import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { ImprovementsListComponent } from '../improvements-list/improvements-list.component';
import { ImprovementsService } from '../../services/improvements.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Improvement } from '../../models/improvement';
import { startWith, map } from 'rxjs/operators';
import { PagedResult } from '@iq/ng-core';
import { Observable } from 'rxjs';
import { AddEditImprovementComponent } from '../../dialogs/add-edit-improvement/add-edit-improvement.component';

/**
 *  Show the embedded list of improvements
 *
 * @export
 * @class ImprovementsListEmbeddedComponent
 * @extends {ImprovementsListComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-improvements-list-embedded',
  templateUrl: './improvements-list-embedded.component.html',
  styleUrls: ['./improvements-list-embedded.component.scss'],
})
export class ImprovementsListEmbeddedComponent
  extends ImprovementsListComponent
  implements OnInit, OnDestroy, OnChanges {
  /**
   *  True/false if the link/unlik should be disabled
   *
   * @type                    {boolean}
   * @memberof                ImprovementsListEmbeddedComponent
   */
  @Input() formDisabled: boolean;

  /**
   *  The improvements list passed from the parent component
   *
   * @type                    {Improvement[]}
   * @memberof                ImprovementsListEmbeddedComponent
   */
  @Input() listData: any[];

  /**
   *  Bind the info to the parent when item is removed.
   *
   * @memberof ImprovementsListEmbeddedComponent
   */
  @Output() itemUnlinked = new EventEmitter<{}>();

  /**
   *  Bind the info to the parent when the item is linked
   *
   * @memberof ImprovementsListEmbeddedComponent
   */
  @Output() itemLinked = new EventEmitter<Improvement>();

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                ImprovementsListEmbeddedComponent
   */
  columnsToDisplay = ['subject', 'status', 'CUD'];

  /**
   *  Form Control of the filter teams
   *
   * @type                  {FormControl}
   * @memberof              ImprovementsListEmbeddedComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  The list of items that can be linked to the parent element
   *
   * @type {Improvement[]}
   * @memberof ImprovementsListEmbeddedComponent
   */
  linkableItems: Observable<Improvement[]>;

  /**
   *  The selected item from the linkable list
   *
   * @type {Improvement}
   * @memberof ImprovementsListEmbeddedComponent
   */
  selectedItem: Improvement;

  /**
   * Creates an instance of ImprovementsListEmbeddedComponent.
   *
   * @param    {ImprovementsService} improvementService
   * @param    {FormBuilder}         fbuilder
   * @param    {MatDialog}           dga
   * @memberof ImprovementsListEmbeddedComponent
   */
  constructor(
    private improvementService: ImprovementsService,
    private fbuilder: UntypedFormBuilder,
    private dga: MatDialog,
    public sharedServiceA: SharedService,
    private actRoute: ActivatedRoute,
    private route: Router,
    private ref?: ChangeDetectorRef,
  ) {
    super(improvementService, fbuilder, dga, sharedServiceA, actRoute, route);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprovementsListEmbeddedComponent
   */
  ngOnInit() {
    this.currentThemesList = this.sharedService.currentThemesList.getValue();

    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));

    this.queryParams.push({
      name: 'teamId',
      value: this.currentUserRights.currentTeamId,
    });

    this.queryParams.push({ name: 'status', value: 0 });

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.dialogParameters.data.message = `Weet je zeker dat je deze verbetermaatregel wilt ontkoppelen?`;

    this.checkPermissions();

    if (this.crudU && !this.formDisabled) {
      this.getLinkableItems();
    }
  }

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof ImprovementsListEmbeddedComponent
   */
  ngOnChanges() {
    this.dataSource.data = this.listData;

    if (this.filterControl) {
      this.filterControl.reset('');
    }
  }

  /**
   *  Respond to the link intent
   *
   * @param    {*}     [element]
   * @param    {Event} [event]
   * @memberof ImprovementsListEmbeddedComponent
   */
  onLinkIntent(value?: any, event?: Event) {
    if (value) {
      this.itemLinked.emit(value);
    } else {
      this.itemLinked.emit(this.selectedItem);
    }
  }

  /**
   *  Respond to the delete intent of the imrpovement item.
   *
   * @memberof ImprovementsListEmbeddedComponent
   */
  onUnlinkIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = row.subject;

    if (event) {
      event.stopImmediatePropagation();
    }

    const deleteDialogRef = this.dga.open(dialog, this.dialogParameters);
    deleteDialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }

      this.itemUnlinked.emit(row);
    });
  }

  /**
   *  Function that maps an option's control value to its display value in the trigger.
   *
   * @param    {Improvement} [item]
   * @returns  {(string | undefined)}
   * @memberof ImprovementsListEmbeddedComponent
   */
  displayFn(item?: Improvement): string | undefined {
    return item ? item.subject : undefined;
  }

  /**
   *  Get the list of linkable items
   *
   * @memberof ImprovementsListEmbeddedComponent
   */
  getLinkableItems() {
    this.serviceSubscription = this.improvementService
      .getAll(undefined, undefined, { createDate: 'desc' }, this.queryParams)
      .pipe(map((resp: PagedResult<Improvement>) => resp))
      .subscribe((result) => {
        let linkableitems = [];

        linkableitems = result.payload.filter(this.notYetLinked, this);

        this.linkableItems = this.filterControl.valueChanges.pipe(
          startWith(''),
          map((val) => {
            linkableitems = result.payload.filter(this.notYetLinked, this);

            if (typeof val === 'string' && val === '') {
              this.selectedItem = null;

              return linkableitems;
            } else if (typeof val === 'string' && val !== '') {
              return this.filterLinkableItems(linkableitems, val);
            } else if (typeof val === 'object' && val.subject) {
              return this.filterLinkableItems(linkableitems, val.subject);
            }
          }),
        );
      });
  }

  /**
   *  Filter the list of items.
   *
   * @param         {string}            val
   * @returns       {Improvement[]}
   * @memberof      ImprovementsListEmbeddedComponent
   */
  filterLinkableItems(itemsList: Improvement[], val: string): Improvement[] {
    return itemsList.filter(
      (option) =>
        option.subject.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
  }

  /**
   * Check if the member of team is already an executioner of current improvement
   *
   * @param {*} element
   * @param {*} index
   * @param {*} array
   * @memberof ImprovementsListEmbeddedComponent
   */
  notYetLinked(element: any, index: any, array: any) {
    let exsists = false;

    if (this.listData && this.listData.length) {
      for (const item of this.listData) {
        if (element.id === item.id) {
          exsists = true;

          break;
        }
      }
    }

    return !exsists;
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    ImprovementssListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = this.prepareEditedFormValues(row);

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dga.open(AddEditImprovementComponent, {
      data: { formValues: formValues, isNew: isNew, improvement: row },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        if (results.resp && results.resp.id && isNew) {
          this.onLinkIntent(results.resp);
        } else if (results.resp && results.resp.id && !isNew && this.listData) {
          for (let i = 0; i < this.listData.length; i++) {
            if (this.listData[i].id === results.resp.id) {
              this.listData[i] = results.resp;

              this.dataSource.connect().next(this.listData);
            }
          }
        }
      }
    });
  }

  /**
   *  Prepare the edited form values
   *
   * @param {*} row
   * @returns
   * @memberof ImprovementsListEmbeddedComponent
   */
  prepareEditedFormValues(row: any) {
    return {
      id: [row.id],
      subject: [row.subject, Validators.required],
      description: [row.description],

      theme: [
        row._embedded && row._embedded.theme ? row._embedded.theme : null,
      ],
      subTheme: [
        row._embedded && row._embedded.subTheme ? row._embedded.subTheme : null,
      ],

      rootCause: [row.rootCause],
      measurement: [row.measurement],

      guard: [
        row._embedded && row._embedded.guard ? row._embedded.guard : null,
      ],
      guardComment: [row.guardComment],
      executionerMembers: [
        row.executionerMembers ? row.executionerMembers : [],
      ],

      registeredOn: [row.registeredOn, Validators.required],
      implementedOn: [row.implementedOn, Validators.required],
      plannedOn: [row.plannedOn, Validators.required],

      efficient: [row.efficient],
      conclusion: [row.conclusion],

      status: [row.status],
      responseStatus: [row.responseStatus],
      teamId: this.currentUserRights.currentTeamId,
      coRemark: [row.coRemark],
      checkbox: [row.checkbox],
      sunburstId: [row.sunburstId]
    };
  }

  /**
   *  Respond to the selection of the linkable list
   *
   * @param       {Improvement}              option
   * @memberof    ImprovementsListEmbeddedComponent
   */
  onOptionSelect(option?: Improvement) {
    if (option && option.id) {
      this.selectedItem = option;
    } else {
      this.selectedItem = null;
    }
  }
}
