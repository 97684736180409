<!-- @format -->

<mat-card class="mat-elevation-z0 dashboard-card">
  <mat-card-header>
    <mat-card-title>{{ cardInfo?.label ? cardInfo.label : '' }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="cardInfo?.counts?.length">
      <div
        class="flex-container"
        fxLayout="row wrap"
        fxLayoutAlign="start none"
      >
        <div
          class="counts-container"
          *ngFor="let count of cardInfo.counts"
          fxFlex="50%"
        >
          <div
            class="card-count {{ count.cssClass }}"
            routerLink="/{{ cardInfo.url }}"
            [queryParams]="count.filters"
          >
            {{ count.value ?? 0 | number: '2.0-0' }}
          </div>
          <div>{{ count.label }}</div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
