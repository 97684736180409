/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { YearPlanningService } from '../../services/year-planning.service';
import { distinctUntilChanged, map, skip, takeUntil, tap } from 'rxjs/operators';
import { MarviqMatTableInlineCrudComponent, PagedResult } from '@iq/ng-core';
import { YearPlanning } from '../../models/year-planning';
import { Theme } from '../../models/theme';
import { UserRights } from '../../models/user-rights';
import { YearPlanningCategories } from '../../models/year-planning-categories';
import { YearPlanningCategoriesService } from '../../services/year-planning-categories.service';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AddEditYearEventComponent } from '../../dialogs/add-edit-year-event/add-edit-year-event.component';
import { UserValidatorService } from '../../services/user-validator.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';

/**
 *  Show the overview of yearplan items
 *
 * @export
 * @class YearPlanningDashboardComponent
 * @extends {MarviqMatTableInlineCrudComponent<YearPlanning>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-year-planning-dashboard',
  templateUrl: './year-planning-dashboard.component.html',
  styleUrls: ['./year-planning-dashboard.component.scss'],
})
export class YearPlanningDashboardComponent
  extends MarviqMatTableInlineCrudComponent<YearPlanning>
  implements OnInit, OnDestroy {
  /**
   *  The default values of the new Form.
   *
   * @memberof YearPlanningDashboardComponent
   */
  emptyFormValues = {
    actionPoint: [false],
    categoryName: [''],
    categoryId: ['', Validators.required],
    description: [''],
    executioners: [[]],
    notificationText: [''],
    plannedSessions: [[]],
    standard: [false],
    teamId: '',
    topic: ['', Validators.required],
    year: [new Date().getFullYear()],
  };

  /**
   *  The Theme list of incident
   *
   * @type {Theme[]}
   * @memberof YearPlanningDashboardComponent
   */
  currentThemesList: Theme[];

  /**
   *  The current user rights.
   *
   * @type {UserRights}
   * @memberof YearPlanningDashboardComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof YearPlanningDashboardComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof YearPlanningDashboardComponent
   */
  columnsToDisplay = [
    'topic',
    'executioners',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];

  /**
   *  Permissions to the specific item
   *
   * @memberof YearPlanningDashboardComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   *  The list of planning categories
   *
   * @type {YearPlanningCategories[]}
   * @memberof YearPlanningDashboardComponent
   */
  public planningCategories: YearPlanningCategories[];

  /**
   *  The expansion panel step.
   *
   * @memberof YearPlanningDashboardComponent
   */
  step = 0;

  /**
   *  Current date
   *
   * @memberof YearPlanningDashboardComponent
   */
  today = moment().format('YYYY-MM-DD');

  #isInitialCallForNextYearData = true;
  #destroySubject = new Subject<void>();

  constructor(
    private yearPlanningService: YearPlanningService,
    private yearPlanningCategoriesService: YearPlanningCategoriesService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    public sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userValidatorService: UserValidatorService,
    private ref: ChangeDetectorRef,
  ) {
    super(yearPlanningService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof YearPlanningDashboardComponent
   */
  ngOnInit() {
    this.yearPlanningService.resetCurrentlyChosenYear();

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.currentThemesList = this.sharedService.currentThemesList.getValue();

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
      this.queryParams.push({
        name: 'teamId',
        value: this.currentUserRights.currentTeamId,
      });
    }

    this.dialogParameters.data.message = `Weet je zeker dat je dit jaarlplan wilt verwijderen?`;

    this.checkPermissions();

    if (this.crudR) {
      this.yearPlanningCategoriesService
        .getAll()
        .pipe(map((resp: PagedResult<YearPlanningCategories>) => resp))
        .subscribe((result) => {
          this.planningCategories = result.payload;
        });

      this.getRows();
    }

    this.#watchYearInDropdownChanges();
    this.#watchCreateNewYearTrigger();
    this.#watchDeleteEvent();
  }

  #watchYearInDropdownChanges(): void {
    this.yearPlanningService.currentlyChosenYear$.pipe(skip(1), distinctUntilChanged(), tap(() => {
      this.getRows();
    }), takeUntil(this.#destroySubject)).subscribe()
  }

  #watchCreateNewYearTrigger(): void {
    this.yearPlanningService.createNewYearPlanTrigger$.pipe(tap(() => {
      this.#isInitialCallForNextYearData = true;
      this.yearPlanningService.resetCurrentlyChosenYear();
    }), takeUntil(this.#destroySubject)).subscribe()
  }

  //override for proper filtering items in categories
  #watchDeleteEvent(): void {
    this.yearPlanningService.wipeOutSourceDataAfterDelete$.pipe(tap(() => {
      const stepPlaceholder = this.step;
      this.step = null;
      setTimeout(() => {
        this.step = stepPlaceholder;
      }, 0)
    }), takeUntil(this.#destroySubject)).subscribe()
  }

  /**
   *  Check the current user permissions
   *
   * @memberof YearPlanningDashboardComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_YEARPLAN
      // DELETE_YEARPLAN
      // CREATE_YEARPLAN
      // UPDATE_YEARPLAN
      // READ_ALL_YEARPLAN
      // DELETE_ALL_YEARPLAN
      // UPDATE_ALL_YEARPLAN
      if (
        perm.permissions.indexOf('READ_YEARPLAN') > -1 ||
        perm.permissions.indexOf('READ_ALL_YEARPLAN') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_YEARPLAN') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_YEARPLAN') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_YEARPLAN') > -1) {
        this.crudC = true;
      }
      if (
        perm.permissions.indexOf('UPDATE_YEARPLAN') > -1 ||
        perm.permissions.indexOf('UPDATE_ALL_YEARPLAN') > -1
      ) {
        this.crudU = true;
        this.crudD = true;
      }
    }
  }

  /**
   *  Get the master data to the list
   *
   * @memberof YearPlanningDashboardComponent
   */
  getRows() {
    this.serviceSubscription = this.yearPlanningService
      .getAll(
        undefined,
        this.filterValues,
        { createDate: 'desc' },
        [...this.queryParams, { name: 'year', value: this.yearPlanningService.currentlyChosenYear$.getValue() }]
      )
      .pipe(map((resp: PagedResult<YearPlanning>) => resp))
      .subscribe((result) => {
        if (this.#isInitialCallForNextYearData && !result.payload.length) {
          this.#isInitialCallForNextYearData = false;

          this.yearPlanningService.nextYearDataPresent$.next(this.yearPlanningService.currentlyChosenYear$.getValue() - 1)
          this.yearPlanningService.currentlyChosenYear$.next(this.yearPlanningService.currentlyChosenYear$.getValue() - 1);
          return;
        } else if (this.#isInitialCallForNextYearData && result.payload.length) {
          this.#isInitialCallForNextYearData = false;

          this.yearPlanningService.nextYearDataPresent$.next(this.yearPlanningService.currentlyChosenYear$.getValue() - 1)
          this.yearPlanningService.nextYearDataPresent$.next(this.yearPlanningService.currentlyChosenYear$.getValue())
        }

        const results = result.payload;

        this.dataSource.data = results;

        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param {*} row       The current row in the data tabl
   * @param {Event} [event]   The (`click`) event signalling the user's intent.
   * @memberof YearPlanningDashboardComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        actionPoint: [row.actionPoint],
        categoryName: [row.categoryName],
        categoryId: [row.categoryId, Validators.required],
        description: [row.description],
        notificationText: [row.notificationText],
        standard: [row.standard],
        id: [row.id],
        executioners: [row.executioners ? row.executioners : []],
        plannedSessions: [row.plannedSessions ? row.plannedSessions : []],
        teamId: this.currentUserRights.currentTeamId,
        topic: [row.topic, Validators.required],
        year: [row.year ? row.year : new Date().getFullYear()],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditYearEventComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        yearPlanItem: row,
        canUpdate: this.crudU,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof            YearPlanningDashboardComponent
   */
  ngOnDestroy() {
    this.#destroySubject.next();
    this.#destroySubject.complete();

    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    if (this.ref) {
      this.ref.detach();
    }
  }

  /**
   *  Set the step of the expansion panel.
   *
   * @param {number} index
   * @memberof YearPlanningDashboardComponent
   */
  setStep(index: number, filter: any) {
    this.step = index;
    this.dataSource.filter = filter.trim().toLowerCase();

    this.ref.detectChanges();
  }
}
