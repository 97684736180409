<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-subtheme-ownership-dialog dialog-title"
>
  {{ modalTitle }}
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <h3 class="mat-subheading-2 subtheme-name">
    {{ initialFormValues.subTheme?.name }}
  </h3>
  <mat-error [hidden]="!formErrors.notValidMembers">{{
    formErrors.notValidMembers
  }}</mat-error>

  <div class="owner">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Dienst</mat-label>
        <input
          type="text"
          matInput
          formControlName="theme"
          required
        />
        <mat-error [hidden]="!formErrors.theme">{{
          formErrors.theme
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="owner">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Thema</mat-label>
        <input
          type="text"
          matInput
          formControlName="subTheme"
          required
        />
        <mat-error [hidden]="!formErrors.subTheme">{{
          formErrors.subTheme
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="owner">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Bewaker</mat-label>
        <mat-select
          formControlName="owner1"
          [compareWith]="compareFn"
        >
          <mat-option
            *ngFor="let member of currentTeamMembers"
            [value]="member"
          >
            {{ member.memberName }}
          </mat-option>
        </mat-select>
        <mat-error [hidden]="!formErrors.owner1">{{
          formErrors.owner1
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="owner">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Uitvoerder</mat-label>
        <mat-select
          formControlName="owner2"
          [compareWith]="compareFn"
        >
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let member of currentTeamMembers"
            [value]="member"
          >
            {{ member.memberName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid || buttonDisabledOnSubmit"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
