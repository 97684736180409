<!-- @format -->

<div class="kms-list files-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Titel
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Categorie
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{
        row.category ? row.category : '--'
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons extended"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons extended"
      >
        <ng-container>
          <button
            mat-icon-button
            color="primary"
            (click)="onFileDownload(row, $event, true)"
          >
            <mat-icon>cloud_download</mat-icon>
          </button>
          <a
            class="download-link"
            #downloadLink
            disabled
          ></a>

          <button
            mat-icon-button
            color="accent"
            (click)="onEditIntent(row, $event)"
            [ngClass]="{ disabled: tableInEditMode.isEditMode }"
          >
            <mat-icon>create</mat-icon>
          </button>

          <button
            mat-icon-button
            color="warn"
            (click)="onUnlinkIntent(row, $event, deleteDialog)"
            *ngIf="!formDisabled"
          >
            <mat-icon>link_off</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
</div>

<ng-container *ngIf="!dataSource?.data?.length">
  <div class="table-placeholder">Geen bijlagen te tonen.</div>
</ng-container>

<div
  class="flex-rows"
  *ngIf="!formDisabled"
>
  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="attachments-list button add-new-incident"
  >
    <mat-icon>add</mat-icon>
    Nieuwe bijlage
  </button>
</div>

<div
  class="link-form-container"
  *ngIf="!formDisabled"
>
  <h4 class="mat-subheading-1 link-form-title">Bestaande bijlage toevoegen</h4>

  <form class="flex-rows">
    <mat-form-field appearance="fill">
      <mat-label>Bijlage</mat-label>
      <input
        type="text"
        aria-label="Bijlage"
        matInput
        [matAutocomplete]="auto"
        [formControl]="filterControl"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let item of linkableItems | async"
          [value]="item"
          (click)="onOptionSelect(item)"
        >
          {{ item.title }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button
      mat-flat-button
      color="accent"
      class="no-grow attachment-list-button"
      (click)="onLinkIntent(selectedItem, $event)"
      [disabled]="!selectedItem"
      i18n="save button @@buttonSave"
    >
      <mat-icon>add_link</mat-icon>
      Toevoegen
    </button>
  </form>
</div>
