/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { Meeting } from '../models/meeting';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as _throw, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from './shared.service';

import * as moment from 'moment';
import { TasksTemplateService } from './tasks-template.service';
import { MeetingSubject } from '../models/meeting-subject';

const apiPath = environment.apiPath;

/**
 *  Service for managing the meetings.
 *
 * @export
 * @class         MeetingsService
 * @extends       {AbstractRestService<Meeting>}
 */
@Injectable()
export class MeetingsService extends AbstractRestService<Meeting> {
  /**
   * Creates an instance of MeetingsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      MeetingsService
   */
  constructor(
    http: HttpClient,
    private sharedService: SharedService,
    private tasksTemplatesService: TasksTemplateService,
  ) {
    super(http, `${apiPath}meetings`, 'meetings');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Meeting>}
   * @memberof        MeetingsService
   */
  protected mapToModel(json: any): PagedResult<Meeting> {
    //  If the Meeting array doesn't exist, create an empty Meeting object
    let payload = [];

    if (json._embedded) {
      payload = <Meeting[]>json._embedded.meetings;
    }

    return new PagedResult<Meeting>(payload, <PageSetting>json.page);
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: any): Observable<Meeting> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`;

    return this.http
      .delete<Meeting>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   * A generic GET SINGLE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  getSingleMeeting(aModel: any): Observable<Meeting> {
    const getURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`;

    return this.http.get<Meeting>(getURL).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => _throw(err)),
    );
  }

  /**
   * Check begin and end dates of the meeting
   *
   * @returns
   * @memberof MeetingsService
   */
  checkDates(formGroup: any) {
    const form = formGroup.value;

    if (!form.beginHour || !form.endHour) {
      formGroup.patchValue({
        duration: '00:00',
      });
    }

    if (form.beginDate && form.beginDate instanceof moment) {
      form.beginDate = form.beginDate.format('YYYY-MM-DD');
    }

    const beginTime: any = moment(
      form.beginDate + ' ' + form.beginHour,
      'YYYY-MM-DD HH:mm',
    );
    let endTime: any = moment(
      form.beginDate + ' ' + form.endHour,
      'YYYY-MM-DD HH:mm',
    );

    if (!(endTime > beginTime)) {
      //  Add one day if the end date is smaller than begin date
      endTime = moment(endTime.add(1, 'days').format('YYYY-MM-DD HH:mm'));
    }

    const durationSet = moment.duration(endTime - beginTime);
    const duration = moment()
      .hours(durationSet.hours())
      .minutes(durationSet.minutes())
      .format('HH:mm');

    if (
      formGroup.get('beginTime').value !==
        beginTime.format('YYYY-MM-DD HH:mm') ||
      formGroup.get('endTime').value !== endTime.format('YYYY-MM-DD HH:mm') ||
      formGroup.get('duration').value !== duration
    ) {
      formGroup.patchValue({
        beginTime: beginTime.format('YYYY-MM-DD HH:mm'),
        endTime: endTime.format('YYYY-MM-DD HH:mm'),
        duration: duration,
      });
    }
  }

  /**
   * Fetch the list of yearly subjects and adjust the results to the meeting subjects model
   *
   * @memberof MeetingsService
   */
  getYearlySubjects(teamId: string): MeetingSubject[] {
    const yearlysubjectsList = [];
    //  Create list of yearly planned subjects
    this.sharedService.yearlyPlannedSubjects$.subscribe((yearlysubjects) => {
      if (yearlysubjects && yearlysubjects.length) {
        for (const subject of yearlysubjects) {
          yearlysubjectsList.push({
            name: subject.name ? subject.name : '',
            description: subject.description ? subject.description : '',
            sequence: yearlysubjectsList.length + 1,
            teamId: teamId,
            yearlyPlannedSubjectId: subject.id,
            temporaryFields: {
              changed: false,
              removed: false,
              isNew: false,
              expanded: false,
              invalid: false,
            },
          });
        }
      }

      return yearlysubjectsList;
    });

    return yearlysubjectsList;
  }
}
