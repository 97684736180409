/** @format */

import { Injectable } from '@angular/core';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AgendaTemplate } from '../models/agenda-template';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';

const apiPath = environment.apiPath;

/**
 *  Service for managing the agenda templates.
 *
 * @export
 * @class   AgendaTemplatesService
 * @extends {AbstractRestService<AgendaTemplate>}
 */
@Injectable()
export class AgendaTemplatesService extends AbstractRestService<AgendaTemplate> {
  /**
   * Creates an instance of AgendaTemplatesService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      AgendaTemplatesService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}agendaTemplates`, 'agendaTemplates');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<AgendaTemplate>}
   * @memberof        AgendaTemplatesService
   */
  protected mapToModel(json: any): PagedResult<AgendaTemplate> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <AgendaTemplate[]>json._embedded.agendaTemplates;
    }

    return new PagedResult<AgendaTemplate>(payload, <PageSetting>json.page);
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: any): Observable<AgendaTemplate> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`;

    return this.http
      .delete<AgendaTemplate>(deleteURL)
      .pipe(catchError((err) => _throw(err)));
  }
}
