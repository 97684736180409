/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService, SnackbarService } from '@iq/ng-core';
import {
  malConfigService,
  malLoginPageComponent,
  malAuthService,
} from '@iq/ng-auth';
import { Credentials } from '@iq/ng-auth/src/app/models/credentials';

/**
 *  Login page
 *
 * @export
 * @class KmsLoginPageComponent
 * @extends {malLoginPageComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'kms-auth-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class KmsLoginPageComponent
  extends malLoginPageComponent
  implements OnInit, OnDestroy
{
  /**
   * Creates an instance of KmsLoginPageComponent.
   *
   * @param       {malAuthService}          _authService
   * @param       {LoaderService}           _loader
   * @param       {Router}                  _router
   * @param       {malConfigService}        _projCFG
   * @memberof    KmsLoginPageComponent
   */
  constructor(
    private _authService: malAuthService,
    private _loader: LoaderService,
    private _router: Router,
    private _projCFG: malConfigService,
    private snackbarService: SnackbarService,
  ) {
    super(_authService, _loader, _router, _projCFG);
  }

  /**
   *  Respond to the submit of login form
   *
   * @param {Credentials} $event
   * @memberof KmsLoginPageComponent
   */
  onSubmit($event: Credentials) {
    this._loader.display(true);

    this._authService.login($event).subscribe(
      (data) => {
        return data;
      },
      (err) => {
        this.snackbarService.showSnackbar(
          'Verkeerde gebruikersnaam of wachtwoord',
        );
        this.loadDone();
      },
      () => {
        this.loadDone();

        //  Just after login get the identity of the user
        //
        this._authService.id().subscribe((resp) => {
          this._authService.saveState();

          return this._router.navigate(['home']);
        });
      },
    );
  }
}
