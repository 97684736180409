<!-- @format -->

<div *ngIf="editedItem">
  <div class="agenda-subject-name">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Titel agendapunt</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          required
        />
        <mat-error [hidden]="!formErrors.name">{{ formErrors.name }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="agenda-subject-description">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Omschrijving</mat-label>
        <textarea
          matInput
          formControlName="description"
          matTextareaAutosize
          matAutosizeMinRows="2"
          resizeToFitContent
        ></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
