<!-- @format -->

<h1
  class="mat-h1"
  i18n="imporvement-plan page-title"
>
  Verbeterplan
</h1>

<div
  class="main-content"
  (swipeleft)="onSwipe($event.type)"
  (swiperight)="onSwipe($event.type)"
>
  <mat-tab-group
    mat-stretch-tabs
    *ngIf="themesList"
    [selectedIndex]="selectedTabInd"
    (selectedTabChange)="tabSelectionChanged($event)"
  >
    <mat-tab
      label="{{ theme.name }}"
      *ngFor="let theme of themesList; let i = index"
    >
      <app-improvement-plan-tab [theme]="theme"></app-improvement-plan-tab>
    </mat-tab>
  </mat-tab-group>
</div>
