<!-- @format -->

<h1
  class="mat-h1"
  i18n="files-library page-title"
>
  Overige bestanden
</h1>

<div class="main-content change-on-mobile">
  <app-files-list></app-files-list>
</div>
