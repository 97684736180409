/** @format */

import { Component, OnInit } from '@angular/core';
import { AgendaTemplateSubject } from '../../models/agenda-template-subject';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { UserValidatorService } from '../../services/user-validator.service';

import 'rxjs/add/operator/debounceTime';
import { EmbeddedChild } from '../../shared/embedded-child';

/**
 *  Show agenda subject form
 *
 * @export
 * @class AgendaSubjectComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-agenda-subject',
  templateUrl: './agenda-subject.component.html',
  styleUrls: ['./agenda-subject.component.scss'],
})
export class AgendaSubjectComponent
  extends EmbeddedChild<AgendaTemplateSubject>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AgendaSubjectComponent
   */
  formErrors = {
    name: '',
    description: '',
    sequence: '',
  };

  /**
   *  The default values of the new Form.
   *
   * @memberof                AgendaSubjectComponent
   */
  initialFormValues = <any>{
    name: ['', Validators.required],
    description: [''],
    sequence: [0],
    teamId: '',
    changed: false,
    removed: false,
    isNew: true,
    newIndex: 0,
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AgendaSubjectComponent
   */
  validationMessages = {
    name: {
      required: 'Titel is een verplicht veld.',
    },
  };

  /**
   * Creates an instance of AgendaSubjectComponent.
   *
   * @param    {UserValidatorService} userValidatorService
   * @param    {FormBuilder}          fb
   * @memberof AgendaSubjectComponent
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private fb: UntypedFormBuilder,
  ) {
    super(fb);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AgendaSubjectComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.setInitialFormValues();

    this.createForm(this.initialFormValues);
  }

  /**
   *  Set the initial values to the child component form
   *
   * @memberof AgendaSubjectComponent
   */
  setInitialFormValues() {
    const agendaSubjectInstance = Object.assign({}, this.editedItem);

    if (agendaSubjectInstance) {
      this.initialFormValues = {
        name: [agendaSubjectInstance.name, Validators.required],
        description: [agendaSubjectInstance.description],
        sequence: [agendaSubjectInstance.sequence],
        teamId: agendaSubjectInstance.teamId,
        temporaryFields: {
          changed: agendaSubjectInstance.temporaryFields
            ? agendaSubjectInstance.temporaryFields.changed
            : false,
          removed: agendaSubjectInstance.temporaryFields
            ? agendaSubjectInstance.temporaryFields.removed
            : false,
          invalid: agendaSubjectInstance.temporaryFields
            ? agendaSubjectInstance.temporaryFields.invalid
            : false,
        },
      };
    }

    if (!this.initialFormValues.teamId) {
      this.initialFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    if (agendaSubjectInstance.id) {
      this.initialFormValues.id = agendaSubjectInstance.id;
    } else {
      this.initialFormValues.temporaryFields.isNew = true;
    }
  }
}
