<!-- @format -->

<h1
  class="mat-h1"
  i18n="agenda page-title"
>
  Agenda
</h1>

<div class="main-content">
  <nav
    mat-tab-nav-bar
    mat-stretch-tabs
    [disablePagination]="true"
    [tabPanel]="tabPanelAgenda"
  >
    <a
      mat-tab-link
      routerLink="meetings"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      >Overleg agenda's</a
    >

    <a
      mat-tab-link
      routerLink="agenda-templates"
      routerLinkActive
      #rlb="routerLinkActive"
      [active]="rlb.isActive"
      >Agenda templates</a
    >
  </nav>

  <mat-tab-nav-panel
    #tabPanelAgenda
    [@routeAnimations]="prepareRoute(outlet)"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-tab-nav-panel>
</div>
