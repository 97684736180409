/** @format */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 *  General component for delete warning message dialog.
 *
 * @export
 * @class   WarningDeleteDialogComponent
 */
@Component({
  selector: 'app-warning-delete-dialog',
  templateUrl: './warning-delete-dialog.component.html',
  styleUrls: ['./warning-delete-dialog.component.scss'],
})
export class WarningDeleteDialogComponent {
  /**
   * Creates an instance of WarningDeleteDialogComponent.
   *
   * @param         {MatDialogRef<WarningDeleteDialogComponent>}    dialogRef The MatDialogRef instance
   * @param         {*}                                             data      The data passed through the dialog
   * @memberof      WarningDeleteDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<WarningDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  /**
   *  Respond to `click` event on the Cancel button in Dialog.
   *
   * @memberof      WarningDeleteDialogComponent
   */
  onNoClick(): void {
    this.dialogRef.close();
  }
}
