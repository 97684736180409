/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Member } from '../models/member';
import { UserRights } from '../models/user-rights';
import { SubTheme } from '../models/subtheme';
import { SubThemeOwnership } from '../models/subtheme-ownership';

const apiPath = environment.apiPath;

/**
 *  Service that validates the user rights.
 *
 * @export
 * @class       UserValidatorService
 */
@Injectable()
export class UserValidatorService {
  manageUserTeamsRefreshTrigger$ = new Subject<void>();

  /**
   *  Subject object of UserRights type that identifies current logged-in user rights.
   *
   * @private
   * @memberof        UserValidatorService
   */
  public currentUserRights = new BehaviorSubject<UserRights>({
    organisationAdmin: false,
    isCurrentTeamAdmin: false,
    currentTeamId: '',
    currentTeamName: '',
    subThemeOwnership: [],
  });

  /**
   *  Subject object of User type that identifies current logged-in user.
   *
   * @private
   * @memberof        UserValidatorService
   */
  public currentUser = new BehaviorSubject<User>({
    id: '',
    emailAddress: '',
    organisationAdmin: false,
    firstName: '',
    lastName: '',
  });

  /**
   *  Observable UserRights stream that identifies current logged-in user rights.
   *
   * @memberof        UserValidatorService
   */
  currentUserRights$ = this.currentUserRights.asObservable();

  /**
   *  Observable User stream that identifies current logged-in user.
   *
   * @memberof        UserValidatorService
   */
  currentUser$ = this.currentUser.asObservable();

  /**
   * Creates an instance of UserValidatorService.
   *
   * @param           {HttpClient}          http HttpClient instance
   * @memberof        UserValidatorService
   */
  constructor(private http: HttpClient) { }

  /**
   *  Set next value to the currentUserRights variable.
   *
   * @param           {UserRights}              userRights      New value of the user rights
   * @memberof        UserValidatorService
   */
  newUserRights(userRights: UserRights) {
    this.currentUserRights.next(userRights);
  }

  /**
   *  Set next value to the currentUser variable.
   *
   * @param           {User}              user      New value of the user
   * @memberof        UserValidatorService
   */
  newUser(user: User) {
    this.currentUser.next(user);
  }

  /**
   * THIS IS THE TEST METHOD FOR THE USER! SHOULD BE CHANGED!
   *
   * @returns {Observable<User>}
   * @memberof UserValidatorService
   */
  getUser(): Observable<User> {
    return this.http.get<any>(apiPath + 'users/identity');
  }

  /**
   *  Check if the user has rights to see the Users and Teams management tabs.
   *
   * @param       {SubThemeOwnership[]}     ownerships  The list of ownerships
   * @param       {Member}                  member      The currently choosen member.
   * @memberof    UserValidatorService
   */
  checkSubThemesOwnership(member: Member, ownerships: SubThemeOwnership[]) {
    const subThemes = [];

    for (const ownership of ownerships) {
      if (ownership._embedded.members) {
        for (const owner of ownership._embedded.members) {
          if (owner.id === member.id) {
            subThemes.push(ownership._embedded.subTheme);

            break;
          }
        }
      }
    }

    return subThemes;
  }

  /**
   *  Check if the user has rights to see the Users and Teams management tabs.
   *
   * @param       {User}                    user    The currently logged-in user
   * @param       {Member}                  member  The currently choosen member.
   * @returns     {{ organisationAdmin: boolean, currentTeamAdmin: boolean }}
   * @memberof    UserValidatorService
   */
  checkUsersRights(
    user: User,
    member: Member,
    subThemes?: SubTheme[],
  ): UserRights {
    if (user.organisationAdmin) {
      return {
        organisationAdmin: true,
        isCurrentTeamAdmin: true,
        currentMember: member,
        currentTeamId: member.team.id,
        currentTeamName: member.team.name,
        subThemeOwnership: subThemes ? subThemes : [],
      };
    } else if (member.teamAdmin) {
      return {
        organisationAdmin: false,
        isCurrentTeamAdmin: true,
        currentMember: member,
        currentTeamId: member.team.id,
        currentTeamName: member.team.name,
        subThemeOwnership: subThemes ? subThemes : [],
      };
    } else {
      return {
        organisationAdmin: false,
        isCurrentTeamAdmin: false,
        currentMember: member,
        currentTeamId: member.team.id,
        currentTeamName: member.team.name,
        subThemeOwnership: subThemes ? subThemes : [],
      };
    }
  }
}
