<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-incident-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe melding toevoegen
</h2>
<h2
  class="title-text"
  i18n="add-edit-incident-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen melding
</h2>

<mat-dialog-content *ngIf="formIsNew || improvementIdsList !== undefined">
  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 0"
      (opened)="setStep(0)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Onderwerp
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="subject">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Onderwerp</mat-label>
            <input
              type="text"
              matInput
              formControlName="subject"
              required
            />
            <mat-error [hidden]="!formErrors.subject">{{
              formErrors.subject
            }}</mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="description">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Omschrijving</mat-label>
            <textarea
              matInput
              formControlName="description"
              matTextareaAutosize
              matAutosizeMinRows="1"
              resizeToFitContent
            ></textarea>
          </mat-form-field>
        </form>
      </div>
      <mat-action-row>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 1"
      (opened)="setStep(1)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Dienst/Thema
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-rows">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Dienst</mat-label>
            <mat-select
              formControlName="theme"
              [compareWith]="compareFn"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let theme of currentThemesList; let i = index"
                [value]="theme"
              >
                {{ theme.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Thema</mat-label>
            <mat-select
              formControlName="subTheme"
              [compareWith]="compareFn"
              (selectionChange)="onSubThemeChange($event)"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="
                  let subTheme of themesListWithIds[
                    newFormGroup.get('theme')?.value?.id
                  ]?._embedded?.subThemes;
                  let i = index
                "
                [value]="subTheme"
              >
                {{ subTheme?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 2"
      (opened)="setStep(2)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Oorsprong
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-rows">
        <form
          class="no-grow"
          [formGroup]="newFormGroup"
        >
          <mat-checkbox
            appearance="fill"
            formControlName="internal"
            labelPosition="after"
            >Intern</mat-checkbox
          >
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Wijze van binnenkomst</mat-label>
            <mat-select formControlName="origin">
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let origin of incidentOrigins; let i = index"
                [value]="i"
              >
                {{ origin }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 3"
      (opened)="setStep(3)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Details van de afzender
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="contact-details">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Naam melder</mat-label>
            <input
              type="text"
              matInput
              formControlName="reporterName"
              required
            />
            <mat-error [hidden]="!formErrors.reporterName">{{
              formErrors.reporterName
            }}</mat-error>
          </mat-form-field>
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Telefoon</mat-label>
            <input
              type="text"
              matInput
              formControlName="reporterPhone"
            />
          </mat-form-field>
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Emailadres</mat-label>
            <input
              type="text"
              matInput
              formControlName="reporterEmail"
            />
            <mat-error [hidden]="!formErrors.reporterEmail">{{
              formErrors.reporterEmail
            }}</mat-error>
          </mat-form-field>
        </form>
      </div>
      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 4"
      (opened)="setStep(4)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Datums
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="dates-container">
        <div class="flex-rows">
          <form [formGroup]="newFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Ontvangstdatum</mat-label>
              <input
                type="text"
                matInput
                formControlName="occurredOn"
                [matDatepicker]="pickerOccured"
                required
              />
              <mat-datepicker #pickerOccured></mat-datepicker>
              <mat-error [hidden]="!formErrors.occurredOn">{{
                formErrors.occurredOn
              }}</mat-error>
            </mat-form-field>
            <mat-icon
              class="date-picker"
              (click)="pickerOccured.open()"
              >timer</mat-icon
            >
          </form>

          <form [formGroup]="newFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Registratiedatum</mat-label>
              <input
                type="text"
                matInput
                formControlName="registeredOn"
                [matDatepicker]="pickerRegistered"
                required
                readonly
                disabled
              />
              <mat-datepicker #pickerRegistered></mat-datepicker>
              <mat-error [hidden]="!formErrors.registeredOn">{{
                formErrors.registeredOn
              }}</mat-error>
            </mat-form-field>
          </form>
        </div>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Plandatum afgehandeld</mat-label>
            <input
              type="text"
              matInput
              [matDatepicker]="pickerPlanned"
              formControlName="plannedOn"
              required
            />
            <mat-datepicker #pickerPlanned></mat-datepicker>
            <mat-error [hidden]="!formErrors.plannedOn">{{
              formErrors.plannedOn
            }}</mat-error>
          </mat-form-field>
          <mat-icon
            class="date-picker"
            (click)="pickerPlanned.open()"
            >timer</mat-icon
          >
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 5"
      (opened)="setStep(5)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Uitvoerder/Bewaker
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-rows">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Bewaker</mat-label>
            <mat-select
              formControlName="guard"
              [compareWith]="compareFn"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="
                  let member of sharedService.currentTeamMembersList$ | async
                "
                [value]="member"
              >
                {{ member.memberName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Uitvoerder</mat-label>
            <mat-select
              formControlName="executioner"
              [compareWith]="compareFn"
              required
            >
              <mat-option
                *ngFor="
                  let member of sharedService.currentTeamMembersList$ | async
                "
                [value]="member"
              >
                {{ member.memberName }}
              </mat-option>
            </mat-select>
            <mat-error [hidden]="!formErrors.executioner">{{
              formErrors.executioner
            }}</mat-error>
          </mat-form-field>
        </form>
      </div>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Afhandeling</mat-label>
          <textarea
            matInput
            formControlName="conclusion"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
        </mat-form-field>
      </form>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 6"
      (opened)="setStep(6)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Status
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Status opvolging:</mat-label>
          <mat-chip-list
            disabled="true"
            selectable="false"
          >
            <mat-chip
              color="{{
                ['accent', 'warn', 'accent', 'warn'][
                  newFormGroup.get('responseStatus').value
                ]
              }}"
              defaultColor="primary"
              disableRipple="true"
              selected="true"
              value="{{ newFormGroup.get('responseStatus').value }}"
              class="incident-response-status incident-response-status-{{
                newFormGroup.get('responseStatus').value
              }}"
              >{{
                incidentResponseStatuses[
                  newFormGroup.get('responseStatus').value
                ]
              }}</mat-chip
            >
          </mat-chip-list>
        </mat-form-field>
      </form>

      <div class="flex-rows">
        <form
          class="no-grow"
          [formGroup]="newFormGroup"
        >
          <mat-checkbox
            appearance="fill"
            formControlName="appealCommittee"
            labelPosition="after"
            >Klachtencommissie ingeschakeld</mat-checkbox
          >
        </form>

        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select
              formControlName="status"
              [disabled]="
                formIsNew ||
                data.formDisabled ||
                (!formChanged && newFormGroup.get('status')?.value !== 0)
              "
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let status of incidentStatuses; let i = index"
                [value]="i"
              >
                {{ status }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 7"
      (opened)="setStep(7)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Verbetermaatregelen
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-improvements-list-embedded
        [formDisabled]="data.formDisabled"
        [listData]="improvementsList"
        (itemLinked)="onItemLink($event)"
        (itemUnlinked)="onItemUnlink($event)"
      ></app-improvements-list-embedded>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 8"
      (opened)="setStep(8)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n="add-edit-incident-dialog expansion-panel-title">
          Bijlage
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-attachments-list-embedded
        *ngIf="attachmentsList !== undefined"
        [listData]="attachmentsList"
        [formDisabled]="data.formDisabled"
        (itemLinked)="onAttachmentLink($event)"
        (itemUnlinked)="onAttachmentUnlink($event)"
      >
      </app-attachments-list-embedded>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
  *ngIf="formIsNew || improvementIdsList !== undefined"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel(true)"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="
      !(formChanged || childChanged) ||
      newFormGroup.invalid ||
      data.formDisabled
    "
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
