/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  The files library container
 *
 * @export
 * @class FilesLibraryComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-files-library',
  templateUrl: './files-library.component.html',
  styleUrls: ['./files-library.component.scss'],
})
export class FilesLibraryComponent implements OnInit {
  /**
   * Creates an instance of FilesLibraryComponent.
   *
   * @memberof FilesLibraryComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof FilesLibraryComponent
   */
  ngOnInit() {}
}
