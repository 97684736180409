/** @format */

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditTaskComponent } from '../../dialogs/add-edit-task/add-edit-task.component';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import * as moment from 'moment';
import { RecurringTasksService } from '../../services/recurring-tasks.service';
import { AddEditImprovementItemComponent } from '../../dialogs/add-edit-improvement-item/add-edit-improvement-item.component';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { SharedService } from '../../services/shared.service';

/**
 *  Show the single task tile.
 *
 * @export
 * @class TaskTileComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-tile',
  templateUrl: './task-tile.component.html',
  styleUrls: ['./task-tile.component.scss'],
})
export class TaskTileComponent implements OnInit, OnDestroy {
  /**
   *  The task to show
   *
   * @type                    {any}
   * @memberof                TaskTileComponent
   */
  @Input() task: any;

  /**
   *  `recurring-task` or `improvement-item`
   *
   * @type                    {string}
   * @memberof                TaskTileComponent
   */
  @Input() taskType: string;

  /**
   *  The end date of tasks group
   *
   * @type                    {string}
   * @memberof                TaskTileComponent
   */
  @Input() groupEndDate: string;

  /**
   *  The start date of tasks group
   *
   * @type                    {string}
   * @memberof                TaskTileComponent
   */
  @Input() groupStartDate: string;

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                TaskTileComponent
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the task is done.
   *
   * @type                    {boolean}
   * @memberof                TaskTileComponent
   */
  taskChecked: boolean;

  /**
   *  The name of the task class
   *
   * @memberof                TaskTileComponent
   */
  taskColorClass = 'task-ok';

  /**
   *  The default values of the new Form.
   *
   * @memberof                TaskTileComponent
   */
  editedFormValues = <any>{};

  /**
   *  The form group storing the new Score.
   *
   * @memberof                TaskTileComponent
   */
  newFormGroup: UntypedFormGroup;

  /**
   * Creates an instance of TaskTileComponent.
   *
   * @param         {MatDialog}             dialog
   * @param         {ChangeDetectorRef}     ref
   * @param         {RecurringTasksService} recurringTasksService
   * @param         {FormBuilder}           fb
   * @param         {UserValidatorService}  userValidatorService
   * @memberof      TaskTileComponent
   */
  constructor(
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private recurringTasksService: RecurringTasksService,
    private fb: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TaskTileComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.task.status === 0) {
      this.taskChecked = false;
    } else if (this.task.status === 2) {
      this.taskChecked = true;
    }

    this.editedFormValues = {
      id: this.task.id,
      critical: this.task.critical,
      title: [this.task.title, Validators.required],
      description: [this.task.description],
      improvementGroupId: this.task.improvementGroupId,
      periodBeginDate: this.task.periodBeginDate,
      periodEndDate: [this.task.periodEndDate, Validators.required],
      plannedOn: [this.task.plannedOn ? moment(this.task.plannedOn) : null],
      privateTask: this.task.privateTask,
      remarks: [this.task.remarks ? this.task.remarks : null],
      responsibleMember: [this.task._embedded.member, Validators.required],
      status: [this.task.status],
      teamId: this.currentUserRights.currentTeamId,
    };

    this.newFormGroup = this.fb.group(this.editedFormValues);

    this.checkTaskColor();
  }

  /**
   *  Check the border color of the task.
   *
   * @memberof TaskTileComponent
   */
  checkTaskColor() {
    if (moment(this.task.periodEndDate) < moment(this.groupStartDate)) {
      this.taskColorClass = 'task-period-end-before-group-start';
    } else if (
      this.task.plannedOn &&
      moment(this.task.plannedOn) < moment(this.groupStartDate)
    ) {
      this.taskColorClass = 'task-planned-before-group-start';
    } else {
      this.taskColorClass = 'task-ok';
    }

    this.ref.detectChanges();
  }

  /**
   *  Respond to the check/uncheck the checkbox
   *
   * @param       {*}                 event
   * @memberof    TaskTileComponent
   */
  onCheckBoxChange(event: any) {
    if (event instanceof Event) {
      event.stopImmediatePropagation();
    } else {
      const status = event.checked ? 2 : 0;

      this.editedFormValues.status = [status];
      this.newFormGroup.patchValue({ status: status });

      this.recurringTasksService.put(this.newFormGroup.value).subscribe(
        (task) => {
          this.task = task;

          this.taskChecked = task.status === 2 ? true : false;

          this.ref.detectChanges();
        },

        () => {
          const statusBack = status === 2 ? 0 : 2;

          this.editedFormValues.status = [statusBack];
          this.newFormGroup.patchValue({ status: statusBack });

          this.taskChecked = !event.checked;

          this.ref.detectChanges();
        },
      );
    }
  }

  /**
   *  Open the edit dialog on task click.
   *
   * @returns
   * @memberof TaskTileComponent
   */
  onTaskSelect() {
    if (this.taskChecked && this.taskType !== 'improvement-item') {
      return;
    }

    const formValues = this.editedFormValues;

    let dialogRef = <any>'';

    if (this.taskType === 'improvement-item') {
      dialogRef = this.dialog.open(AddEditImprovementItemComponent, {
        data: {
          formValues: formValues,
          relationInfo: this.task.relationInfo,
          isNew: false,
          subTheme: this.task._embedded.subTheme,
        },
        disableClose: true,
        height: '500px',
        maxHeight: 'calc(100vh - 120px)',
        width: '1000px',
        panelClass: ['primary-dialog', 'tasks-dialog'],
      });
    } else {
      dialogRef = this.dialog.open(AddEditTaskComponent, {
        data: {
          formValues: formValues,
          relationInfo: this.task.relationInfo,
          isNew: false,
          subTheme: this.task._embedded.subTheme,
          frequency: this.task._embedded.taskTemplateFrequency,
        },
        disableClose: true,
        height: '900px',
        maxHeight: 'calc(100vh - 120px)',
        width: '1000px',
        panelClass: ['primary-dialog', 'tasks-dialog'],
      });
    }

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.task) {
        if (results.task !== this.task) {
          this.task = results.task;

          this.sharedService.newRefreshTasks(this.task);

          this.checkTaskColor();
        }

        this.taskChecked = this.task.status === 2 ? true : false;

        this.editedFormValues = {
          id: this.task.id,
          critical: this.task.critical,
          title: [this.task.title, Validators.required],
          description: [this.task.description],
          improvementGroupId: this.task.improvementGroupId,
          periodBeginDate: this.task.periodBeginDate,
          periodEndDate: [this.task.periodEndDate, Validators.required],
          plannedOn: [this.task.plannedOn ? this.task.plannedOn : null],
          privateTask: this.task.privateTask,
          remarks: [this.task.remarks ? this.task.remarks : null],
          responsibleMember: [this.task._embedded.member, Validators.required],
          status: [this.task.status],
          teamId: this.currentUserRights.currentTeamId,
        };

        this.newFormGroup = this.fb.group(this.editedFormValues);
        this.ref.detectChanges();
      }
    });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof TaskTileComponent
   */
  ngOnDestroy() {
    this.ref.detach();
  }
}
