<!-- @format -->

<mat-accordion
  class="flex-tasks-overview mobile-match"
  displayMode="flat"
  multi
>
  <mat-expansion-panel
    class="task-overview-container mat-elevation-z0"
    *ngFor="let frequency of frequencies; let statusInd = index"
    [expanded]="!mobileQuery.matches"
    [disabled]="!mobileQuery.matches"
  >
    <mat-expansion-panel-header class="tasks-group-container-header">
      <mat-panel-title>
        <span class="task-group-title"> {{ frequency.name }}</span>
        <span
          class="tasks-group-date"
          *ngIf="frequency.date"
          >{{ frequency.date }}</span
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-tasks-group-container
      [fromDate]="frequency.fromDate"
      [toDate]="frequency.toDate"
      [tasksList]="tasksList"
      *ngIf="tasksList"
    ></app-tasks-group-container>
  </mat-expansion-panel>
</mat-accordion>
