/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { ScoreItem } from '../../models/scoreitem';

/**
 *  Show the scoreItem tile in the overview.
 *
 * @export
 * @class         OverviewScoreItemTileComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-overview-score-item-tile',
  templateUrl: './overview-score-item-tile.component.html',
  styleUrls: ['./overview-score-item-tile.component.scss'],
})
export class OverviewScoreItemTileComponent implements OnInit {
  /**
   *  The current scoreItem.
   *
   * @type                    {ScoreItem}
   * @memberof                OverviewScoreItemTileComponent
   */
  @Input() scoreItem: ScoreItem;

  /**
   *  The average score for the score item.
   *
   * @type                    {number}
   * @memberof                OverviewScoreItemTileComponent
   */
  @Input() score: number;

  /**
   *  Creates an instance of OverviewScoreItemTileComponent.
   *
   * @memberof OverviewScoreItemTileComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof OverviewScoreItemTileComponent
   */
  ngOnInit() {
    if (!this.score) {
      this.score = 0;
    }
  }
}
