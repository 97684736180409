/** @format */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { UserValidatorService } from '../services/user-validator.service';
import { UserRights } from '../models/user-rights';

/**
 *  Check if the user has rights to see the content
 *
 * @export
 * @class NoRightsGuard
 * @implements {CanActivate}
 */
@Injectable()
export class NoRightsGuard implements CanActivate {
  /**
   *  The rights of currently logged in user
   *
   * @type {UserRights}
   * @memberof NoRightsGuard
   */
  currentUserRights: UserRights;

  constructor(
    private router: Router,
    private userValidatorService: UserValidatorService,
  ) {}

  /**
   *  Interface that a class can implement to be a guard deciding if a route can be activated
   *
   * @param       {ActivatedRouteSnapshot}  route The ActivatedRouteSnapshot instance.
   * @param       {RouterStateSnapshot}     state The instance of the RouterStateSnapshot
   * @returns     {boolean}
   * @memberof    NoRightsGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.currentUserRights && this.currentUserRights.currentMember) {
      if (route.data && route.data.readRights && route.data.readRights.length) {
        for (const item of route.data.readRights) {
          if (this.currentUserRights.currentMember.teamRole.permissions) {
            if (
              this.currentUserRights.currentMember.teamRole.permissions.indexOf(
                item,
              ) > -1
            ) {
              return true;
            } else if (
              this.currentUserRights.organisationAdmin &&
              item === 'ONLY_ORG_ADMIN'
            ) {
              return true;
            } else {
              this.router.navigate(['403']);
            }
          }
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['auth/login']);

      return false;
    }
  }
}
