/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { SubThemeOwnership } from '../../models/subtheme-ownership';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubthemeOwnershipService } from '../../services/subtheme-ownership.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Member } from '../../models/member';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';

/**
 *  Dialog for edit/add the subThemeOwnership
 *
 * @export
 * @class   AddEditSubthemeOwnershipComponent
 * @extends {AddEditAbstractDialog<SubThemeOwnership>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-subtheme-ownership',
  templateUrl: './add-edit-subtheme-ownership.component.html',
  styleUrls: ['./add-edit-subtheme-ownership.component.scss'],
})
export class AddEditSubthemeOwnershipComponent
  extends AddEditAbstractDialog<SubThemeOwnership>
  implements OnInit {
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditSubthemeOwnershipComponent
   */
  formErrors = <any>{ owner1: '', owner2: '', notValidMembers: '', theme: '', subTheme: '' };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditSubthemeOwnershipComponent
   */
  validationMessages = <any>{
    theme: {
      required: 'Voer a.u.b. een dienst in.',
    },
    subTheme: {
      required: 'Voer a.u.b. een thema in.',
    },
    notValidMembers: 'De verantwoordelijken mogen niet gelijk zijn',
  };

  /**
   *  The list of teamMembers
   *
   * @type                  {Member[]}
   * @memberof              AddEditSubthemeOwnershipComponent
   */
  currentTeamMembers: Member[];

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              UsersListComponent
   */
  currentUserRights: UserRights;

  theme: any;
  subTheme: any;
  buttonDisabledOnSubmit = false;
  modalTitle = '';

  /**
   * Creates an instance of AddEditSubthemeOwnershipComponent.
   *
   * @param     {MatDialogRef<AddEditSubthemeOwnershipComponent>}     dialogRefA                The instance of MatDialogRef
   * @param     {*}                                                   data                      The data passed from parent component
   * @param     {SubthemeOwnershipService}                            subThemeOwnershipService
   * @param     {FormBuilder}                                         fbA
   * @param     {UserValidatorService}                                userValidatorService
   * @memberof  AddEditSubthemeOwnershipComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditSubthemeOwnershipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private subThemeOwnershipService: SubthemeOwnershipService,
    private fbA: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
  ) {
    super(dialogRefA, data, subThemeOwnershipService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof         AddEditSubthemeOwnershipComponent
   */
  ngOnInit() {
    this.currentTeamMembers = this.data.membersList;
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.theme = this.data.theme;
    this.subTheme = this.data.subTheme;

    this.createForm(this.initialFormValues);

    if (!this.newFormGroup.controls.theme.value && this.theme) {
      this.newFormGroup.controls.theme.setValue(this.theme.name);
    }

    if (!this.newFormGroup.controls.subTheme.value && this.subTheme) {
      this.newFormGroup.controls.subTheme.setValue(this.subTheme.name);
    }

    this.#handleModalTitle();
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddEditTeamComponent
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fbA.group(data, {
      validator: this.equalMembersValidator,
    });

    this.newFormGroup.valueChanges.subscribe((changes) =>
      this.onFormValueChanged(changes),
    );

    this.onFormValueChanged();
  }

  /**
   *  Check if the fields are equal.
   *
   * @memberof AddEditSubthemeOwnershipComponent
   */
  equalMembersValidator(group: UntypedFormGroup) {
    if (
      group.controls.owner1.value &&
      group.controls.owner2.value &&
      group.controls.owner1.value.id === group.controls.owner2.value.id
    ) {
      return { notValidMembers: true };
    }
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @memberof    AddEditUserComponent
   */
  onSubmit() {
    this.buttonDisabledOnSubmit = true;

    const modalCloseActions = () => {
      this.formSubmitted = true;
      this.dialogStateChanged = true;
      this.formIsNew = false;

      this.dialogRef.close({ changed: this.dialogStateChanged });
      setTimeout(() => {
        this.formSubmitted = false;
      }, 0)
    }

    const ownershipCall = (subTheme: object) => {
      const { owner1, owner2 } = this.newFormGroup.value;

      if (!(owner1 || owner2)) {
        modalCloseActions();
        return;
      }

      let formGroupToSend = { ...this.newFormGroup.value, subTheme }

      if (!owner1 && owner2) {
        formGroupToSend = { ...formGroupToSend, owner1: owner2, owner2: null }
      }

      this.subThemeOwnershipService
        .postOwnership(
          formGroupToSend,
          this.currentUserRights.currentTeamId,
        )
        .subscribe(
          () => {
            modalCloseActions();
          },
        );
    }

    const ownershipEditCall = (subTheme: object) => {
      const { owner1, owner2 } = this.newFormGroup.value;

      if (!(owner1 || owner2)) {
        modalCloseActions();
        return;
      }

      let formGroupToSend = { ...this.newFormGroup.value, subTheme }

      if (!owner1 && owner2) {
        formGroupToSend = { ...formGroupToSend, owner1: owner2, owner2: null }
      }

      this.subThemeOwnershipService
        .putOwnership(
          formGroupToSend,
          this.currentUserRights.currentTeamId,
        )
        .subscribe(
          () => {
            modalCloseActions();
          },
        );
    }

    const isThemeTheSame = this.newFormGroup.value.theme === this.theme?.name;
    const isSubThemeTheSame = this.newFormGroup.value.subTheme === this.subTheme?.name;

    if (!this.theme) {
      // create new theme, subtheme (new or existing) and ownership
      this.subThemeOwnershipService.createNewThemeAndSubTheme(this.newFormGroup.value, this.subTheme).subscribe(subTheme => {
        ownershipCall(subTheme);
      });
    }

    if (this.theme && this.subTheme && !isThemeTheSame) {
      // edit just theme
      this.subThemeOwnershipService.editTheme(this.theme.id, this.newFormGroup.value.theme).subscribe((res) => {
        ownershipEditCall(res._embedded.subThemes[0])
      }
      )
    }

    if (this.theme && this.subTheme && !isSubThemeTheSame) {
      // update only subtheme + ownership
      this.subThemeOwnershipService.editSubThemeForExistingTheme(this.newFormGroup.value.subTheme, this.subTheme).subscribe(subTheme => {
        ownershipEditCall(subTheme);
      })
    }

    if (this.formIsNew) {
      if (isThemeTheSame && isSubThemeTheSame) {
        // create new item from blank default theme
        ownershipCall(this.subTheme);
      }
    } else {
      if (isThemeTheSame && isSubThemeTheSame) {
        // edit exisgting ownership
        ownershipEditCall(this.subTheme);
      }
    }
  }

  #handleModalTitle(): void {
    if (!(this.theme && this.subTheme) && this.formIsNew) {
      // create new theme
      this.modalTitle = 'Toevoegen nieuw thema';
    } else if (!this.formIsNew || (this.theme && this.subTheme && this.formIsNew)) {
      // edit existing theme - second if condition for detault existing themes + subthemes
      this.modalTitle = 'Wijzigen thema';
    }
  }
}
