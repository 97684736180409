
/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserValidatorService } from '../../services/user-validator.service';
import { FormControl } from '@angular/forms';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Member } from '../../models/member';
import { PracticeChangeService } from '../../services/practice-change.service';

@Component({
  selector: 'app-practice-select-dropdown',
  templateUrl: './practice-select-dropdown.component.html',
  styleUrls: ['./practice-select-dropdown.component.scss'],
})
export class PracticeSelectDropdownComponent implements OnInit {
  @Input() practiceName: string;
  @Input() isPracticeChooseAfterLogin = false;
  @Output() chosenMember = new EventEmitter<Member>();

  searchValueControl = new FormControl('');
  menuOpened = false;
  selectedMember: Member = null;

  currentUser$ = this.userValidatorService.currentUser$;

  filteredMembers$ = this.searchValueControl.valueChanges.pipe(startWith(''), switchMap(searchVal =>
    this.currentUser$.pipe(map(currentUser =>
      currentUser._embedded.members.filter(member => member.team.name.toLowerCase().includes(searchVal.toLowerCase()))
    ))), map(val => val.sort((a, b) => a.team.name.localeCompare(b.team.name))))

  constructor(public userValidatorService: UserValidatorService, private practiceChangeService: PracticeChangeService) { }

  ngOnInit(): void {
    this.selectedMember = null;
  }

  triggerMenu(): void {
    this.menuOpened = !this.menuOpened;

    if (!this.menuOpened) {
      this.searchValueControl.setValue('')
    }
  }

  selectMember(member: Member) {
    this.selectedMember = member;
    this.triggerMenu();

    if (this.isPracticeChooseAfterLogin) {
      this.chosenMember.emit(member);
    } else {
      this.practiceChangeService.onMemberSubmit(this.selectedMember)
    }
  }
}
