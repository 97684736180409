<!-- @format -->

<div class="flex-rows filters-container">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title
        class="filters-title"
        i18n="files-list expansion-panel-title"
      >
        Filter
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div
      class="flex-rows"
      [formGroup]="filterForm"
    >
      <mat-form-field appearance="fill">
        <mat-label>Titel</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
        />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Categorieën</mat-label>
        <mat-select formControlName="category">
          <mat-option value="uncategorized">--</mat-option>
          <mat-option
            *ngFor="let category of sharedService.filesCategories$ | async"
            [value]="category.name"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-rows">
      <button
        mat-flat-button
        type="button"
        color="warn"
        class="no-grow"
        matBadge="8"
        matBadgePosition="after"
        matBadgeColor="accent"
        (click)="onFiltersClear()"
      >
        Reset filter
      </button>
    </div>
  </mat-expansion-panel>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="agenda-templates-list button add-new-incident"
  >
    <mat-icon>add</mat-icon>
    Nieuw bestand
  </button>
</div>

<div class="files-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Titel
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Categorie
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.category }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileType">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Bestandstype
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.fileType }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileSize">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Bestandsgrootte
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.fileSize | fileSize }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createDate">
      <mat-header-cell
        *matHeaderCellDef
        i18n="files-list list-header"
        >Datum van Inzending
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{
        row.createDate | date: 'dd-MM-yyyy'
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons extended"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons extended"
      >
        <ng-container>
          <button
            mat-icon-button
            color="primary"
            (click)="onFileDownload(row, $event, true)"
          >
            <mat-icon>cloud_download</mat-icon>
          </button>
          <a
            class="download-link"
            #downloadLink
            disabled
          ></a>
          <button
            mat-icon-button
            color="accent"
            (click)="onEditIntent(row, $event)"
            [ngClass]="{ disabled: tableInEditMode.isEditMode }"
          >
            <mat-icon>create</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            *ngIf="currentUserRights?.currentMember?.id === row.ownerMemberId"
            (click)="onDeleteIntent(row, $event, deleteDialog)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
