<!-- @format -->
<div
  class="container"
  [ngClass]="{
    'container--opened': menuOpened
  }"
>
  <div
    class="inner-container"
    (click)="triggerMenu()"
  >
    <div class="year">
      <span *ngIf="showCurrentYearLabel">{{
        yearPlanningService.currentlyChosenYear$ | async
      }}</span>
      <span *ngIf="!showCurrentYearLabel"> - </span>
    </div>
  </div>
  <mat-icon (click)="triggerMenu()">{{
    menuOpened ? 'expand_less' : 'expand_more'
  }}</mat-icon>
  <div
    class="menu"
    [ngClass]="{ 'menu--opened': menuOpened }"
  >
    <div class="year__list__wrapper">
      <div>
        <div
          *ngIf="listOfYears.length < 2 && isUserPracticeOwnerOrQualityManager"
          class="year__item"
          (click)="createNewYearsPlan()"
        >
          {{ '+ Aanmaken nieuw jaarplan' }}
        </div>
        <div
          class="year__item"
          *ngFor="let year of listOfYears"
          (click)="selectYear(year)"
        >
          {{ year.label }}
        </div>
      </div>
    </div>
  </div>
</div>
