/** @format */

import { MeetingMember } from './meeting-member';
import { MeetingSubject } from './meeting-subject';
import { TasksTemplate } from './tasks-template';

export class Meeting {
  /**
   *  The internal id of the Meeting
   *
   * @type            {string}
   * @memberof        Meeting
   */
  id: string;

  /**
   *  The begin time of the meeting
   *
   * @type            {string}
   * @memberof        Meeting
   */
  beginTime: string;

  /**
   *  The id of consultation type
   *
   * @type            {string}
   * @memberof        Meeting
   */
  consultationTypeId: string;

  /**
   *  The name of consultation type
   *
   * @type            {string}
   * @memberof        Meeting
   */
  consultationTypeName: string;

  /**
   *  The end time of the meeting
   *
   * @type            {string}
   * @memberof        Meeting
   */
  endTime: string;

  /**
   *  The array of meeting members
   *
   * @type            {MeetingMember[]}
   * @memberof        Meeting
   */
  meetingMembers: MeetingMember[];

  /**
   *  The meeting note
   *
   * @type            {string}
   * @memberof        Meeting
   */
  note: string;

  /**
   *  True/false if the meeting notes are already created
   *
   * @type {boolean}
   * @memberof Meeting
   */
  noteExist: boolean;

  /**
   *  The id of the meeting owner
   *
   * @type            {string}
   * @memberof        Meeting
   */
  ownerMemberId: string;

  /**
   *  The name of the meeting owner
   *
   * @type            {string}
   * @memberof        Meeting
   */
  ownerMemberName: string;

  /**
   *  The status of the Meeting ['0', '1', '2']
   *
   * @type            {number}
   * @memberof        Meeting
   */
  status: number;

  /**
   *  The list of meeting subjects
   *
   * @type            {number}
   * @memberof        Meeting
   */
  subjects: MeetingSubject[];

  /**
   *  The team id of the meeting
   *
   * @type            {string}
   * @memberof        Meeting
   */
  teamId: string;

  /**
   *  The title of the meeting
   *
   * @type            {string}
   * @memberof        Meeting
   */
  title: string;

  /**
   *  The list of ids of attached taskTemplates
   *
   * @type            {string[]}
   * @memberof        Meeting
   */
  taskTemplateIds: string[];

  /**
   *  The embedded objects to the meeting
   *
   * @type            {string[]}
   * @memberof        Meeting
   */
  _embedded: {
    /**
     * The list of embedded task templates
     *
     * @type {TasksTemplate[]}
     */
    taskTemplate: TasksTemplate[];
  };
}

export const MEETING_STATUS = ['Gepland', 'Geannuleerd', 'Gedaan'];
