/** @format */

import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';

@Directive() // DIRECTIVE?
@Injectable()
export class MenuTogglerService {
  isOpen = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle() {
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
  }
}
