/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { TeamGroup } from '../../models/team-group';
import { SharedService } from '../../services/shared.service';
import { Subscription } from 'rxjs';

/**
 *  The main component for teams management.
 *
 * @export
 * @class         TeamsManagementComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-teams-management',
  templateUrl: './teams-management.component.html',
  styleUrls: ['./teams-management.component.scss'],
})
export class TeamsManagementComponent implements OnInit, OnDestroy {
  /**
   *  The current SelectionModel of the TeamGroup.
   *
   * @memberof        TeamsManagementComponent
   */
  selectedTeamGroup: SelectionModel<TeamGroup>;

  /**
   *  The subscription of the sharedService
   *
   * @memberof        TeamsManagementComponent
   */
  sharedServiceSubs: Subscription;

  /**
   * Creates an instance of TeamsManagementComponent.
   *
   * @param       {SharedService}         sharedService The instance of service that shares the values between components
   * @memberof    TeamsManagementComponent
   */
  constructor(private sharedService: SharedService) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof    TeamsManagementComponent
   */
  ngOnInit() {
    this.sharedServiceSubs = this.sharedService.currentTeamGroup$.subscribe(
      (teamGroupSelection) => {
        this.selectedTeamGroup = teamGroupSelection;
      },
    );
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof    TeamsManagementComponent
   */
  ngOnDestroy() {
    this.sharedServiceSubs.unsubscribe();
    this.sharedService.newSelectedTeamGroup(
      new SelectionModel<TeamGroup>(false, []),
    );
  }
}
