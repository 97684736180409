/** @format */

import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { PMR } from '../../models/pmr';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { PMRItem } from '../../models/pmr-item';

/**
 *  Show the PMR tab with the active PMR
 *
 * @export
 * @class PmrTabComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pmr-tab',
  templateUrl: './pmr-tab.component.html',
  styleUrls: ['./pmr-tab.component.scss'],
})
export class PmrTabComponent implements OnInit {
  /**
   *  The pmr selection model passed from the child component
   *
   * @type {SelectionModel<PMR>}
   * @memberof PmrTabComponent
   */
  pmrSelection: SelectionModel<PMR>;

  /**
   *  Read rights to the PMR tab
   *
   * @memberof PmrTabComponent
   */
  crudR = false;

  /**
   *  The rights of current user
   *
   * @type            {UserRights}
   * @memberof        PmrTabComponent
   */
  currentUserRights: UserRights;

  /**
   *  The updated PMR item (only if it has status Afgerond)
   *
   * @type            {PMRItem}
   * @memberof        PmrTabComponent
   */
  updatedPmrItem: PMRItem;

  /**
   * Creates an instance of PmrTabComponent.
   *
   * @memberof PmrTabComponent
   */
  constructor(private userValidatorService: UserValidatorService) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof PmrTabComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.checkPermissions();
  }

  /**
   *  Assign new value from the child component
   *
   * @param {*} event
   * @memberof PmrTabComponent
   */
  onPmrChange(event: any) {
    this.pmrSelection = event;
  }

  /**
   *  Refetch the pmrs list when the item change
   *
   * @param {*} event
   * @memberof PmrTabComponent
   */
  onPmrItemChange(event: any) {
    this.updatedPmrItem = event;
  }

  /**
   *  Check the current user permissions
   *
   * @memberof PmrTabComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      if (
        perm.permissions.indexOf('READ_PMR') > -1 ||
        perm.permissions.indexOf('READ_ALL_PMR') > -1
      ) {
        this.crudR = true;
      }
    }
  }
}
