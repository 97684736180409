<!-- @format -->

<mat-card
  class="mat-elevation-z0"
  fxLayout="row"
  fxLayoutAlign="start none"
>
  <div
    class="kms-auth"
    fxFlex="50%"
  >
    <div class="kms-auth-form">
      <h3
        class="mat-subheading-2"
        i18n="choose-team-dialog dialog-title"
      >
        Hoi {{ currentUser.emailAddress }}! Kies een praktijk
      </h3>

      <app-practice-select-dropdown
        isPracticeChooseAfterLogin="true"
        (chosenMember)="onMemberChoose($event)"
      ></app-practice-select-dropdown>

      <button
        mat-flat-button
        color="primary"
        (click)="onMemberSubmit(selectedMember)"
        [disabled]="!selectedMember"
        type="submit"
        i18n="submit button @@buttonSubmit"
      >
        Opslaan
      </button>
    </div>
  </div>
</mat-card>
