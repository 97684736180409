/** @format */

import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
  inject
} from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SharedService } from '../../services/shared.service';

import { Theme } from '../../models/theme';
import {
  Improvement,
  // IMPROVEMENT_STATUS,
  IMPROVEMENT_RESPONSE_STATUS,
  NEW_ORDERED_IMPROVEMENT_STATUSES,
} from '../../models/improvement';
import { ImprovementsService } from '../../services/improvements.service';
import { Executioner } from '../../models/executioner';
import { Member } from '../../models/member';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Attachment } from '../../models/attachment';
import { HttpClient } from '@angular/common/http';
import { SunburstGraphService } from '../../services/sunburst-graph.service';
import { CHART_TYPES } from '../../models/sunburst';

/**
 *  Add/edit incident dialog.
 *
 * @export
 * @class AddEditImprovementComponent
 * @extends {AddEditAbstractDialog<Task>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-improvement',
  templateUrl: './add-edit-improvement.component.html',
  styleUrls: ['./add-edit-improvement.component.scss'],
})
export class AddEditImprovementComponent
  extends AddEditAbstractDialog<Improvement>
  implements OnInit, OnDestroy {
  /**
   *  The list of attachment ids.
   *
   * @type                  {string[]}
   * @memberof              AddEditImprovementComponent
   */
  attachmentIdsList: string[];

  /**
   *  The list of related attachments
   *
   * @type                  {Attachment[]}
   * @memberof              AddEditImprovementComponent
   */
  attachmentsList: Attachment[];

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditImprovementComponent
   */
  formErrors = {
    subject: '',
    description: '',

    theme: '',
    subTheme: '',

    rootCause: '',
    measurement: '',

    guard: '',
    guardComment: '',

    implementedOn: '',
    registeredOn: '',
    plannedOn: '',

    efficient: '',
    conclusion: '',

    status: '',

    newLinkedAttachmentIds: '',
    newUnLinkedAttachmentIds: '',

    coRemark: '',
    checkbox: '',
    sunburstId: ''
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditImprovementComponent
   */
  validationMessages = {
    subject: {
      required: 'Onderwerp is een verplicht veld.',
    },
    implementedOn: {
      required: 'Implementatiedatum is een verplicht veld.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    registeredOn: {
      required: 'Melddatum is een verplicht veld.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    plannedOn: {
      required: 'Evaluatiedatum is een verplicht veld.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    coRemark: {},
    checkbox: {},
    sunburstId: {}
  };

  /**
   *  The improvement created by the API
   *
   * @type                    {Improvement}
   * @memberof                AddEditImprovementComponent
   */
  createdImprovement: Improvement;

  /**
   *  The Theme list of incident
   *
   * @type                    {Theme[]}
   * @memberof                AddEditImprovementComponent
   */
  currentThemesList: Theme[];

  /**
   *  The current member
   *
   * @type                    {Member}
   * @memberof                AddEditImprovementComponent
   */
  currentMember: Member;

  /**
   *  update rights for the current member
   *
   * @type                    {'NONE'/'ALL'/'EXECUTIONER'}
   * @memberof                AddEditImprovementComponent
   */
  crudUpdate = 'NONE';

  /**
   *  The list of executioners assigned to the improvement
   *
   * @type                    {Executioner[]}
   * @memberof                AddEditImprovementComponent
   */
  executionersList: Executioner[];

  /**
   *  The list of executioners members
   *
   * @memberof               AddEditImprovementComponent
   */
  executionersMembers: string[];

  /**
   *  Form Control of the filter teams
   *
   * @type                  {FormControl}
   * @memberof              AddEditImprovementComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  The list of team members
   *
   * @memberof                AddEditImprovementComponent
   */
  filteredMembers: Observable<Member[]>;

  /**
   *  The subscription of filtered Members
   *
   * @memberof                AddEditImprovementComponent
   */
  filteredMembersSubs: Subscription;

  /**
   *  True/false if the form should be disabled
   *
   * @memberof AddEditImprovementComponent
   */
  formDisabled = false;

  /**
   *  The list of attachments linked to the parent object in the current session
   *
   * @type {string[]}
   * @memberof AddEditImprovementComponent
   */
  newLinkedAttachmentsList: string[];

  /**
   *  The list of attachments unlinked from the parent object in the current session
   *
   * @type {string[]}
   * @memberof AddEditImprovementComponent
   */
  newUnlinkedAttachmentsList: string[];

  /**
   *  The member selected in the filter form
   *
   * @type {Member}
   * @memberof AddEditImprovementComponent
   */
  selectedMember: Member;

  /**
   *  The Theme list of incident with ids as keys
   *
   * @type                    {}
   * @memberof                AddEditImprovementComponent
   */
  themesListWithIds: {};

  /**
   *  The expansion panel step.
   *
   * @memberof                AddEditImprovementComponent
   */
  step = 0;

  /**
   *  Current date
   *
   * @memberof                AddEditImprovementComponent
   */
  today = new Date();

  /**
   *  The list of improvement statuses
   *
   * @memberof                AddEditImprovementComponent
   */
  improvementStatuses = NEW_ORDERED_IMPROVEMENT_STATUSES;

  /**
   *  The list of improvement response statuses
   *
   * @memberof                AddEditImprovementComponent
   */
  improvementsResponseStatuses = IMPROVEMENT_RESPONSE_STATUS;

  graphCellId: string;
  graphType: CHART_TYPES;

  isUserACoCoachOrCoAdmin: boolean;

  linkedToSunburstInfo = '';

  /**
   * Creates an instance of AddEditImprovementComponent.
   *
   * @param        {MatDialogRef<AddEditImprovementComponent>}   dialogRefA              The instance of MatDialogRef
   * @param        {*}                                           data                    The data passed from parent component
   * @param        {IncidentsService}                            improvementsService
   * @param        {FormBuilder}                                 fbA
   * @param        {SharedService}                               sharedService           The instance of sharedService
   * @memberof     AddEditImprovementComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditImprovementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private improvementsService: ImprovementsService,
    private ref: ChangeDetectorRef,
    private fbA: UntypedFormBuilder,
    private warningDialog: MatDialog,
    public http: HttpClient,
    public sharedService: SharedService
  ) {
    super(dialogRefA, data, improvementsService, fbA, warningDialog, http);
  }

  sunburstGraphService = inject(SunburstGraphService);

  #handleCheckboxAndCoRemark(): void {
    const currentMember = this.currentUserRights.currentMember;
    const isUserACoCoachOrCoAdmin = currentMember.teamRole.name === 'TEAM_GROUP_ADMIN' || currentMember.teamRole.name === 'ORG_COACH' || currentMember.teamRole.name === 'ORG_ADMIN' // ORG_COACH taken from jira, TEAM_GROUP_ADMIN taken from application storage
    isUserACoCoachOrCoAdmin ? this.newFormGroup.get('checkbox').enable() : this.newFormGroup.get('checkbox').disable()
    isUserACoCoachOrCoAdmin ? this.newFormGroup.get('coRemark').enable() : this.newFormGroup.get('coRemark').disable()
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditImprovementComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.graphCellId = this.data.graphCellId;
    this.graphType = this.data.graphType;

    this.warningDialogParameters.data.message = `Er zijn nog niet opgeslagen wijzigingen. Weet je zeker dat je wilt annuleren?`;

    this.currentThemesList = this.sharedService.currentThemesList.getValue();
    this.themesListWithIds = this.sharedService.currentThemesWithIds.getValue();

    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));
    this.currentMember = this.currentUserRights.currentMember;
    this.isUserACoCoachOrCoAdmin = this.currentMember.teamRole.name === 'TEAM_GROUP_ADMIN' || this.currentMember.teamRole.name === 'ORG_COACH' || this.currentMember.teamRole.name === 'ORG_ADMIN' // ORG_COACH taken from jira, TEAM_GROUP_ADMIN taken from application storage

    this.newLinkedAttachmentsList = [];
    this.newUnlinkedAttachmentsList = [];

    if (this.initialFormValues.executionerMembers[0] && !this.formIsNew) {
      this.executionersList = this.initialFormValues.executionerMembers[0];
    } else {
      this.initialFormValues.executionerMembers[0] = [];
      this.executionersList = this.initialFormValues.executionerMembers[0];
    }

    this.createForm(this.initialFormValues);

    if (!this.formIsNew && this.data.improvement) {
      this.createdImprovement = this.data.improvement;

      this.checkPermissions();

      if (this.crudUpdate === 'NONE' || this.data.improvement.status === 1) {
        this.newFormGroup.disable();
        this.filterControl.disable();
        this.formDisabled = true;
      }

      this.improvementsService
        .getSingleImprovement(
          this.createdImprovement.id,
          this.currentUserRights.currentTeamId,
        )
        .subscribe((response) => {
          this.attachmentIdsList = response.attachmentIds
            ? response.attachmentIds.slice()
            : [];

          if (response._embedded && response._embedded.attachment) {
            this.attachmentsList = response._embedded.attachment;
          } else {
            this.attachmentsList = [];
          }
        });
    } else if (this.formIsNew) {
      this.crudUpdate = 'ALL';
      this.attachmentIdsList = [];
      this.attachmentsList = [];
    }

    this.addExecutionerFilter();

    //  Add the child component
    this.childComponentsList = [
      {
        value: this.executionersList,
        fieldName: 'executionerMembers',
        hasChanged: false,
        invalid: false,
        separateRequests: false,
        teamId: this.currentUserRights.currentTeamId,
        emptyFormValues: {
          comment: '',
          efficient: false,
          evaluationDone: false,
          teamId: this.currentUserRights.currentTeamId,
          memberId: '',
          memberName: '',
          temporaryFields: {
            changed: false,
            removed: false,
            isNew: true,
            expanded: true,
            invalid: false,
          },
        },
      },
    ];
  }

  /**
   *  Add the filter of executioners
   *
   * @memberof AddEditImprovementComponent
   */
  addExecutionerFilter() {
    // Create list of executioners members
    this.executionersMembers = [];
    if (this.createdImprovement && this.createdImprovement.executionerMembers) {
      for (const executioner of this.createdImprovement.executionerMembers) {
        this.executionersMembers.push(executioner.memberId);
      }
    }

    this.filteredMembersSubs =
      this.sharedService.currentTeamMembersList$.subscribe((membersList) => {
        let memberslist = [];

        memberslist = membersList.filter(this.notCurrentExecutioner, this);

        this.filteredMembers = this.filterControl.valueChanges.pipe(
          startWith(''),
          map((val) => {
            memberslist = membersList.filter(this.notCurrentExecutioner, this);

            if (typeof val === 'string' && val === '') {
              this.onOptionSelect();

              return memberslist;
            } else if (typeof val === 'string' && val !== '') {
              return this.filterMember(memberslist, val);
            } else if (typeof val === 'object' && val.memberName) {
              return this.filterMember(memberslist, val.memberName);
            }
          }),
        );
      });
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddEditImprovementComponent
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fbA.group(data);
    this.#handleCheckboxAndCoRemark();
    this.#handleSunburstIdControl();
    this.constructLinkedToGraphInformation();

    this.newFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((changes) => this.onFormChanged(changes));

    this.onFormChanged();
  }

  /**
   *  Check the current user permissions
   *
   * @memberof AddEditImprovementComponent
   */
  checkPermissions() {
    const permissions = this.currentMember.teamRole.permissions;

    if (permissions && permissions.indexOf('UPDATE_ALL_IMPROVEMENT') > -1) {
      this.crudUpdate = 'ALL';
    } else if (
      this.createdImprovement._embedded &&
      this.createdImprovement._embedded.createdBy &&
      this.createdImprovement._embedded.createdBy.id === this.currentMember.id
    ) {
      this.crudUpdate = 'ALL';
    } else if (
      this.createdImprovement._embedded &&
      this.createdImprovement._embedded.guard &&
      this.createdImprovement._embedded.guard.id === this.currentMember.id
    ) {
      this.crudUpdate = 'ALL';
    }
  }

  /**
   *  Set the step of the expansion panel.
   *
   * @param     {number}                    index
   * @memberof  AddEditImprovementComponent
   */
  setStep(index: number) {
    this.step = index;
  }

  /**
   *  Set the next step of the expansion panel.
   *
   * @memberof AddEditImprovementComponent
   */
  nextStep() {
    this.step++;
  }

  /**
   *  Set the previous step of the expansion panel.
   *
   * @memberof AddEditImprovementComponent
   */
  prevStep() {
    this.step--;
  }

  /**
   *  Link the item to the parent component
   *
   * @param {*} item
   * @memberof AddEditImprovementComponent
   */
  onAttachmentLink(item: any) {
    if (item && item.id) {
      this.newLinkedAttachmentsList.push(item.id);

      this.newFormGroup.patchValue({
        newLinkedAttachmentIds: this.newLinkedAttachmentsList,
      });

      this.childChanged = true;

      if (!this.attachmentsList) {
        this.attachmentsList = [item];
      } else {
        this.attachmentsList.push(item);
      }

      //  Workaround to make change detector working in the child component
      this.attachmentsList = this.attachmentsList.slice();
    }
  }

  /**
   *  Respond to the unlink of the child item
   *
   * @param {*} item
   * @memberof AddEditImprovementComponent
   */
  onAttachmentUnlink(item: Attachment) {
    if (item && item.id) {
      this.childChanged = true;

      this.newUnlinkedAttachmentsList.push(item.id);

      if (this.newFormGroup.get('newUnLinkedAttachmentIds')) {
        this.newFormGroup
          .get('newUnLinkedAttachmentIds')
          .patchValue(this.newUnlinkedAttachmentsList);
      }

      const index = this.attachmentsList.indexOf(item);

      if (index > -1) {
        this.attachmentsList.splice(index, 1);

        //  Workaround to make change detector working in the child component
        this.attachmentsList = this.attachmentsList.slice();
      }
    }
  }

  /**
   *  Respond to the change of subTheme in dropdown list
   *
   * @param {*} change
   * @memberof AddEditImprovementComponent
   */
  onSubThemeChange(change: any) {
    if (this.formIsNew) {
      const ownerships = this.sharedService.subThemesOwnershipList.getValue();

      if (change.value && change.value.id && ownerships[change.value.id]) {
        if (
          !this.executionersList.length &&
          ownerships[change.value.id]._embedded.members &&
          ownerships[change.value.id]._embedded.members[1]
        ) {
          const emptyFormValuesClone = JSON.parse(
            JSON.stringify(this.childComponentsList[0].emptyFormValues),
          );

          emptyFormValuesClone.memberId =
            ownerships[change.value.id]._embedded.members[1].id;
          emptyFormValuesClone.memberName =
            ownerships[change.value.id]._embedded.members[1].memberName;

          this.childComponentsList[0].value.push(emptyFormValuesClone);

          this.childComponentsList[0].invalid = true;
          this.childComponentsList[0].hasChanged = true;
          this.childInvalid = true;
          this.childChanged = true;
          this.formSubmitted = false;
        }

        const _guard = ownerships[change.value.id]._embedded.member
          ? ownerships[change.value.id]._embedded.member
          : ownerships[change.value.id]._embedded.members[0];

        this.newFormGroup.patchValue({
          guard: _guard,
        });

        this.ref.detectChanges();
      }
    }
  }

  /**
   *  Set the disabled attribute for the specific form fields.
   *
   * @memberof AddEditImprovementComponent
   */
  onFormChanged(data?: any) {
    if (
      this.formIsNew ||
      (!this.formChanged && this.newFormGroup.get('status').value !== 0) ||
      this.crudUpdate !== 'ALL'
    ) {
      // commented tou since I don't know the purpose & the logic blocks edit flow
      // if (this.newFormGroup.get('status').enabled) {
      // this.newFormGroup.get('status').disable();
      // }
      // } else if (this.newFormGroup.get('status').disabled) {
      //   this.newFormGroup.get('status').enable();
    }

    if (data) {
      this.onFormValueChanged(data);
    } else {
      this.onFormValueChanged();
    }
  }

  /**
   *  Function that maps an option's control value to its display value in the trigger.
   *
   * @param    {Member} [member]
   * @returns  {(string | undefined)}
   * @memberof AddEditImprovementComponent
   */
  displayFn(member?: Member): string | undefined {
    return member ? member.memberName : undefined;
  }

  /**
   * Check if the member of team is already an executioner of current improvement
   *
   * @param {*} element
   * @param {*} index
   * @param {*} array
   * @memberof AddEditImprovementComponent
   */
  notCurrentExecutioner(element, index, array) {
    let exsists = false;

    for (const executioner of this.executionersMembers) {
      if (element.id === executioner) {
        exsists = true;

        break;
      }
    }

    return !exsists;
  }

  /**
   *  Filter the list of Members.
   *
   * @param         {string}            val
   * @returns       {Member[]}
   * @memberof      AddEditImprovementComponent
   */
  filterMember(membersList: Member[], val: string): Member[] {
    return membersList.filter(
      (option) =>
        option.memberName.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
  }

  /**
   *  Add a new child item to the childComponent list
   *
   * @param    {Event}  [event]
   * @param    {number} [childsListIndex]
   * @memberof AddEditImprovementComponent
   */
  onChildItemAdd(event?: Event, childsListIndex?: number) {
    if (event) {
      event.stopImmediatePropagation();
    }

    if (
      this.childComponentsList &&
      this.childComponentsList.length &&
      this.childComponentsList[childsListIndex] &&
      this.selectedMember
    ) {
      const emptyFormValuesClone = JSON.parse(
        JSON.stringify(
          this.childComponentsList[childsListIndex].emptyFormValues,
        ),
      );

      this.childComponentsList[childsListIndex].value.push(
        emptyFormValuesClone,
      );

      this.childComponentsList[childsListIndex].invalid = false;
      this.childComponentsList[childsListIndex].hasChanged = true;
      this.childChanged = true;
      this.formSubmitted = false;
      this.executionersMembers.push(this.selectedMember.id);

      this.selectedMember = null;
      this.childComponentsList[childsListIndex].emptyFormValues.memberId = '';
      this.childComponentsList[childsListIndex].emptyFormValues.memberName = '';
      this.filterControl.reset('');
    }
  }

  /**
   *  Respond to the executioner delete intent
   *
   * @param    {*}                 item
   * @param    {number}            childListIndex
   * @param    {Event}             [event]
   * @param    {MatExpansionPanel} [element]
   * @param    {number}            [ind]
   * @memberof AddEditImprovementComponent
   */
  onExecutionerDelete(
    item: any,
    childListIndex: number,
    event?: Event,
    element?: MatExpansionPanel,
    ind?: number,
  ) {
    if (
      item &&
      item.memberId &&
      item.temporaryFields &&
      item.temporaryFields.isNew
    ) {
      const index = this.executionersMembers.indexOf(item.memberId);

      if (index > -1) {
        this.executionersMembers.splice(index, 1);

        this.filterControl.reset('');
      }
    }

    this.onChildItemDelete(item, childListIndex, event, element, ind);
  }

  /**
   *  Respond to the selection of the Member in members list
   *
   * @param       {Member}              option
   * @memberof    AddEditImprovementComponent
   */
  onOptionSelect(option?: Member) {
    if (!option) {
      this.selectedMember = null;
      this.childComponentsList[0].emptyFormValues.memberId = '';
      this.childComponentsList[0].emptyFormValues.memberName = '';
    } else if (option.id) {
      this.selectedMember = option;
      this.childComponentsList[0].emptyFormValues.memberId = option.id;
      this.childComponentsList[0].emptyFormValues.memberName =
        option.memberName;
    }
  }

  #handleSunburstIdControl(): void {
    const sunburstIdControl = this.newFormGroup.controls.sunburstId;

    if (!this.formIsNew) {
      if (this.newFormGroup.controls.sunburstId.value) {
        sunburstIdControl.enable();
      } else {
        sunburstIdControl.disable();
      }
      return;
    }

    if (this.graphCellId) {
      const contactFormValue = this.sunburstGraphService.contactFormData$.getValue();
      sunburstIdControl.enable();
      sunburstIdControl.setValue(`${contactFormValue.graphType}/${contactFormValue.dimensionId}/${contactFormValue.questionId || ''}`)
    } else {
      sunburstIdControl.disable();
    }
  }

  constructLinkedToGraphInformation(): void {
    if (this.newFormGroup.controls.sunburstId.value) {
      const separateIds = this.newFormGroup.controls.sunburstId.value.split('/');

      const infoMainPart: string = `${separateIds[0]} > ${separateIds[1]} `;
      const infoAdditionalPart: string = `> ${separateIds[2]}`;

      this.linkedToSunburstInfo = separateIds[2] ? infoMainPart + infoAdditionalPart : infoMainPart;
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof AddEditImprovementComponent
   */
  ngOnDestroy() {
    if (this.filteredMembersSubs) {
      this.filteredMembersSubs.unsubscribe();
    }
  }
}
