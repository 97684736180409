/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { AgendaTemplate } from '../../models/agenda-template';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { AgendaTemplatesService } from '../../services/agenda-templates.service';
import { UntypedFormBuilder } from '@angular/forms';
import { AgendaTemplateSubject } from '../../models/agenda-template-subject';
import { UserValidatorService } from '../../services/user-validator.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

/**
 *  Add edit agenda template
 *
 * @export
 * @class AddEditAgendaTemplateComponent
 * @extends {AddEditAbstractDialog<AgendaTemplate>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-agenda-template',
  templateUrl: './add-edit-agenda-template.component.html',
  styleUrls: ['./add-edit-agenda-template.component.scss'],
})
export class AddEditAgendaTemplateComponent
  extends AddEditAbstractDialog<AgendaTemplate>
  implements OnInit
{
  /**
   *  True/false if any child form is invalid
   *
   * @memberof AddEditAgendaTemplateComponent
   */
  childInvalid = false;

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditAgendaTemplateComponent
   */
  formErrors = {
    name: '',
    description: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditAgendaTemplateComponent
   */
  validationMessages = {
    name: {
      required: 'Naam is een verplicht veld.',
    },
  };

  /**
   *  The index of new subject
   *
   * @memberof AddEditAgendaTemplateComponent
   */
  newSubjectIndex = 0;

  /**
   *  The current agenda template object
   *
   * @type     {AgendaTemplate}
   * @memberof AddEditAgendaTemplateComponent
   */
  savedAgendaTemplate: AgendaTemplate;

  /**
   *  The list of agenda templates subjects
   *
   * @type     {AgendaTemplateSubject[]}
   * @memberof AddEditAgendaTemplateComponent
   */
  subjectsList: AgendaTemplateSubject[];

  /**
   * Creates an instance of AddEditAgendaTemplateComponent.
   *
   * @param    {MatDialogRef<AddEditAgendaTemplateComponent>} dialogRefA
   * @param    {*}                                            data
   * @param    {AgendaTemplatesService}                       agendaTemplatesService
   * @param    {FormBuilder}                                  fbA
   * @memberof AddEditAgendaTemplateComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditAgendaTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private agendaTemplatesService: AgendaTemplatesService,
    private fbA: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
    private warningDialog: MatDialog,
  ) {
    super(dialogRefA, data, agendaTemplatesService, fbA, warningDialog);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditAgendaTemplateComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.warningDialogParameters.data.message = `Er zijn nog niet opgeslagen wijzigingen. Weet je zeker dat je wilt annuleren?`;

    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.savedAgendaTemplate = this.data.element;

    if (this.initialFormValues.subjects[0] && !this.formIsNew) {
      this.subjectsList = this.initialFormValues.subjects[0];
    } else {
      this.initialFormValues.subjects[0] = [];
      this.subjectsList = this.initialFormValues.subjects[0];
    }

    this.createForm(this.initialFormValues);

    this.childComponentsList = [
      {
        value: this.subjectsList,
        fieldName: 'subjects',
        hasChanged: false,
        invalid: false,
        separateRequests: false,
        teamId: this.currentUserRights.currentTeamId,
        emptyFormValues: {
          name: '',
          description: '',
          sequence: this.subjectsList.length + 1,
          teamId: this.currentUserRights.currentTeamId,
          temporaryFields: {
            changed: false,
            removed: false,
            isNew: true,
            expanded: true,
            invalid: true,
          },
        },
      },
    ];
  }

  /**
   *  Drop the dragged element in the list
   *
   * @param {CdkDragDrop<string[]>} event
   * @memberof AddEditAgendaTemplateComponent
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subjectsList, event.previousIndex, event.currentIndex);

    for (let i = 0; i < this.subjectsList.length; i++) {
      if (this.subjectsList[i].sequence !== i + 1) {
        this.subjectsList[i].sequence = i + 1;

        if (this.subjectsList[i].temporaryFields) {
          this.subjectsList[i].temporaryFields.changed = true;
        } else {
          this.subjectsList[i].temporaryFields = {
            changed: true,
          };
        }

        this.formHasChanged();
      }
    }

    this.formChanged = true;
  }
}
