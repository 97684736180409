/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { ImprovementItem } from '../../models/improvement_item';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImprovementItemsService } from '../../services/improvement-items.service';
import { UntypedFormBuilder } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { IMPROVEMENT_STATUS } from '../../models/improvement-group';

import * as moment from 'moment';

/**
 *  Add/edit improvement-item dialog
 *
 * @export
 * @class         AddEditImprovementItemComponent
 * @extends       {AddEditAbstractDialog<ImprovementItem>}
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-add-edit-improvement-item',
  templateUrl: './add-edit-improvement-item.component.html',
  styleUrls: ['./add-edit-improvement-item.component.scss'],
})
export class AddEditImprovementItemComponent
  extends AddEditAbstractDialog<ImprovementItem>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditImprovementItemComponent
   */
  formErrors = {
    description: '',
    responsibleMember: '',
    periodEndDate: '',
    status: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditImprovementItemComponent
   */
  validationMessages = {
    description: {
      required: 'Voer a.u.b. een verbeteractie in.',
    },
    responsibleMember: {
      required: 'Voer a.u.b. een actiehouder in.',
    },
    periodEndDate: {
      required: 'Voer a.u.b. een deadline in.',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
  };

  /**
   *  The list of improvement statuses
   *
   * @memberof                AddEditImprovementItemComponent
   */
  improvementStatuses = IMPROVEMENT_STATUS;

  /**
   * Creates an instance of AddEditImprovementItemComponent.
   *
   * @param     {MatDialogRef<AddEditImprovementItemComponent>}       dialogRefA                The instance of MatDialogRef
   * @param     {*}                                                   data                      Data passed from the parent component
   * @param     {ImprovementItemsService}                             improvementItemsService
   * @param     {FormBuilder}                                         fbA
   * @param     {SharedService}                                       sharedService             The instance of sharedService
   * @memberof  AddEditImprovementItemComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditImprovementItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private improvementItemsService: ImprovementItemsService,
    private fbA: UntypedFormBuilder,
    public sharedService: SharedService,
  ) {
    super(dialogRefA, data, improvementItemsService, fbA);
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @memberof    AddEditTaskComponent
   */
  onSubmit() {
    if (this.newFormGroup.get('periodEndDate').value) {
      const periodEndDate = moment(
        this.newFormGroup.get('periodEndDate').value,
      ).format('YYYY-MM-DD');
      this.newFormGroup.patchValue({ periodEndDate: periodEndDate });
    }

    this.newFormGroup.patchValue({
      periodBeginDate: this.newFormGroup.get('periodEndDate').value,
    });

    this.improvementItemsService.put(this.newFormGroup.value).subscribe(
      (newtask) => {
        this.formSubmitted = true;
        this.dialogStateChanged = true;

        this.dialogRef.close({ task: newtask });
      },

      () => {
        this.formSubmitted = false;
      },
    );
  }
}
