/** @format */

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { UserValidatorService } from '../../services/user-validator.service';

import * as moment from 'moment';
import { TasksTemplate } from '../../models/tasks-template';
import { EmbeddedChild } from '../../shared/embedded-child';

/**
 *  Add/edit tasks templates dialog
 *
 * @export
 * @class         AddEditTasksTemplateComponent
 * @extends       {AddEditAbstractDialog<TasksTemplate>}
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-add-edit-tasks-template-embedded',
  templateUrl: './add-edit-tasks-template-embedded.component.html',
  styleUrls: ['./add-edit-tasks-template.component.scss'],
})
export class AddEditTasksTemplateEmbeddedComponent
  extends EmbeddedChild<TasksTemplate>
  implements OnInit
{
  /**
   *  Current date
   *
   * @memberof                AddEditTasksTemplateEmbeddedComponent
   */
  today = moment().format('YYYY-MM-DD');

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditTasksTemplateEmbeddedComponent
   */
  formErrors = {
    description: '',
    title: '',
    startDate: '',
    endDate: '',
    subTheme: '',
    critical: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditTasksTemplateEmbeddedComponent
   */
  validationMessages = {
    title: {
      required: 'Voer a.u.b. een titel in.',
    },
    startDate: {
      required: 'Voer a.u.b. een begindatum in.',
      matDatepickerParse: 'De waarde is niet correct',
    },
    endDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
  };

  /**
   * Creates an instance of AddEditTasksTemplateEmbeddedComponent.
   *
   * @param             {FormBuilder}                           fb
   * @memberof          AddEditTasksTemplateEmbeddedComponent
   */
  constructor(
    private fb: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
    public sharedService: SharedService,
  ) {
    super(fb);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditTasksTemplateEmbeddedComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.setInitialFormValues();

    this.createForm(this.initialFormValues);
  }

  /**
   *  Set the initial values to the child component form
   *
   * @memberof AddEditTasksTemplateEmbeddedComponent
   */
  setInitialFormValues() {
    const editedItemInstance = Object.assign({}, this.editedItem);

    if (editedItemInstance) {
      let freq: any;

      if (editedItemInstance.frequency) {
        freq = editedItemInstance.frequency;
      } else if (
        editedItemInstance._embedded &&
        editedItemInstance._embedded.taskTemplateFrequency
      ) {
        freq = editedItemInstance._embedded.taskTemplateFrequency;
      }

      this.initialFormValues = {
        critical: [editedItemInstance.critical],
        title: [editedItemInstance.title, Validators.required],
        description: [editedItemInstance.description],
        endDate: [
          editedItemInstance.endDate ? editedItemInstance.endDate : null,
        ],
        frequency: [freq, Validators.required],
        privateTaskTemplate: editedItemInstance.privateTaskTemplate,
        responsibleMember: [
          editedItemInstance.responsibleMember
            ? editedItemInstance.responsibleMember
            : this.currentUserRights.currentMember,
        ],
        startDate: [
          editedItemInstance.startDate
            ? editedItemInstance.startDate
            : moment().format('YYYY-MM-DD'),
          Validators.required,
        ],
        teams: [
          [
            editedItemInstance._embedded && editedItemInstance._embedded.team
              ? editedItemInstance._embedded.team
              : this.currentUserRights.currentMember.team,
          ],
          Validators.required,
        ],
        subTheme: [
          editedItemInstance._embedded && editedItemInstance._embedded.subTheme
            ? editedItemInstance._embedded.subTheme
            : null,
        ],
        teamId: editedItemInstance.teamId
          ? editedItemInstance.teamId
          : this.currentUserRights.currentTeamId,
        temporaryFields: {
          changed: editedItemInstance.temporaryFields
            ? editedItemInstance.temporaryFields.changed
            : false,
          removed: editedItemInstance.temporaryFields
            ? editedItemInstance.temporaryFields.removed
            : false,
          invalid: editedItemInstance.temporaryFields
            ? editedItemInstance.temporaryFields.invalid
            : false,
        },
      };
    }

    if (editedItemInstance.id) {
      this.initialFormValues.id = editedItemInstance.id;
    } else {
      this.initialFormValues.temporaryFields.isNew = true;
    }
  }
}
