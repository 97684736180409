<!-- @format -->

<div class="average-scores-block-container">
  <div class="block-name">{{ blockName }}</div>
  <mat-tab-group
    mat-stretch-tabs
    *ngIf="blockTiles"
    class="average-scores-tab-group"
  >
    <mat-tab *ngFor="let tile of blockTiles; let i = index">
      <ng-template mat-tab-label>
        <div
          (click)="onTileClick(tile)"
          class="tile-label"
          [ngClass]="{ selected: selectedTile.isSelected(tile) }"
        >
          <div
            class="smileys smiley-{{
              scores[tile.id]?.average | number: '1.0-0'
            }}"
          ></div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<div
  class="details-view"
  *ngIf="selectedTile.hasValue() && scoreItemsList"
>
  <ng-container *ngIf="teamGroup && subThemes">
    <app-overview-score-item-tile
      *ngFor="let scoreItem of scoreItemsList"
      [scoreItem]="scoreItem"
      [score]="
        scoreItemsScores?.subThemes[selectedTile.selected[0].id]?.scoreItems[
          scoreItem.id
        ]?.average
      "
    >
    </app-overview-score-item-tile>
  </ng-container>
  <ng-container *ngIf="team && subThemes">
    <app-overview-score-item-tile
      *ngFor="let scoreItem of scoreItemsList"
      [scoreItem]="scoreItem"
      [score]="scoreItem._embedded?.score.score"
    >
    </app-overview-score-item-tile>
  </ng-container>
</div>
