/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { SubTheme } from '../../models/subtheme';
import { Theme } from '../../models/theme';

/**
 *  Main component for managing theme.
 *
 * @export
 * @class       ThemeComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent implements OnInit {
  /**
   *  The theme value sent by parent component.
   *
   * @type            {Theme}
   * @memberof        ThemeComponent
   */
  @Input() theme: Theme;

  /**
   *  The list of subthemes existing in the theme
   *
   * @type            {Array<SubTheme>}
   * @memberof        ThemeComponent
   */
  subthemesList: SubTheme[];

  /**
   * Creates an instance of ThemeComponent.
   *
   * @memberof        ThemeComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TheLampComponent
   */
  ngOnInit() {
    if (this.theme._embedded) {
      this.subthemesList = this.theme._embedded.subThemes;
    }
  }
}
