<!-- @format -->

<div class="flex-rows filters-container">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngIf="crudR"
  >
    <mat-expansion-panel-header class="filters-header">
      <mat-panel-title
        class="filters-title"
        i18n="improvement-analysis-list expansion-panel-title"
      >
        Filter
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div
        class="flex-rows"
        [formGroup]="filterForm"
      >
        <mat-form-field appearance="fill">
          <mat-label>Onderwerp</mat-label>
          <input
            type="text"
            matInput
            formControlName="title"
          />
        </mat-form-field>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="improvement-analysis-list button add-new-analysis"
    *ngIf="crudC"
  >
    <mat-icon>add</mat-icon>
    Nieuwe analyse verbetermaatregel
  </button>
</div>

<div
  class="improvement-analysis-list-panel kms-list"
  *ngIf="crudR"
>
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvement-analysis-list list-header"
        >Onderwerp</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvement-analysis-list list-header"
        >Omschrijving</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="IVM">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvement-analysis-list list-header"
        >Verbetermaatregel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.improvementIds?.length ? row.improvementIds?.length : 0 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          *ngIf="crudR"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="
            row.teamId = currentUserRights?.currentTeamId;
            onDeleteIntent(row, $event, deleteDialog)
          "
          *ngIf="crudD"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
