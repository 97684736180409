<!-- @format -->

<div
  class="container"
  #container
>
  <button
    mat-flat-button
    color="primary"
    class="action-retreat"
    [routerLink]="'../list'"
  >
    Terug
  </button>

  <ng-container [ngTemplateOutlet]="singleChart"></ng-container>

  <!-- ToDo uncomment and remove ng-container above when data is ready -->
  <!-- ToDo uncomment data fetching in service! -->
  <!-- <ng-container
    *ngIf="
      chartId && chartId !== chartTypes.COMPLIANCY;
      then singleChart;
      else tabsWithCharts
    "
  ></ng-container> -->
</div>
.

<ng-template #singleChart>
  <div class="graph-container">
    <div class="graph">
      <app-sunburst-graph
        *ngIf="graphData"
        [isDetailsView]="true"
        [isPresentationalGraph]="false"
        [singleGraphData]="graphData"
        [chartId]="chartId"
      ></app-sunburst-graph>
    </div>
    <!-- <app-sunburst-graph-tooltip
      *ngIf="chartId && chartId !== chartTypes.COMPLIANCY"
    ></app-sunburst-graph-tooltip> -->
    <app-sunburst-graph-tooltip></app-sunburst-graph-tooltip>
  </div>
</ng-template>

<!-- ToDo uncomment when data is ready -->
<!-- <ng-template #tabsWithCharts>
  <mat-tab-group
    mat-stretch-tabs
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab [label]="currentYear + 1">
      <ng-container [ngTemplateOutlet]="singleChart"></ng-container>
    </mat-tab>
    <mat-tab [label]="currentYear">
      <ng-container [ngTemplateOutlet]="singleChart"></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template> -->
