<!-- @format -->

<div *ngIf="editedItem">
  <div class="subthema">
    <form [formGroup]="newFormGroup">
      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Dienst/type</mat-label>
          <mat-select
            formControlName="subTheme"
            panelClass="impr-group-panel"
            [compareWith]="compareFn"
          >
            <mat-option [value]="null">--</mat-option>
            <mat-optgroup
              *ngFor="let theme of sharedService.currentThemesList$ | async"
              [label]="theme.name"
            >
              <ng-container *ngIf="theme._embedded">
                <mat-option
                  *ngFor="let subTheme of theme._embedded.subThemes"
                  [value]="subTheme"
                >
                  {{ subTheme.name }}
                </mat-option>
              </ng-container>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </form>
  </div>

  <div class="member">
    <div class="flex-rows">
      <div class="pseudo-form-field team-name">
        {{ this.newFormGroup.get('teams')?.value[0]?.name }}
      </div>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Verantwoordelijke</mat-label>
          <mat-select
            formControlName="responsibleMember"
            required
            [compareWith]="compareFn"
            [disabled]="currentUserRights.organisationAdmin"
          >
            <mat-option
              *ngFor="
                let member of sharedService.currentTeamMembersList$ | async
              "
              [value]="member"
            >
              {{ member.memberName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>

  <div class="status">
    <div class="flex-rows">
      <div class="status-field">
        <span>Status: </span
        >{{ editedItem?.statusOfLastTask === 'COMPLETED' ? 'GEREED' : 'TO DO' }}
      </div>
    </div>
  </div>

  <div class="description">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Titel</mat-label>
        <textarea
          matInput
          formControlName="title"
          required
          matTextareaAutosize
          matAutosizeMinRows="1"
          resizeToFitContent
        ></textarea>
        <mat-error [hidden]="!formErrors.title">{{
          formErrors.title
        }}</mat-error>
      </mat-form-field>
    </form>

    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Omschrijving</mat-label>
        <textarea
          matInput
          formControlName="description"
          matTextareaAutosize
          matAutosizeMinRows="1"
          resizeToFitContent
        ></textarea>
        <mat-error [hidden]="!formErrors.description">{{
          formErrors.description
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="dates-container">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Einddatum</mat-label>
        <input
          type="text"
          matInput
          formControlName="endDate"
          [min]="initialFormValues.temporaryFields.isNew ? today : null"
          [matDatepicker]="pickerEnd"
        />
        <mat-datepicker #pickerEnd></mat-datepicker>
        <mat-error [hidden]="!formErrors.endDate">{{
          formErrors.endDate
        }}</mat-error>
        <mat-icon (click)="pickerEnd.open()">calendar_month</mat-icon>
      </mat-form-field>
    </form>
  </div>
</div>
