/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Nor rights main page, shows up when the user tries to reach the page that he is not allowed
 *
 * @export
 * @class NotRightsPageComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-not-rights-page',
  templateUrl: './not-rights-page.component.html',
  styleUrls: ['./not-rights-page.component.scss'],
})
export class NotRightsPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
