import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class ImprovementReviewGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userRights = JSON.parse(localStorage.getItem('userRights'));
    const allowedRoles = ['ORG_ADMIN', 'ORG_COACH', 'TEAM_GROUP_ADMIN'];
    const isGraphRouteAllowed = allowedRoles.includes(userRights.currentMember.teamRole.name);

    if (isGraphRouteAllowed) {
      return true;
    } else {
      this.router.navigate(['403']);
      return false;
    }
  }
}
