/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MarviqMatTableInlineCrudComponent,
  PagedResult,
  PageSetting,
} from '@iq/ng-core';
import { AgendaTemplate } from '../../models/agenda-template';
import { AgendaTemplatesService } from '../../services/agenda-templates.service';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { UserRights } from '../../models/user-rights';
import { AddEditAgendaTemplateComponent } from '../../dialogs/add-edit-agenda-template/add-edit-agenda-template.component';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

/**
 *  Show the list of agenda templates
 *
 * @export
 * @class AgendaTemplatesListComponent
 * @extends {MarviqMatTableInlineCrudComponent<AgendaTemplate>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-agenda-templates-list',
  templateUrl: './agenda-templates-list.component.html',
  styleUrls: ['./agenda-templates-list.component.scss'],
})
export class AgendaTemplatesListComponent
  extends MarviqMatTableInlineCrudComponent<AgendaTemplate>
  implements OnInit, OnDestroy
{
  /**
   *  The default values of the new Form.
   *
   * @memberof                AgendaTemplatesListComponent
   */
  emptyFormValues = {
    name: ['', Validators.required],
    description: [''],
    subjects: [[]],

    teamId: '',
  };

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              AgendaTemplatesListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                AgendaTemplatesListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The filters form
   *
   * @type                    {FormGroup}
   * @memberof                AgendaTemplatesListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                AgendaTemplatesListComponent
   */
  columnsToDisplay = ['name', 'description', 'subjects', 'CUD'];

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                AgendaTemplatesListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Apply user rights
   *
   * @memberof AgendaTemplatesListComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   *   Creates an instance of AgendaTemplatesListComponent.
   *
   * @param    {AgendaTemplatesService} agendaTemplatesService
   * @param    {FormBuilder}            formBuilder
   * @param    {MatDialog}              dg
   * @memberof AgendaTemplatesListComponent
   */
  constructor(
    private agendaTemplatesService: AgendaTemplatesService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
  ) {
    super(agendaTemplatesService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AgendaTemplatesListComponent
   */
  ngOnInit() {
    this.dialogParameters.data.message = `Weet je zeker dat je deze agenda wilt verwijderen?`;

    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));
    this.queryParams.push({
      name: 'teamId',
      value: this.currentUserRights.currentTeamId,
    });

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.filterForm = this.formBuilder.group({
      name: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          this.filterValues[field] = changes[field];
        }

        this.usersPageSetting.number = 0;
        this.paginator.firstPage();
        this.getRows();
      });

    this.getRows();

    this.checkPermissions();
  }

  /**
   *  Check the current user permissions
   *
   * @memberof IncidentsListComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_AGENDA
      // DELETE_AGENDA
      // CREATE_AGENDA
      // UPDATE_AGENDA
      // READ_ALL_AGENDA
      // DELETE_ALL_AGENDA
      // UPDATE_ALL_AGENDA
      if (
        perm.permissions.indexOf('READ_AGENDA') > -1 ||
        perm.permissions.indexOf('READ_ALL_AGENDA') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_AGENDA') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_AGENDA') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_AGENDA') > -1) {
        this.crudC = true;
      }
      if (
        perm.permissions.indexOf('UPDATE_AGENDA') > -1 ||
        perm.permissions.indexOf('UPDATE_ALL_AGENDA') > -1
      ) {
        this.crudU = true;
      }
    }
  }

  /**
   *  Get the master data to the list
   *
   * @memberof AgendaTemplatesListComponent
   */
  getRows() {
    this.serviceSubscription = this.agendaTemplatesService
      .getAll(
        this.usersPageSetting,
        this.filterValues,
        { createDate: 'desc' },
        this.queryParams,
      )
      .pipe(map((resp: PagedResult<AgendaTemplate>) => resp))
      .subscribe((result) => {
        const results = result.payload;

        this.dataSource.data = results;

        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    AgendaTemplatesListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        id: [row.id],
        name: [row.name ? row.name : null, Validators.required],
        description: [row.description ? row.description : ''],

        subjects: [row.subjects ? row.subjects : []],

        teamId: this.currentUserRights.currentTeamId,
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditAgendaTemplateComponent, {
      data: { formValues: formValues, isNew: isNew, element: row },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          AgendaTemplatesListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
