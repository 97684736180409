<!-- @format -->

<div
  class="impr-group-details-header status-{{ imprGroupForm.value.status }}"
  *ngIf="imprGroupForm"
>
  <div class="group-priority">{{ imprGroupForm.value.prio }}</div>
  <div
    class="group-header"
    #groupHeader
    i18n="improvement-group-details-dialog dialog-title"
    [matTooltip]="selectedScoreItemName"
    matTooltipClass="dialog-tooltip"
    [matTooltipDisabled]="!isOverflown(groupHeader)"
  >
    Details - {{ selectedScoreItemName }}
  </div>
  <mat-icon (click)="onDetailsClose()">clear</mat-icon>
</div>

<div class="impr-group-details-content">
  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
      hideToggle
    >
      <div class="flex-rows">
        <form
          [formGroup]="imprGroupForm"
          class="left-field group-priority-form"
        >
          <mat-form-field appearance="fill">
            <mat-label>Prio</mat-label>
            <mat-select
              formControlName="prio"
              panelClass="impr-group-panel"
              required
            >
              <mat-option
                *ngFor="let prio of improvementGroupPrio"
                [value]="prio"
              >
                {{ prio }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <form
          [formGroup]="imprGroupForm"
          class="group-scoreitem-form"
        >
          <mat-form-field appearance="fill">
            <mat-label>Score item</mat-label>
            <mat-select
              formControlName="scoreItemId"
              panelClass="impr-group-panel"
              required
            >
              <mat-optgroup
                *ngFor="let subtheme of subThemesList"
                [label]="subtheme.name"
              >
                <ng-container *ngIf="subtheme._embedded">
                  <mat-option
                    *ngFor="let scoreItem of subtheme._embedded.scoreItems"
                    [value]="scoreItem.id"
                    (click)="selectedScoreItemName = scoreItem.name"
                  >
                    {{ scoreItem.name }}
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 0"
      (opened)="setStep(0)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="improvement-group-details-dialog expansion-panel-title"
        >
          Knelpunt
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="imprGroupForm">
        <mat-form-field appearance="fill">
          <mat-label>Knelpunt</mat-label>
          <textarea
            matInput
            formControlName="bottleneck"
            required
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
          <mat-error [hidden]="!formErrors.bottleneck">{{
            formErrors.bottleneck
          }}</mat-error>
        </mat-form-field>
      </form>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 1"
      (opened)="setStep(1)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="improvement-group-details-dialog expansion-panel-title"
        >
          Oorzaak
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="imprGroupForm">
        <mat-form-field appearance="fill">
          <mat-label>Oorzaak</mat-label>
          <textarea
            matInput
            formControlName="reason"
            required
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
          <mat-error [hidden]="!formErrors.reason">{{
            formErrors.reason
          }}</mat-error>
        </mat-form-field>
      </form>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 2"
      (opened)="setStep(2)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="improvement-group-details-dialog expansion-panel-title"
        >
          Gevolg
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="imprGroupForm">
        <mat-form-field appearance="fill">
          <mat-label>Gevolg</mat-label>
          <textarea
            matInput
            formControlName="consequence"
            required
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
          <mat-error [hidden]="!formErrors.consequence">{{
            formErrors.consequence
          }}</mat-error>
        </mat-form-field>
      </form>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
          [disabled]="!improvementGroup"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 3"
      (opened)="setStep(3)"
      [disabled]="!improvementGroup"
      *ngIf="improvementGroup"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="improvement-group-details-dialog expansion-panel-title"
        >
          Verbeteracties
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="improvementGroup">
        <ng-container *ngIf="improvementGroup._embedded">
          <app-improvement-item
            *ngFor="
              let item of improvementGroup._embedded.improvementItems;
              let ind = index
            "
            (itemRemoved)="afterItemRemove(ind)"
            (itemChanged)="onItemChange()"
            [improvementGroup]="improvementGroup"
            [improvementItem]="item"
            [index]="ind"
          ></app-improvement-item>
        </ng-container>
        <app-improvement-item
          [index]="
            improvementGroup._embedded
              ? improvementGroup._embedded.improvementItems.length
              : 0
          "
          (itemChanged)="onItemAdd($event)"
          [improvementGroup]="improvementGroup"
        ></app-improvement-item>
        <mat-action-row>
          <button
            mat-flat-button
            color="primary"
            class="action-retreat"
            (click)="prevStep()"
            i18n="previous button @@buttonPrevious"
          >
            Vorige
          </button>
        </mat-action-row>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="impr-group-buttons flex-rows">
  <button
    mat-flat-button
    class="impr-group-button"
    (click)="onGroupDelete()"
    [disabled]="!improvementGroup"
    i18n="delete-improvement-plan button @@buttonDeleteImprovementPlan"
  >
    Verwijder
  </button>
  <button
    mat-flat-button
    *ngIf="!formSubmitted"
    class="impr-group-button"
    (click)="onGroupSave()"
    [disabled]="!formChanged || imprGroupForm.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    *ngIf="formSubmitted"
    mat-flat-button
    class="impr-group-button submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
</div>
