<!-- @format -->

<mat-card
  class="mat-elevation-z0"
  fxLayout="row"
  fxLayoutAlign="start none"
>
  <div
    class="kms-auth"
    fxFlex="50%"
  >
    <div class="kms-auth-form">
      <h3
        class="mat-subheading-2"
        i18n="
          reset-password-form form-title window@@auth.resetPasswordWindowTitle"
      >
        Reset wachtwoord
      </h3>

      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label i18n="reset-password-form form-field-label"
            >Nieuw wachtwoord</mat-label
          >
          <input
            type="password"
            matInput
            i18n-placeholder="reset-password-form form-field-placeholder"
            placeholder=""
            formControlName="password"
            required
          />
          <mat-error [hidden]="!formErrors.password">{{
            formErrors.password
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label
            i18n="
              reset-password-form form-title
              window@@auth.resetPasswordWindowTitle"
            >Herhaal wachtwoord</mat-label
          >
          <input
            type="password"
            matInput
            i18n-placeholder="reset-password-form form-field-placeholder"
            placeholder=""
            formControlName="confirmPassword"
            required
          />
          <mat-error [hidden]="!formErrors.confirmPassword">{{
            formErrors.confirmPassword
          }}</mat-error>
        </mat-form-field>

        <button
          type="submit"
          mat-flat-button
          color="primary"
          (click)="submit()"
          [disabled]="form.invalid || form.disabled"
          i18="send button @@buttonSend"
        >
          Versturen
        </button>
      </form>
    </div>
  </div>
</mat-card>
