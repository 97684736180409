<!-- @format -->

<ng-container *ngIf="selectedTeamGroup && selectedThemes">
  <ng-container
    *ngIf="selectedTeamGroup.hasValue() && avgMatrixByTeamPerSubTheme"
  >
    <ng-container *ngIf="selectedThemes.hasValue()">
      <div class="themes-labels-container">
        <div class="block-name">Onderdeel</div>
        <mat-tab-group
          mat-stretch-tabs
          class="average-scores-tab-group"
        >
          <ng-container *ngFor="let title of selectedThemes.selected">
            <mat-tab *ngFor="let finalTitle of title._embedded.subThemes">
              <ng-template mat-tab-label>
                <div class="tile-label">
                  <div
                    #tileCurrentTheme
                    class="tile-name"
                    [matTooltip]="finalTitle.name"
                    [matTooltipDisabled]="!isOverflown(tileCurrentTheme)"
                  >
                    {{ finalTitle.name }}
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>

      <div
        class="average-scores"
        *ngFor="let team of selectedTeamGroup.selected[0]._embedded?.teams"
      >
        <app-overview-average-scores-block
          [team]="team"
          [subThemes]="selectedThemes.selected"
          [scores]="
            avgMatrixByTeamPerSubTheme[selectedTeamGroup?.selected[0].id]
              ?.teams[team.id]
          "
        >
        </app-overview-average-scores-block>
      </div>
    </ng-container>

    <ng-container *ngIf="!selectedThemes.hasValue()">
      <div class="themes-labels-container">
        <div class="block-name">Onderdeel</div>
        <mat-tab-group
          mat-stretch-tabs
          class="average-scores-tab-group"
        >
          <ng-container
            *ngFor="let title of sharedService.currentThemesList.getValue()"
          >
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="tile-label">
                  <div
                    #tileNotCurrentTheme
                    class="tile-name"
                    [matTooltip]="title.name"
                    [matTooltipDisabled]="!isOverflown(tileNotCurrentTheme)"
                  >
                    {{ title.name }}
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>

      <div
        class="average-scores"
        *ngFor="let team of selectedTeamGroup.selected[0]._embedded?.teams"
      >
        <app-overview-average-scores-block
          [team]="team"
          [themes]="sharedService.currentThemesList.getValue()"
          [scores]="
            avgMatrixByTeamPerSubTheme[selectedTeamGroup.selected[0].id]?.teams[
              team.id
            ]?.themes
          "
        >
        </app-overview-average-scores-block>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!selectedTeamGroup.hasValue()">
    <div class="themes-labels-container aa">
      <div class="block-name">Onderdeel</div>
      <mat-tab-group
        mat-stretch-tabs
        class="average-scores-tab-group"
      >
        <ng-container
          *ngFor="let title of sharedService.currentThemesList.getValue()"
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="tile-label">
                <div
                  #tileTeamGroup
                  class="tile-name"
                  [matTooltip]="title.name"
                  [matTooltipDisabled]="!isOverflown(tileTeamGroup)"
                >
                  {{ title.name }}
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>

    <div class="average-scores">
      <ng-container
        *ngFor="let teamGroup of sharedService.currentTeamGroupsList$ | async"
      >
        <app-overview-average-scores-block
          [teamGroup]="teamGroup"
          [themes]="sharedService.currentThemesList.getValue()"
          [scores]="avgMatrixByTeamPerSubTheme[teamGroup.id]?.themes"
        ></app-overview-average-scores-block>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
