
/** @format */
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { CHART_COLORS, CHART_TYPES, CriteriaCheck, Dimension, GraphData, Question, RawAllGraphsData, RawGraphData, SingleGraphData } from '../models/sunburst';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserValidatorService } from './user-validator.service';
import { TooltipData } from '../components/sunburst-graph-tooltip/sunburst-graph-tooltip.component';
import { ContactFormData } from '../components/graph-contact-form/graph-contact-form.component';

@Injectable()
export class SunburstGraphService {
  graphData$ = new BehaviorSubject<GraphData | null>(null);
  rawGraphData$ = new BehaviorSubject<RawAllGraphsData | null>(null);
  graphSize$ = new BehaviorSubject<number>(0);
  tooltipData$ = new BehaviorSubject<TooltipData>(null);
  contactFormData$ = new BehaviorSubject<ContactFormData>(null);

  #http = inject(HttpClient);
  #userValidatorService = inject(UserValidatorService);

  // replace this with call to backend
  fetchGraphData(): any {
    this.graphData$.next(null);
    const currentYear = new Date().getFullYear();

    const apiPath = environment.apiPath;
    const teamId = this.#userValidatorService.currentUserRights.getValue().currentTeamId;

    const innovationGraphData$ = this.#http.get<RawGraphData>(`${apiPath}innovatie-benchmark-results/${teamId}`).pipe(catchError(() => of(null)));
    const qualityGraphData$ = this.#http.get<RawGraphData>(`${apiPath}quality-benchmark-results/${teamId}`).pipe(catchError(() => of(null)));
    //ToDo uncomment below and remove of(null) when data is ready
    // const compliancyGraphData$ = this.#http.get<RawGraphData>(`${apiPath}compliance-benchmark-results/${teamId}/${currentYear}`).pipe(catchError(() => of(null)));
    const compliancyGraphData$ = of(null);
    const compliancyGraphDataNextYear$ = this.#http.get<RawGraphData>(`${apiPath}compliance-benchmark-results/${teamId}/${currentYear + 1}`).pipe(catchError(() => of(null)));
    const businessOperationsGraphData$ = of(null).pipe(first());

    forkJoin([innovationGraphData$, qualityGraphData$, compliancyGraphData$, compliancyGraphDataNextYear$, businessOperationsGraphData$]).subscribe(
      ([innovationGraphData, qualityGraphData, compliancyGraphData, compliancyGraphDataNextYear, businessOperationsGraphData]) => {
        this.rawGraphData$.next({
          innovationGraph: innovationGraphData,
          qualityGraph: qualityGraphData,
          compliancyGraph: compliancyGraphData,
          compliancyGraphNextYear: compliancyGraphDataNextYear,
          businessOperationsGraph: businessOperationsGraphData
        })

        this.graphData$.next({
          innovationGraph: this.#prepareDataForCharts(innovationGraphData, CHART_TYPES.INNOVATION),
          qualityGraph: this.#prepareDataForCharts(qualityGraphData, CHART_TYPES.QUALITY),
          compliancyGraph: this.#prepareDataForCharts(compliancyGraphData, CHART_TYPES.COMPLIANCY),
          compliancyGraphNextYear: this.#prepareDataForCharts(compliancyGraphDataNextYear, CHART_TYPES.COMPLIANCY_NEXT_YEAR),
          businessOperationsGraph: this.#prepareDataForCharts(businessOperationsGraphData, CHART_TYPES.BUSINESS_OPERATIONS)
        })
      }
    )
  }

  #prepareDataForCharts(data: RawGraphData, graphName: CHART_TYPES): SingleGraphData {
    const transformedData: SingleGraphData = {
      labels: [],
      parents: [],
      colors: [],
      textTemplate: []
    }

    if (!data) {
      return transformedData;
    }

    const { colors, labels, parents, textTemplate } = transformedData;

    labels.push(graphName);
    parents.push('');
    textTemplate.push(graphName)
    colors.push(CHART_COLORS.CENTER);

    data.dimensions.map((dimension) => {
      labels.push(dimension.id);
      parents.push(graphName);
      textTemplate.push(dimension.shortname)
      colors.push(
        this.#handleGraphColor(dimension.criteriaCheck)
      );

      dimension.questions.map((question) => {
        labels.push(question.id);
        parents.push(dimension.id);
        textTemplate.push(question.shortname)
        colors.push(
          this.#handleGraphColor(question.criteriaCheck)
        );
      });
    });

    return transformedData;
  }

  #handleGraphColor(criteriaCheck: CriteriaCheck): CHART_COLORS {
    switch (criteriaCheck) {
      case 'FAILED':
        return CHART_COLORS.NOT_COMPLETED;
      case 'PASSED':
        return CHART_COLORS.COMPLETED;
      case 'UNKNOWN':
      default:
        return CHART_COLORS.UNKNOWN;
    }
  }

  constructTooltipData(parent: string, child: string, graphType: CHART_TYPES): void {
    this.rawGraphData$.pipe(first()).subscribe(val => {
      let parentObj: Question | Dimension = null;
      let childObj: Question | null = null;

      const getCurrentChartData = (): RawGraphData => {
        if (graphType === CHART_TYPES.INNOVATION) {
          return val.innovationGraph;
        } else if (graphType === CHART_TYPES.QUALITY) {
          return val.qualityGraph;
        } if (graphType === CHART_TYPES.COMPLIANCY) {
          // return val.compliancyGraph; // was previously
          return val.compliancyGraphNextYear;
        } if (graphType === CHART_TYPES.BUSINESS_OPERATIONS) {
          return val.businessOperationsGraph;
        }
      }

      const findItem = (): Question | Dimension => {
        if (parent === graphType) {
          const foundDimension = getCurrentChartData().dimensions.find(dimension => dimension.id === child);
          parentObj = foundDimension;
          return foundDimension || null;
        } else {
          const foundParentDimension = getCurrentChartData().dimensions.find(dimension => dimension.id === parent);
          if (foundParentDimension) {
            const childResult = foundParentDimension.questions.find(question => question.id === child)
            parentObj = foundParentDimension;
            childObj = childResult;
            return childResult;
          } else return null;
        }
      }

      const result = findItem();

      if (result) {
        this.tooltipData$.next({ id: result.id, improvementId: result.improvementId, indicator: (result as Question)?.indicator || '', title: result.name, documentationUrl: (result as Question)?.documentationUrl });
        this.contactFormData$.next({ graphType, dimensionId: parentObj.shortname, questionId: childObj?.shortname, cellShortName: result.shortname });
      }
    })
  }

  // replace with graph name once call to be is done
  generateEmptyGraphData(graphName: string): SingleGraphData {
    const emptyGraphData: SingleGraphData = {
      parents: [''],
      labels: [],
      colors: [CHART_COLORS.CENTER],
      textTemplate: []
    }

    const { parents, labels, colors } = emptyGraphData;

    for (let index = 0; index < 25; index++) {
      if (index === 0) {
        labels.push(graphName);
      } else {
        if (index % 3 === 0 || index % 5 === 0 || index % 4 === 0) {
          labels.push(graphName + index + index);
          parents.push(graphName + index);
          colors.push(CHART_COLORS.EMPTY)
        }
        labels.push(graphName + index);
        parents.push(graphName);
        colors.push(CHART_COLORS.EMPTY)
      }
    }
    return emptyGraphData;
  }
}
