/** @format */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { malAuthService, malForgotPasswordPageComponent } from '@iq/ng-auth';

/**
 *  Forgot password page
 *
 * @export
 * @class KmsForgotPasswordPageComponent
 * @extends {malForgotPasswordPageComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'kms-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class KmsForgotPasswordPageComponent
  extends malForgotPasswordPageComponent
  implements OnInit
{
  /**
   * Creates an instance of KmsForgotPasswordPageComponent.
   *
   * @param       {Router}                        _router
   * @param       {malAuthService}                _auth
   * @param       {ActivatedRoute}                _rout
   * @memberof    KmsForgotPasswordPageComponent
   */
  constructor(
    private _router: Router,
    private _auth: malAuthService,
    private _rout: ActivatedRoute,
  ) {
    super(_router, _auth, _rout);
  }
}
