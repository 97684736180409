<!-- @format -->

<div class="flex-rows filters-container">
  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    *ngIf="
      currentUserRights.isCurrentTeamAdmin ||
      currentUserRights.organisationAdmin ||
      currentUserRights.subThemeOwnership?.length > 0
    "
    (click)="onEditIntent(undefined, $event, 'shared')"
    i18n="tasks-templates-list button add-new-cyclic-task"
  >
    <mat-icon>add</mat-icon>
    Nieuwe gedeelde taak
  </button>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    *ngIf="!currentUserRights.organisationAdmin"
    (click)="onEditIntent(undefined, $event, 'private')"
    i18n="tasks-templates-list button add-new-cyclic-task"
  >
    <mat-icon>add</mat-icon>
    Nieuwe persoonlijke taak
  </button>
</div>

<div class="tasks-templates-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="subThemeName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        class="hide-mobile"
        >Thema</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        {{ row._embedded?.subTheme?.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        class="hide-mobile"
        >Titel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
      >
        <ng-container *ngIf="!mobileQuery.matches">Omschrijving</ng-container>
        <ng-container *ngIf="mobileQuery.matches">Tasks templates</ng-container>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="task-template-description"
      >
        <div *ngIf="mobileQuery.matches">
          <div class="info">
            <div class="subtheme">
              {{ row._embedded?.subTheme?.name }}
            </div>
            <div class="smaller-text header">
              {{ row._embedded?.team.name }}
              {{ row.responsibleMember?.memberName }}
            </div>
            <div class="smaller-text">
              <span class="smaller-text header">{{
                row._embedded?.taskTemplateFrequency?.name
              }}</span>
              <span class="mobile-dates"
                >{{ row.startDate | date: 'dd-MM-yyyy' }} -
                {{ row.endDate | date: 'dd-MM-yyyy' }}</span
              >
            </div>
          </div>
        </div>
        <div>{{ row.description }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        class="hide-mobile"
        >Frequentie</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        <div class="smaller-text header">
          {{ row._embedded?.taskTemplateFrequency?.name }}
        </div>
        <div
          class="smaller-text"
          i18n="tasks-templates-list from-date-prefix"
        >
          Van: {{ row.startDate | date: 'dd-MM-yyyy' }}
        </div>
        <div
          class="smaller-text"
          i18n="tasks-templates-list to-date-prefix"
        >
          Naar:
          {{ row.endDate ? (row.endDate | date: 'dd-MM-yyyy') : '-' }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        >Begindatum</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.startDate | date: 'dd-MM-yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        >Einddatum</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.endDate | date: 'dd-MM-yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="teamName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        >Praktijk naam</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row._embedded?.team.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="responisbleMember">
      <mat-header-cell
        *matHeaderCellDef
        i18n="tasks-templates-list list-header"
        class="hide-mobile"
        >Verantwoordelijke</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        <div class="smaller-text header">
          {{ row._embedded?.team.name }}
        </div>
        <div class="smaller-text">
          {{ row.responsibleMember?.memberName }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="critical-private">
      <mat-header-cell
        *matHeaderCellDef
        class="critical-private"
      ></mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="critical-private"
      >
        <mat-icon
          color="warn"
          *ngIf="row.critical"
          matTooltip="Critical"
          >priority_high</mat-icon
        >
        <mat-icon
          color="primary"
          *ngIf="row.privateTaskTemplate"
          matTooltip="Privé"
          >lock</mat-icon
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          *ngIf="checkRowEditingRigths(row)"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
          *ngIf="checkRowEditingRigths(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
