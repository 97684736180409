/**
 * The main model for the dashboard counts
 *
 * @format
 * @export
 * @class DashboardCounts
 */

export class DashboardCounts {
  /**
   *  The id of Dashboard counts
   *
   * @type                            {string}
   * @memberof                        DashboardCounts
   */
  id: string;

  /**
   *  The count of opened, too-late improvements that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  improvementExecutionerLate: number;

  /**
   *  The count of opened, on-time improvements that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  improvementExecutionerTime: number;

  /**
   *  The count of opened, too-late improvements that member is guard of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  improvementGuardLate: number;

  /**
   *  The count of opened, on-time improvements that member is guard of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  improvementGuardTime: number;

  /**
   *  The count of opened, too-late improvements for the whole team (Praktijk)
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  improvementTeamLate: number;

  /**
   *  The count of opened, on-time improvements for the whole team (Praktijk)
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  improvementTeamTime: number;

  /**
   *  The count of external, opened, too-late incidents that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentExternalExecutionerLate: number;

  /**
   *  The count of external, opened, on-time incidents that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentExternalExecutionerTime: number;

  /**
   *  The count of external, opened, too-late incidents that member is guard of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentExternalGuardLate: number;

  /**
   *  The count of external, opened, on-time incidents that member is guard of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentExternalGuardTime: number;

  /**
   *  The count of external, opened, too-late incidents for the whole team
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentExternalTeamLate: number;

  /**
   *  The count of external, opened, on-time incidents for the whole team
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentExternalTeamTime: number;

  /**
   *  The count of internal, opened, too-late incidents that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentInternalExecutionerLate: number;

  /**
   *  The count of internal, opened, on-time incidents that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentInternalExecutionerTime: number;

  /**
   *  The count of internal, opened, too-late incidents that member is guard of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentInternalGuardLate: number;

  /**
   *  The count of internal, opened, on-time incidents that member is guard of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentInternalGuardTime: number;

  /**
   *  The count of internal, opened, too-late incidents for the whole team
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentInternalTeamLate: number;

  /**
   *  The count of internal, opened, on-time incidents for the whole team
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  incidentInternalTeamTime: number;

  /**
   *  The id of the member
   *
   * @type                            {string}
   * @memberof                        DashboardCounts
   */
  memberId: string;

  /**
   *  The count of opened, too-late tasks that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  taskLate: number;

  /**
   *  The count of opened, on-time tasks that member is executioner of
   *
   * @type                            {number}
   * @memberof                        DashboardCounts
   */
  taskTime: number;
}

export const DASHBOARD_CATEGORIES = [
  {
    name: 'incidents-combined',
    label: 'Meldingen',
    rights: ['READ_INCIDENT', 'READ_ALL_INCIDENT'],
    orgAdminVisible: true,
    cards: [
      {
        name: 'incidents-combined-executioner',
        label: 'Uitvoerder',
        url: 'incidents/incidents-overview',
        rights: ['READ_INCIDENT'],
        orgAdminVisible: false,
        counts: [
          {
            fieldNames: ['incidentExternalExecutionerLate','incidentInternalExecutionerLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
            filters: {
              responseStatus: 1,
              status: 0,
              executionerId: '',
            },
          },
          {
            fieldNames: ['incidentExternalExecutionerTime','incidentInternalExecutionerTime'],
            label: 'Normaal',
            value: -1,
            filters: {
              responseStatus: 0,
              status: 0,
              executionerId: '',
            },
          },
        ],
      },
      {
        name: 'incidents-combined-guard',
        label: 'Bewaker',
        url: 'incidents/incidents-overview',
        rights: ['READ_INCIDENT'],
        orgAdminVisible: false,
        counts: [
          {
            fieldNames: ['incidentExternalGuardLate','incidentInternalGuardLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
            filters: {
              responseStatus: 1,
              status: 0,
              guardId: '',
            },
          },
          {
            fieldNames: ['incidentExternalGuardTime','incidentInternalGuardTime'],
            label: 'Normaal',
            value: -1,
            filters: {
              responseStatus: 0,
              status: 0,
              guardId: '',
            },
          },
        ],
      },
      {
        name: 'incidents-combined-team',
        label: 'Praktijk',
        url: 'incidents/incidents-overview',
        rights: ['READ_ALL_INCIDENT'],
        orgAdminVisible: true,
        counts: [
          {
            fieldNames: ['incidentExternalTeamLate','incidentInternalTeamLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
            filters: {
              responseStatus: 1,
              status: 0,
            },
          },
          {
            fieldNames: ['incidentExternalTeamTime','incidentInternalTeamTime'],
            label: 'Normaal',
            value: -1,
            filters: {
              responseStatus: 0,
              status: 0,
            },
          },
        ],
      },
    ],
  },
  {
    name: 'improvements',
    label: 'Verbetermaatregelen',
    rights: ['READ_IMPROVEMENT', 'READ_ALL_IMPROVEMENT'],
    orgAdminVisible: true,
    cards: [
      {
        name: 'improvements-executioner',
        label: 'Uitvoerder',
        url: 'improvements/improvements-overview',
        rights: ['READ_IMPROVEMENT'],
        orgAdminVisible: false,
        counts: [
          {
            fieldNames: ['improvementExecutionerLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
            filters: {
              responseStatus: 1,
              status: 0,
              executionerId: '',
            },
          },
          {
            fieldNames: ['improvementExecutionerTime'],
            label: 'Normaal',
            value: -1,
            filters: {
              responseStatus: 0,
              status: 0,
              executionerId: '',
            },
          },
        ],
      },
      {
        name: 'improvements-guard',
        label: 'Bewaker',
        url: 'improvements/improvements-overview',
        rights: ['READ_IMPROVEMENT'],
        orgAdminVisible: false,
        counts: [
          {
            fieldNames: ['improvementGuardLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
            filters: {
              responseStatus: 1,
              status: 0,
              guardId: '',
            },
          },
          {
            fieldNames: ['improvementGuardTime'],
            label: 'Normaal',
            value: -1,
            filters: {
              responseStatus: 0,
              status: 0,
              guardId: '',
            },
          },
        ],
      },
      {
        name: 'improvements-team',
        label: 'Praktijk',
        url: 'improvements/improvements-overview',
        rights: ['READ_ALL_IMPROVEMENT'],
        orgAdminVisible: true,
        counts: [
          {
            fieldNames: ['improvementTeamLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
            filters: {
              responseStatus: 1,
              status: 0,
            },
          },
          {
            fieldNames: ['improvementTeamTime'],
            label: 'Normaal',
            value: -1,
            filters: {
              responseStatus: 0,
              status: 0,
            },
          },
        ],
      },
    ],
  },
  {
    name: 'tasks',
    label: 'Taken',
    orgAdminVisible: false,
    cards: [
      {
        name: 'tasks',
        label: 'Uitvoerder',
        url: 'tasks-list',
        orgAdminVisible: false,
        counts: [
          {
            fieldNames: ['taskLate'],
            label: 'Overtijd',
            cssClass: 'warning',
            value: -1,
          },
          {
            fieldNames: ['taskTime'],
            label: 'Normaal',
            value: -1,
          },
        ],
      },
    ],
  },
];
