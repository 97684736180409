/** @format */

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SunburstGraphService } from '../../services/sunburst-graph.service';
import { CHART_TYPES } from '../../models/sunburst';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-charts',
  templateUrl: './graph-list.component.html',
  styleUrls: ['./graph-list.component.scss'],
})
export class GraphListComponent implements OnInit, AfterViewInit {
  @ViewChild('column') column: ElementRef;

  chartTypes = CHART_TYPES;

  constructor(private router: Router, public sunburstGraphService: SunburstGraphService) { }

  ngOnInit() {
    window.addEventListener('resize', () => {
    });
  }

  ngAfterViewInit(): void {
    // how about up and down window resize?
    this.sunburstGraphService.graphSize$.next(this.column.nativeElement.offsetHeight)
  }

  navigateToDetailsPage(chartId: CHART_TYPES): void {
    this.sunburstGraphService.graphData$.pipe(first(), tap(val => {
      if (chartId === CHART_TYPES.INNOVATION && !val.innovationGraph.parents.length ||
        chartId === CHART_TYPES.QUALITY && !val.qualityGraph.parents.length ||
        chartId === CHART_TYPES.COMPLIANCY && !val.compliancyGraphNextYear.parents.length || // was val.compliancyGraph
        chartId === CHART_TYPES.BUSINESS_OPERATIONS && !val.businessOperationsGraph.parents.length) {
        return;
      }

      this.router.navigate(['graphs', chartId]);

    })).subscribe()

  }
}
