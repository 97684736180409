<!-- @format -->

<div class="tasks-group">
  <h4 class="mat-subheading-1" i18n="tasks-group-container tasks-list-title">Actiepunten</h4>
  <div
    class="tasks-list"
    *ngIf="cyclicTasksList && cyclicTasksList.length > 0"
  >
    <ng-container *ngFor="let task of cyclicTasksList">
      <app-task-tile
        [task]="task"
        [groupEndDate]="toDate"
        [groupStartDate]="fromDate"
      ></app-task-tile>
    </ng-container>
  </div>
  <div
    *ngIf="!(cyclicTasksList?.length > 0)"
    class="tasks-placeholder"
    i18n="dashboard-tasks no-items-to-show"
  >
    Geen actiepunten deze periode
  </div>
</div>

<div class="tasks-group">
  <h4 class="mat-subheading-1" i18n="tasks-group-container tasks-list-title">Geplande taken</h4>
  <div
    class="tasks-list"
    *ngIf="plannedTasksList && plannedTasksList.length > 0"
  >
    <ng-container *ngFor="let task of plannedTasksList">
      <app-task-tile
        [task]="task"
        [groupEndDate]="toDate"
        [groupStartDate]="fromDate"
      ></app-task-tile>
    </ng-container>
  </div>
  <div
    *ngIf="!(plannedTasksList?.length > 0)"
    class="tasks-placeholder"
    i18n="dashboard-tasks no-items-to-show"
  >
    Geen geplande taken deze periode
  </div>
</div>
