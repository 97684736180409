<!-- @format -->

<div class="kms-list meeting-members-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
    *ngIf="dataSource.data"
  >
    <ng-container matColumnDef="memberName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="meeting-members-list list-header"
        class="member-name"
        >Naam</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="member-name"
      >
        {{ row.memberName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="invited">
      <mat-header-cell
        *matHeaderCellDef
        i18n="meeting-members-list list-header"
        >Uitgenodigd</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          [disabled]="!updateRights"
          (click)="$event.stopPropagation()"
          (change)="onSelectionChange($event, invitedSelection, row, 'invited')"
          [checked]="invitedSelection.isSelected(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="attended">
      <mat-header-cell
        *matHeaderCellDef
        i18n="meeting-members-list list-header"
        >Bijgewoond</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          [disabled]="!updateRights"
          (click)="$event.stopPropagation()"
          (change)="
            onSelectionChange($event, attendedSelection, row, 'attended')
          "
          [checked]="attendedSelection.isSelected(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="notesEnabled">
      <mat-header-cell
        *matHeaderCellDef
        i18n="meeting-members-list list-header"
        >Notulen</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [disabled]="!updateRights"
          (change)="
            onSelectionChange(
              $event,
              notesEnabledMembersSelection,
              row,
              'notesEnabled'
            )
          "
          [checked]="notesEnabledMembersSelection.isSelected(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
</div>
