<!-- @format -->

<div class="executioner-container">
  <form [formGroup]="newFormGroup">
    <mat-checkbox
      appearance="fill"
      formControlName="evaluationDone"
      labelPosition="after"
      >Evaluatie voldaan</mat-checkbox
    >
  </form>
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Toelichting uitvoerder</mat-label>
      <textarea
        matInput
        formControlName="comment"
        matTextareaAutosize
        matAutosizeMinRows="1"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>
</div>
