<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-tasks-template-dialog dialog-title"
>
  <mat-icon
    *ngIf="newFormGroup.get('privateTaskTemplate').value"
    matTooltip="Privé"
    >lock</mat-icon
  ><span>Toevoegen / wijzigen takentemplate</span>
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <mat-accordion displayMode="flat">
    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-tasks-template-dialog expansion-panel-title"
        >
          Dienst/type
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="newFormGroup">
        <ng-container
          *ngIf="
            currentUserRights.organisationAdmin ||
            currentUserRights.isCurrentTeamAdmin ||
            newFormGroup.get('privateTaskTemplate').value
          "
        >
          <mat-form-field appearance="fill">
            <mat-label>Dienst/type</mat-label>
            <mat-select
              formControlName="subTheme"
              panelClass="impr-group-panel"
              [compareWith]="compareFn"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-optgroup
                *ngFor="let theme of sharedService.currentThemesList$ | async"
                [label]="theme.name"
              >
                <ng-container *ngIf="theme._embedded">
                  <mat-option
                    *ngFor="let subTheme of theme._embedded.subThemes"
                    [value]="subTheme"
                  >
                    {{ subTheme.name }}
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container
          *ngIf="
            !(
              currentUserRights.organisationAdmin ||
              currentUserRights.isCurrentTeamAdmin ||
              newFormGroup.get('privateTaskTemplate').value
            ) && currentUserRights.subThemeOwnership?.length > 0
          "
        >
          <mat-form-field appearance="fill">
            <mat-label>Dienst/type</mat-label>
            <mat-select
              formControlName="subTheme"
              panelClass="impr-group-panel"
              [compareWith]="compareFn"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let subTheme of currentUserRights.subThemeOwnership"
                [value]="subTheme"
              >
                {{ subTheme.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-tasks-template-dialog expansion-panel-title"
        >
          Verantwoordelijke
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-rows">
        <ng-container
          *ngIf="!currentUserRights.organisationAdmin || !formIsNew"
        >
          <div class="pseudo-form-field team-name">
            {{ this.newFormGroup.get('teams')?.value[0]?.name }}
          </div>
        </ng-container>

        <form
          [formGroup]="newFormGroup"
          class="multiselect-team"
          *ngIf="currentUserRights.organisationAdmin && formIsNew"
        >
          <mat-form-field appearance="fill">
            <mat-label>Praktijk</mat-label>
            <mat-select
              formControlName="teams"
              panelClass="impr-group-panel"
              required
              multiple
              [compareWith]="compareFn"
            >
              <mat-optgroup
                *ngFor="
                  let teamGroup of sharedService.currentTeamGroupsList$ | async
                "
                [label]="teamGroup.name"
              >
                <ng-container *ngIf="teamGroup._embedded">
                  <mat-option
                    *ngFor="let team of teamGroup._embedded.teams"
                    [value]="team"
                  >
                    {{ team.name }}
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
            <mat-error [hidden]="!formErrors.teams">{{
              formErrors.teams
            }}</mat-error>
          </mat-form-field>
        </form>

        <ng-container
          *ngIf="
            (!currentUserRights.subThemeOwnership[0] &&
              !currentUserRights.isCurrentTeamAdmin) ||
            currentUserRights.organisationAdmin ||
            newFormGroup.get('privateTaskTemplate').value
          "
        >
          <div class="pseudo-form-field team-name">
            {{ this.newFormGroup.get('responsibleMember')?.value?.memberName }}
          </div>
        </ng-container>

        <form
          [formGroup]="newFormGroup"
          *ngIf="
            !currentUserRights.organisationAdmin &&
            (currentUserRights.isCurrentTeamAdmin ||
              currentUserRights.subThemeOwnership?.length > 0) &&
            !newFormGroup.get('privateTaskTemplate').value
          "
        >
          <mat-form-field appearance="fill">
            <mat-label>Verantwoordelijke</mat-label>
            <mat-select
              formControlName="responsibleMember"
              required
              [compareWith]="compareFn"
              [disabled]="currentUserRights.organisationAdmin"
            >
              <mat-option
                *ngFor="
                  let member of sharedService.currentTeamMembersList$ | async
                "
                [value]="member"
              >
                {{ member.memberName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-tasks-template-dialog expansion-panel-title"
        >
          Omschrijving
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form
        [formGroup]="newFormGroup"
        class="critical-title"
      >
        <mat-checkbox
          appearance="fill"
          formControlName="critical"
          labelPosition="after"
          >Critical</mat-checkbox
        >
      </form>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Titel</mat-label>
          <textarea
            matInput
            formControlName="title"
            required
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
          <mat-error [hidden]="!formErrors.title">{{
            formErrors.title
          }}</mat-error>
        </mat-form-field>
      </form>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Omschrijving</mat-label>
          <textarea
            matInput
            formControlName="description"
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
          <mat-error [hidden]="!formErrors.description">{{
            formErrors.description
          }}</mat-error>
        </mat-form-field>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-tasks-template-dialog expansion-panel-title"
        >
          Frequentie
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="dates-container">
        <div class="flex-rows">
          <form
            [formGroup]="newFormGroup"
            class="left-field"
          >
            <mat-form-field
              appearance="fill"
              [ngClass]="{
                'constraint-violation': formErrors.notValidDates
              }"
            >
              <mat-label>Begindatum</mat-label>
              <input
                type="text"
                matInput
                formControlName="startDate"
                [matDatepicker]="pickerStart"
                required
              />
              <mat-datepicker #pickerStart></mat-datepicker>
              <mat-error [hidden]="!formErrors.startDate">{{
                formErrors.startDate
              }}</mat-error>
              <mat-icon (click)="pickerStart.open()">calendar_month</mat-icon>
            </mat-form-field>
          </form>

          <form [formGroup]="newFormGroup">
            <mat-form-field
              appearance="fill"
              [ngClass]="{
                'constraint-violation': formErrors.notValidDates
              }"
            >
              <mat-label>Einddatum</mat-label>
              <input
                type="text"
                matInput
                formControlName="endDate"
                [min]="today"
                [matDatepicker]="pickerEnd"
              />
              <mat-datepicker #pickerEnd></mat-datepicker>
              <mat-error [hidden]="!formErrors.endDate">{{
                formErrors.endDate
              }}</mat-error>
              <mat-icon (click)="pickerEnd.open()">calendar_month</mat-icon>
            </mat-form-field>
          </form>
        </div>
        <mat-error [hidden]="!formErrors.notValidDates">{{
          formErrors.notValidDates
        }}</mat-error>
      </div>

      <form [formGroup]="newFormGroup">
        <mat-form-field appearance="fill">
          <mat-label>Frequentie</mat-label>
          <mat-select
            formControlName="frequency"
            [compareWith]="compareFn"
            required
          >
            <mat-option
              *ngFor="let frequency of sharedService.frequenciesList$ | async"
              [value]="frequency"
            >
              {{ frequency.name }}
            </mat-option>
          </mat-select>
          <mat-error [hidden]="!formErrors.frequency">{{
            formErrors.frequency
          }}</mat-error>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
