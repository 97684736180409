<!-- @format -->

<h1
  class="mat-h1"
  i18n="agenda page-title"
>
  Jaarplan
</h1>

<div class="main-content change-on-mobile">
  <app-year-planning-dashboard></app-year-planning-dashboard>
</div>
