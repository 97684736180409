/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { Theme } from '../../models/theme';
import { SelectionModel } from '@angular/cdk/collections';
import { TeamGroup } from '../../models/team-group';

/**
 *  Component that shows the themes tiles in the Overview
 *
 * @export
 * @class           ThemesTileListComponent
 * @implements      {OnInit}
 * @implements      {OnDestroy}
 */
@Component({
  selector: 'app-themes-tile-list',
  templateUrl: './themes-tile-list.component.html',
  styleUrls: ['./themes-tile-list.component.scss'],
})
export class ThemesTileListComponent implements OnInit, OnDestroy {
  /**
   *  The index of selected tab.
   *
   * @memberof            ThemesTileListComponent
   */
  selectedTabInd: number;

  /**
   *  The selected themes
   *
   * @memberof            ThemesTileListComponent
   */
  selectedThemes: SelectionModel<Theme>;

  /**
   *  The selected themes
   *
   * @memberof            ThemesTileListComponent
   */
  selectedTeamGroup: SelectionModel<TeamGroup>;

  /**
   *  The subscription of theme
   *
   * @type                {Subscription}
   * @memberof            ThemesTileListComponent
   */
  themeSubscription: Subscription;

  /**
   *  The subscription of teamGroup
   *
   * @type                {Subscription}
   * @memberof            ThemesTileListComponent
   */
  teamGroupSubscription: Subscription;

  /**
   *  The subscription of themesList
   *
   * @type                {Subscription}
   * @memberof            ThemesTileListComponent
   */
  themesListSubs: Subscription;

  /**
   *  The list of themes
   *
   * @type                    {Theme[]}
   * @memberof                ThemesTileListComponent
   */
  themesList: Theme[];

  /**
   * Creates an instance of ThemesTileListComponent.
   *
   * @param      {SharedService}       sharedService The instance of shared service.
   * @memberof   ThemesTileListComponent
   */
  constructor(
    public sharedService: SharedService,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ThemesTileListComponent
   */
  ngOnInit() {
    this.selectedThemes = this.sharedService.currentTheme.getValue();
    this.selectedTeamGroup = this.sharedService.currentTeamGroup.getValue();

    this.themesListSubs = this.sharedService.currentThemesList$.subscribe(
      (themeslist) => {
        this.themesList = themeslist;

        this.ref.detectChanges();
      },
    );

    this.themeSubscription = this.sharedService.currentTheme$.subscribe(
      (newthemes) => {
        this.selectedThemes = newthemes;
      },
    );

    this.teamGroupSubscription = this.sharedService.currentTeamGroup$.subscribe(
      (newteamgroup) => {
        if (!newteamgroup.selected[0]) {
          this.sharedService.newSelectedTheme(new SelectionModel(true, null));
        }
        this.selectedTeamGroup = newteamgroup;
      },
    );
  }

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    ThemesTileListComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent, theme?: Theme) {
    this.selectedTabInd = event.index;

    this.selectedThemes.toggle(theme);

    this.sharedService.newSelectedTheme(this.selectedThemes);
    this.sharedService.newSelectedScoreTile(
      new SelectionModel<any>(true, null),
    );
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof ThemesTileListComponent
   */
  ngOnDestroy() {
    this.themesListSubs.unsubscribe();
    this.themeSubscription.unsubscribe();
    this.teamGroupSubscription.unsubscribe();
    this.selectedThemes.clear();

    this.sharedService.newSelectedTheme(this.selectedThemes);
  }
}
