<!-- @format -->

<div class="overview-group-title">
  <button
    mat-icon-button
    (click)="toggleMenusForce(headerTitle, true)"
  >
    <mat-icon>first_page</mat-icon>
  </button>
  <h2 *ngIf="headerTitle">{{ headerTitle?.name }}</h2>
  <h2 *ngIf="!headerTitle">Organisatieonderdelen</h2>
</div>
<div
  class="team-groups-tiles"
  fxLayout="row"
  fxLayoutAlign="start none"
>
  <mat-list
    #teamgroupslist
    class="team-groups-container"
    [fxFlex]="groupsVisible ? '215px' : '0px'"
    [ngClass]="{ 'groups-hidden': !groupsVisible }"
  >
    <mat-list-item
      *ngFor="let teamGroup of teamGroupsList; let i = index"
      (click)="onGroupClick(teamGroup)"
      [ngClass]="{ selected: selectedTeamGroup.isSelected(teamGroup) }"
    >
      <mat-icon>people</mat-icon>
      <div class="team-group-name">{{ teamGroup.name }}</div>
      <div
        *ngIf="teamGroup._embedded"
        class="teams-count"
        i18n="team-groups-tile-list number-of-teams"
      >
        {teamGroup._embedded.teams.length, plural,
          =1 {1 team }
          other {{{teamGroup._embedded.teams.length}} teams }
        }
      </div>
    </mat-list-item>
  </mat-list>

  <div
    class="team-groups-toogler"
    fxFlex="20px"
  >
    <mat-icon
      *ngIf="groupsVisible"
      matTooltip="Verberg groepen"
      matTooltipPosition="right"
      (click)="toggleGroups('hide')"
      >chevron_left</mat-icon
    >
    <mat-icon
      *ngIf="!groupsVisible"
      matTooltip="Toon groepen"
      matTooltipPosition="right"
      (click)="toggleGroups('show')"
      >chevron_right</mat-icon
    >
  </div>

  <div
    class="team-groups-content"
    [ngClass]="{ 'groups-hidden-c': !groupsVisible }"
    [fxFlex]="groupsVisible ? 'calc(100%-200px)' : 'calc(100%-20px)'"
  >
    <app-themes-tile-list></app-themes-tile-list>
  </div>
</div>
