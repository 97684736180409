/** @format */

import { catchError, switchMap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import {
  AbstractRestService,
  PagedResult,
  PageSetting,
  BaseModel,
} from '@iq/ng-core';

import { HttpClient } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { SubThemeOwnership } from '../models/subtheme-ownership';
import { Member } from '../models/member';
import { UserValidatorService } from './user-validator.service';
import { Team } from '../models/team';

const apiPath = environment.apiPath;


export interface ThemeModel { id: string, name: string, sequence: number };

interface ThemeModelEditRespose extends ThemeModel {
  _embedded: {
    subThemes: SubthemeModel[];
  }
}


export interface SubthemeModel { id: string, name: string, sequence: number, themeId: string };

/**
 *  Service for managing the subtheme-ownership
 *
 * @export
 * @class   SubthemeOwnershipService
 * @extends {AbstractRestService<SubThemeOwnership> }
 */
@Injectable()
export class SubthemeOwnershipService extends AbstractRestService<SubThemeOwnership> {
  /**
   * Creates an instance of SubthemeOwnershipService
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      SubthemeOwnershipService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}subtheme_ownership`, 'subtheme_ownership');
  }

  #userValidatorService = inject(UserValidatorService);

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ScoreItem>}
   * @memberof        SubthemeOwnershipService
   */
  protected mapToModel(json: any): PagedResult<SubThemeOwnership> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <SubThemeOwnership[]>json._embedded.subThemeOwnerships;
    }

    return new PagedResult<SubThemeOwnership>(payload, <PageSetting>json.page);
  }

  /**
   *  Post ownership with the teamId param
   *
   * @param       {BaseModel}   aModel  The posted model
   * @param       {string}      teamId  The id of the team
   * @returns
   * @memberof    SubthemeOwnershipService
   */
  postOwnership(
    aModel: BaseModel,
    teamId: string,
  ): Observable<SubThemeOwnership> {
    return this.http
      .post<SubThemeOwnership>(`${this.actionUrl}?teamId=${teamId}`, aModel)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Put ownership with the teamId param
   *
   * @param       {BaseModel}   aModel  The posted model
   * @param       {string}      teamId  The id of the team
   * @returns
   * @memberof    SubthemeOwnershipService
   */
  putOwnership(
    aModel: BaseModel,
    teamId: string,
  ): Observable<SubThemeOwnership> {
    return this.http
      .put<SubThemeOwnership>(
        `${this.actionUrl}/${aModel.id}?teamId=${teamId}`,
        aModel,
      )
      .pipe(catchError((err) => _throw(err)));
  }

  private getCurrentTeam(): Team {
    return this.#userValidatorService.currentUserRights.getValue().currentMember.team;
  }


  createNewThemeAndSubTheme(form: { theme: string, subTheme: string, owner1: Member | null, owner2: Member | null }, existingSubTheme?: any): Observable<SubthemeModel> {
    const { theme, subTheme } = form;
    const subThemeNameToUse = (): string => {
      if (existingSubTheme?.name !== subTheme) {
        return subTheme;
      } else return existingSubTheme?.name || '';
    }

    return this.http.post<ThemeModel>(`${apiPath}themes`, { name: theme, team: this.getCurrentTeam() }).pipe(switchMap(newTheme =>
      this.createNewSubThemeForExistingTheme(subThemeNameToUse(), newTheme.id)
    ))
  }

  editTheme(themeId: string, name: string): Observable<ThemeModelEditRespose> {
    return this.http.put<ThemeModelEditRespose>(`${apiPath}themes/${themeId}`, { name });
  }

  createNewSubThemeForExistingTheme(subThemeName: string, themeId: string): Observable<SubthemeModel> {
    return this.http.post<SubthemeModel>(`${apiPath}sub-themes`, { name: subThemeName, themeId: themeId });
  }

  editSubThemeForExistingTheme(name: string, subTheme: SubthemeModel): Observable<SubthemeModel> {
    return this.http.put<SubthemeModel>(`${apiPath}sub-themes/${subTheme.id}`, { name, themeId: subTheme.themeId });
  }

  deleteTheme(themeId: string): Observable<any> {
    return this.http.delete(`${apiPath}themes/${themeId}`)

  }
}
