<!-- @format -->

<h1
  class="mat-h1"
  i18n="pmr page-title"
>
  Periodieke Management Review
</h1>

<div class="main-content">
  <nav
    mat-tab-nav-bar
    mat-stretch-tabs
    [disablePagination]="true"
    [tabPanel]="tabPanelPMR"
    *ngIf="currentUserRights"
  >
    <a
      mat-tab-link
      routerLink="pmr"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      >PMR</a
    >

    <a
      mat-tab-link
      routerLink="pmr-items-overview"
      routerLinkActive
      #rlb="routerLinkActive"
      *ngIf="currentUserRights.organisationAdmin"
      [active]="rlb.isActive"
      >PMR items overzicht</a
    >

    <a
      mat-tab-link
      routerLink="default-pmr-items"
      routerLinkActive
      #rlb="routerLinkActive"
      *ngIf="currentUserRights.organisationAdmin"
      [active]="rlb.isActive"
      >Default PMR items</a
    >
  </nav>

  <mat-tab-nav-panel
    #tabPanelPMR
    [@routeAnimations]="prepareRoute(outlet)"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-tab-nav-panel>
</div>
