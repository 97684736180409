<!-- @format -->

<div class="pmr-items-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="containsCopiedImprovements">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-list list-header"
        class="copied-item"
      ></mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="copied-item"
      >
        <mat-icon
          color="warn"
          *ngIf="row.containsCopiedImprovements"
          matTooltip="Item van vorige PMR"
          matTooltipPosition="right"
          >warning</mat-icon
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell
        fxFlex="2 0 0"
        *matHeaderCellDef
        i18n="pmr-items-list list-header"
        >Titel</mat-header-cell
      >
      <mat-cell
        fxFlex="2 0 0"
        *matCellDef="let row"
      >
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        fxFlex="3 0 0"
        *matHeaderCellDef
        i18n="pmr-items-list list-header"
        >Omschrijving</mat-header-cell
      >
      <mat-cell
        fxFlex="3 0 0"
        *matCellDef="let row"
      >
        {{ row.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell
        fxFlex="1 0 0"
        *matHeaderCellDef
        i18n="pmr-items-list list-header"
        >Status</mat-header-cell
      >
      <mat-cell
        fxFlex="1 0 0"
        *matCellDef="let row"
      >
        <mat-chip-list selectable="false">
          <mat-chip
            disableRipple="true"
            value="{{ row.status }}"
            class="is-status pmr-item-status pmr-item-status-{{ row.status }}"
          >
            {{ pmrItemStatuses[row.status] }}
          </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="conclusion">
      <mat-header-cell
        fxFlex="1 0 0"
        *matHeaderCellDef
        i18n="pmr-items-list list-header"
        >Conclusie</mat-header-cell
      >
      <mat-cell
        fxFlex="1 0 0"
        *matCellDef="let row"
      >
        <mat-chip-list selectable="false">
          <mat-chip
            color="{{
              ['default', 'accent', 'warn', 'primary'][row.conclusion]
            }}"
            defaultColor="primary"
            disableRipple="true"
            selected="true"
            value="{{ row.conclusion }}"
            class="pmr-item-conclusion pmr-item-conclusion-{{ row.conclusion }}"
          >
            {{ pmrItemConclusions[row.conclusion] }}
          </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="IVM">
      <mat-header-cell
        fxFlex="0.5 0 0"
        *matHeaderCellDef
        i18n="pmr-items-list list-header"
      >
        Verbeter-
        <br />
        maatregel
      </mat-header-cell>
      <mat-cell
        fxFlex="0.5 0 0"
        *matCellDef="let row"
      >
        {{ row.improvementCount ? row.improvementCount : 0 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row; let i = index"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event, i)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
</div>
