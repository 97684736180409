/** @format */

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Score } from '../../models/score';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ScoreItem } from '../../models/scoreitem';
import { LampService } from '../../services/lamp.service';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

/**
 *  Main component for updating the score.
 *
 * @export
 * @class         ScoreItemsListItemComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-score-items-list-item',
  templateUrl: './score-items-list-item.component.html',
  styleUrls: ['./score-items-list-item.component.scss'],
})
export class ScoreItemsListItemComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /**
   *  The current latest score for the scoreItem.
   *
   * @type                    {Score}
   * @memberof                ScoreItemsListItemComponent
   */
  @Input() scoreItem: ScoreItem;

  /**
   *  The index of the scoreItem
   *
   * @type                    {number}
   * @memberof                ScoreItemsListItemComponent
   */
  @Input() index: number;

  /**
   *  The list of rating scores with labels and description
   *
   * @memberof                ScoreItemsListItemComponent
   */
  ratingScores = [
    { value: 0, field: 'test0', label: 'Kies een schaal', description: '' },
    { value: 1, field: 'test1', label: 'Schaal 1', description: '' },
    { value: 2, field: 'test2', label: 'Schaal 2', description: '' },
    { value: 3, field: 'test3', label: 'Schaal 3', description: '' },
    { value: 4, field: 'test4', label: 'Schaal 4', description: '' },
    { value: 5, field: 'test5', label: 'Schaal 5', description: '' },
  ];

  /**
   *  The form group storing the new Score.
   *
   * @type                    {FormGroup}
   * @memberof                ScoreItemsListItemComponent
   */
  newScoreGroup: UntypedFormGroup;

  /**
   *  Description of currently selected score.
   *
   * @memberof                ScoreItemsListItemComponent
   */
  currentDescription = { value: 0, field: '', label: '', description: '' };

  /**
   *  Current score instance.
   *
   * @memberof                ScoreItemsListItemComponent
   */
  currentScore: Score;

  /**
   *  Default score value.
   *
   * @memberof                ScoreItemsListItemComponent
   */
  currentScoreValue = 0;

  /**
   *  Default value of the score motivation.
   *
   * @memberof                ScoreItemsListItemComponent
   */
  currentMotivation = '';

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                ScoreItemsListItemComponent
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the score form is changed.
   *
   * @memberof                ScoreItemsListItemComponent
   */
  formChanged = false;

  /**
   *  True/false if the form is successfully submited
   *
   * @memberof                ScoreItemsListItemComponent
   */
  formSubmitted = false;

  /**
   *  The subscription of the new score.
   *
   * @type                    {Subscription}
   * @memberof                ScoreItemsListItemComponent
   */
  newScoreSubs: Subscription;

  /**
   * Creates an instance of ScoreItemsListItemComponent.
   *
   * @param         {FormBuilder}           fb            Form builder instance
   * @param         {LampService}           lampService   The lamp service instance
   * @memberof      ScoreItemsListItemComponent
   */
  constructor(
    private fb: UntypedFormBuilder,
    private lampService: LampService,
    private userValidatorService: UserValidatorService,
    private ref: ChangeDetectorRef,
    private el: ElementRef,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/ritual_icon.svg'),
    );
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      ScoreItemsListItemComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    for (const rate of this.ratingScores) {
      if (rate.value === 0) {
        rate.description =
          'Door een emoij te selecteren kun je de beschrijving lezen per schaal en je keuze maken.';
      } else {
        rate.description = this.scoreItem[rate.field];
      }
    }

    if (this.scoreItem._embedded) {
      this.currentScore = this.scoreItem._embedded.score;
      this.currentScoreValue = this.currentScore.score;
      this.currentMotivation = this.currentScore.motivation;
    }

    this.currentDescription = this.ratingScores[this.currentScoreValue];
    this.formSubmitted = false;

    this.createForm();
  }

  /**
   * Respond after Angular initializes the component's views and child views
   * / the view that a directive is in.
   * Called once after the first ngAfterContentChecked().
   *
   * @memberof ScoreItemsListItemComponent
   */
  ngAfterViewInit(): void {
    this.updateSmileDescClass(this.currentScoreValue);
  }

  /**
   * Respond to the save score intent.
   *
   * @memberof ScoreItemsListItemComponent
   */
  onScoreSave() {
    this.newScoreSubs = this.lampService
      .addNewScore(this.newScoreGroup.value)
      .subscribe((newscore) => {
        this.currentScoreValue = newscore.score;
        this.currentMotivation = newscore.motivation;
        this.currentDescription = this.ratingScores[this.currentScoreValue];

        this.updateSmileDescClass(this.currentScoreValue);
        this.formSubmitted = true;

        this.newScoreGroup.reset({
          score: this.currentScoreValue,
          motivation: this.currentMotivation,
          scoreItemId: this.scoreItem.id,
          teamId:
            this.userValidatorService.currentUserRights.getValue()
              .currentTeamId,
        });
      });
  }

  /**
   * Cancel updating the score.
   *
   * @memberof ScoreItemsListItemComponent
   */
  onCancel() {
    this.newScoreGroup.reset({
      score: this.currentScoreValue,
      motivation: this.currentMotivation,
      scoreItemId: this.scoreItem.id,
      teamId:
        this.userValidatorService.currentUserRights.getValue().currentTeamId,
    });
    this.currentDescription = this.ratingScores[this.currentScoreValue];
    this.formSubmitted = false;
    this.updateSmileDescClass(this.currentScoreValue);
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof ScoreItemsListItemComponent
   */
  createForm(): void {
    this.newScoreGroup = this.fb.group({
      score: this.currentScoreValue,
      motivation: this.currentMotivation,
      scoreItemId: this.scoreItem.id,
      teamId:
        this.userValidatorService.currentUserRights.getValue().currentTeamId,
    });

    this.newScoreGroup.valueChanges.subscribe((changes) => {
      if (
        this.newScoreGroup.value.score === this.currentScoreValue &&
        this.newScoreGroup.value.motivation === this.currentMotivation
      ) {
        this.formChanged = false;
      } else {
        this.formChanged = true;
        this.formSubmitted = false;
      }

      this.ref.detectChanges();
    });
  }

  /**
   *  Respond to the hover event on smiley
   *
   * @param       {*}                         rate
   * @param       {Event}                     [event]
   * @memberof    ScoreItemsListItemComponent
   */
  onSmileyHover(rate: any, event?: Event) {
    if (event) {
      event.stopImmediatePropagation();
    }

    this.currentDescription = this.ratingScores[rate.value];
    this.updateSmileDescClass(rate.value);
  }

  /**
   *  Respond to the mouseleave event on smiley
   *
   * @param       {*}                         rate
   * @param       {Event}                     [event]
   * @memberof    ScoreItemsListItemComponent
   */
  onSmileyLeave(rate: any, event?: Event) {
    if (event) {
      event.stopImmediatePropagation();
    }

    this.currentDescription = this.ratingScores[this.newScoreGroup.value.score];
    this.updateSmileDescClass(this.newScoreGroup.value.score);
  }

  /**
   *  Update the class of score description
   *
   * @param       {*}                         score
   * @memberof    ScoreItemsListItemComponent
   */
  updateSmileDescClass(score: any) {
    const smDesc = $(this.el.nativeElement).find('.smiley-description');
    smDesc.removeClass();
    smDesc.addClass(`smiley-description sm-desc-${score}`);
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof    ScoreItemsListItemComponent
   */
  ngOnDestroy() {
    if (this.newScoreSubs) {
      this.newScoreSubs.unsubscribe();
    }
  }
}
