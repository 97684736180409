/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Improvement } from '../../models/improvement';
import { ImprovementAnalysis } from '../../models/improvement-analysis';
import { ImprovementAnalysisService } from '../../services/improvement-analysis.service';

/**
 *  Add/edit dialog of the improvement analysis
 *
 * @export
 * @class       AddEditImprovementAnalysisComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-add-edit-improvement-analysis',
  templateUrl: './add-edit-improvement-analysis.component.html',
  styleUrls: ['./add-edit-improvement-analysis.component.scss'],
})
export class AddEditImprovementAnalysisComponent
  extends AddEditAbstractDialog<ImprovementAnalysis>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditImprovementAnalysisComponent
   */
  formErrors = {
    title: '',
    description: '',
    improvementIds: '',
  };

  /**
   *  The list of improvementIds linked to the parent component
   *
   * @type                  {string[]}
   * @memberof              AddEditImprovementAnalysisComponent
   */
  improvementIdsList: string[];

  /**
   *  The list of attached improvements
   *
   * @type                  {Improvement[]}
   * @memberof              AddEditImprovementAnalysisComponent
   */
  improvementsList: Improvement[];

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditImprovementAnalysisComponent
   */
  validationMessages = {
    title: {
      required: 'Onderwerp is een verplicht veld.',
    },
  };

  constructor(
    public dialogRefA: MatDialogRef<AddEditImprovementAnalysisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analysisService: ImprovementAnalysisService,
    private fbA: UntypedFormBuilder,
  ) {
    super(dialogRefA, data, analysisService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditImprovementAnalysisComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    if (!this.formIsNew && this.data.analysis) {
      this.improvementIdsList = this.data.analysis.improvementIds.slice();

      if (
        this.data.analysis._embedded &&
        this.data.analysis._embedded.improvement
      ) {
        this.improvementsList = this.data.analysis._embedded.improvement;
      }
    } else {
      this.improvementIdsList = [];
    }

    this.createForm(this.initialFormValues);

    if (!this.formIsNew && !this.data.canUpdate) {
      this.newFormGroup.disable();
    }
  }

  /**
   *  Respond to the unlink of the child item
   *
   * @param {*} item
   * @memberof AddEditImprovementAnalysisComponent
   */
  onItemUnlink(item: Improvement) {
    if (item && item.id) {
      for (let i = 0; i < this.improvementIdsList.length; i++) {
        if (this.improvementIdsList[i] === item.id) {
          this.improvementIdsList.splice(i, 1);

          this.newFormGroup.patchValue({
            improvementIds: this.improvementIdsList,
          });

          this.childChanged = true;
          const index = this.improvementsList.indexOf(item);

          if (index > -1) {
            this.improvementsList.splice(index, 1);

            //  Workaround to make change detector working in the child component
            this.improvementsList = this.improvementsList.slice();
          }

          break;
        }
      }
    }
  }

  /**
   *  Link the item to the parent component
   *
   * @param {*} item
   * @memberof AddEditImprovementAnalysisComponent
   */
  onItemLink(item: any) {
    if (item && item.id) {
      this.improvementIdsList.push(item.id);

      this.newFormGroup.patchValue({
        improvementIds: this.improvementIdsList,
      });

      this.childChanged = true;

      if (!this.improvementsList) {
        this.improvementsList = [item];
      } else {
        this.improvementsList.push(item);
      }

      //  Workaround to make change detector working in the child component
      this.improvementsList = this.improvementsList.slice();
    }
  }
}
