/** @format */

import { Injectable } from '@angular/core';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { TeamGroup } from '../models/team-group';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const apiPath = environment.apiPath;

/**
 *  Service for managing the team-groups list.
 *
 * @export
 * @class   TeamGroupsListService
 * @extends {AbstractRestService<TeamGroup>}
 */
@Injectable()
export class TeamGroupsListService extends AbstractRestService<TeamGroup> {
  /**
   * Creates an instance of TeamGroupsListService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      TeamGroupsListService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}team-groups`, 'team-groups');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ScoreItem>}
   * @memberof        TeamGroupsListService
   */
  protected mapToModel(json: any): PagedResult<TeamGroup> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <TeamGroup[]>json._embedded.teamGroups;
    }

    return new PagedResult<TeamGroup>(payload, <PageSetting>json.page);
  }
}
