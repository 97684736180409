/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Main component for managing the (Sub)theme owners
 *
 * @export
 * @class         SubthemeOwnersManagementComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-subtheme-owners-management',
  templateUrl: './subtheme-owners-management.component.html',
  styleUrls: ['./subtheme-owners-management.component.scss'],
})
export class SubthemeOwnersManagementComponent implements OnInit {
  /**
   *  Creates an instance of SubthemeOwnersManagementComponent.
   *
   * @memberof SubthemeOwnersManagementComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof  SubthemeOwnersManagementComponent
   */
  ngOnInit() {}
}
