/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { Executioner } from '../../models/executioner';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { EmbeddedChild } from '../../shared/embedded-child';
import { UserValidatorService } from '../../services/user-validator.service';

/**
 *  The component for managing the improvement executioner
 *
 * @export
 * @class ExecutionerComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-executioner',
  templateUrl: './executioner.component.html',
  styleUrls: ['./executioner.component.scss'],
})
export class ExecutionerComponent
  extends EmbeddedChild<Executioner>
  implements OnInit
{
  /**
   *  Update rights from the parent component
   *
   * @type {string}
   * @memberof ExecutionerComponent
   */
  @Input() crudUpdate: string;

  /**
   *  The object of form validation errors.
   *
   * @memberof                ExecutionerComponent
   */
  formErrors = {
    comment: '',
    evaluationDone: '',
    memberId: '',
  };

  /**
   *  The default values of the new Form.
   *
   * @memberof                ExecutionerComponent
   */
  initialFormValues = <any>{
    comment: [''],
    evaluationDone: [false],
    memberId: ['', Validators.required],
    memberName: '',
    teamId: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                ExecutionerComponent
   */
  validationMessages = {};

  /**
   * Creates an instance of ExecutionerComponent.
   * @param {FormBuilder} fb
   * @param {UserValidatorService} userValidatorService
   * @param {SharedService} sharedService
   * @memberof ExecutionerComponent
   */
  constructor(
    private fb: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
  ) {
    super(fb);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof  ExecutionerComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.setInitialFormValues();

    this.createForm(this.initialFormValues);

    this.onFormValueChanged();

    if (this.crudUpdate && this.crudUpdate === 'NONE') {
      if (this.editedItem) {
        if (
          this.editedItem.memberId === this.currentUserRights.currentMember.id
        ) {
          this.crudUpdate = 'ALL';
        } else {
          this.newFormGroup.disable();
        }
      }
    }
  }

  /**
   *  Set the initial values to the child component form
   *
   * @memberof ExecutionerComponent
   */
  setInitialFormValues() {
    const executionerInstance = Object.assign({}, this.editedItem);

    if (executionerInstance) {
      this.initialFormValues = {
        comment: [executionerInstance.comment],
        evaluationDone: [executionerInstance.evaluationDone],
        teamId: this.currentUserRights.currentTeamId,
        memberId: [executionerInstance.memberId, Validators.required],
        memberName: executionerInstance.memberName,
        temporaryFields: {
          changed: executionerInstance.temporaryFields
            ? executionerInstance.temporaryFields.changed
            : false,
          removed: executionerInstance.temporaryFields
            ? executionerInstance.temporaryFields.removed
            : false,
          invalid: executionerInstance.temporaryFields
            ? executionerInstance.temporaryFields.invalid
            : false,
        },
      };
    }

    if (!this.initialFormValues.teamId) {
      this.initialFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    if (executionerInstance.id) {
      this.initialFormValues.id = executionerInstance.id;
    } else {
      this.initialFormValues.temporaryFields.isNew = true;
    }
  }
}
