/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Show the agenda templates
 *
 * @export
 * @class AgendaTemplatesComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-agenda-templates',
  templateUrl: './agenda-templates.component.html',
  styleUrls: ['./agenda-templates.component.scss'],
})
export class AgendaTemplatesComponent implements OnInit {
  /**
   *  Creates an instance of AgendaTemplatesComponent.
   *
   * @memberof AgendaTemplatesComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AgendaTemplatesComponent
   */
  ngOnInit() {}
}
