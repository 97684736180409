<!-- @format -->

<h1
  class="mat-h1"
  i18n="dashboard page-title"
>
  Dashboard
</h1>

<div class="main-content change-on-mobile">
  <app-dashboard-view></app-dashboard-view>
</div>
