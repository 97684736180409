/** @format */

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ScoreByTeamAndSubTheme } from '../models/score-by-team-and-subtheme';

const apiPath = environment.apiPath;

/**
 *  Service for managing the overview matrix.
 *
 * @export
 * @class         OverviewService
 */
@Injectable()
export class OverviewService {
  /**
   *  The mapped object of averageScores
   *
   * @memberof                OverviewContentComponent
   */
  avgMatrixByTeamPerSubTheme = <any>{};

  /**
   *  The mapped object of averageScores
   *
   * @memberof                OverviewContentComponent
   */
  avgMatrixByTeamGroupPerScoreItem = <any>{};

  /**
   * Creates an instance of OverviewService.
   *
   * @param         {HttpClient}      http
   * @memberof      OverviewService
   */
  constructor(private http: HttpClient) {}

  /**
   *  Fetch the average scores for each team and subtheme
   *
   * @returns       {Observable<ScoreByTeamAndSubTheme[]>}    Observable list of average scores
   * @memberof      OverviewService
   */
  getAverages(): Observable<ScoreByTeamAndSubTheme[]> {
    return this.http.get<any>(apiPath + 'teamscores/organisation/average');
  }

  /**
   *  Fetch the average scores for each teamgroup and scoreItem
   *
   * @returns       {Observable<ScoreByTeamAndSubTheme[]>}    Observable list of average scores
   * @memberof      OverviewService
   */
  getAveragesByGroupPerScoreItem(): Observable<ScoreByTeamAndSubTheme[]> {
    return this.http.get<any>(
      apiPath + 'teamscores/teamgroup/scoreitem/average',
    );
  }

  /**
   *  Fetch the average scores for team and subTheme
   *
   * @returns       {Observable<ScoreByTeamAndSubTheme[]>}    Observable list of average scores
   * @memberof      OverviewService
   */
  getAveragesByTeamAndSubTheme(
    teamId: string,
  ): Observable<ScoreByTeamAndSubTheme[]> {
    return this.http.get<any>(
      apiPath + 'teamscores/team/average?teamId=' + teamId,
    );
  }

  /**
   * Map the averages by team and subtheme for the overview purposes.
   *
   * @returns       {Observable<any>}
   * @memberof      OverviewService
   */
  averageMatrixByTeamPerSubTheme(): Observable<any> {
    this.avgMatrixByTeamPerSubTheme = {};

    return this.getAverages().pipe(
      map((averages) => {
        for (const row of averages) {
          let teamGroupObject =
            this.avgMatrixByTeamPerSubTheme[`${row.teamGroupId}`];

          if (!teamGroupObject) {
            this.avgMatrixByTeamPerSubTheme[`${row.teamGroupId}`] = {
              themes: {},
              teams: {},
            };

            teamGroupObject =
              this.avgMatrixByTeamPerSubTheme[`${row.teamGroupId}`];
          }

          if (!teamGroupObject.themes[`${row.themeId}`]) {
            teamGroupObject.themes[`${row.themeId}`] = {
              average: 0,
              rowsCount: 0,
              subThemes: {},
            };
          }

          if (
            !teamGroupObject.themes[`${row.themeId}`].subThemes[
              `${row.subThemeId}`
            ]
          ) {
            teamGroupObject.themes[`${row.themeId}`].subThemes[
              `${row.subThemeId}`
            ] = {
              average: 0,
              rowsCount: 0,
            };
          }

          if (!teamGroupObject.teams[`${row.teamId}`]) {
            teamGroupObject.teams[`${row.teamId}`] = {
              themes: {},
            };
          }

          if (
            !teamGroupObject.teams[`${row.teamId}`].themes[`${row.themeId}`]
          ) {
            teamGroupObject.teams[`${row.teamId}`].themes[`${row.themeId}`] = {
              average: 0,
              rowsCount: 0,
              subThemes: {},
            };
          }

          if (
            !teamGroupObject.teams[`${row.teamId}`].themes[`${row.themeId}`]
              .subThemes[`${row.subThemeId}`]
          ) {
            teamGroupObject.teams[`${row.teamId}`].themes[
              `${row.themeId}`
            ].subThemes[`${row.subThemeId}`] = {
              average: 0,
              rowsCount: 0,
            };
          }

          //  Calculate the average in team group per theme

          this.calculateAverage(row, teamGroupObject.themes, 'themeId');

          //  Calculate the average in team group per subtheme

          this.calculateAverage(
            row,
            teamGroupObject.themes[`${row.themeId}`].subThemes,
            'subThemeId',
          );

          //  Calculate the average in teams per theme

          this.calculateAverage(
            row,
            teamGroupObject.teams[`${row.teamId}`].themes,
            'themeId',
          );

          //  Calculate the average in teams per subTheme

          this.calculateAverage(
            row,
            teamGroupObject.teams[`${row.teamId}`].themes[`${row.themeId}`]
              .subThemes,
            'subThemeId',
          );
        }

        return this.avgMatrixByTeamPerSubTheme;
      }),
    );
  }

  /**
   * Map the averages by teamgroup and scoreItems for the overview purposes.
   *
   * @returns       {Observable<any>}
   * @memberof      OverviewService
   */
  averageMatrixByTeamGroupPerScoreItem(): Observable<any> {
    this.avgMatrixByTeamGroupPerScoreItem = {};

    return this.getAveragesByGroupPerScoreItem().pipe(
      map((averages) => {
        for (const row of averages) {
          let teamGroupObject =
            this.avgMatrixByTeamGroupPerScoreItem[`${row.teamGroupId}`];

          if (!teamGroupObject) {
            this.avgMatrixByTeamGroupPerScoreItem[`${row.teamGroupId}`] = {
              subThemes: {},
            };

            teamGroupObject =
              this.avgMatrixByTeamGroupPerScoreItem[`${row.teamGroupId}`];
          }

          if (!teamGroupObject.subThemes[`${row.subThemeId}`]) {
            teamGroupObject.subThemes[`${row.subThemeId}`] = {
              scoreItems: {},
            };
          }

          if (
            !teamGroupObject.subThemes[`${row.subThemeId}`].scoreItems[
              `${row.scoreItemId}`
            ]
          ) {
            teamGroupObject.subThemes[`${row.subThemeId}`].scoreItems[
              `${row.scoreItemId}`
            ] = {
              average: 0,
              rowsCount: 0,
            };
          }

          this.calculateAverage(
            row,
            teamGroupObject.subThemes[`${row.subThemeId}`].scoreItems,
            'scoreItemId',
          );
        }

        return this.avgMatrixByTeamGroupPerScoreItem;
      }),
    );
  }

  /**
   *  Calculate iterative average for given data.
   *
   * @param                     {*}         row
   * @param                     {*}         container
   * @param                     {string}    field
   * @memberof                  OverviewService
   */
  calculateAverage(row: any, container: any, field: string) {
    const currentCount = container[row[field]].rowsCount;
    const currentAverage = container[row[field]].average;

    container[row[field]].average =
      currentAverage + (1 / (currentCount + 1)) * (row.score - currentAverage);

    container[row[field]].rowsCount += 1;
  }
}
