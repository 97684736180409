<!-- @format -->

<div
  class="flex-rows filters-container"
  *ngIf="currentUserRights.organisationAdmin"
>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="filters-header">
      <mat-panel-title
        class="filters-title"
        i18n="pmr-default-items-list expansion-panel-title"
      >
        Filters
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div *ngIf="teamsList">
        <div
          class="flex-rows"
          [formGroup]="filterForm"
        >
          <mat-form-field
            appearance="fill"
            class="select-team"
          >
            <mat-label>Praktijk</mat-label>
            <input
              type="text"
              aria-label="Praktijk"
              matInput
              [matAutocomplete]="auto"
              [formControl]="filterControl"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let team of filteredTeams | async"
                [value]="team.name"
                (click)="onTeamSelect(team)"
              >
                {{ team.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="flex-rows">
          <button
            mat-flat-button
            color="accent"
            class="no-grow"
            matBadge="8"
            matBadgePosition="after"
            matBadgeColor="accent"
            [disabled]="!filterForm.valid || !filterForm?.get('teamId')?.value"
            (click)="onFiltersSubmit()"
          >
            Toepassen
          </button>

          <button
            mat-flat-button
            type="button"
            color="warn"
            class="no-grow"
            matBadge="8"
            matBadgePosition="after"
            matBadgeColor="accent"
            (click)="onFiltersClear()"
          >
            Reset filter
          </button>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>

<div class="pmr-items-co-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="teamName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-co-list list-header"
        >Praktijk</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.teamName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-co-list list-header"
        >Titel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-co-list list-header"
        >Omschrijving</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-co-list list-header"
        >Status</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-chip-list selectable="false">
          <mat-chip
            disableRipple="true"
            value="{{ row.status }}"
            class="is-status pmr-item-status pmr-item-status-{{ row.status }}"
          >
            {{ pmrItemStatuses[row.status] }}
          </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="conclusion">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-co-list list-header"
        >Conclusie</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-chip-list selectable="false">
          <mat-chip
            color="{{
              ['default', 'accent', 'warn', 'primary'][row.conclusion]
            }}"
            defaultColor="primary"
            disableRipple="true"
            selected="true"
            value="{{ row.conclusion }}"
            class="pmr-item-conclusion pmr-item-conclusion-{{ row.conclusion }}"
          >
            {{ pmrItemConclusions[row.conclusion] }}
          </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="IVM">
      <mat-header-cell
        *matHeaderCellDef
        i18n="pmr-items-co-list list-header"
        >Verbetermaatregel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.improvementCount ? row.improvementCount : 0 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row; let i = index"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event, i)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
        >
          <mat-icon>create</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{ 'has-error': row.hasError, 'edit-mode': row.editMode }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
