/** @format */

import { ImprovementItem } from './improvement_item';

/**
 *  Default model for the improvement group
 *
 * @export
 * @class ImprovementGroup
 */
export class ImprovementGroup {
  /**
   *  The endpoint to the API
   *
   * @memberof        ImprovementGroup
   */
  actionUrl = 'improvement-groups';

  /**
   *  The bottleneck of the improvementGroup
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */

  bottleneck: string; //  knelpunt

  /**
   *  The consequence of the improvementGroup
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  consequence: string; //  gevolg

  /**
   *  The date of the improvement group
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  date: string;

  /**
   *  The deadline of the improvement group.
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  deadline: string;

  /**
   *  The internal id of the ImprovementGroup
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  id: string;

  /**
   *  True/false if the improvementGroup is already a best practice.
   *
   * @type            {boolean}
   * @memberof        ImprovementGroup
   */
  hasBestPractice: boolean;

  /**
   *  The priority of the tasks in group
   *  Can be the one of the 3 following values: 'A', 'B', 'C'.
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  prio: string;

  /**
   *  The reason of the ImprovementGroup
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  reason: string; //  oorzaak

  /**
   *  The improvement group status.
   *  Can be the one of the 3 following values: 'Nog niet opgepakt', 'Opgepakt', 'Afgerond/ opgelost'.
   *
   * @type            {number}
   * @memberof        ImprovementGroup
   */
  status: number;

  /**
   *  The id of the assigned scoreItem
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  scoreItemId: string;

  /**
   *  The name of the assigned scoreItem
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  scoreItemName: string;

  /**
   *  The id of the team.
   *
   * @type            {string}
   * @memberof        ImprovementGroup
   */
  teamId: string;

  /**
   *  The _embedded improvementItems
   *
   * @type            { improvementItems: ImprovementItem[] };
   * @memberof        ImprovementGroup
   */
  _embedded: { improvementItems: ImprovementItem[] };
}

export const IMPROVEMENT_STATUS = [
  'Nog niet opgepakt',
  'Opgepakt',
  'Afgerond/ opgelost',
];

export const PRIO = ['A', 'B', 'C'];
