/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TeamGroup } from '../models/team-group';
import { SelectionModel } from '@angular/cdk/collections';
import { Member } from '../models/member';
import { Theme } from '../models/theme';
import { SubTheme } from '../models/subtheme';
import { TasksTemplateFrequency } from '../models/tasks-template-frequency';
import { Task } from '../models/task';
import { Role } from '../models/role';
import { SubThemeOwnership } from '../models/subtheme-ownership';
import { YearlyPlannedSubject } from '../models/yearly-planned-subject';

/**
 *  Service that shares values between app components.
 *
 * @export
 * @class SharedService
 */
@Injectable()
export class SharedService {
  /**
   *  Behavior Subject of type SelectionModel<TeamGroup> with initial value empty SelectioModel that handles the currentTeamGroup
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public currentTeamGroup = new BehaviorSubject<SelectionModel<TeamGroup>>(
    new SelectionModel(false, null),
  );

  /**
   *  Behavior Subject of type TeamGroup[]
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @public
   * @memberof        SharedService
   */
  public currentTeamGroupsList = new BehaviorSubject<TeamGroup[]>([]);

  /**
   *  Behavior Subject of type Member[]
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @public
   * @memberof        SharedService
   */
  public currentTeamMembersList = new BehaviorSubject<Member[]>([]);

  /**
   *  Behavior Subject of type Role[]
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @public
   * @memberof        SharedService
   */
  public teamRolesList = new BehaviorSubject<Role[]>([]);

  /**
   *  Behavior Subject of type Theme[]
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public currentThemesList = new BehaviorSubject<Theme[]>([]);

  /**
   *  Behavior Subject of themes list with ids as keys
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public currentThemesWithIds = new BehaviorSubject<{}>({});

  /**
   *  Behavior Subject of type Theme with initial value empty SelectioModel that handles the currentTheme
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public currentTheme = new BehaviorSubject<SelectionModel<Theme>>(
    new SelectionModel(true, null),
  );

  /**
   * Use to send swipe events for each tab group, to the other tab groups to have them swipe in the same direction.
   *

   * @type {BehaviorSubject<number>}
   */
  public overviewScoresBlockSwipe = new BehaviorSubject(<number>0);

  /**
   *  Behavior Subject of type `any` with initial value empty SelectioModel that handles the currently selected Score tile in the Overview
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public selectedScoreTile = new BehaviorSubject<SelectionModel<any>>(
    new SelectionModel(true, null),
  );

  /**
   *  Behavior Subject of type `TasksTemplateFrequency[]` that stores the list of frequenciec for the tasks list
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public frequenciesList = new BehaviorSubject<TasksTemplateFrequency[]>([]);

  /**
   *  Behavior Subject of type `Subtheme[]` with initial value empty SelectioModel that stores the subThemes list
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public subThemesList = new BehaviorSubject<SubTheme[]>([]);

  /**
   *  Behavior Subject of type Task
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @public
   * @memberof        SharedService
   */
  private refreshTasks = new BehaviorSubject<Task>(<any>{});

  /**
   *  Behavior Subject of type SubthemeOwnership[] that handles the subThemeOwnershipList for current team.
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public subThemesOwnershipList = new BehaviorSubject<{}>([]);

  /**
   *  Behavior Subject of type `YearlyPlannedSubject[]` that stores the list of yearly planned subjects for the choosen team
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject

   *
   * @private
   * @memberof        SharedService
   */
  public yearlyPlannedSubjects = new BehaviorSubject<YearlyPlannedSubject[]>(
    [],
  );

  /**
   *  BehaviorSubject has its initial value and gives you acces to the current value, contrary to Subject
   *
   * @public
   * @memberof        SharedService
   */
  public filesCategories = new BehaviorSubject<any[]>([]);

  /**
   *  Observable SelectionModel<TeamGroup> stream that identifies currently selected TeamGroup
   *
   * @memberof        SharedService
   */
  currentTeamGroup$ = this.currentTeamGroup.asObservable();

  /**
   *  Observable TeamGroup[] stream that identifies current TeamGroups List
   *
   * @memberof        SharedService
   */
  currentTeamGroupsList$ = this.currentTeamGroupsList.asObservable();

  /**
   *  Observable Member[] stream that identifies current members list.
   *
   * @memberof        SharedService
   */
  currentTeamMembersList$ = this.currentTeamMembersList.asObservable();

  /**
   *  Observable Role[] stream that identifies the list of roles in the team
   *
   * @memberof        SharedService
   */
  teamRolesList$ = this.teamRolesList.asObservable();

  /**
   *  Observable Themes[] stream that identifies current ThemesList
   *
   * @memberof        SharedService
   */
  currentThemesList$ = this.currentThemesList.asObservable();

  /**
   *  Observable stream that identifies current ThemesList with Ids as keys
   *
   * @memberof        SharedService
   */
  currentThemesWithIds$ = this.currentThemesWithIds.asObservable();

  /**
   *  Observable Theme stream that identifies currently selected Theme
   *
   * @memberof        SharedService
   */
  currentTheme$ = this.currentTheme.asObservable();

  /**
   * Obserable overviewScoresBlockSwipe stream of swipe events.
   *
   * @type {Observable<number>} Number is direction
   *
   * 2: DIRECTION_LEFT
   * 4: DIRECTION_RIGHT
   *
   */
  overviewScoresBlockSwipe$ = this.overviewScoresBlockSwipe.asObservable();

  /**
   *  Observable selectedScoreTile stream that identifies currently selected scoreTile
   *
   * @memberof        SharedService
   */
  selectedScoreTile$ = this.selectedScoreTile.asObservable();

  /**
   *  Observable subThemesList  stream that identifies the list of subThemes
   *
   * @memberof        SharedService
   */
  subThemesList$ = this.subThemesList.asObservable();

  /**
   *  Observable requenciesList  stream that identifies the list of frequencies.
   *
   * @memberof        SharedService
   */
  frequenciesList$ = this.frequenciesList.asObservable();

  /**
   *  Observable refreshTasks  stream that identifies change in the task tile.
   *
   * @memberof        SharedService
   */
  refreshTasks$ = this.refreshTasks.asObservable();

  /**
   *  Observable subThemesOwnershipList  stream that identifies change in the subThemesOwnershipList.
   *
   * @memberof        SharedService
   */
  subThemesOwnershipList$ = this.subThemesOwnershipList.asObservable();

  /**
   *  Observable stream that identifies change in the yearlyPlannedSubjects.
   *
   * @memberof        SharedService
   */
  yearlyPlannedSubjects$ = this.yearlyPlannedSubjects.asObservable();

  /**
   *  Observable stream that identifies change in the filesCategories.
   *
   * @memberof        SharedService
   */
  filesCategories$ = this.filesCategories.asObservable();

  /**
   * Creates an instance of SharedService.
   *
   * @memberof        SharedService
   */
  constructor() {}

  /**
   *  Set next value to the currentTeamGroup variable.
   *
   * @param           {SelectionModel<TeamGroup>}     teamGroupSelectionModel   New SelectionModel of the TeamGroup
   * @memberof        SharedService
   */
  newSelectedTeamGroup(teamGroupSelectionModel: SelectionModel<TeamGroup>) {
    this.currentTeamGroup.next(teamGroupSelectionModel);
  }

  /**
   *  Set next value to the currentTeamGroup variable.
   *
   * @param           {TeamGroup[]}                   teamGroupsList            New list of the team groups.
   * @memberof        SharedService
   */
  newSelectedTeamGroupsList(teamGroupsList: TeamGroup[]) {
    this.currentTeamGroupsList.next(teamGroupsList);
  }

  /**
   *  Set next value to the currentTeamMembersList variable.
   *
   * @param           {Member[]}                   teamMembersList            New list of the team members.
   * @memberof        SharedService
   */
  newTeamMembersList(teamMembersList: Member[]) {
    this.currentTeamMembersList.next(teamMembersList);
  }

  /**
   *  Set next value to the teamRolesList variable.
   *
   * @param           {Role[]}                   teamRolesList            New list of the team roles.
   * @memberof        SharedService
   */
  newTeamRolesList(teamRolesList: Role[]) {
    this.teamRolesList.next(teamRolesList);
  }

  /**
   *  Set next value to the currentThemesList variable.
   *
   * @param           {Theme[]}                   themesList            New list of the themes.
   * @memberof        SharedService
   */
  newThemesList(themesList: Theme[]) {
    this.currentThemesList.next(themesList);
  }

  /**
   *  Set next value to the currentThemesWithIds variable.
   *
   * @param           {}                   themesWithIds           New list of the themes with ids as keys.
   * @memberof        SharedService
   */
  newThemesWithIdsList(themesWithIds: {}) {
    this.currentThemesWithIds.next(themesWithIds);
  }

  /**
   *  Set next value to the currentTheme variable.
   *
   * @param           {Theme}                   theme            New theme
   * @memberof        SharedService
   */
  newSelectedTheme(theme: SelectionModel<Theme>) {
    this.currentTheme.next(theme);
  }

  /**
   *  Set next value to the selectedScoreTile variable.
   *
   * @param           {any}                   tile            New tile
   * @memberof        SharedService
   */
  newSelectedScoreTile(tile: SelectionModel<any>) {
    this.selectedScoreTile.next(tile);
  }

  /**
   *  Set next value to the subThemesList variable.
   *
   * @param           {SubTheme[]}                   subThemesList            New subThemesList
   * @memberof        SharedService
   */
  newSubThemesList(subThemesList: SubTheme[]) {
    this.subThemesList.next(subThemesList);
  }

  /**
   *  Set next value to the frequenciesList variable.
   *
   * @param           {TasksTemplateFrequency[] }   frequenciesList         The list of frequencies
   * @memberof        SharedService
   */
  newFrequenciesList(frequenciesList: TasksTemplateFrequency[]) {
    this.frequenciesList.next(frequenciesList);
  }

  /**
   *  Set next value to the refreshTasks variable.
   *
   * @param           { Task }   task         The new task
   * @memberof        SharedService
   */
  newRefreshTasks(task: Task) {
    this.refreshTasks.next(task);
  }

  /**
   *  Set next value to the subThemeOwnershipList: variable.
   *
   * @param           { SubThemeOwnership[] }   subThemeOwnershipList        The new subThemeOwnershipList:
   * @memberof        SharedService
   */
  newSubhtemeOwnershipList(subThemeOwnershipList: {}) {
    this.subThemesOwnershipList.next(subThemeOwnershipList);
  }

  /**
   *  Set next value to the yearlyPlannedsubjects .
   *
   * @param           { YearlyPlannedSubject[] }   yearlyPlannedSubjects        The new yearlyPlannedSubjects
   * @memberof        SharedService
   */
  newYearlyPlannedSubjects(yearlyPlannedSubjects: YearlyPlannedSubject[]) {
    this.yearlyPlannedSubjects.next(yearlyPlannedSubjects);
  }

  /**
   *  Set next value to the files categories
   *
   * @param           {any[]}                       categories                The new filesCategories
   * @memberof        SharedService
   */
  newFilesCategories(categories: any[]) {
    this.filesCategories.next(categories);
  }
}
