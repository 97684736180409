<!-- @format -->

<h2
  mat-dialog-title
  i18n="delete-warning-dialog dialog-title"
>
  Waarschuwing
</h2>

<mat-dialog-content>
  <div class="dialog-object-name">{{ data.name }}</div>
  <div class="dialog-message">{{ data.message }}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="true"
    i18n="delete-warning-dialog button agree-on-delete @@buttonAgree"
  >
    Ja
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onNoClick()"
    i18n="delete-warning-dialog button disagree-on-delete @@buttonDisagree"
  >
    Nee
  </button>
</mat-dialog-actions>
