<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-pmr-item-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuw PMR item
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-pmr-item-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen PMR item
</h2>

<mat-dialog-content>
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Onderwerp</mat-label>
      <input
        type="text"
        matInput
        formControlName="title"
        required
      />
      <mat-error [hidden]="!formErrors.title">{{ formErrors.title }}</mat-error>
    </mat-form-field>
  </form>

  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Omschrijving</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMinRows="5"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
