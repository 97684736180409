<!-- @format -->

<div
  class="container"
  [ngClass]="{ 'container--opened': menuOpened }"
>
  <div
    class="inner-container"
    (click)="triggerMenu()"
  >
    <mat-icon class="avatar">account_circle</mat-icon>
    <div class="user">
      <span class="welcome">Welkom,</span>
      <span>{{ userName }}</span>
    </div>
  </div>
  <mat-icon (click)="triggerMenu()">{{
    menuOpened ? 'expand_less' : 'expand_more'
  }}</mat-icon>
  <div
    class="menu"
    [ngClass]="{ 'menu--opened': menuOpened }"
  >
    <button
      mat-flat-button
      color="primary"
      class="logout"
      [routerLink]="'auth/login'"
    >
      Uitloggen
    </button>
  </div>
</div>
