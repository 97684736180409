<!-- @format -->

<mat-card
  class="mat-elevation-z0"
  fxLayout="row"
  fxLayoutAlign="start none"
>
  <div
    class="kms-auth"
    fxFlex="50%"
  >
    <a
      mat-flat-button
      color="primary"
      routerLink="/"
      class="action-retreat attached north-east"
    >
      Terug
    </a>
    <div class="kms-auth-form">
      <h3
        class="mat-subheading-2"
        i18n="
          forgot-password-form form-title window@@auth.forgotPasswordWindowTitle"
      >
        Wachtwoord vergeten?
      </h3>

      <p>
        Voer het e-mailadres in dat aan uw account is gekoppeld om uw wachtwoord
        te wijzigen.
      </p>

      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label i18n="forgot-password-form form-field-label"
            >E-mailadres</mat-label
          >
          <input
            type="email"
            matInput
            i18n-placeholder="forgot-password-form form-placeholder"
            placeholder="e-mail@domein.nl"
            formControlName="emailAddress"
            required
          />
          <mat-error [hidden]="!formErrors.emailAddress">{{
            formErrors.emailAddress
          }}</mat-error>
        </mat-form-field>

        <button
          type="submit"
          mat-flat-button
          color="primary"
          (click)="submit()"
          [disabled]="form.invalid || form.disabled"
          i18="send button @@buttonSend"
        >
          Verstuur
        </button>
      </form>
    </div>
  </div>
</mat-card>
