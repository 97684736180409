<!-- @format -->

<h2
  mat-dialog-title
  i18n="error-dialog dialog-title"
>
  Fout
</h2>

<mat-dialog-content>
  <div class="dialog-object-name">{{ data.name }}</div>
  <div class="dialog-message">{{ data.message }}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    class="button-full-width"
    i18n="error-dialog button agree-on-error @@buttonAgree"
  >
    OK
  </button>
</mat-dialog-actions>
