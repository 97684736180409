<!-- @format -->

<div class="contact-form__container">
  <div class="contact-form__back-btn">
    <button
      mat-flat-button
      color="primary"
      class="action-retreat"
      [routerLink]="'../list'"
    >
      Terug
    </button>
  </div>
  <section class="contact-form__form-container">
    <form
      [formGroup]="contactCoachForm"
      class="example-form"
      (ngSubmit)="submitForm()"
    >
      <mat-form-field appearance="fill">
        <mat-label>Onderwerp</mat-label>
        <input
          type="text"
          matInput
          formControlName="heading"
          readonly
        />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Beschrijving</mat-label>
        <textarea
          matInput
          formControlName="additionalInfo"
          matTextareaAutosize
          matAutosizeMinRows="1"
          resizeToFitContent
          rows="5"
        ></textarea>
      </mat-form-field>
      <button
        mat-flat-button
        color="primary"
        type="submit"
      >
        Versturen
      </button>
    </form>
  </section>
  <div></div>
</div>
