/** @format */

import {
  trigger,
  animateChild,
  group,
  transition,
  animate,
  style,
  query,
} from '@angular/animations';

const duration = '500ms';
const containerAnimatingStyle = style({
  position: 'relative',
  overflowX: 'clip',
});

const forward = [
  containerAnimatingStyle,
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    }),
  ]),
  query(':enter', [style({ left: '100%' })]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(`${duration} ease-out`, style({ left: '-100%' })),
    ]),
    query(':enter', [animate(`${duration} ease-out`, style({ left: '0%' }))]),
  ]),
  query(':enter', animateChild()),
];

const backward = [
  containerAnimatingStyle,
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    }),
  ]),
  query(':enter', [style({ left: '-100%' })]),
  query(':leave', animateChild()),
  group([
    query(':leave', [animate(`${duration} ease-out`, style({ left: '100%' }))]),
    query(':enter', [animate(`${duration} ease-out`, style({ left: '0%' }))]),
  ]),
  query(':enter', animateChild()),
];

export const navTabAnimation = trigger('routeAnimations', [
  transition('animatedTab1 => animatedTab2', forward),
  transition('animatedTab2 => animatedTab3', forward),
  transition('animatedTab1 => animatedTab3', forward),

  transition('animatedTab3 => animatedTab2', backward),
  transition('animatedTab2 => animatedTab1', backward),
  transition('animatedTab3 => animatedTab1', backward),
]);
