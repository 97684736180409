<!-- @format -->

<div class="container">
  <div class="graphs-wrapper">
    <div class="row">
      <div
        class="column"
        #column
      >
        <div class="cell">
          <app-sunburst-graph
            (click)="navigateToDetailsPage(chartTypes.QUALITY)"
            [singleGraphData]="
              (sunburstGraphService.graphData$ | async)?.qualityGraph
            "
            [chartId]="chartTypes.QUALITY"
          ></app-sunburst-graph>
        </div>
      </div>
      <div class="column">
        <div class="cell">
          <app-sunburst-graph
            (click)="navigateToDetailsPage(chartTypes.INNOVATION)"
            [singleGraphData]="
              (sunburstGraphService.graphData$ | async)?.innovationGraph
            "
            [chartId]="chartTypes.INNOVATION"
          ></app-sunburst-graph>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="cell">
          <!-- ToDo replace with (sunburstGraphService.graphData$ | async)?.compliancyGraph when data is ready -->
          <app-sunburst-graph
            (click)="navigateToDetailsPage(chartTypes.COMPLIANCY)"
            [singleGraphData]="
              (sunburstGraphService.graphData$ | async)?.compliancyGraphNextYear
            "
            [chartId]="chartTypes.COMPLIANCY"
          ></app-sunburst-graph>
        </div>
      </div>
      <div class="column">
        <div class="cell">
          <app-sunburst-graph
            (click)="navigateToDetailsPage(chartTypes.BUSINESS_OPERATIONS)"
            [singleGraphData]="
              (sunburstGraphService.graphData$ | async)?.businessOperationsGraph
            "
            [chartId]="chartTypes.BUSINESS_OPERATIONS"
          ></app-sunburst-graph>
        </div>
      </div>
    </div>
  </div>
</div>
