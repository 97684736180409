/** @format */

import { distinctUntilChanged, debounceTime, catchError } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { TeamGroup } from '../../models/team-group';
import { SharedService } from '../../services/shared.service';
import { Subscription, of } from 'rxjs';
import { MarviqMatTableInlineCrudComponent, PageSetting } from '@iq/ng-core';
import { Team } from '../../models/team';
import { TeamsListService } from '../../services/teams-list.service';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { AddEditTeamComponent } from '../../dialogs/add-edit-team/add-edit-team.component';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { malAuthService } from '@iq/ng-auth';
import { UserValidatorService } from '../../services/user-validator.service';

/**
 *  Display an overview of teams.
 *
 * @export
 * @class       TeamsListComponent
 * @extends     {MarviqMatTableComponent<Team>}
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss'],
})
export class TeamsListComponent
  extends MarviqMatTableInlineCrudComponent<Team>
  implements OnInit, OnDestroy {
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                TeamsListComponent
   */
  columnsToDisplay = ['index', 'group', 'name', 'CUD'];

  /**
   *  The default values of the new Form.
   *
   * @memberof                TeamsListComponent
   */
  emptyFormValues = {
    group: [''],
    teamGroupId: ['', Validators.required],
    name: ['', Validators.required],
  };

  /**
   *  The form group storing filters
   *
   * @type                    {FormGroup}
   * @memberof                TeamsListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The currently selected row in the TeamGroup table.
   *
   * @type                    {SelectionModel<TeamGroup>}
   * @memberof                TeamsListComponent
   */
  selectedTeamGroup: SelectionModel<TeamGroup>;

  /**
   *  The Subscription of sharedService
   *
   * @type                    {Subscription}
   * @memberof                TeamsListComponent
   */
  sharedServiceSubs: Subscription;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof  TeamsListComponen
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The dialog component assigned to the error.
   *
   * @memberof                 TeamsListComponent
   */
  errorDialog = ErrorDialogComponent;

  /**
   *  Error dialog paramters
   *
   * @memberof  TeamsListComponen
   */
  errorDialogParameters = <any>{
    panelClass: 'delete-dialog',
    maxWidth: '800px',
    disableClose: true,
    data: {},
  };

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                TeamsListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * Creates an instance of TeamsListComponent.
   *
   * @param         {TeamsListService}      teamsListService    The instance of the teamList service
   * @param         {SharedService}         sharedService       The instance of the service that shares the values between components
   * @memberof      TeamsListComponent
   */
  constructor(
    private teamsListService: TeamsListService,
    public sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private fbuild: UntypedFormBuilder,
    private editDialog: MatDialog,
    private dg: MatDialog,
    private http: HttpClient,
    private snackBar: MatSnackBar,
  ) {
    super(teamsListService, formBuilder, dg);
  }

  #authService = inject(malAuthService);
  #userValidatorService = inject(UserValidatorService);

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      TeamsListComponent
   */
  ngOnInit() {
    this.sortValues = { updateDate: 'desc' };
    this.selection = new SelectionModel<Team>(
      this.allowMultiSelect,
      this.initialSelection,
    );
    this.dialogParameters.data.message = `Weet je zeker dat je deze praktijk wilt verwijderen? Hiermee worden ook de toegewezen gebruikers verwijderd!`;
    this.errorDialogParameters.data.message = `Je kunt de praktijk niet verwijderen omdat deze leden bevat`;

    this.filterForm = this.fbuild.group({
      name: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(changes)) {
          this.filterValues[field] = changes[field];
        }

        this.usersPageSetting.number = 0;
        this.paginator.firstPage();
        this.getRows();
      });

    this.sharedServiceSubs = this.sharedService.currentTeamGroup$.subscribe(
      (teamGroupSelection) => {
        this.selectedTeamGroup = teamGroupSelection;

        if (this.selectedTeamGroup.hasValue()) {
          this.queryParams = [
            {
              name: 'teamgroup-id',
              value: this.selectedTeamGroup.selected[0].id,
            },
          ];
          this.emptyFormValues = {
            group: [this.selectedTeamGroup.selected[0].name],
            teamGroupId: [
              this.selectedTeamGroup.selected[0].id,
              Validators.required,
            ],
            name: ['', Validators.required],
          };
        } else {
          this.queryParams = [];
        }

        this.getRows();
      },
    );
  }

  /**
   *  Overrides the existing method in the parent component.
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    TeamGroupsListComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      const editedRowFormValues = {
        id: [row.id],
        group: [row.group],
        teamGroupId: [row.teamGroupId, Validators.required],
        name: [row.name, Validators.required],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.editDialog.open(AddEditTeamComponent, {
      data: { formValues: formValues, isNew: isNew },
      disableClose: true,
      height: '500px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'users-teams-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.#authService.id().subscribe((val) => {
          this.#userValidatorService.newUser(val);
          this.#userValidatorService.manageUserTeamsRefreshTrigger$.next();
          this.#authService.saveState();
          this.getRows();
        })

      }
    });
  }

  /**
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      TeamGroupsListComponent
   */
  onDeleteIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = row.name;
    this.errorDialogParameters.data.name = row.name;

    if (event) {
      event.stopImmediatePropagation();
    }

    if (dialog) {
      const dialogRef = this.dg.open(dialog, this.dialogParameters);

      dialogRef.afterClosed().subscribe((results) => {
        if (!results) {
          return;
        }

        this.teamsListService.delete(row).subscribe(
          (resp) => {
            this.#authService.id().subscribe((val) => {
              this.#userValidatorService.newUser(val);
              this.#userValidatorService.manageUserTeamsRefreshTrigger$.next();
              this.#authService.saveState();
              this.getRows();

              row.hasError = false;
              this.updateTable(row, resp, 'delete');
            })

          },
          (err) => {
            if (err.error && err.error.code === 409) {
              const dialogRefA = this.dg.open(
                this.errorDialog,
                this.errorDialogParameters,
              );
            }

            row.hasError = true;
          },
        );
      });
    } else {
      this.teamsListService.delete(row).subscribe(
        (resp) => {
          this.#authService.id().subscribe((val) => {
            this.#userValidatorService.newUser(val);
            this.#userValidatorService.manageUserTeamsRefreshTrigger$.next();
            this.#authService.saveState();
            this.getRows();

            row.hasError = false;
            this.updateTable(row, resp, 'delete');
          })
        },
        (err) => {
          if (err.error && err.error.code === 409) {
            const dialogRefA = this.dg.open(
              this.errorDialog,
              this.errorDialogParameters,
            );
          }

          row.hasError = true;
        },
      );
    }
  }

  triggerGraphDataRefresh(row: any, graphTypeEndpoint: 'innovatie' | 'quality' | 'compliance'): void {
    const apiPath = environment.apiPath;
    this.http
      .post(
        `${apiPath}${graphTypeEndpoint}-benchmark-results/${row.id}`,
        {},
      )
      .pipe(
        catchError(() => {
          this.snackBar.open('Er is iets fout gegaan', null, {
            duration: 2500,
          });
          return of();
        }),
      )
      .subscribe(() => {
        this.snackBar.open('Gegevens vernieuwd', null, { duration: 2500 });
      });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof    TeamsListComponent
   */
  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
    this.sharedServiceSubs.unsubscribe();
  }
}
