<!-- @format -->

<div class="impr-item-container">
  <form [formGroup]="imprItemForm">
    <mat-form-field appearance="fill">
      <mat-label>Verbeteractie {{ index + 1 }}</mat-label>
      <textarea
        matInput
        formControlName="description"
        required
        matTextareaAutosize
        matAutosizeMinRows="1"
        resizeToFitContent
      ></textarea>
      <mat-error [hidden]="!formErrors.description">{{
        formErrors.description
      }}</mat-error>
    </mat-form-field>
  </form>
  <div
    fxLayout="row"
    fxLayout.cmb="column"
    fxLayoutAlign="start none"
    class="flex-improvement-item"
  >
    <form
      [formGroup]="imprItemForm"
      fxFlex="50%"
      fxFlex.cmb="100%"
      class="left-field"
    >
      <mat-form-field appearance="fill">
        <mat-label>Actiehouder</mat-label>
        <mat-select
          formControlName="responsibleMember"
          [compareWith]="compareFn"
          required
        >
          <mat-option
            *ngFor="let member of sharedService.currentTeamMembersList$ | async"
            [value]="member"
          >
            {{ member.memberName }}
          </mat-option>
        </mat-select>
        <mat-error [hidden]="!formErrors.responsibleMember">{{
          formErrors.responsibleMember
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div
    fxLayout="row"
    fxLayout.cmb="column"
    fxLayoutAlign="start none"
    class="flex-improvement-item"
  >
    <form
      [formGroup]="imprItemForm"
      fxFlex="50%"
      fxFlex.cmb="100%"
      class="left-field"
    >
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select
          formControlName="status"
          required
        >
          <mat-option
            *ngFor="let status of improvementStatuses; let i = index"
            [value]="i"
          >
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <form
      [formGroup]="imprItemForm"
      fxFlex="50%"
      fxFlex.cmb="100%"
    >
      <mat-form-field appearance="fill">
        <mat-label>Deadline</mat-label>
        <input
          type="text"
          matInput
          formControlName="periodEndDate"
          [min]="itemIsNew ? today : null"
          [matDatepicker]="picker"
          required
        />
        <mat-datepicker #picker></mat-datepicker>
        <mat-error [hidden]="!formErrors.periodEndDate">{{
          formErrors.periodEndDate
        }}</mat-error>
        <mat-icon (click)="picker.open()">calendar_month</mat-icon>
      </mat-form-field>
    </form>
  </div>
  <div class="button-row mobile-button-row">
    <button
      mat-flat-button
      *ngIf="!itemIsNew"
      class="impr-item-button remove-item-button"
      (click)="onDeleteIntent()"
      i18n="delete button @@buttonDelete"
    >
      Verwijder
    </button>
    <button
      mat-flat-button
      class="impr-item-button"
      (click)="onCancel()"
      i18n="cancel button @@buttonCancel"
    >
      Annuleren
    </button>
    <button
      *ngIf="!formSubmitted"
      mat-flat-button
      class="impr-item-button"
      (click)="onFormSave()"
      [disabled]="!formChanged || imprItemForm.invalid"
      i18n="save button @@buttonSave"
    >
      Opslaan
    </button>
    <button
      *ngIf="formSubmitted"
      mat-flat-button
      class="impr-item-button submitted"
      i18n="saved button @@buttonSaved"
    >
      <mat-icon>check</mat-icon> Opgeslagen
    </button>
  </div>
</div>
