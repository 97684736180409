<!-- @format -->

<div
  fxLayout="row"
  fxLayout.cmb="column"
  fxLayoutAlign="start none"
  class="flex-score-item"
>
  <div
    class="scores-container"
    fxFlex="45%"
    fxFlex.cmb="100%"
  >
    <div class="review-question">
      {{ scoreItem.reviewQuestion }}
    </div>
    <div class="smiley-rating-container">
      <div class="smileys smiley-{{ score | number: '1.0-0' }}"></div>
    </div>
  </div>
  <div
    class="score-item-content change-on-mobile"
    fxFlex="55%"
    fxFlex.cmb="100%"
  >
    <div class="tile-name">
      {{ scoreItem.name }}
    </div>
    <ul>
      <li *ngIf="scoreItem.explanation">{{ scoreItem.explanation }}</li>
      <li *ngIf="scoreItem.measuringInstrument">
        {{ scoreItem.measuringInstrument }}
      </li>
    </ul>
  </div>
</div>
