/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';

import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  DASHBOARD_CATEGORIES,
  DashboardCounts,
} from '../../models/dashboard-counts';
import { DashboardService } from '../../services/dashboard.service';
import { Subject, Subscription } from 'rxjs';
import { PagedResult } from '@iq/ng-core';
import { PracticeChangeService } from '../../services/practice-change.service';

/**
 *  Show the overview of dashboard counts
 *
 * @export
 * @class         DashboardViewComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent implements OnInit, OnDestroy {
  #destroyRef$ = new Subject<void>();

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              DashboardViewComponent
   */
  currentUserRights: UserRights;

  /**
   *  The categories of dashboard that indicates the columns
   *
   * @memberof              DashboardViewComponent
   */
  dashboardCategories = DASHBOARD_CATEGORIES;

  /**
   *  The dashboard counts fetched from the service
   *
   * @type                    {DashboardCounts}
   * @memberof                DashboardViewComponent
   */
  dashboardCounts: DashboardCounts;

  /**
   *  The subscription of the dashboard service
   *
   * @type                    {Subscription}
   * @memberof                DashboardViewComponent
   */
  dashboardSubs: Subscription;

  /**
   *  mobile Query
   *
   * @memberof                DashboardViewComponent
   */
  mobileQuery: MediaQueryList;

  /**
   *  The listener for mobileQuery changes
   *
   * @private
   * @memberof                DashboardViewComponent
   */
  private _mobileQueryListener: (change: any) => void;

  /**
   *  Current date
   *
   * @memberof                DashboardTasksContainerComponent
   */
  today = moment().format('YYYY-MM-DD');

  /**
   * Creates an instance of DashboardViewComponent.
   *
   * @memberof    DashboardViewComponent
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private media: MediaMatcher,
    private ref: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private practiceChangeService: PracticeChangeService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof DashboardViewComponent
   */
  ngOnInit() {
    this.practiceChangeService.refreshDashboardDataTrigger$
      .pipe(takeUntil(this.#destroyRef$))
      .subscribe(() => {
        this.#initDashoboardData();
      });
    this.#initDashoboardData();
  }

  #initDashoboardData(): void {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    //  Change the expansion panel when the mediaQuery matches
    this.mobileQuery = this.media.matchMedia('( max-width: 1200px )');
    this._mobileQueryListener = (change: any) => this.ref.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    this.dashboardSubs = this.dashboardService
      .getDashboardCounts(this.currentUserRights.currentTeamId)
      .subscribe((resp) => {
        this.dashboardCounts = resp;

        for (const category of this.dashboardCategories) {
          for (const card of category.cards) {
            for (const count of card.counts) {
              count.value = 0;
              for (const fieldName of count.fieldNames) {
                if (this.dashboardCounts[fieldName] !== undefined) {
                  count.value += this.dashboardCounts[fieldName];
                }
              }
            }
          }
        }
      });
  }

  /**
   *  Check if the current member is allowed to see the specific card
   *
   * @param {*} card
   * @memberof DashboardViewComponent
   */
  checkPermissions(card: any) {
    let permitted = false;
    const permissions =
      this.currentUserRights.currentMember.teamRole.permissions;

    if (this.currentUserRights.organisationAdmin) {
      if (card && card.orgAdminVisible) {
        permitted = true;
      }
    } else {
      if (card && card.rights) {
        for (const right of card.rights) {
          if (permissions.indexOf(right) > -1) {
            permitted = true;

            break;
          }
        }
      } else {
        permitted = true;
      }
    }

    return permitted;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof DashboardViewComponent
   */
  ngOnDestroy() {
    this.#destroyRef$.next();
    this.#destroyRef$.complete();

    this.ref.detach();
    this.mobileQuery.removeListener(this._mobileQueryListener);

    if (this.dashboardSubs) {
      this.dashboardSubs.unsubscribe();
    }
  }
}
