/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  The best practices container.
 *
 * @export
 * @class         BestPracticesComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-best-practices',
  templateUrl: './best-practices.component.html',
  styleUrls: ['./best-practices.component.scss'],
})
export class BestPracticesComponent implements OnInit {
  /**
   * Creates an instance of BestPracticesComponent.
   *
   * @memberof BestPracticesComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof BestPracticesComponent
   */
  ngOnInit() {}
}
