/** @format */

import { Component, ChangeDetectorRef, OnChanges, Input } from '@angular/core';
import { ImprovementsService } from '../../services/improvements.service';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEditImprovementComponent } from '../../dialogs/add-edit-improvement/add-edit-improvement.component';
import { ImprovementsListEmbeddedComponent } from '../improvements-list-embedded/improvements-list-embedded.component';

/**
 *  Show the list of embedded pmr improvements
 *
 * @export
 * @class PmrImprovementsListEmbeddedComponent
 * @extends {ImprovementsListEmbeddedComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-pmr-improvements-list-embedded',
  templateUrl: './pmr-improvements-list-embedded.component.html',
  styleUrls: ['./pmr-improvements-list-embedded.component.scss'],
})
export class PmrImprovementsListEmbeddedComponent
  extends ImprovementsListEmbeddedComponent
  implements OnChanges
{
  /**
   *  True/false if the form should be disabled
   *
   * @type {boolean}
   * @memberof PmrImprovementsListEmbeddedComponent
   */
  @Input() formDisabled: boolean;

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                PmrImprovementsListEmbeddedComponent
   */
  columnsToDisplay = [
    'copiedFromPrevious',
    'improvementSubject',
    'responseStatus',
    'CUD',
  ];

  /**
   * Creates an instance of PmrImprovementsListEmbeddedComponent.
   *
   * @param    {ImprovementsService} improvementServiceA
   * @param    {FormBuilder}         fbuilderA
   * @param    {MatDialog}           dgA
   * @param    {SharedService}       sharedServiceB
   * @param    {ActivatedRoute}      actRouteA
   * @param    {Router}              routeA
   * @param    {ChangeDetectorRef}   refA
   * @memberof PmrImprovementsListEmbeddedComponent
   */
  constructor(
    private improvementServiceA: ImprovementsService,
    private fbuilderA: UntypedFormBuilder,
    private dgA: MatDialog,
    public sharedServiceB: SharedService,
    private actRouteA: ActivatedRoute,
    private routeA: Router,
    private refA: ChangeDetectorRef,
  ) {
    super(
      improvementServiceA,
      fbuilderA,
      dgA,
      sharedServiceB,
      actRouteA,
      routeA,
    );
  }

  /**
   * Check if the member of team is already an executioner of current improvement
   *
   * @param {*} element
   * @param {*} index
   * @param {*} array
   * @memberof PmrImprovementsListEmbeddedComponent
   */
  notYetLinked(element: any, index: any, array: any) {
    let exsists = false;

    if (this.listData && this.listData.length) {
      for (const item of this.listData) {
        if (element.id === item.improvementId) {
          exsists = true;

          break;
        }
      }
    }

    return !exsists;
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    PmrImprovementsListEmbeddedComponent
   */
  onEditIntent(row?: any, event?: Event) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;

      this.openEditDialog(formValues, isNew, row);
    } else {
      isNew = false;

      this.improvementServiceA
        .getSingleImprovement(
          row.improvementId,
          this.currentUserRights.currentTeamId,
        )
        .subscribe((response) => {
          const editedRowFormValues = this.prepareEditedFormValues(response);
          formValues = editedRowFormValues;

          this.openEditDialog(formValues, isNew, response);
        });
    }
  }

  /**
   * Open the add/edit dialog
   *
   * @param    {*}       formValues
   * @param    {boolean} isNew
   * @param    {*}       item
   * @memberof PmrImprovementsListEmbeddedComponent
   */
  openEditDialog(formValues: any, isNew: boolean, item: any) {
    const dialogRef = this.dgA.open(AddEditImprovementComponent, {
      data: { formValues: formValues, isNew: isNew, improvement: item },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        if (results.resp && results.resp.id && isNew) {
          this.onLinkIntent(results.resp);
        } else if (results.resp && results.resp.id && !isNew && this.listData) {
          for (let i = 0; i < this.listData.length; i++) {
            if (this.listData[i].improvementId === results.resp.id) {
              const pmrimprovement = {
                improvementSubject: results.resp.subject,
                responseStatus: results.resp.responseStatus,
              };

              this.listData[i] = pmrimprovement;

              this.dataSource.connect().next(this.listData);
            }
          }
        }
      }
    });
  }
}
