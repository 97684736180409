/** @format */

import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImprovementGroup } from '../../models/improvement-group';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { BestPractice } from '../../models/best-practice';
import { SharedService } from '../../services/shared.service';
import { BestPracticesService } from '../../services/best-practices.service';

/**
 *  The dialog with the best practice form.
 *
 * @export
 * @class           AddBestPracticeComponent
 * @implements      {OnInit}
 * @implements      {OnDestroy}
 */
@Component({
  selector: 'app-add-best-practice',
  templateUrl: './add-best-practice.component.html',
  styleUrls: ['./add-best-practice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddBestPracticeComponent implements OnInit, OnDestroy {
  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                AddBestPracticeComponent
   */
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareBestPractices;

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                AddBestPracticeComponent
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the dialog state has been changed
   *  If yes the add/put/ delete/ request was successfully sent
   *
   * @memberof                AddBestPracticeComponent
   */
  dialogStateChanged = false;

  /**
   *  The default values of the new Form.
   *
   * @memberof                AddBestPracticeComponent
   */
  emptyFormValues = {
    improvementGroup: [{}],
    proposal: ['', Validators.required],
    relatedThemes: [[], Validators.required],
    status: [0],
  };

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddBestPracticeComponent
   */
  formErrors = { proposal: '', relatedThemes: '' };

  /**
   *  True/false if the bestPractice is New.
   *
   * @memberof                AddBestPracticeComponent
   */
  bestPracticeIsNew = false;

  /**
   *  The current bestPractice
   *
   * @type                    {BestPractice}
   * @memberof                AddBestPracticeComponent
   */
  bestPractice: BestPractice;

  /**
   *  The current improvementGroup
   *
   * @type                    {ImprovementGroup}
   * @memberof                AddBestPracticeComponent
   */
  improvementGroup: ImprovementGroup;

  /**
   *  The form group storing the bestPractice
   *
   * @type                    {FormGroup}
   * @memberof                AddBestPracticeComponent
   */
  bestPracticeForm: UntypedFormGroup;

  /**
   *  The initial values of the form that will be compared to the current
   *
   * @memberof                AddBestPracticeComponent
   */
  initialFormValues;

  /**
   *  True/false if the score form is changed.
   *
   * @memberof                AddBestPracticeComponent
   */
  formChanged = false;

  /**
   *  True/false if the form is successfully submited
   *
   * @memberof                AddBestPracticeComponent
   */
  formSubmitted = false;

  /**
   *  The name of team that bestPractice comes from.
   *
   * @memberof                AddBestPracticeComponent
   */
  teamName = '';

  /**
   *  The member name of the bestPractice author
   *
   * @memberof                AddBestPracticeComponent
   */
  memberName = '';

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddBestPracticeComponent
   */
  validationMessages = {
    proposal: {
      required: 'Voer a.u.b. een best practice in.',
    },
    relatedThemes: {
      required: `Voer a.u.b. de thema's in.`,
    },
  };

  /**
   *  Creates an instance of AddBestPracticeComponent.
   *
   * @param         {MatDialogRef<AddBestPracticeComponent>}   dialogRef                The MatDialogRef instance
   * @param         {FormBuilder}                              fb                       the formBuilder instance
   * @param         {UserValidatorService}                     userValidatorService     service that handles the user validation logic
   * @param         {SharedService}                            sharedService            the instance of shared service.
   * @param         {ImprovementGroupService}                  improvementGroupService  the service that handles improvement group requests
   * @param         {ChangeDetectorRef}                        ref                      the instance of ChangeDetectorRef
   * @param         {*}                                        data                     The data passed through the dialog
   * @memberof      AddBestPracticeComponent
   */
  constructor(
    public dialogRef: MatDialogRef<AddBestPracticeComponent>,
    private fb: UntypedFormBuilder,
    private bestPracticesService: BestPracticesService,
    private userValidatorService: UserValidatorService,
    public sharedService: SharedService,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddBestPracticeComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.improvementGroup = this.data.improvementGroup;

    if (!this.data.bestPractice) {
      this.bestPracticeIsNew = true;
      this.emptyFormValues.improvementGroup = [this.improvementGroup];

      if (this.improvementGroup._embedded) {
        let proposalText = '';

        for (const item of this.improvementGroup._embedded.improvementItems) {
          proposalText += `${item.description}\n`;
        }

        this.emptyFormValues.proposal = [proposalText, Validators.required];
      }

      if (this.data.theme) {
        this.emptyFormValues.relatedThemes = [
          [this.data.theme],
          Validators.required,
        ];
      }

      this.initialFormValues = this.emptyFormValues;

      this.teamName = this.currentUserRights.currentTeamName;
      this.memberName = this.currentUserRights.currentMember.memberName;
    } else {
      this.bestPractice = this.data.bestPractice;

      this.teamName = this.bestPractice._embedded.team.name;
      this.memberName = this.bestPractice._embedded.member.memberName;

      this.initialFormValues = {
        id: [this.bestPractice.id],
        improvementGroup: [this.bestPractice.improvementGroup],
        proposal: [this.bestPractice.proposal, Validators.required],
        relatedThemes: [
          this.bestPractice._embedded.themes,
          Validators.required,
        ],
        status: [this.bestPractice.status],
      };
    }

    this.createForm(this.initialFormValues);
  }

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      AddBestPracticeComponent
   */
  compareBestPractices(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddBestPracticeComponent
   */
  createForm(data?: any): void {
    this.bestPracticeForm = this.fb.group(data);

    this.bestPracticeForm.valueChanges.subscribe((changes) =>
      this.onFormValueChanged(changes),
    );

    this.onFormValueChanged();
  }

  /**
   *  Respond to the close intent on the dialog.
   *
   * @memberof AddBestPracticeComponent
   */
  onDetailsClose() {
    this.dialogRef.close({ changed: this.dialogStateChanged });
  }

  /**
   *  Respond to the change of the form value.
   *
   * @param       {*}                       [data]
   * @returns
   * @memberof    AddBestPracticeComponent
   */
  onFormValueChanged(data?: any) {
    if (!this.bestPracticeForm) {
      return;
    }

    const form = this.bestPracticeForm;

    for (const field of Object.keys(this.formErrors)) {
      this.formErrors[field] = ''; //  Clear all of messages
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }

    this.formChanged = false;

    for (const field of Object.keys(this.bestPracticeForm.value)) {
      if (
        this.bestPracticeForm.value[field] !== this.initialFormValues[field][0]
      ) {
        this.formChanged = true;
        this.formSubmitted = false;

        break;
      }
    }

    this.ref.detectChanges();
  }

  /**
   *  Respond to the delete intent of the group.
   *
   * @returns
   * @memberof AddBestPracticeComponent
   */
  onCancel() {
    this.dialogStateChanged = false;
    this.dialogRef.close({ changed: this.dialogStateChanged });
  }

  /**
   *  Respond to the submit intent.
   *
   * @memberof AddBestPracticeComponent
   */
  onSubmit() {
    if (this.bestPracticeIsNew) {
      this.bestPracticesService
        .addBestPractice(
          this.bestPracticeForm.value,
          this.currentUserRights.currentTeamId,
        )
        .subscribe(
          (response) => {
            this.bestPractice = response;
            this.formSubmitted = true;
            this.bestPracticeIsNew = false;
            this.dialogStateChanged = true;

            this.ref.detectChanges();
          },

          (err) => {
            this.formSubmitted = false;
          },
        );
    } else {
      this.bestPracticesService.put(this.bestPracticeForm.value).subscribe(
        (response) => {
          this.bestPractice = response;
          this.formSubmitted = true;
          this.dialogStateChanged = true;

          this.ref.detectChanges();
        },

        (err) => {
          this.formSubmitted = false;
        },
      );
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          AddBestPracticeComponent
   */
  ngOnDestroy() {
    this.ref.detach();
  }
}
