/** @format */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SunburstGraphService } from '../../services/sunburst-graph.service';
import { CHART_TYPES, SingleGraphData } from '../../models/sunburst';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-chart-details',
  templateUrl: './graph-details.component.html',
  styleUrls: ['./graph-details.component.scss'],
})
export class GraphDetailsComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('container') container: ElementRef;
  chartId: CHART_TYPES;
  chartTypes = CHART_TYPES;
  graphData: SingleGraphData;
  activeTabIndex: number = 0;
  currentYear = new Date().getFullYear();

  #destroyRef = new Subject<void>();

  constructor(public sunburstGraphService: SunburstGraphService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.#destroyRef)).subscribe(params => {
      this.chartId = params.chart;

      this.sunburstGraphService.graphData$.pipe(takeUntil(this.#destroyRef)).subscribe(val => {
        if (val) {
          if (params.chart === CHART_TYPES.INNOVATION) {
            this.graphData = val.innovationGraph;
          } else if (params.chart === CHART_TYPES.QUALITY) {
            this.graphData = val.qualityGraph;
          }
          else if (params.chart === CHART_TYPES.COMPLIANCY) {
            this.graphData = val.compliancyGraphNextYear;
          }
          else if (params.chart === CHART_TYPES.BUSINESS_OPERATIONS) {
            this.graphData = val.businessOperationsGraph;
          }
        }
      })
    })
  }

  ngAfterViewInit(): void {
    this.sunburstGraphService.graphSize$.next(
      this.container.nativeElement.offsetHeight - 70,
    );
  }

  ngOnDestroy(): void {
    this.#destroyRef.next();
    this.#destroyRef.complete();
  }

  // ToDo uncomment when data is ready
  // onTabChange($event: any) {
  //   this.activeTabIndex = $event.index;

  //   if (this.activeTabIndex === 0) {
  //     this.graphData = this.sunburstGraphService.graphData$.getValue().compliancyGraphNextYear;
  //   } else if (this.activeTabIndex === 1) {
  //     this.graphData = this.sunburstGraphService.graphData$.getValue().compliancyGraph;
  //   }
  // }
}
