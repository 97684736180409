<!-- @format -->

<div class="container">
  <div
    *ngIf="isTooltipVisible"
    class="tooltip"
  >
    <div class="header">
      <h4 class="mat-subheading-1">{{ tooltipData.title }}</h4>
      <button
        mat-icon-button
        (click)="closeTooltip()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <p *ngIf="tooltipData.indicator">{{ tooltipData.indicator }}</p>
    <p *ngIf="!tooltipData.indicator">
      Een sunburst is een visuele weergave van hiërarchische gegevens. <br />
      <br />
      Het biedt een overzichtelijke en interactieve manier om complexe
      gegevensstructuren te begrijpen.<br />
      <br />
      Door te klikken op verschillende secties, kun je dieper in de gegevens
      duiken en specifieke details verkennen. <br />
      <br />
      Ideaal voor datavisualisatie en gegevensanalyse.
    </p>
    <div class="actions">
      <button
        mat-flat-button
        color="accent"
        *ngIf="showExternalLink && tooltipData.documentationUrl"
        (click)="navigateToExternalPage()"
      >
        Naar handboek
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="navigateToContactForm()"
      >
        Vraag coach
      </button>
      <!-- for now we turn off practice (improvement) creation in compliancy graph -->
      <button
        mat-flat-button
        color="primary"
        *ngIf="
          !tooltipData.improvementId &&
          currentGraphType !== CHART_TYPES.COMPLIANCY
        "
        (click)="navigateToCreatePractice()"
      >
        Maak verbetermaatregel aan
      </button>
      <button
        mat-flat-button
        color="accent"
        *ngIf="tooltipData.improvementId"
        (click)="navigateToPractice()"
      >
        Ga naar verbetermaatregel
      </button>
    </div>
  </div>
</div>
