/** @format */

import { inject, Injectable } from '@angular/core';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject, throwError as _throw } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { YearPlanning } from '../models/year-planning';
import { UserValidatorService } from './user-validator.service';

const apiPath = environment.apiPath;

/**
 *  Service for managing the agenda templates.
 *
 * @export
 * @class   YearPlanningService
 * @extends {AbstractRestService<YearPlanning>}
 */
@Injectable()
export class YearPlanningService extends AbstractRestService<YearPlanning> {
  currentlyChosenYear$ = new BehaviorSubject<number | null>(new Date().getFullYear() + 1);
  nextYearDataPresent$ = new BehaviorSubject<number | null>(null);
  createNewYearPlanTrigger$ = new Subject<void>();
  wipeOutSourceDataAfterDelete$ = new Subject<void>();

  /**
   * Creates an instance of YearPlanningService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      YearPlanningService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}yearPlanningItems`, 'yearPlanningItems');
  }

  #userValidatorService = inject(UserValidatorService)

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<AgendaTemplate>}
   * @memberof        AgendaTemplatesService
   */
  protected mapToModel(json: any): PagedResult<YearPlanning> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <YearPlanning[]>json._embedded.yearPlanningItems;
    }

    return new PagedResult<YearPlanning>(payload, <PageSetting>json.page);
  }

  /**
   * A generic DELETE method.
   * @param BaseModel aModel
   * @returns Observable<T>
   */
  delete(aModel: any): Observable<YearPlanning> {
    const deleteURL = `${this.actionUrl}/${aModel.id}?teamId=${aModel.teamId}`;
    return this.http
      .delete<YearPlanning>(deleteURL)
      .pipe(catchError((err) => _throw(err)), tap(() => {
        this.wipeOutSourceDataAfterDelete$.next();
      }));
  }

  resetCurrentlyChosenYear(): void {
    this.currentlyChosenYear$.next(new Date().getFullYear() + 1);
  }

  createNextYearPlan(): void {
    const teamId = this.#userValidatorService.currentUserRights.getValue().currentTeamId;
    const previousYear = new Date().getFullYear();
    const nextYear = new Date().getFullYear() + 1;

    this.http.post(`${apiPath}yearPlanningItems/copy?teamId=${teamId}&previousYear=${previousYear}&nextYear=${nextYear}`, {
      teamId: this.#userValidatorService.currentUserRights.getValue().currentTeamId,
      previousYear: new Date().getFullYear(),
      nextYear: new Date().getFullYear() + 1,
    }).subscribe({
      complete: () => {
        this.createNewYearPlanTrigger$.next();
      },
    });
  }

}
