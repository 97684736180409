<!-- @format -->

<h1
  class="mat-h1"
  i18n="best-practices page-title"
>
  Best practices
</h1>

<div class="main-content change-on-mobile">
  <app-best-practices-list></app-best-practices-list>
</div>
