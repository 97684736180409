/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { TeamGroupsListService } from '../../services/team-groups-list.service';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { TeamGroup } from '../../models/team-group';
import { SharedService } from '../../services/shared.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

/**
 *  Dialog for edit/add the team group
 *
 * @export
 * @class AddEditTeamGroupComponent
 * @extends {AddEditAbstractDialog<TeamGroup>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-team-group',
  templateUrl: './add-edit-team-group.component.html',
  styleUrls: ['./add-edit-team-group.component.scss'],
})
export class AddEditTeamGroupComponent
  extends AddEditAbstractDialog<TeamGroup>
  implements OnInit {
  /**
   *  The object of form validation errors.
   *
   * @memberof                TeamGroupsListComponent
   */
  formErrors = <any>{ name: '', admins: '' };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                TeamGroupsListComponent
   */
  validationMessages = <any>{
    name: {
      required: 'Voer a.u.b. een praktijkgroep in.',
    },
    admins: {
      required: 'Voer a.u.b. één of meer CO Coaches in.',
    }
  };

  listOfCOCoaches = [];
  selectedValue = [];

  /**
   * Creates an instance of AddEditTeamGroupComponent.
   *
   * @param      {MatDialogRef<AddEditUserComponent>}          dialogRefA              The instance of MatDialogRef
   * @param      {*}                                           data                    The data passed from the parent component
   * @param      {FormBuilder}                                 fbA
   * @param      {SharedService}                               sharedService           The instance of Shared Service
   * @param      {TeamGroupsListService}                       teamGroupsListService
   * @memberof AddEditTeamGroupComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditTeamGroupComponent>,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private teamGroupsListService: TeamGroupsListService,
    private fbA: UntypedFormBuilder,
    public http: HttpClient
  ) {
    super(dialogRefA, data, teamGroupsListService, fbA);
    this.getListOfCoCoaches();
  }

  getListOfCoCoaches(): void {
    const apiPath = environment.apiPath;

    this.http.get<Array<any>>(`${apiPath}/users/team-group-admins`).subscribe(res => {
      this.listOfCOCoaches = res;
      if (this.newFormGroup.value.admins) {
        this.newFormGroup.value.admins.map(coach => this.selectedValue.push({
          emailAddress: coach.emailAddress,
          firstName: coach.firstName,
          lastName: coach.lastName,
          active: coach.active,
          organisationAdmin: coach.organisationAdmin,
          id: coach.id
        }));
      }
    }
    );
  }

  // override function because abstract service does not support patch request
  createOrEditOverride(): void {
    const apiPath = environment.apiPath;

    if (this.formIsNew) {
      this.onSubmit();
    } else {
      this.http.patch(`${apiPath}team-groups/${this.newFormGroup.value.id}`, this.newFormGroup.value).subscribe(
        (response) => {
          this.formSubmitted = true;
          this.dialogStateChanged = true;
          this.formChanged = false;
          this.dialogResults.resp = response;

          this.onCancel(false, true);
        },
        () => {
          this.formSubmitted = false;
          this.dialogResults.resp = {};
        }
      );
    }
  }

  objectComparisonFunction(option, value): boolean {
    return option.id === value.id;
  }
}
