/** @format */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImprovementItem } from '../../models/improvement_item';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import {
  IMPROVEMENT_STATUS,
  ImprovementGroup,
} from '../../models/improvement-group';
import { ImprovementItemsService } from '../../services/improvement-items.service';
import { UserValidatorService } from '../../services/user-validator.service';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';

/**
 *  The improvement item component.
 *
 * @export
 * @class         ImprovementItemComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-improvement-item',
  templateUrl: './improvement-item.component.html',
  styleUrls: ['./improvement-item.component.scss'],
})
export class ImprovementItemComponent implements OnInit {
  /**
   *  The improvement item value sent by parent component.
   *
   * @type                    {ImprovementItem}
   * @memberof                ImprovementItemComponent
   */
  @Input() improvementItem: ImprovementItem;

  /**
   *  The improvement group id sent by parent component.
   *
   * @type                    {ImprovementGroup}
   * @memberof                ImprovementItemComponent
   */
  @Input() improvementGroup: ImprovementGroup;

  /**
   *  The index of improvement item.
   *
   * @type                    {number}
   * @memberof                ImprovementItemComponent
   */
  @Input() index: number;

  /**
   *  Bind the info to the parent when item is removed.
   *
   * @memberof ImprovementItemComponent
   */
  @Output() itemRemoved = new EventEmitter<{ removed: boolean }>();

  /**
   *  Bind the info to the parent when the itemChange
   *
   * @memberof ImprovementItemComponent
   */
  @Output() itemChanged = new EventEmitter<ImprovementItem>();

  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                ImprovementItemComponent
   */
  compareFn: ((f1: any, f2: any) => boolean) | null =
    this.compareMembersByValue;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                ImprovementItemComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The data passed to the dialog.
   *
   * @memberof                ImprovementItemComponent
   */
  delDialogParameters = <any>{
    panelClass: 'delete-dialog',
    minHeight: 'auto',
    maxWidth: '800px',
    disableClose: true,
    data: {
      message: `Weet je zeker de je dit verbeteritem wilt verwijderen?'

      `,
    },
  };

  /**
   *  The object of form validation errors.
   *
   * @memberof                ImprovementItemComponent
   */
  formErrors = {
    description: '',
    responsibleMember: '',
    periodEndDate: '',
    status: '',
  };

  /**
   *  The form group storing the improvementItem
   *
   * @type                    {FormGroup}
   * @memberof                ImprovementItemComponent
   */
  imprItemForm: UntypedFormGroup;

  /**
   *  True/ false if the item form is for the new item or exsisting
   *
   * @memberof                ImprovementItemComponent
   */
  itemIsNew = false;

  /**
   *  The default values of the new Form.
   *
   * @memberof                ImprovementItemComponent
   */
  emptyFormValues = {
    critical: false,
    description: ['', Validators.required],
    improvementGroupId: [''],
    periodBeginDate: [new Date()],
    periodEndDate: [new Date(), Validators.required],
    responsibleMember: [{}, Validators.required],
    status: [0],
    teamId: [''],
  };

  /**
   *  The values of exsisting form.
   *
   * @memberof                ImprovementItemComponent
   */
  initialFormValues;

  /**
   *  True/false if the score form is changed.
   *
   * @memberof                ImprovementItemComponent
   */
  formChanged = false;

  /**
   *  True/false if the form is successfully submited
   *
   * @memberof                ImprovementItemComponent
   */
  formSubmitted = false;

  /**
   *  The list of improvement statuses
   *
   * @memberof                ImprovementItemComponent
   */
  improvementStatuses = IMPROVEMENT_STATUS;

  /**
   *  Current date
   *
   * @memberof                ImprovementItemComponent
   */
  today = new Date();

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                ImprovementItemComponent
   */
  validationMessages = {
    description: {
      required: 'Voer a.u.b. een verbeteractie in.',
    },
    responsibleMember: {
      required: 'Voer a.u.b. een actiehouder in.',
    },
    periodEndDate: {
      required: 'Voer a.u.b. een deadline in.',
      matDatepickerMin: 'Datum mag niet in het verleden liggen',
      matDatepickerParse: 'Het type moet een datum zijn',
    },
  };

  /**
   * Creates an instance of ImprovementItemComponent.
   *
   * @param      {FormBuilder}             fb
   * @param      {SharedService}           sharedService             The instance of shared service
   * @param      {ImprovementItemsService} improvementItemsService
   * @memberof   ImprovementItemComponent
   */
  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    public sharedService: SharedService,
    private userValidationService: UserValidatorService,
    private improvementItemsService: ImprovementItemsService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof  ImprovementItemComponent
   */
  ngOnInit() {
    if (!this.improvementItem) {
      this.itemIsNew = true;
      this.emptyFormValues.improvementGroupId = [this.improvementGroup.id];
      this.emptyFormValues.teamId = [this.improvementGroup.teamId];

      //  Assign
      const userName = this.userValidationService.currentUser
        .getValue()
        .emailAddress.split('@')[0];
      const membersList = this.sharedService.currentTeamMembersList.getValue();
      const currentMember = membersList.filter(
        (member) => member.memberName === userName,
      );

      if (currentMember[0]) {
        this.emptyFormValues.responsibleMember = [
          currentMember[0],
          Validators.required,
        ];
      }

      this.initialFormValues = this.emptyFormValues;
    } else {
      this.initialFormValues = {
        critical: this.improvementItem.critical,
        id: [this.improvementItem.id],
        description: [this.improvementItem.description, Validators.required],
        improvementGroupId: [this.improvementItem.improvementGroupId],
        periodBeginDate: [this.improvementItem.periodBeginDate],
        periodEndDate: [
          this.improvementItem.periodEndDate,
          Validators.required,
        ],
        responsibleMember: [
          this.improvementItem._embedded.member,
          Validators.required,
        ],
        teamId: [this.improvementItem.teamId],
        status: [this.improvementItem.status],
      };
    }
    this.createForm(this.initialFormValues);
  }

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      mprGroupDetailsComponent
   */
  compareMembersByValue(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof ImprGroupDetailsComponent
   */
  createForm(data?: any): void {
    this.imprItemForm = this.fb.group(data);

    this.imprItemForm.valueChanges.subscribe((changes) => {
      this.onFormValueChanged(changes);
    });

    this.onFormValueChanged();
  }

  /**
   * Cancel updating the score.
   *
   * @memberof ScoreItemsListItemComponent
   */
  onCancel() {
    this.createForm(this.initialFormValues);

    this.formSubmitted = false;
    this.formChanged = false;
  }

  /**
   *  Respond to the delete intent of the imrpovement item.
   *
   * @memberof ImprovementItemComponent
   */
  onDeleteIntent() {
    const currentTeamId =
      this.userValidationService.currentUserRights.getValue().currentTeamId;
    this.delDialogParameters.data.name = this.improvementGroup.scoreItemName;

    const deleteDialogRef = this.dialog.open(
      this.deleteDialog,
      this.delDialogParameters,
    );
    deleteDialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }

      this.improvementItemsService
        .deleteItem(this.imprItemForm.value, currentTeamId)
        .subscribe((resp) => {
          this.itemRemoved.emit({ removed: true });
          this.itemChanged.emit(this.improvementItem);
        });
    });
  }

  /**
   *  Respond to the change of the form value.
   *
   * @param {*} [data]
   * @returns
   * @memberof ImprovementItemComponent
   */
  onFormValueChanged(data?: any) {
    if (!this.imprItemForm) {
      return;
    }

    const form = this.imprItemForm;

    for (const field of Object.keys(this.formErrors)) {
      this.formErrors[field] = ''; //  Clear all of messages
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }

    this.formChanged = false;

    for (const field of Object.keys(this.formErrors)) {
      if (
        this.initialFormValues[field] &&
        this.imprItemForm.value[field] !== this.initialFormValues[field][0]
      ) {
        this.formChanged = true;
        this.formSubmitted = false;

        break;
      }
    }
  }

  /**
   *  Respond to the save intent of the improvement Item form.
   *
   * @memberof ImprovementItemComponent
   */
  onFormSave() {
    if (this.imprItemForm.get('periodEndDate').value) {
      const periodEndDate = moment(
        this.imprItemForm.get('periodEndDate').value,
      ).format('YYYY-MM-DD');
      this.imprItemForm.patchValue({ periodEndDate: periodEndDate });
    }

    this.imprItemForm.patchValue({
      periodBeginDate: this.imprItemForm.get('periodEndDate').value,
    });

    if (this.itemIsNew) {
      this.improvementItemsService.post(this.imprItemForm.value).subscribe(
        (newitem) => {
          this.improvementItem = newitem;
          this.formSubmitted = false;
          this.formChanged = false;
          this.itemChanged.emit(this.improvementItem);

          this.createForm(this.initialFormValues);
        },

        (err) => {},
      );
    } else {
      this.improvementItemsService.put(this.imprItemForm.value).subscribe(
        (updateditem) => {
          this.improvementItem = updateditem;
          this.formSubmitted = true;
          this.formChanged = false;
          this.itemChanged.emit(this.improvementItem);
        },

        (err) => {},
      );
    }
  }
}
