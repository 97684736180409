<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-yearplan-item-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuw jaarplan item toevoegen
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-yearplan-item-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen jaarplan sessie
</h2>

<mat-dialog-content>
  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 0"
      (opened)="setStep(0)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-yearplan-item-dialog expansion-panel-title"
        >
          Onderwerp
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="topic">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Onderwerp</mat-label>
            <input
              type="text"
              matInput
              formControlName="topic"
              required
            />
            <mat-error [hidden]="!formErrors.topic">{{
              formErrors.topic
            }}</mat-error>
          </mat-form-field>
        </form>
      </div>

      <div class="description">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Omschrijving</mat-label>
            <input
              type="text"
              matInput
              formControlName="description"
            />
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 1"
      (opened)="setStep(1)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-yearplan-item-dialog expansion-panel-title"
        >
          Details
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div
        class="category"
        *ngIf="categoryNameList"
      >
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Categorie</mat-label>
            <mat-select
              formControlName="categoryId"
              required
              [disabled]="!formIsNew"
            >
              <mat-option
                *ngFor="let category of categoryNameList"
                [value]="category.id"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error [hidden]="!formErrors.categoryId">{{
              formErrors.categoryId
            }}</mat-error>
          </mat-form-field>
        </form>
      </div>

      <div class="description">
        <form [formGroup]="newFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Notificatie</mat-label>
            <textarea
              matInput
              formControlName="notificationText"
              matTextareaAutosize
              matAutosizeMinRows="1"
              resizeToFitContent
            ></textarea>
          </mat-form-field>
        </form>
      </div>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 2"
      (opened)="setStep(2)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-yearplan-item-dialog expansion-panel-title"
        >
          Uitvoerders
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div class="executioners">
          <div class="children-expansion-container">
            <mat-accordion
              displayMode="flat"
              hideToggle
            >
              <ng-container *ngIf="exeList.length">
                <mat-expansion-panel
                  class="mat-elevation-z0 insertable-item"
                  disabled
                  #expPanel
                  *ngFor="let executioner of exeList; let ind = index"
                  [ngClass]="{
                    'is-new': executioner.temporaryFields?.isNew,
                    'removed': executioner.temporaryFields?.removed,
                    'has-error': executioner.temporaryFields?.invalid
                  }"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon *ngIf="executioner.temporaryFields?.invalid"
                        >error</mat-icon
                      >
                      <span>{{
                        executioner.memberName
                          ? executioner.memberName
                          : 'Nieuwe uitvoerder ' + (ind + 1)
                      }}</span>
                      <div class="cud-icons">
                        <button
                          mat-icon-button
                          color="accent"
                          *ngIf="
                            (data.canUpdate || formIsNew) &&
                            executioner.temporaryFields?.removed
                          "
                          (click)="
                            onChildItemRestore(executioner, 0, $event, ind)
                          "
                        >
                          <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <button
                          mat-icon-button
                          color="warn"
                          *ngIf="
                            (data.canUpdate || formIsNew) &&
                            (!executioner.temporaryFields?.removed ||
                              executioner.temporaryFields?.isNew)
                          "
                          (click)="
                            onExecutionerDelete(
                              executioner,
                              0,
                              $event,
                              expPanel,
                              ind
                            )
                          "
                        >
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>

          <form
            class="flex-rows"
            *ngIf="formIsNew || data.canUpdate"
          >
            <mat-form-field
              appearance="fill"
              class="select-member"
            >
              <mat-label>Uitvoerder</mat-label>
              <input
                type="text"
                aria-label="Uitvoerder"
                matInput
                [matAutocomplete]="auto"
                [formControl]="filterControl"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let member of filteredMembers | async"
                  [value]="member"
                  (click)="onOptionSelect(member)"
                >
                  {{ member.memberName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <button
              mat-flat-button
              color="accent"
              class="no-grow"
              [disabled]="!this.selectedMember"
              (click)="onChildItemAdd($event, 0)"
            >
              <mat-icon>person_add</mat-icon>
              Toevoegen
            </button>
          </form>
        </div>
      </ng-template>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
        <button
          mat-flat-button
          color="accent"
          class="action-advance"
          (click)="nextStep()"
          i18n="next button @@buttonNext"
        >
          Volgende
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="step === 3"
      (opened)="setStep(3)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-yearplan-item-dialog expansion-panel-title"
        >
          Planning
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div class="subjects">
          <div class="children-expansion-container">
            <mat-accordion
              displayMode="flat"
              multi
            >
              <ng-container *ngIf="dateList.length">
                <mat-expansion-panel
                  class="mat-elevation-z0 insertable-item"
                  #expPaneldate
                  *ngFor="let subject of dateList; let ind = index"
                  [ngClass]="{
                    'is-new': subject.temporaryFields?.isNew,
                    'removed': subject.temporaryFields?.removed,
                    'has-error': subject.temporaryFields?.invalid
                  }"
                  [disabled]="
                    (!formIsNew && !data.canUpdate) ||
                    subject.temporaryFields?.removed
                  "
                  [expanded]="subject.temporaryFields?.expanded"
                  (opened)="subject.expanded = true"
                  (closed)="subject.expanded = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon *ngIf="subject.temporaryFields?.invalid"
                        >error</mat-icon
                      >
                      <span>{{
                        subject.plannedDate
                          ? (subject.plannedDate | date: 'dd-MM-yyyy')
                          : 'Datum ' + (ind + 1)
                      }}</span>
                      <div class="cud-icons">
                        <button
                          mat-icon-button
                          color="accent"
                          *ngIf="
                            (formIsNew || data.canUpdate) &&
                            subject.temporaryFields?.removed
                          "
                          (click)="onChildItemRestore(subject, 1, $event, ind)"
                        >
                          <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <button
                          mat-icon-button
                          color="warn"
                          *ngIf="
                            (formIsNew || data.canUpdate) &&
                            (!subject.temporaryFields?.removed ||
                              subject.temporaryFields?.isNew)
                          "
                          (click)="
                            onExecutionerDelete(
                              subject,
                              1,
                              $event,
                              expPaneldate,
                              ind
                            )
                          "
                        >
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-template matExpansionPanelContent>
                    <app-planned-session
                      [editedItem]="subject"
                      [year]="newFormGroup.get('year')?.value"
                      (itemChanged)="
                        onChildItemChange($event, 1, ind, expPaneldate)
                      "
                    >
                    </app-planned-session>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>

          <form
            class="flex-rows"
            [formGroup]="newFormGroup"
          >
            <mat-form-field appearance="fill">
              <mat-label>Jaar</mat-label>
              <input
                type="number"
                matInput
                min="1980"
                max="2040"
                formControlName="year"
              />
            </mat-form-field>

            <button
              mat-flat-button
              color="accent"
              class="no-grow"
              (click)="onChildItemAdd($event, 1)"
              *ngIf="formIsNew || data.canUpdate"
            >
              <mat-icon>calendar_add_on</mat-icon>
              Datum toevoegen
            </button>
          </form>
        </div>
      </ng-template>

      <mat-action-row>
        <button
          mat-flat-button
          color="primary"
          class="action-retreat"
          (click)="prevStep()"
          i18n="previous button @@buttonPrevious"
        >
          Vorige
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel(true)"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="
      !(formChanged || childChanged) || newFormGroup.invalid || childInvalid
    "
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
