/** @format */

import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { Attachment } from '../../models/attachment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FilesLibraryService } from '../../services/files-library.service';
import { UntypedFormBuilder } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { DocumentsService } from '../../services/documents.service';
import { UserValidatorService } from '../../services/user-validator.service';

/**
 * The dialog for managing the files
 *
 * @export
 * @class AddEditFileComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-file',
  templateUrl: './add-edit-file.component.html',
  styleUrls: ['./add-edit-file.component.scss'],
})
export class AddEditFileComponent
  extends AddEditAbstractDialog<Attachment>
  implements OnInit
{
  /**
   *  The download link DOM element
   *
   * @private
   * @type                  {ElementRef}
   * @memberof              FilesListComponent
   */
  @ViewChild('downloadLinkForm') private downloadLinkForm: ElementRef;

  /**
   *  The name of the exsisting file
   *
   * @memberof AddEditFileComponent
   */
  exsistingFileName = '';

  /**
   *  The fileError message
   *
   * @memberof AddEditFileComponent
   */
  fileError = '';

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditFileComponent
   */
  formErrors = {
    title: '',

    description: '',
    category: '',

    allowedMemberIds: '',
  };

  /**
   *  True/false if the current user is the owner of the file
   *
   * @type {boolean}
   * @memberof AddEditFileComponent
   */
  fileOwner: boolean;

  /**
   *  The selected file connected with the attachment
   *
   * @type {File}
   * @memberof AddEditFileComponent
   */
  selectedFile: File;

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditFileComponent
   */
  validationMessages = {
    title: {
      required: 'Titel is een verplicht veld.',
    },
    file: {
      required: 'Bestand is een verplicht veld.',
    },
    allowedMemberIds: {
      required: 'Zichtbaar voor is een verplicht veld.',
    },
  };

  /**
   * Creates an instance of AddEditFileComponent.
   *
   * @param {MatDialogRef<AddEditFileComponent>} dialogRefA
   * @param {*}                                  data
   * @param {FilesLibraryService}                filesLibraryService
   * @param {ChangeDetectorRef}                  ref
   * @param {FormBuilder}                        fbA
   * @memberof AddEditFileComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filesLibraryService: FilesLibraryService,
    private ref: ChangeDetectorRef,
    private fbA: UntypedFormBuilder,
    public sharedService: SharedService,
    private documentsService: DocumentsService,
    private userValidatorService: UserValidatorService,
  ) {
    super(dialogRefA, data, filesLibraryService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditFileComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.data.allowHidden && this.formIsNew) {
      this.initialFormValues.isHidden = true;
    }

    this.createForm(this.initialFormValues);

    if (this.formIsNew) {
      this.onFileChange();
      this.fileOwner = true;
    } else {
      if (
        this.newFormGroup &&
        this.newFormGroup.get('docName') &&
        this.newFormGroup.get('docName').value
      ) {
        this.exsistingFileName += this.newFormGroup.get('docName').value;
      }

      if (this.data.fileOwner) {
        this.fileOwner = true;
      } else {
        this.fileOwner = false;
        this.newFormGroup.disable();
      }
    }
  }

  /**
   *  Respond to the selection of the file to upload
   *
   * @memberof AddEditFileComponent
   */
  onFileChange(file?: File) {
    this.fileError = '';
    let fileName = '';
    let fileSize = '';
    let fileType = '';

    if (file && file.name) {
      this.selectedFile = file;
      fileName = file.name;

      fileSize = file.size ? file.size.toString() : '';
      fileType = file.type ? file.type : '';
    } else {
      this.fileError = this.validationMessages.file.required;
    }

    this.newFormGroup.patchValue({
      docName: fileName,
      fileSize: fileSize,
      fileType: fileType,
    });
  }

  /**
   *  Respond to the download file intent
   *
   * @param {*} [file]
   * @param {*} [event]
   * @memberof AddEditFileComponent
   */
  async onFileDownload(
    attachment?: Attachment,
    event?: any,
    fetchAndDownload?: boolean,
  ) {
    if (event) {
      event.stopPropagation();
    }

    let file = null;

    if (attachment && attachment.docId) {
      file = await this.documentsService.getSingleFile(
        '/' + attachment.docId,
        '',
      );

      if (fetchAndDownload) {
        const url = window.URL.createObjectURL(file);
        const link = this.downloadLinkForm.nativeElement;
        link.href = url;

        link.download = 'file_KMS';

        if (attachment.docName) {
          link.download = attachment.docName;
        }

        link.click();
        window.URL.revokeObjectURL(url);
      }
    }

    return file;
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @memberof    AddEditFileComponent
   */
  onSubmit() {
    if (this.formIsNew && this.selectedFile) {
      const fileFormData: FormData = new FormData();

      fileFormData.append(
        'fileToUpload',
        this.selectedFile,
        this.selectedFile.name,
      );

      this.documentsService.postFile(fileFormData).subscribe((data) => {
        this.newFormGroup.patchValue({
          docId: data.documentId,
        });

        this.filesLibraryService.post(this.newFormGroup.value).subscribe(
          (response) => {
            this.formSubmitted = true;
            this.dialogStateChanged = true;
            this.formIsNew = false;
            this.childInvalid = false;
            this.formChanged = false;
            this.dialogResults.resp = response;

            this.onCancel(false, true);
          },

          () => {
            this.formSubmitted = false;
            this.dialogResults.resp = {};
          },
        );
      });
    } else if (!this.formIsNew) {
      this.filesLibraryService.put(this.newFormGroup.value).subscribe(
        (response) => {
          this.formSubmitted = true;
          this.dialogStateChanged = true;
          this.formChanged = false;
          this.dialogResults.resp = response;

          this.onCancel(false, true);
        },

        () => {
          this.formSubmitted = false;
          this.dialogResults.resp = {};
        },
      );
    }
  }

  /**
   *  Toggle the slide hidden/show in the library
   *
   * @param {MatSlideToggleChange} event
   * @memberof AddEditFileComponent
   */
  onToggleChange(event: MatSlideToggleChange) {
    if (this.newFormGroup.get('isHidden') && event) {
      this.newFormGroup.get('isHidden').patchValue(!event.checked);
    }

    if (!event.checked) {
      this.newFormGroup.patchValue({
        category: '',
      });
    }
  }
}
