/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Main container for managing the agenda meetings
 *
 * @export
 * @class AgendaMeetingsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-agenda-meetings',
  templateUrl: './agenda-meetings.component.html',
  styleUrls: ['./agenda-meetings.component.scss'],
})
export class AgendaMeetingsComponent implements OnInit {
  /**
   * Creates an instance of AgendaMeetingsComponent.
   *
   * @memberof AgendaMeetingsComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AgendaMeetingsComponent
   */
  ngOnInit() {}
}
