<!-- @format -->

<div
  class="best-practice-header status-{{ improvementGroup.status }}"
  *ngIf="bestPracticeForm"
>
  <mat-icon
    class="best-practice"
    aria-label="Best practice"
    >stars</mat-icon
  >
  <div
    class="group-header"
    i18n="add-edit-best-practice-dialog dialog-title"
  >
    Best practice - {{ improvementGroup.scoreItemName }}
  </div>
  <mat-icon
    class="close"
    (click)="onDetailsClose()"
    >clear</mat-icon
  >
</div>

<div class="best-practice-content">
  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-best-practice-dialog expansion-panel-title"
        >
          Auteur
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="author-info flex-rows">
        <span>{{ memberName }}</span>

        <span>{{ teamName }}</span>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-best-practice-dialog expansion-panel-title"
        >
          Thema's
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="bestPracticeForm">
        <mat-form-field appearance="fill">
          <mat-label>Thema's</mat-label>
          <mat-select
            multiple
            formControlName="relatedThemes"
            [compareWith]="compareFn"
            required
          >
            <mat-option
              *ngFor="let theme of sharedService.currentThemesList$ | async"
              [value]="theme"
              >{{ theme.name }}</mat-option
            >
          </mat-select>
          <mat-error [hidden]="!formErrors.relatedThemes">{{
            formErrors.relatedThemes
          }}</mat-error>
        </mat-form-field>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-best-practice-dialog expansion-panel-title"
        >
          Best Practice
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="bestPracticeForm">
        <mat-form-field appearance="fill">
          <mat-label>Best practice</mat-label>
          <textarea
            matInput
            formControlName="proposal"
            required
            matTextareaAutosize
            matAutosizeMinRows="1"
            resizeToFitContent
          ></textarea>
          <mat-error [hidden]="!formErrors.proposal">{{
            formErrors.proposal
          }}</mat-error>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="impr-group-buttons flex-rows">
  <button
    mat-flat-button
    class="impr-group-button"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    *ngIf="!formSubmitted"
    class="impr-group-button"
    (click)="onSubmit()"
    [disabled]="!formChanged || bestPracticeForm.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    *ngIf="formSubmitted"
    mat-flat-button
    class="impr-group-button submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
</div>
