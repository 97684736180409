/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { PMR } from '../../models/pmr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PmrService } from '../../services/pmr.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

/**
 *  The dialog for add/edit PMR
 *
 * @export
 * @class AddEditPmrComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-pmr',
  templateUrl: './add-edit-pmr.component.html',
  styleUrls: ['./add-edit-pmr.component.scss'],
})
export class AddEditPmrComponent
  extends AddEditAbstractDialog<PMR>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditPmrComponent
   */
  formErrors = {
    beginTime: '',
    coEnabled: '',
    endTime: '',
    note: '',
    title: '',
    notValidDates: '',
  };

  /**
   *  Current date
   *
   * @memberof                AddEditPmrComponent
   */
  today = moment().format('YYYY-MM-DD');

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditPmrComponent
   */
  validationMessages = {
    title: {
      required: 'Titel is een verplicht veld.',
    },
    beginTime: {
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    endTime: {
      matDatepickerParse: 'Het type moet een datum zijn',
    },
    notValidDates: 'Begindatum kan niet later zijn dan de einddatum',
  };

  /**
   * Creates an instance of AddEditPmrComponent.
   *
   * @param    {MatDialogRef<AddEditPmrComponent>} dialogRefA
   * @param    {*}                                 data
   * @param    {PmrService}                        pmrService
   * @param    {FormBuilder}                       fbA
   * @memberof AddEditPmrComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditPmrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pmrService: PmrService,
    private fbA: UntypedFormBuilder,
  ) {
    super(dialogRefA, data, pmrService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditPmrComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.createForm(this.initialFormValues);
  }

  /**
   *  Validate if the endDate is earlier than startDate
   *
   * @param     {FormGroup}                     group
   * @returns
   * @memberof  AddEditPmrComponent
   */
  checkDates(group: UntypedFormGroup) {
    if (group.get('endTime') && group.get('beginTime')) {
      if (group.get('beginTime').value instanceof moment) {
        group
          .get('beginTime')
          .setValue(group.get('beginTime').value.format('YYYY-MM-DD'));
      }

      if (group.get('endTime').value instanceof moment) {
        group
          .get('endTime')
          .setValue(group.get('endTime').value.format('YYYY-MM-DD'));
      }

      if (group.get('endTime').value < group.get('beginTime').value) {
        return { notValidDates: true };
      }
    }
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddEditPmrComponent
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fbA.group(data, { validator: this.checkDates });

    this.newFormGroup.valueChanges.subscribe((changes) => {
      if (changes.endTime instanceof moment) {
        changes.endTime = changes.endTime.format('YYYY-MM-DD');
      }

      if (changes.beginTime instanceof moment) {
        changes.beginTime = changes.beginTime.format('YYYY-MM-DD');
      }

      this.onFormValueChanged(changes);
    });

    this.onFormValueChanged();
  }
}
