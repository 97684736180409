<!-- @format -->

<button
  mat-flat-button
  class="add-group-button"
  (click)="onAddGroup()"
  i18n="improvement-plan-tab button add-new-plan-button"
>
  Voeg nieuw plan toe
</button>

<mat-accordion
  class="flex-improvement-statuses mobile-match"
  displayMode="flat"
  multi
  *ngIf="improvementGroupsList"
  fxLayout="row"
  fxLayoutGap="20px"
  fxLayout.cmb="column"
  fxLayoutAlign="start none"
>
  <mat-expansion-panel
    class="improvement-status-container mat-elevation-z0"
    fxFlex="100/statuses.length%"
    fxFlex.cmb="100%"
    *ngFor="let status of statuses; let statusInd = index"
    [expanded]="!mobileQuery.matches"
    [hideToggle]="!mobileQuery.matches"
    [disabled]="!mobileQuery.matches"
  >
    <mat-expansion-panel-header
      class="improvement-status-header"
      [collapsedHeight]="mobileQuery.matches ? '40px' : '50px'"
      [expandedHeight]="mobileQuery.matches ? '40px' : '50px'"
    >
      {{ status.toUpperCase() }}
    </mat-expansion-panel-header>
    <div
      class="improvement-status-content"
      *ngIf="improvementGroupsList"
    >
      <ng-container *ngFor="let group of improvementGroupsList">
        <app-improvement-group
          *ngIf="group.status === statusInd"
          [improvementGroup]="group"
          [theme]="theme"
          [subThemesList]="subThemesList"
          (groupChanged)="getImprovementGroups()"
        ></app-improvement-group>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
