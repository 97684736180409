/** @format */

import { Component, OnInit, Inject, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { TeamGroup } from '../../models/team-group';
import { TeamsListService } from '../../services/teams-list.service';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { Team } from '../../models/team';
import { PagedResult, PageSetting } from '@iq/ng-core';
import { TeamGroupsListService } from '../../services/team-groups-list.service';
import { map } from 'rxjs/operators';

/**
 *  Dialog for edit/add the team
 *
 * @export
 * @class   AddEditTeamComponent
 * @extends {AddEditAbstractDialog<Team>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-team',
  templateUrl: './add-edit-team.component.html',
  styleUrls: ['./add-edit-team.component.scss'],
})
export class AddEditTeamComponent
  extends AddEditAbstractDialog<Team>
  implements OnInit {
  /**
   *  The object of form validation errors.
   *
   * @memberof                TeamsListComponent
   */
  formErrors = <any>{ teamGroupId: '', name: '' };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                TeamsListComponent
   */
  validationMessages = <any>{
    name: {
      required: 'Voer a.u.b. een teamnaam in.',
    },
    teamGroupId: {
      required: 'Voer a.u.b. een team groep in.',
    },
  };

  currentTeamGroups: TeamGroup[] = [];

  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                TeamsListComponent
   */
  compareFn: ((f1: any, f2: any) => boolean) | null =
    this.compareTeamGroupsByValue;

  /**
   * Creates an instance of AddEditTeamComponent.
   *
   * @param      {MatDialogRef<AddEditUserComponent>}          dialogRefA              The instance of MatDialogRef
   * @param      {*}                                           data                    The data passed from the parent component
   * @param      {TeamsListService}                            teamsListService
   * @param      {FormBuilder}                                 fbA
   * @param      {SharedService}                               sharedService           The instance of Shared Service
   * @memberof AddEditTeamComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private teamsListService: TeamsListService,
    private fbA: UntypedFormBuilder,
    public sharedService: SharedService,
  ) {
    super(dialogRefA, data, teamsListService, fbA);
    this.fetchPracticeGroups();
  }

  #teamGroupsListService = inject(TeamGroupsListService);

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      AddEditTeamComponent
   */
  compareTeamGroupsByValue(f1: any, f2: any) {
    return f1 && f2 && f1 === f2;
  }

  fetchPracticeGroups() {
    //  Get all rows in the list
    this.#teamGroupsListService
      .getAll(
        new PageSetting(1000, 0, 0, 0)
      )
      .pipe(map((resp: PagedResult<TeamGroup>) => resp))
      .subscribe((result) => {
        this.currentTeamGroups = result.payload;
      });
  }

  /**
   *  Respond to the select intent in the TeamGroup select list.
   *
   * @param       {TeamGroup}         group Currently selected teamGroup
   * @memberof    AddEditTeamComponent
   */
  onTeamGroupSelect(group: TeamGroup) {
    this.newFormGroup.patchValue({ group: group.name });
  }
}
