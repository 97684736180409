/** @format */

import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import {
  MarviqMatTableInlineCrudComponent,
  PagedResult,
  PageSetting,
} from '@iq/ng-core';
import {
  Improvement,
  IMPROVEMENT_RESPONSE_STATUS,
  // IMPROVEMENT_STATUS,
  NEW_ORDERED_IMPROVEMENT_STATUSES,
} from '../../models/improvement';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import * as moment from 'moment';
import { map, filter } from 'rxjs/operators';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { ImprovementsService } from '../../services/improvements.service';
import { MatDialog } from '@angular/material/dialog';
import { UserRights } from '../../models/user-rights';
import { AddEditImprovementComponent } from '../../dialogs/add-edit-improvement/add-edit-improvement.component';
import { Theme } from '../../models/theme';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { CHART_TYPES } from '../../models/sunburst';

/**
 *  Show the list of improvements
 *
 * @export
 * @class ImprovementsListComponent
 * @extends {MarviqMatTableInlineCrudComponent<Improvement>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-improvements-list',
  templateUrl: './improvements-list.component.html',
  styleUrls: ['./improvements-list.component.scss'],
})
export class ImprovementsListComponent
  extends MarviqMatTableInlineCrudComponent<Improvement>
  implements OnInit, OnDestroy {
  /**
   *  The default values of the new Form.
   *
   * @memberof                ImprovementsListComponent
   */
  emptyFormValues = {
    subject: ['', Validators.required],
    description: [''],

    theme: [null],
    subTheme: [null],

    rootCause: [''],
    measurement: [''],

    guard: [null],
    guardComment: [''],
    executionerMembers: [[]],

    registeredOn: [moment(), Validators.required],
    implementedOn: [moment(), Validators.required],
    plannedOn: [moment(), Validators.required],

    efficient: [null],
    conclusion: [''],

    status: [0],
    coRemark: [''],
    checkbox: [false],
    responseStatus: [0],

    newLinkedAttachmentIds: [[]],
    newUnLinkedAttachmentIds: [[]],

    teamId: '',

    sunburstId: ''
  };

  /**
   *  The dialog component assigned to the error.
   *
   * @memberof                ImprovementsListComponent
   */
  errorDialog = ErrorDialogComponent;

  /**
   *  Error dialog paramters
   *
   * @memberof                  ImprovementsListComponent
   */
  errorDialogParameters = <any>{
    panelClass: 'delete-dialog',
    maxWidth: '800px',
    disableClose: true,
    data: {},
  };

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              ImprovementsListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                ImprovementsListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   * The form group storing the filters
   *
   * @type                      {FormGroup}
   * @memberof                  ImprovementsListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The object of form validation errors.
   *
   * @memberof ImprovementsListComponent
   */
  filterFormErrors = {
    beginDate: '',
    endDate: '',
    status: '',
    responseStatus: '',
    guardId: '',
    executionerId: '',
    effective: '',
    description: '',
    subject: '',
    themeId: '',
    subThemeId: '',
    notValidDates: '',
  };

  /**
   * The form validation messages
   *
   * @memberof ImprovementsListComponent
   */
  filterValidationMessages = {
    beginDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    endDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    notValidDates: 'Begindatum kan niet later zijn dan de einddatum',
  };

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                ImprovementsListComponent
   */
  columnsToDisplay = [
    'subject',
    'guard',
    'executioners',
    'implementedOn',
    'plannedOn',
    'evaluationDone',
    'responseStatus',
    'status',
    'CUD',
  ];

  reviewPagecolumnsToDisplay = [
    'practice',
    'subject', // title
    'description',
    'status',
    'effective',
    'CUD',
  ];

  isReviewPage: boolean;

  /**
   *  The list of incident response statuses
   *
   * @memberof                AddEditIncidentComponent
   */
  improvementResponseStatuses = IMPROVEMENT_RESPONSE_STATUS;

  /**
   *  The list of incident response statuses
   *
   * @memberof                AddEditIncidentComponent
   */
  improvementStatuses = NEW_ORDERED_IMPROVEMENT_STATUSES;

  /**
   *  The initial values of the filters
   *
   * @memberof ImprovementsListComponent
   */
  initialFilterValues = {
    beginDate: '',
    endDate: '',
    status: '',
    responseStatus: '',
    guardId: '',
    executionerId: '',
    effective: '',
    description: '',
    subject: '',
    themeId: '',
    subThemeId: '',
  };

  /**
   *  The list of themes identified by ids
   *
   * @type {*}
   * @memberof ImprovementsListComponent
   */
  themesListWithIds: any;

  /**
   *  The object of queryParams passed through the route
   *
   * @type {{}}
   * @memberof ImprovementsListComponent
   */
  routeQueryParams: {};

  /**
   *  The subscription of the router
   *
   * @type {Subscription}
   * @memberof ImprovementsListComponent
   */
  routerSubs: Subscription;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                ImprovementsListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * The users permissions
   *
   * @memberof ImprovementsListComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                AddEditIncidentComponent
   */
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareValues;

  /**
   *  The Theme list of incident
   *
   * @type                    {Theme[]}
   * @memberof                AddEditIncidentComponent
   */
  currentThemesList: Theme[];

  /**
   * Creates an instance of ImprovementsListComponent.
   *
   * @param    {ImprovementsService} improvementsService
   * @param    {FormBuilder}         formBuilder
   * @param    {MatDialog}           dg
   * @memberof ImprovementsListComponent
   */
  constructor(
    private improvementsService: ImprovementsService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    public sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(improvementsService, formBuilder, dg);
  }

  #location = inject(Location);

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprovementsListComponent
   */
  ngOnInit() {
    this.isReviewPage = this.router.url.includes('improvements-review');

    this.currentThemesList = this.sharedService.currentThemesList.getValue();
    this.sharedService.currentThemesWithIds.subscribe((themes) => {
      this.themesListWithIds = themes;
    });

    this.queryParams.splice(0, this.queryParams.length);

    this.currentUserRights = JSON.parse(localStorage.getItem('userRights'));
    this.queryParams.push({
      name: 'teamId',
      value: this.currentUserRights.currentTeamId,
    });
    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;
    }

    this.dialogParameters.data.message = `Weet je zeker dat je deze verbetermaatregel wilt annuleren?`;
    this.errorDialogParameters.data.message = `Deze verbetermaatregel kan niet geannuleerd worden omdat deze gekoppeld is aan een melding.`;

    this.checkPermissions();

    if (this.crudR) {
      this.checkRouteParams();
    }

    //  Subscribe the changes of the router
    this.routerSubs = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.filterForm) {
          this.onFiltersClear();
        }
      });

    if ((this.#location.getState() as any).sunburstCellData) {
      this.onEditIntent(
        undefined,
        null,
        (this.#location.getState() as any).sunburstCellData.id,
        (this.#location.getState() as any).graphType,
      );
    }
  }

  /**
   *  Get the master data to the list
   *
   * @memberof ImprovementsListComponent
   */
  getRows() {
    if (this.isReviewPage) {
      this.serviceSubscription = this.improvementsService
        .getImprovementsForReviewPage()
        .pipe(map((resp: Improvement[]) => resp))
        .subscribe((result) => {

          this.dataSource.data = result;

        });

    } else {
      this.serviceSubscription = this.improvementsService
        .getAll(
          this.usersPageSetting,
          this.filterValues,
          { createDate: 'desc' },
          this.queryParams,
        )
        .pipe(map((resp: PagedResult<Improvement>) => resp))
        .subscribe((result) => {
          const improvementId = (this.#location.getState() as any).improvementId;

          const results = result.payload;

          if (improvementId) {

            this.dataSource.data = results.filter(result => result.id === improvementId);
          } else {
            this.dataSource.data = results;
          }

          if (result.page) {
            this.usersPageSetting = result.page;
          }
        });
    }
  }

  /**
   *  Validate if the endDate is earlier than startDate
   *
   * @param     {FormGroup}                     group
   * @returns
   * @memberof  ImprovementsListComponent
   */
  checkDates(group: UntypedFormGroup) {
    if (
      group.controls.endDate.value &&
      group.controls.endDate.value < group.controls.beginDate.value
    ) {
      return { notValidDates: true };
    }
  }

  /**
   *  Check the current user permissions
   *
   * @memberof IncidentsListComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_IMPROVEMENT
      // DELETE_IMPROVEMENT
      // CREATE_IMPROVEMENT
      // UPDATE_IMPROVEMENT
      // READ_ALL_IMPROVEMENT
      // DELETE_ALL_IMPROVEMENT
      // UPDATE_ALL_IMPROVEMENT
      if (
        perm.permissions.indexOf('READ_IMPROVEMENT') > -1 ||
        perm.permissions.indexOf('READ_ALL_IMPROVEMENT') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_IMPROVEMENT') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_IMPROVEMENT') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_IMPROVEMENT') > -1) {
        this.crudC = true;
      }
      if (
        perm.permissions.indexOf('UPDATE_IMPROVEMENT') > -1 ||
        perm.permissions.indexOf('UPDATE_ALL_IMPROVEMENT') > -1
      ) {
        this.crudU = true;
      }
    }
  }

  /**
   *  Check the params sent through the activatedRoute
   *
   * @memberof ImprovementsListComponent
   */
  checkRouteParams() {
    this.activatedRoute.queryParams.subscribe((qparams) => {
      if (qparams && qparams instanceof Object) {
        this.routeQueryParams = qparams;

        for (const filter of Object.keys(qparams)) {
          if (this.initialFilterValues[filter] !== undefined) {
            if (filter === 'guardId' || filter === 'executionerId') {
              this.initialFilterValues[filter] =
                this.currentUserRights.currentMember.id;
            } else {
              this.initialFilterValues[filter] = qparams[filter];
            }
          }
        }
      }

      this.createFilterForm();
      this.getRows();
    });
  }

  /**
   *  Create the form for the filtering
   *
   * @memberof ImprovementsListComponent
   */
  createFilterForm() {
    this.filterForm = this.formBuilder.group(this.initialFilterValues, {
      validator: this.checkDates,
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        this.onFilterValueChange(changes);
      });

    if (this.routeQueryParams) {
      this.filterForm.markAsDirty();
      this.onFilterValueChange(this.initialFilterValues);
    }
  }

  /**
   * Respond to the change of the filter values
   *
   * @param {*} changes
   * @memberof ImprovementsListComponent
   */
  onFilterValueChange(changes: any) {
    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];
    this.filterValues = {};

    for (const field of Object.keys(this.filterFormErrors)) {
      const control = this.filterForm.get(field);
      this.filterFormErrors[field] = '';

      if (control && !control.valid) {
        const messages = this.filterValidationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.filterFormErrors[field] += messages[key] + ' ';
        }
      }

      if (this.filterForm.errors && this.filterForm.errors[field]) {
        this.filterFormErrors[field] +=
          this.filterValidationMessages[field] + ' ';
      }

      if (this.filterForm.valid && field !== 'notValidDates') {
        if (
          (field === 'beginDate' || field === 'endDate') &&
          changes[field] &&
          changes[field] instanceof moment
        ) {
          const dateField = changes[field].format('YYYY-MM-DD');
          this.queryParams.push({ name: field, value: dateField });
        } else if (
          (field === 'description' || field === 'subject') &&
          changes[field] !== ''
        ) {
          this.filterValues[field] = changes[field];
        } else if (changes[field] !== '') {
          this.queryParams.push({ name: field, value: changes[field] });
        }
      }
    }
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    ImprovementssListComponent
   */
  onEditIntent(row?: any, event?: Event, graphCellId?: string, graphType?: CHART_TYPES) {
    let isNew = true;
    let formValues = <any>{};

    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      isNew = false;

      const editedRowFormValues = {
        id: [row.id],
        subject: [row.subject, Validators.required],
        description: [row.description],

        theme: [
          row._embedded && row._embedded.theme ? row._embedded.theme : null,
        ],
        subTheme: [
          row._embedded && row._embedded.subTheme
            ? row._embedded.subTheme
            : null,
        ],

        rootCause: [row.rootCause],
        measurement: [row.measurement],

        guard: [
          row._embedded && row._embedded.guard ? row._embedded.guard : null,
        ],
        guardComment: [row.guardComment],
        executionerMembers: [
          row.executionerMembers ? row.executionerMembers : [],
        ],

        registeredOn: [row.registeredOn, Validators.required],
        implementedOn: [row.implementedOn, Validators.required],
        plannedOn: [row.plannedOn, Validators.required],

        efficient: [row.efficient],
        conclusion: [row.conclusion],

        status: [row.status],
        coRemark: [row.coRemark],
        checkbox: [row.checkbox],
        responseStatus: [row.responseStatus],

        newLinkedAttachmentIds: [[]],
        newUnLinkedAttachmentIds: [[]],

        teamId: this.currentUserRights.currentTeamId,

        sunburstId: [row.sunburstId]
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditImprovementComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        improvement: row,
        graphCellId,
        graphType
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   * Reset filters on clear click
   *
   * @memberof ImprovementsListComponent
   */
  onFiltersClear() {
    this.filterForm.reset(this.initialFilterValues);

    this.queryParams = [
      { name: 'teamId', value: this.currentUserRights.currentTeamId },
    ];
    this.filterValues = {};
    this.onFiltersSubmit();
  }

  /**
   *  Submit the filter form
   *
   * @memberof ImprovementsListComponent
   */
  onFiltersSubmit() {
    if (this.filterForm.valid) {
      this.usersPageSetting.number = 0;
      this.paginator.firstPage();
      this.getRows();
    }
  }

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      ImprovementsListComponent
   */
  compareValues(f1: any, f2: any) {
    let returnedValue = false;

    if (f1 !== undefined && f2 !== undefined) {
      if (f1.id && f2.id) {
        if (f1.id === f2.id) {
          returnedValue = true;
        }
      } else if (typeof f2 === 'string' && parseInt(f2, 10) === f1) {
        returnedValue = true;
      }
    }

    return returnedValue;
  }

  /**
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      ImprovementsListComponent
   */
  onDeleteIntent(row: any, event?: Event, dialog?: any) {
    this.dialogParameters.data.name = row.name;
    this.errorDialogParameters.data.name = row.name;

    if (event) {
      event.stopImmediatePropagation();
    }

    const dialogRef = this.dg.open(dialog, this.dialogParameters);

    dialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }

      this.improvementsService.delete(row).subscribe(
        (resp) => {
          row.hasError = false;
          this.updateTable(row, resp, 'delete');
        },
        (err) => {
          if (err.error && err.error.code === 409) {
            const dialogRefA = this.dg.open(
              this.errorDialog,
              this.errorDialogParameters,
            );
          }

          row.hasError = true;
        },
      );
    });
  }

  /**
   *  Update the MatTable after success post/put/delete request.
   *
   * @param         row                 The current row in the data table
   * @param         results             The API response after success request
   * @memberof        ImprovementsListComponent
   */
  updateTable(row: any, results: any, method?: string) {
    const rowIndex = this.dataSource.data.indexOf(row);

    if (method === 'delete') {
      row.status = 1;
    } else {
      this.dataSource.data[rowIndex] = results;
      if (method === 'post') {
        this.usersPageSetting.totalElements += 1;
      }
    }

    this.tableInEditMode = { isEditMode: false, row: {} };

    //  This is a tricky solution to refresh the material table
    //
    this.dataSource.filter = '';

    delete this.newFormGroup;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          ImprovementsListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    if (this.routerSubs) {
      this.routerSubs.unsubscribe();
    }
  }

  getImprovementStatus(statusOrder): string {
    return this.improvementStatuses.find(status => status.order === statusOrder).statusName;
  }
}
