/** @format */

import { Injectable } from '@angular/core';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Attachment } from '../models/attachment';
import { throwError as _throw, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AttachmentCategory } from '../models/attachment-category';

const apiPath = environment.apiPath;

/**
 *  Service for managing the files.
 *
 * @export
 * @class   FilesLibraryService
 * @extends {AbstractRestService<Attachment>}
 */
@Injectable()
export class FilesLibraryService extends AbstractRestService<Attachment> {
  /**
   * Creates an instance of FilesLibraryService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      FilesLibraryService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}attachments`, 'attachments');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<Attachment>}
   * @memberof        FilesLibraryService
   */
  protected mapToModel(json: any): PagedResult<Attachment> {
    //  If the array doesn't exist, create an empty object
    let payload = [];

    if (json._embedded) {
      payload = <Attachment[]>json._embedded.attachments;
    }

    return new PagedResult<Attachment>(payload, <PageSetting>json.page);
  }

  /**
   *  Get the list of files categories that can be shown for the file
   *
   * @memberof FilesLibraryService
   */
  getFilesCategories(): Observable<AttachmentCategory[]> {
    return this.http.get<any>(`${apiPath}attachmentCategories`).pipe(
      map((data) => data._embedded.attachmentCategories),
      catchError((err) => _throw(err)),
    );
  }
}
