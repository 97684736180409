<!-- @format -->

<div
  class="task-tile-container {{ taskColorClass }}"
  (click)="onTaskSelect()"
  [ngClass]="{
    'done-task': taskChecked,
    'improvement-item': taskType === 'improvement-item'
  }"
>
  <div class="task-header flex-rows">
    <div class="task-frequency emphasized">
      {{ task._embedded?.taskTemplateFrequency?.name }}

      <span
        class="member"
        *ngIf="
          currentUserRights.currentMember.id !== task._embedded?.member?.id
        "
      >
        {{ task._embedded?.member?.memberName }}
      </span>
    </div>

    <div class="task-dates">
      <div class="task-deadline">
        {{ task.periodEndDate | date: 'dd-MM-yyyy' }}
      </div>

      <div
        class="task-planned"
        *ngIf="
          !(
            task._embedded?.taskTemplateFrequency?.unit === 'DAY' &&
            task._embedded?.taskTemplateFrequency?.frequencyValue === 1
          ) && !(taskType === 'improvement-item')
        "
      >
        <ng-container
          *ngIf="task.plannedOn"
          i18n="tasks-tile planned-task-description"
        >
          Gepland: {{ task.plannedOn | date: 'dd-MM-yyyy' }}
        </ng-container>
        <ng-container
          *ngIf="!task.plannedOn"
          i18n="tasks-tile not-planned-task-description"
        >
          Nog in te plannen
        </ng-container>
      </div>
    </div>

    <div class="no-grow task-icons">
      <mat-icon
        color="warn"
        *ngIf="task.critical"
        >priority_high</mat-icon
      >
      <mat-icon
        color="primary"
        *ngIf="task.privateTask"
        >lock</mat-icon
      >
    </div>

    <div class="no-grow task-actions">
      <mat-checkbox
        [checked]="taskChecked"
        class="task-checkbox"
        (change)="onCheckBoxChange($event)"
        (click)="onCheckBoxChange($event)"
        *ngIf="taskType !== 'improvement-item'"
      ></mat-checkbox>
    </div>
  </div>

  <div class="subtheme emphasized separated">
    {{ task._embedded?.subTheme ? task._embedded?.subTheme?.name : '-' }}
  </div>

  <div class="task-description">{{ task.title ? task.title : '-' }}</div>

  <dl
    class="remarkable separated"
    *ngIf="task.relationInfo"
  >
    <dt>Oorsprong:</dt>
    <dd>{{ task.relationInfo }}</dd>
  </dl>

  <div
    class="remarkable separated"
    *ngIf="task.remarks"
  >
    * {{ task.remarks }}
  </div>
</div>
