/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { PMRItem } from '../models/pmr-item';

const apiPath = environment.apiPath;

/**
 *  Service for managing the CO PMR-Items.
 *
 * @export
 * @class         PmrItemsCoService
 * @extends       {AbstractRestService<PMRItem>}
 */
@Injectable()
export class PmrItemsCoService extends AbstractRestService<PMRItem> {
  /**
   * Creates an instance of PmrItemsCoService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      PmrItemsCoService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}pmr_items/CO`, 'pmr_items');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<PMRItem>}
   * @memberof        PmrItemsCoService
   */
  protected mapToModel(json: any): PagedResult<PMRItem> {
    //  If the PMRItem array doesn't exist, create an empty PMRItem object
    let payload = [];

    if (json._embedded) {
      payload = <PMRItem[]>json._embedded['pmr-items'];
    }

    return new PagedResult<PMRItem>(payload, <PageSetting>json.page);
  }
}
