/** @format */

import { Injectable } from '@angular/core';
import {
  AbstractRestService,
  PagedResult,
  PageSetting,
  BaseModel,
} from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';
import { BestPracticeLike } from '../models/bestpractice-like';

import { environment } from '../../environments/environment';

const apiPath = environment.apiPath;

/**
 *  Service for managing the team-groups list.
 *
 * @export
 * @class   BestPracticeLikeService
 * @extends {AbstractRestService<>}
 */
@Injectable()
export class BestPracticeLikeService extends AbstractRestService<BestPracticeLike> {
  /**
   * Creates an instance of BestPracticeLikeService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      BestPracticeLikeService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}bestpractice-likes`, 'bestpractice-likes');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ScoreItem>}
   * @memberof        BestPracticeLikeService
   */
  protected mapToModel(json: any): PagedResult<BestPracticeLike> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <BestPracticeLike[]>json._embedded.bestPracticeLikes;
    }

    return new PagedResult<BestPracticeLike>(payload, <PageSetting>json.page);
  }
}
