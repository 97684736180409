<!-- @format -->

<h1
  class="mat-h1"
  i18n="audit page-title"
>
  Audit -
  <span class="mat-h1-light">Thema's</span>
</h1>

<div
  class="main-content"
  (swipeleft)="onSwipe($event.type)"
  (swiperight)="onSwipe($event.type)"
>
  <mat-tab-group
    mat-stretch-tabs
    *ngIf="themesList"
    [selectedIndex]="selectedTabInd"
    (selectedTabChange)="tabSelectionChanged($event)"
  >
    <mat-tab
      label="{{ theme.name }}"
      *ngFor="let theme of themesList; let i = index"
    >
      <app-theme [theme]="theme"></app-theme>
    </mat-tab>
  </mat-tab-group>
</div>
