<!-- @format -->

<mat-expansion-panel
  class="mat-elevation-z0"
  [expanded]="!mobileQuery.matches"
>
  <mat-expansion-panel-header>
    <mat-panel-title
      class="filters-title"
      i18n="best-practices-list expansion-panel-title"
    >
      Filters
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="best-practices-filters">
    <span [formGroup]="filterForm">
      <mat-form-field appearance="fill">
        <mat-label>Naam</mat-label>
        <input
          type="text"
          matInput
          formControlName="username"
        />
      </mat-form-field>
    </span>
    <span [formGroup]="filterForm">
      <mat-form-field appearance="fill">
        <mat-label>Locatie</mat-label>
        <input
          type="text"
          matInput
          formControlName="teamName"
        />
      </mat-form-field>
    </span>
    <mat-form-field appearance="fill">
      <mat-label>Thema's</mat-label>
      <mat-select
        multiple
        (selectionChange)="onSelectChange($event)"
      >
        <mat-option
          *ngFor="let theme of sharedService.currentThemesList$ | async"
          [value]="theme"
          >{{ theme.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</mat-expansion-panel>

<div class="best-practices-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="index">
      <mat-header-cell
        *matHeaderCellDef
        i18n="best-practices-list list-header"
        class="index-cell hide-mobile"
        >Rang</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row; let i = index"
        class="index-cell hide-mobile"
      >
        {{ i + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="proposedByMemberName">
      <mat-header-cell
        *matHeaderCellDef
        class="hide-mobile"
        i18n="best-practices-list list-header"
        >Naam</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        {{ row._embedded.member.memberName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="teamName">
      <mat-header-cell
        *matHeaderCellDef
        i18n="best-practices-list list-header"
        class="hide-mobile"
        >Locatie</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        {{ row._embedded.team.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="themes">
      <mat-header-cell
        *matHeaderCellDef
        i18n="best-practices-list list-header"
        class="hide-mobile"
        >Thema's</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="hide-mobile"
      >
        <ng-container *ngIf="row._embedded.themes">
          <div *ngFor="let theme of row._embedded.themes">
            {{ theme.name }}
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="proposal">
      <mat-header-cell
        *matHeaderCellDef
        i18n="best-practices-list list-header"
        >Best practice</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <div *ngIf="mobileQuery.matches">
          <div class="best-practice-info">
            <div class="best-practice-author">
              {{ row._embedded.member.memberName }}
              {{ row._embedded.team.name }}
            </div>
            <div
              class="best-practice-themes"
              *ngIf="row._embedded.themes"
              i18n="best-practices-list list-header"
            >
              Thema's:
              <span *ngFor="let theme of row._embedded.themes; let i = index"
                >{{ theme.name }}
                <ng-container *ngIf="i !== row._embedded.themes.length - 1"
                  >,</ng-container
                >
              </span>
            </div>
          </div>
        </div>
        <div class="bestpractice-text">{{ row.proposal }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="likes">
      <mat-header-cell
        *matHeaderCellDef
        i18n="best-practices-list list-header"
        class="likes-cell"
        >Likes</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="likes-cell"
      >
        <div
          class="likes-group"
          (click)="onLikeToggle(row)"
        >
          <mat-icon *ngIf="!row.likedByUser">favorite_border</mat-icon>
          <mat-icon
            *ngIf="row.likedByUser"
            color="warn"
            >favorite</mat-icon
          >
          {{ row.numberOfLikes }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
        [ngClass]="{ 'hide-no-admin': !currentUserRights.organisationAdmin }"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
        [ngClass]="{ 'hide-no-admin': !currentUserRights.organisationAdmin }"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
