/** @format */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { AgendaTemplate } from '../../models/agenda-template';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';
import { AgendaTemplatesService } from '../../services/agenda-templates.service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { UserValidatorService } from '../../services/user-validator.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Meeting, MEETING_STATUS } from '../../models/meeting';
import { MeetingsService } from '../../services/meetings.service';
import { SharedService } from '../../services/shared.service';
import { Subscription } from 'rxjs';
import { MeetingSubject } from '../../models/meeting-subject';
import { YearlyPlannedSubjectsService } from '../../services/yearly-planned-subjects.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';
import { TasksTemplateService } from '../../services/tasks-template.service';
import { TasksTemplate } from '../../models/tasks-template';
import { TasksTemplateFrequency } from '../../models/tasks-template-frequency';

/**
 * Add edit agenda meeting form
 *
 * @export
 * @class AddEditAgendaMeetingComponent
 * @extends {AddEditAbstractDialog<Meeting>}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-edit-agenda-meeting',
  templateUrl: './add-edit-agenda-meeting.component.html',
  styleUrls: ['./add-edit-agenda-meeting.component.scss'],
})
export class AddEditAgendaMeetingComponent
  extends AddEditAbstractDialog<Meeting>
  implements OnInit, OnDestroy
{
  /**
   *  The list of agenda points attached to the meeting
   *
   * @type {TasksTemplate[]}
   * @memberof AddEditAgendaMeetingComponent
   */
  agendaPointsList: TasksTemplate[];

  /**
   *  The agenda service subscription
   *
   * @type {Subscription}
   * @memberof AddEditAgendaMeetingComponent
   */
  agendaSubs: Subscription;

  /**
   *  The agenda templates list
   *
   * @type              {AgendaTemplate[]}
   * @memberof          AddEditAgendaMeetingComponent
   */
  agendaTemplatesList: AgendaTemplate[];

  /**
   *  True/false if the user is allowed to see/edit the meeting notes
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  allowedToSeeNotes = false;

  /**
   * True/false if the user can update the current meeting
   *
   * @type {boolean}
   * @memberof AddEditAgendaMeetingComponent
   */
  canUpdate: boolean;

  /**
   *  True/false if any child form is invalid
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  childInvalid = false;

  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditAgendaMeetingComponent
   */
  formErrors = {
    consultationTypeId: '',
    meetingMembers: '',
    note: '',
    title: '',

    beginDate: '',
    beginHour: '',
    endHour: '',
    duration: '',
  };

  /**
   *  The subscription of the formResponse value
   *
   * @type {Subscription}
   * @memberof AddEditAgendaMeetingComponent
   */
  formResponseSubs: Subscription;

  /**
   *  The list of task template frequencies
   *
   * @type {TasksTemplateFrequency[]}
   * @memberof AddEditAgendaMeetingComponent
   */
  frequenciesList: TasksTemplateFrequency[];

  /**
   *  The frequencies subscription
   *
   * @type {Subscription}
   * @memberof AddEditAgendaMeetingComponent
   */
  frequenciesSubs: Subscription;

  /**
   * True/false if the notes container is in the full screen mode
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  fullScreenMode = false;

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditAgendaMeetingComponent
   */
  validationMessages = {
    title: {
      required: 'Titel is een verplicht veld.',
    },
    beginDate: {
      required: 'Begindatum is een verplicht veld.',
    },
    beginHour: {
      required: 'Begintijd is een verplicht veld.',
    },
    endHour: {
      required: 'Eindtijd is een verplicht veld.',
    },
    duration: {
      required: 'Duur is een verplicht veld.',
    },
  };

  /**
   *  The list of meeting statuses
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  meetingStatuses = MEETING_STATUS;

  /**
   * True/false if the list of meeting members has been changed
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  meetingMembersChanged = false;

  /**
   *  True/false if the user is the current meeting owner
   *
   * @type {boolean}
   * @memberof AddEditAgendaMeetingComponent
   */
  meetingOwner: boolean;

  /**
   *  The current meeting object
   *
   * @type     {Meeting}
   * @memberof AddEditAgendaMeetingComponent
   */
  savedMeeting: Meeting;

  /**
   *  The list of meeting's subjects
   *
   * @type            {MeetingSubject[]}
   * @memberof        AddEditAgendaMeetingComponent
   */
  subjectsList: MeetingSubject[];

  /**
   * Add config to the summernote rich editor
   *
   * @type {*}
   * @memberof AddEditAgendaMeetingComponent
   */
  summerNoteConfig: any = {
    placeholder: '',
    tabsize: 2,
    height: '300px',
    uploadImagePath: '',
    toolbar: [
      ['fontsize', ['fontsize', 'forecolor']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'hr']],
      ['misc', ['codeview', 'undo', 'redo']],
    ],
  };

  /**
   *  The list of yearly planned subjects
   *
   * @type {MeetingSubject[]}
   * @memberof AddEditAgendaMeetingComponent
   */
  yearlyPlannedSubjectList: MeetingSubject[];

  /**
   * Creates an instance of AddEditAgendaMeetingComponent.
   *
   * @param    {MatDialogRef<AddEditAgendaMeetingComponent>} dialogRefA
   * @param    {*}                                            data
   * @param    {AgendaTemplatesService}                       agendaTemplatesService
   * @param    {FormBuilder}                                  fbA
   * @memberof AddEditAgendaMeetingComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditAgendaMeetingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private meetingsService: MeetingsService,
    private fbA: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
    private dialog: MatDialog,
    public sharedService: SharedService,
    private agendaTemplatesService: AgendaTemplatesService,
    private yearlyPlannedSubjectsService: YearlyPlannedSubjectsService,
    private tasksTemplatesService: TasksTemplateService,
  ) {
    super(dialogRefA, data, meetingsService, fbA, dialog);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.warningDialogParameters.data.message = `Er zijn nog niet opgeslagen wijzigingen. Weet je zeker dat je wilt annuleren?`;

    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;
    this.savedMeeting = this.data.element;

    this.yearlyPlannedSubjectList = this.meetingsService.getYearlySubjects(
      this.currentUserRights.currentTeamId,
    );

    this.checkUserRights();

    if (this.data.fullscreen) {
      this.fullScreenMode = true;
    }

    this.agendaSubs = this.agendaTemplatesService
      .getAll(undefined, undefined, undefined, [
        { name: 'teamId', value: this.currentUserRights.currentTeamId },
      ])
      .subscribe((templates) => {
        this.agendaTemplatesList = templates.payload;
      });

    this.frequenciesSubs = this.tasksTemplatesService
      .getFrequencies()
      .subscribe((frequencies) => {
        this.sharedService.newFrequenciesList(frequencies);
        this.frequenciesList = frequencies;
        this.assignChildComponents();
      });
  }

  /**
   *  Create the list of child components
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  assignChildComponents() {
    //  Specify the child components
    if (this.initialFormValues.subjects[0] && !this.formIsNew) {
      this.subjectsList = this.initialFormValues.subjects[0];
    } else {
      this.initialFormValues.subjects[0] = [];
      this.subjectsList = this.initialFormValues.subjects[0];
    }

    this.initialFormValues.meetingMembers[0] = this.savedMeeting
      ? this.savedMeeting.meetingMembers
      : [];

    if (
      this.savedMeeting &&
      this.savedMeeting._embedded &&
      this.savedMeeting._embedded.taskTemplate
    ) {
      this.agendaPointsList = JSON.parse(
        JSON.stringify(this.savedMeeting._embedded.taskTemplate),
      );
    } else {
      this.agendaPointsList = [];
    }

    let frequencyNew = null;

    if (this.frequenciesList) {
      for (const freq of this.frequenciesList) {
        if (freq.unit === 'NONE') {
          frequencyNew = freq;
        }
      }
    }

    this.childComponentsList = [
      {
        value: this.subjectsList,
        fieldName: 'subjects',
        hasChanged: false,
        invalid: false,
        separateRequests: false,
        teamId: this.currentUserRights.currentTeamId,
        emptyFormValues: {
          name: '',
          description: '',
          sequence: this.subjectsList.length + 1,
          teamId: this.currentUserRights.currentTeamId,
          temporaryFields: {
            changed: false,
            removed: false,
            isNew: true,
            expanded: true,
            invalid: true,
          },
        },
      },
      {
        value: this.agendaPointsList,
        fieldName: 'taskTemplateIds',
        hasChanged: false,
        invalid: false,
        teamId: this.currentUserRights.currentTeamId,
        componentService: this.tasksTemplatesService,
        separateRequests: true,
        emptyFormValues: {
          critical: false,
          title: '',
          description: '',
          endDate: null,
          frequency: frequencyNew,
          privateTaskTemplate: false,
          responsibleMember: this.currentUserRights.currentMember,
          startDate: moment().format('YYYY-MM-DD'),
          teams: [this.currentUserRights.currentMember.team],
          subTheme: null,
          teamId: this.currentUserRights.currentTeamId,
          temporaryFields: {
            changed: false,
            removed: false,
            isNew: true,
            expanded: true,
            invalid: true,
          },
        },
      },
    ];
  }

  /**
   *  Check the rights of the current user
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  checkUserRights() {
    if (!this.formIsNew) {
      this.allowedToSeeNotes =
        this.data.updateRights &&
        (this.data.updateRights.full || this.data.updateRights.own);
      if (
        !this.allowedToSeeNotes &&
        this.savedMeeting.meetingMembers &&
        this.savedMeeting.meetingMembers.length
      ) {
        for (const member of this.savedMeeting.meetingMembers) {
          if (this.currentUserRights.currentMember.id === member.memberId) {
            this.allowedToSeeNotes = member.notesEnabled;
          }
        }
      }

      if (
        this.currentUserRights.currentMember.id ===
        this.savedMeeting.ownerMemberId
      ) {
        this.meetingOwner = true;
      }

      this.canUpdate = false;
      if (
        this.data.updateRights &&
        (this.data.updateRights.full ||
          (this.data.updateRights.own && this.meetingOwner))
      ) {
        this.canUpdate = true;
      }

      if (this.allowedToSeeNotes) {
        this.meetingsService
          .getSingleMeeting(this.savedMeeting)
          .subscribe((meeting) => {
            this.initialFormValues.note[0] = meeting.note;
            this.createForm(this.initialFormValues);
            this.setDisabledFormFields();
            if (!this.canUpdate) {
              this.newFormGroup.disable();
            }
          });
      } else {
        this.createForm(this.initialFormValues);
        this.setDisabledFormFields();

        if (!this.canUpdate) {
          this.newFormGroup.disable();
        }
      }
    } else {
      this.meetingOwner = true;
      this.canUpdate = true;
      this.allowedToSeeNotes = true;

      this.createForm(this.initialFormValues);
      this.setDisabledFormFields();
    }
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddEditAbstractDialog
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fbA.group(data);

    this.newFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((changes) => {
        this.onFormValueChanged(changes);
        this.meetingsService.checkDates(this.newFormGroup);
      });

    this.onFormValueChanged();
    this.meetingsService.checkDates(this.newFormGroup);
  }

  /**
   *  Drop the dragged element in the list
   *
   * @param {CdkDragDrop<string[]>} event
   * @memberof AddEditAgendaMeetingComponent
   */
  drop(event: CdkDragDrop<string[]>) {
    const containerData: any = event.container.data;

    if (event.previousContainer === event.container) {
      moveItemInArray(containerData, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    for (let i = 0; i < containerData.length; i++) {
      if (containerData[i].sequence !== i + 1) {
        containerData[i].sequence = i + 1;
        if (containerData[i].temporaryFields) {
          containerData[i].temporaryFields.changed = true;
        } else {
          containerData[i].temporaryFields = {
            changed: true,
          };
        }
        this.formHasChanged();
      }
    }

    this.formChanged = true;
    this.childChanged = true;
  }

  /**
   *  Prevent user from dropping the subjects to the yearlyplanned list
   *
   * @returns
   * @memberof AddEditAgendaMeetingComponent
   */
  noReturnPredicate() {
    return false;
  }

  /**
   *  Respond to the create notes intent
   *
   * @param    {*}                 event
   * @param    {MatExpansionPanel} expPanel
   * @memberof AddEditAgendaMeetingComponent
   */
  onCreateNotes(event: any, expPanel: MatExpansionPanel) {
    if (event) {
      event.stopImmediatePropagation();
    }

    let meetingNotes = this.newFormGroup.value.note;

    if (!meetingNotes && this.subjectsList && this.subjectsList.length) {
      for (let i = 0; i < this.subjectsList.length; i++) {
        if (
          !this.subjectsList[i].temporaryFields ||
          (this.subjectsList[i].temporaryFields &&
            !this.subjectsList[i].temporaryFields.removed)
        ) {
          meetingNotes += `<b>${this.subjectsList[i].name}</b><br><br>`;
          if (this.subjectsList[i].description) {
            meetingNotes += `${this.subjectsList[i].description}<br>`;
          }
        }
      }
    }

    if (this.newFormGroup.value.note !== meetingNotes) {
      this.newFormGroup.patchValue({
        note: meetingNotes,
      });
    }

    if (expPanel) {
      expPanel.expanded = true;
      expPanel.disabled = false;
    }
  }

  /**
   *  Respond to the user deleting a single item.
   *
   * @param         event The (`click`) event signalling the user's intent.
   * @param         row   The current row in the data table
   * @memberof      AddEditAgendaMeetingComponent
   */
  onDeleteAgendaSubject(
    item: any,
    childListIndex: number,
    event?: Event,
    element?: MatExpansionPanel,
    ind?: number,
  ) {
    if (item.yearlyPlannedSubjectId && !item.id) {
      if (event) {
        event.stopImmediatePropagation();
      }

      if (element && element.expanded) {
        item.temporaryFields.expanded = false;

        element.close();
      }

      transferArrayItem(
        this.subjectsList,
        this.yearlyPlannedSubjectList,
        ind,
        0,
      );

      this.onChildItemChange();
    } else {
      this.onChildItemDelete(item, childListIndex, event, element, ind);
    }
  }

  /**
   * Respond to the event emmited from the child component
   *
   * @param {boolean} changed
   * @memberof AddEditAgendaMeetingComponent
   */
  onMeetingMembersChange(changed: boolean) {
    this.meetingMembersChanged = changed;

    this.formHasChanged(true);
  }

  /**
   *  Respond to the agenda template change
   *
   * @param {MatSelectChange} [event]
   * @memberof AddEditAgendaMeetingComponent
   */
  onTemplateChange(event?: MatSelectChange) {
    if (event && event.value) {
      for (const template of this.agendaTemplatesList) {
        if (event.value === template.id) {
          this.subjectsList = template.subjects ? template.subjects : [];
          this.childComponentsList[0].value = this.subjectsList;

          this.newFormGroup.patchValue({
            title: template.name,
            subjects: this.subjectsList,
          });

          break;
        }
      }
    } else {
      this.subjectsList = [];
      this.childComponentsList[0].value = this.subjectsList;

      this.newFormGroup.patchValue({
        title: '',
        subjects: this.subjectsList,
      });
    }
  }

  /**
   *  Set the disabled attribute for the specific form fields.
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  setDisabledFormFields() {
    this.newFormGroup.get('duration').disable();
  }

  /**
   *  Toggle the full screen view of the notes container
   *
   * @memberof AddEditAgendaMeetingComponent
   */
  toggleFullScreen() {
    this.fullScreenMode = !this.fullScreenMode;
  }

  /**
   * Update the value of the formgroup
   *
   * @param {FormGroup} formGroup
   * @param {Meeting} response
   * @memberof AddEditAgendaMeetingComponent
   */
  updateFormGroupAfterSave(response: any) {
    if (this.newFormGroup.value && !this.newFormGroup.value.id) {
      this.newFormGroup.addControl('id', new UntypedFormControl(response.id));
    }

    const beginTime: any = moment(
      response.beginTime ? response.beginTime : moment(),
      'YYYY-MM-DD HH:mm',
    );
    const endTime: any = moment(
      response.endTime ? response.endTime : response.beginTime,
      'YYYY-MM-DD HH:mm',
    );

    const durationSet = moment.duration(endTime - beginTime);
    const duration = moment()
      .hours(durationSet.hours())
      .minutes(durationSet.minutes())
      .format('HH:mm');

    this.newFormGroup.patchValue({
      id: response.id,
      beginTime: response.beginTime,
      consultationTypeId: response.consultationTypeId
        ? response.consultationTypeId
        : null,
      consultationTypeName: response.consultationTypeName
        ? response.consultationTypeName
        : '',
      endTime: response.endTime,
      meetingMembers: response.meetingMembers ? response.meetingMembers : [],
      note: response.note ? response.note : '',
      ownerMemberId: response.ownerMemberId,
      ownerMemberName: response.ownerMemberName,
      status: response.status ? response.status : 0,
      subjects: response.subjects ? response.subjects : [],
      title: response.title ? response.title : '',
      taskTemplateIds: response.taskTemplateIds
        ? response.taskTemplateIds
        : null,
      teamId: response.teamId
        ? response.teamId
        : this.currentUserRights.currentTeamId,

      beginDate: moment(beginTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
      beginHour: moment(beginTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
      endHour: moment(endTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
      duration: duration,
    });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof        AddEditAgendaMeetingComponent
   */
  ngOnDestroy() {
    if (this.agendaSubs) {
      this.agendaSubs.unsubscribe();
    }
    if (this.frequenciesSubs) {
      this.frequenciesSubs.unsubscribe();
    }
    if (this.formResponseSubs) {
      this.formResponseSubs.unsubscribe();
    }
  }
}
