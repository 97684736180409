/** @format */

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  AbstractRestService,
  PagedResult,
  PageSetting,
  BaseModel,
} from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { BestPractice } from '../models/best-practice';

import { environment } from '../../environments/environment';

const apiPath = environment.apiPath;

/**
 *  Service for managing the team-groups list.
 *
 * @export
 * @class   BestPracticesService
 * @extends {AbstractRestService<>}
 */
@Injectable()
export class BestPracticesService extends AbstractRestService<BestPractice> {
  /**
   * Creates an instance of BestPracticesService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      BestPracticesService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}best-practices`, 'best-practices');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<ScoreItem>}
   * @memberof        BestPracticesService
   */
  protected mapToModel(json: any): PagedResult<BestPractice> {
    //  If the Team array doesn't exist, create an empty Team object
    let payload = [];

    if (json._embedded) {
      payload = <BestPractice[]>json._embedded.bestPractices;
    }

    return new PagedResult<BestPractice>(payload, <PageSetting>json.page);
  }

  /**
   *  Delete the bestPractice with the teamId query param.
   *
   * @param     {BaseModel}               aModel
   * @param     {string}                  teamId
   * @returns   {Observable<T>}
   * @memberof  BestPracticesService
   */
  deleteBestPractice(
    aModel: BaseModel,
    teamId: string,
  ): Observable<BestPractice> {
    return this.http
      .delete<BestPractice>(`${this.actionUrl}/${aModel.id}?teamId=${teamId}`)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Add the bestPractice with the teamId query param.
   *
   * @param     {BaseModel}               aModel
   * @param     {string}                  teamId
   * @returns   {Observable<T>}
   * @memberof  BestPracticesService
   */
  addBestPractice(aModel: BaseModel, teamId: string): Observable<BestPractice> {
    return this.http
      .post<BestPractice>(`${this.actionUrl}?teamId=${teamId}`, aModel)
      .pipe(catchError((err) => _throw(err)));
  }
}
