/** @format */

import { Member } from './member';
import { SubTheme } from './subtheme';
import { Theme } from './theme';
import { Improvement } from './improvement';
import { Attachment } from './attachment';

/**
 *  Default model for the Incident
 *
 * @export
 * @class Incident
 */
export class Incident {
  /**
   *  The list of attachment ids attached to the parent
   *
   * @type            {string[]}
   * @memberof        Incident
   */
  attachmentIds: string[];

  /**
   *  The internal id of the Incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  id: string;

  /**
   *  The count of attached improvements
   *
   * @type                {number}
   * @memberof            Incident
   */
  improvementCount: number;

  /**
   *  The list of improvement ids attached to the incident
   *
   * @type            {string[]}
   * @memberof        Incident
   */
  improvementIds?: string[];

  /**
   *  The comittee of incident
   *
   * @type            {boolean}
   * @memberof        Incident
   */
  appealCommittee: boolean;

  /**
   *  The completed date of incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  completedOn: string;

  /**
   *  The conclusion
   *
   * @type            {string}
   * @memberof        Incident
   */
  conclusion: string;

  /**
   *  The member who created the incident
   *
   * @type            {Member}
   * @memberof        Incident
   */
  createdBy: Member;

  /**
   *  The descrpition of the incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  description: string;

  /**
   *  The responsible member
   *
   * @type            {Member}
   * @memberof        Incident
   */
  executioner: Member;

  /**
   *  The guard member
   *
   * @type            {Member}
   * @memberof        Incident
   */
  guard: Member;

  /**
   *  True/false if the incident is internal
   *
   * @type            {boolean}
   * @memberof        Incident
   */
  internal: boolean;

  /**
   *  The list of new linked attachments to the parent
   *
   * @type {string[]}
   * @memberof Incident
   */
  newLinkedAttachmentIds: string[];

  /**
   *  The list of new unlikend attachments from the parent
   *
   * @type {string[]}
   * @memberof Incident
   */
  newUnLinkedAttachmentIds: string[];

  /**
   *  The occured date of incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  occurredOn: string;

  /**
   *  Can have ['0', '1', '2', '3', '4']
   *
   * @type            {number}
   * @memberof        Incident
   */
  origin: number;

  /**
   *  The planned date of incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  plannedOn: string;

  /**
   *  The registered date of incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  registeredOn: string;

  /**
   *  The email of the incident reporter
   *
   * @type            {string}
   * @memberof        Incident
   */
  reporterEmail: string;

  /**
   *  The name of the incident reporter
   *
   * @type            {string}
   * @memberof        Incident
   */
  reporterName: string;

  /**
   *  The phone number of the incident reporter
   *
   * @type            {string}
   * @memberof        Incident
   */
  reporterPhone: string;

  /**
   *  The remarks of the incident reporter
   *
   * @type            {string}
   * @memberof        Incident
   */
  reporterRemarks: string;

  /**
   *  The status of the response ['0', '1', '2', '3']
   *
   * @type            {number}
   * @memberof        Incident
   */
  responseStatus: number;

  /**
   *  The status of the incident ['0', '1', '2']
   *
   * @type            {number}
   * @memberof        Incident
   */
  status: number;

  /**
   *  The subTheme of the incident
   *
   * @type            {SubTheme}
   * @memberof        Incident
   */
  subTheme: SubTheme;

  /**
   *  The subject of the incident
   *
   * @type            {string}
   * @memberof        Incident
   */
  subject: string;

  /**
   *  The Theme of the incident
   *
   * @type            {Theme}
   * @memberof        Incident
   */
  theme: Theme;

  /**
   *  The _embedded objects of the incident
   *
   * @memberof        Incident
   */
  _embedded?: {
    /**
     *  The list of linked attachments
     *
     * @type {Attachment[]}
     */
    attachment?: Attachment[];

    /**
     *  The list of linked improvements
     *
     * @type {Improvement[]}
     */
    improvement?: Improvement[];

    /**
     * The Theme of the incident
     *
     * @type {Theme}
     */
    theme?: Theme;

    /**
     * The subTheme of the incident
     *
     * @type {SubTheme}
     */
    subTheme?: SubTheme;

    /**
     * The executioner of the incident
     *
     * @type {Member}
     */
    executioner?: Member;

    /**
     *  The incident guard
     *
     * @type {Member}
     */
    guard?: Member;
  };
}

export const INCIDENT_ORIGIN = [
  'Brief',
  'Email',
  'Mondeling',
  'Telefonisch',
  'Overig',
];

export const INCIDENT_RESPONSE_STATUS = [
  'Normaal',
  'Overtijd',
  'Gesloten',
  'Te laat afgehandeld',
];

export const INCIDENT_STATUS = ['Gerapporteerd', 'Geannuleerd', 'Gesloten'];
