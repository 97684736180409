/** @format */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { OverviewService } from '../../services/overview.service';
import { Subscription } from 'rxjs';
import { Theme } from '../../models/theme';
import { SelectionModel } from '@angular/cdk/collections';
import { TeamGroup } from '../../models/team-group';

/**
 *  Show the content of overview based on selected theme/ teamGroup
 *
 * @export
 * @class OverviewContentComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-overview-content',
  templateUrl: './overview-content.component.html',
  styleUrls: ['./overview-content.component.scss'],
})
export class OverviewContentComponent implements OnInit, OnDestroy {
  /**
   *  The mapped object of averageScores
   *
   * @memberof                OverviewContentComponent
   */
  avgMatrixByTeamPerSubTheme = <any>{};

  /**
   *  The selected themes list
   *
   * @memberof                OverviewContentComponent
   */
  selectedThemes: SelectionModel<Theme>;

  /**
   *  The selected team group
   *
   * @memberof                OverviewContentComponent
   */
  selectedTeamGroup: SelectionModel<TeamGroup>;

  /**
   *  The subscription of the selected themes list
   *
   * @type                {Subscription}
   * @memberof            OverviewContentComponent
   */
  themeSubscription: Subscription;

  /**
   *  The subscription of the selected team group
   *
   * @type                {Subscription}
   * @memberof            OverviewContentComponent
   */
  teamGroupSubscription: Subscription;

  /**
   *  The subscription of the avg matrix.
   *
   * @type                {Subscription}
   * @memberof            OverviewContentComponent
   */
  avgMatrixByTeamPerSubThemeSubs: Subscription;

  /**
   *  The mapped object of averageScores
   *
   * @memberof                OverviewContentComponent
   */
  avgMatrixByTeamGroupPerScoreItem = <any>{};

  /**
   *  The subscription of the avg matrix.
   *
   * @type                {Subscription}
   * @memberof            OverviewContentComponent
   */
  avgMatrixByTeamGroupPerScoreItemSubs: Subscription;

  /**
   * Creates an instance of OverviewContentComponent.
   *
   * @param       {SharedService}           sharedService     The instance of shared service
   * @param       {OverviewService}         overviewService
   * @memberof    OverviewContentComponent
   */
  constructor(
    public sharedService: SharedService,
    private overviewService: OverviewService,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof OverviewContentComponent
   */
  ngOnInit() {
    this.selectedTeamGroup = this.sharedService.currentTeamGroup.getValue();
    this.selectedThemes = this.sharedService.currentTheme.getValue();

    this.avgMatrixByTeamPerSubThemeSubs = this.overviewService
      .averageMatrixByTeamPerSubTheme()
      .subscribe((averagematrix) => {
        this.avgMatrixByTeamPerSubTheme = averagematrix;
        this.ref.detectChanges();
      });

    this.avgMatrixByTeamGroupPerScoreItemSubs = this.overviewService
      .averageMatrixByTeamGroupPerScoreItem()
      .subscribe((averagematrix) => {
        this.avgMatrixByTeamGroupPerScoreItem = averagematrix;
        this.ref.detectChanges();
      });

    this.teamGroupSubscription = this.sharedService.currentTeamGroup$.subscribe(
      (newteamgroup) => {
        this.selectedTeamGroup = newteamgroup;

        if (!newteamgroup.selected[0]) {
          this.selectedThemes.clear();
          this.sharedService.newSelectedTheme(this.selectedThemes);
        } else {
          this.ref.detectChanges();
        }
      },
    );

    this.themeSubscription = this.sharedService.currentTheme$.subscribe(
      (newthemes) => {
        this.selectedThemes = newthemes;
        this.ref.detectChanges();
      },
    );
  }

  /**
   *  Check if the DOM element overflows
   *
   * @param    {*}      el              DOM element
   * @returns
   * @memberof OverviewContentComponent
   */
  isOverflown(el: any) {
    return el.scrollWidth > el.clientWidth;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof OverviewContentComponent
   */
  ngOnDestroy() {
    this.avgMatrixByTeamPerSubThemeSubs.unsubscribe();
    this.avgMatrixByTeamGroupPerScoreItemSubs.unsubscribe();
    this.themeSubscription.unsubscribe();
    this.teamGroupSubscription.unsubscribe();
    this.ref.detach();
  }
}
