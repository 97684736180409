/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { DefaultPmrItem } from '../../models/pmr-item-default';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PmrItemsDefaultService } from '../../services/pmr-items-default.service';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-edit-default-pmr-item',
  templateUrl: './add-edit-default-pmr-item.component.html',
  styleUrls: ['./add-edit-default-pmr-item.component.scss'],
})
export class AddEditDefaultPmrItemComponent
  extends AddEditAbstractDialog<DefaultPmrItem>
  implements OnInit
{
  /**
   *  The object of form validation errors.
   *
   * @memberof                AddEditDefaultPmrItemComponent
   */
  formErrors = {
    title: '',
    description: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                AddEditDefaultPmrItemComponent
   */
  validationMessages = {
    title: {
      required: 'Onderwerp is een verplicht veld.',
    },
  };

  /**
   * Creates an instance of AddEditDefaultPmrItemComponent.
   *
   * @param    {MatDialogRef<AddEditDefaultPmrItemComponent>} dialogRefA
   * @param    {*}                                     data
   * @param    {PmrItemsDefaultService}                pmrItemsService
   * @param    {FormBuilder}                           fbA
   * @memberof AddEditDefaultPmrItemComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditDefaultPmrItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pmrItemsService: PmrItemsDefaultService,
    private fbA: UntypedFormBuilder,
  ) {
    super(dialogRefA, data, pmrItemsService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AddEditDefaultPmrItemComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.createForm(this.initialFormValues);
  }
}
