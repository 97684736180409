/** @format */

import { Component, OnInit } from '@angular/core';
import { SunburstGraphService } from '../../services/sunburst-graph.service';

/**
 *  The dashboard container
 *
 * @export
 * @class DashboardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss'],
})
export class GraphsComponent implements OnInit {
  /**
   * Creates an instance of DashboardComponent.
   *
   * @memberof DashboardComponent
   */
  constructor(private sunburstGraphService: SunburstGraphService) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof DashboardComponent
   */
  ngOnInit(): void {
    this.sunburstGraphService.fetchGraphData();
  }
}
