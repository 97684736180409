<!-- @format -->

<h1
  class="mat-h1"
  i18n="overview page-title"
>
  Meetlat
</h1>

<div class="main-content change-on-mobile overview-content">
  <app-team-groups-tile-list></app-team-groups-tile-list>
</div>
