/** @format */

import { Component, OnInit } from '@angular/core';
import { PmrItemsListComponent } from '../pmr-items-list/pmr-items-list.component';
import { PmrService } from '../../services/pmr.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserValidatorService } from '../../services/user-validator.service';
import {
  distinctUntilChanged,
  debounceTime,
  map,
  startWith,
} from 'rxjs/operators';
import { TeamsListService } from '../../services/teams-list.service';
import { Team } from '../../models/team';
import { PmrItemsCoService } from '../../services/pmr-items-co.service';
import { PMRItem } from '../../models/pmr-item';
import { PagedResult } from '@iq/ng-core';
import { Observable } from 'rxjs';

/**
 *  Show the organisation overview of the pmr-items/ Only for CO
 *
 * @export
 * @class PmrItemsCoOverviewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pmr-items-co-overview',
  templateUrl: './pmr-items-co-overview.component.html',
  styleUrls: ['./pmr-items-co-overview.component.scss'],
})
export class PmrItemsCoOverviewComponent
  extends PmrItemsListComponent
  implements OnInit
{
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                PmrItemsCoOverviewComponent
   */
  columnsToDisplay = [
    'teamName',
    'title',
    'description',
    'status',
    'conclusion',
    'IVM',
    'CUD',
  ];

  /**
   *  Form Control of the filter teams
   *
   * @type                  {FormControl}
   * @memberof              PmrItemsCoOverviewComponent
   */
  filterControl: UntypedFormControl = new UntypedFormControl();

  /**
   *  The filters form
   *
   * @type                    {FormGroup}
   * @memberof                PmrItemsCoOverviewComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The filtered list of teams
   *
   * @type {Team[]}
   * @memberof PmrItemsCoOverviewComponent
   */
  filteredTeams: Observable<Team[]>;

  /**
   *  The list of teams in the organisation
   *
   * @type {Team[]}
   * @memberof PmrItemsCoOverviewComponent
   */
  teamsList: Team[];

  /**
   * Creates an instance of PmrItemsCoOverviewComponent.
   *
   * @memberof PmrItemsCoOverviewComponent
   */
  constructor(
    private _pmrService: PmrService,
    private _formBuilder: UntypedFormBuilder,
    private _dg: MatDialog,
    private _userValidatorService: UserValidatorService,
    private teamsListService: TeamsListService,
    private pmrItemsCoService: PmrItemsCoService,
  ) {
    super(_pmrService, _formBuilder, _dg, _userValidatorService);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof PmrItemsCoOverviewComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this._userValidatorService.currentUserRights.getValue();
    this.sortValues = { 'pmr.team.name': 'asc' };

    this.addFiltersForm();

    this.teamsListService
      .getAll(undefined, undefined, { name: 'asc' }, undefined)
      .subscribe((response) => {
        this.teamsList = response.payload;

        this.filteredTeams = this.filterControl.valueChanges.pipe(
          startWith(''),
          map((val) => {
            if (val === '') {
              this.onTeamSelect();

              return this.teamsList;
            } else {
              return this.teamsListService.filterTeam(this.teamsList, val);
            }
          }),
        );
      });

    this.getRows();
  }

  /**
   *  Add and subscribe the form group for list filters
   *
   * @memberof PmrItemsCoOverviewComponent
   */
  addFiltersForm() {
    this.filterForm = this._formBuilder.group({
      teamId: '',
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        this.queryParams = [];
        this.filterValues = {};

        for (const field of Object.keys(changes)) {
          if (field === 'teamId' && changes[field] !== '') {
            this.queryParams.push({ name: field, value: changes[field] });
          } else if (field !== 'teamId' && changes[field] !== '') {
            this.filterValues[field] = changes[field];
          }
        }
      });
  }

  /**
   *  Get the master data to the list
   *
   * @memberof PmrItemsCoOverviewComponent
   */
  getRows() {
    this.serviceSubscription = this.pmrItemsCoService
      .getAll(
        this.usersPageSetting,
        this.filterValues,
        this.sortValues,
        this.queryParams,
      )
      .pipe(map((resp: PagedResult<PMRItem>) => resp))
      .subscribe((result) => {
        const results = result.payload;

        this.dataSource.data = results;

        if (result.page) {
          this.usersPageSetting = result.page;
        }
      });
  }

  /**
   * Reset filters on clear click
   *
   * @memberof IncidentsListComponent
   */
  onFiltersClear() {
    this.filterForm.reset({
      teamId: '',
    });

    this.queryParams = [];
    this.filterValues = {};
    this.filterControl.reset('');
    this.onFiltersSubmit();
  }

  /**
   *  Submit the filter form
   *
   * @memberof PmrItemsCoOverviewComponent
   */
  onFiltersSubmit() {
    if (this.filterForm.valid) {
      this.usersPageSetting.number = 0;
      this.paginator.firstPage();
      this.getRows();
    }
  }

  /**
   *  Respond to the selection of the Team in team-select list
   *
   * @param       {Team}              team
   * @memberof    PmrItemsCoOverviewComponent
   */
  onTeamSelect(team?: Team) {
    if (team) {
      if (
        this.filterForm.get('teamId') &&
        team.id !== this.filterForm.get('teamId').value
      ) {
        this.filterForm.patchValue({
          teamId: team.id,
        });
      }
    } else {
      this.filterForm.patchValue({
        teamId: '',
      });
    }
  }
}
