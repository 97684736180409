/** @format */

import { OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { UserRights } from '../models/user-rights';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Directive() // DIRECTIVE?
export abstract class EmbeddedChild<T> implements OnInit {
  /**
   *  The existing item passed by the parent component
   *
   * @type                    {T}
   * @memberof                EmbeddedChild
   */
  @Input() editedItem: T;

  /**
   *  Bind the info to the parent when item is removed.
   *
   * @memberof                EmbeddedChild
   */
  @Output() itemRemoved = new EventEmitter<{ removed: boolean }>();

  /**
   *  Bind the info to the parent when the itemChange
   *
   * @memberof                EmbeddedChild
   */
  @Output() itemChanged = new EventEmitter<any>();

  /**
   *  The rights of currents user
   *
   * @type                    {UserRights}
   * @memberof                EmbeddedChild
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the form is changed.
   *
   * @memberof                EmbeddedChild
   */
  formChanged = false;

  /**
   *  The object of form validation errors.
   *
   * @memberof                EmbeddedChild
   */
  formErrors = {};

  /**
   *  The default values of the new Form.
   *
   * @memberof                EmbeddedChild
   */
  initialFormValues = <any>{};

  /**
   *  The form group.
   *
   * @memberof                EmbeddedChild
   */
  newFormGroup: UntypedFormGroup;

  /**
   *  The list of empty fields shown in the tooltip message
   *
   * @memberof                EmbeddedChild
   */
  tooltipMessage = '';

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                EmbeddedChild
   */
  validationMessages = {};

  /**
   *  Compare the prev and the current option from the MatSelect element.
   *
   * @memberof                EmbeddedChild
   */
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareValues;

  /**
   *   Creates an instance of EmbeddedChild.
   *
   * @param    {FormBuilder} formBuilder
   * @memberof EmbeddedChild
   */
  constructor(private formBuilder: UntypedFormBuilder) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof EmbeddedChild
   */
  ngOnInit() {
    this.setInitialFormValues();

    this.createForm(this.initialFormValues);
  }

  /**
   *  Compare two select options form the MatSelect form field.
   *
   * @param         {*}           f1
   * @param         {*}           f2
   * @returns
   * @memberof      EmbeddedChild
   */
  compareValues(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof EmbeddedChild
   */
  createForm(data?: any): void {
    this.newFormGroup = this.formBuilder.group(data);

    this.newFormGroup.valueChanges
      .debounceTime(500)
      .subscribe((changes) => this.onFormValueChanged(changes));
  }

  /**
   *  Respond to the change of the form value.
   *
   * @param       {*}                       [data]
   * @returns
   * @memberof    EmbeddedChild
   */
  onFormValueChanged(data?: any) {
    if (!this.newFormGroup) {
      return;
    }

    const form = this.newFormGroup;
    this.tooltipMessage = '';

    for (const field of Object.keys(this.formErrors)) {
      this.formErrors[field] = ''; //  Clear all of messages
      const control = form.get(field);

      if (control && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }

        this.tooltipMessage += this.formErrors[field] + '\n';
      }

      if (form.errors && form.errors[field]) {
        this.formErrors[field] += this.validationMessages[field] + ' ';
      }
    }

    this.formChanged = false;

    for (const field of Object.keys(this.formErrors)) {
      if (
        this.initialFormValues[field] &&
        this.newFormGroup.value[field] !== this.initialFormValues[field][0]
      ) {
        this.formChanged = true;

        break;
      }
    }

    if (
      this.newFormGroup.get('temporaryFields').value &&
      this.formChanged !==
        this.newFormGroup.get('temporaryFields').value.changed
    ) {
      const tempFields = this.newFormGroup.get('temporaryFields').value;
      tempFields.changed = this.formChanged;

      this.newFormGroup.patchValue({ temporaryFields: tempFields });
    }
    if (
      this.newFormGroup.invalid !==
      this.newFormGroup.get('temporaryFields').value.invalid
    ) {
      const tempFields = this.newFormGroup.get('temporaryFields').value;
      tempFields.invalid = this.newFormGroup.invalid;

      this.newFormGroup.patchValue({ temporaryFields: tempFields });
    }

    if (data) {
      this.itemChanged.emit(this.newFormGroup.value);
    }
  }

  /**
   *  Set the initial values to the child component form
   *
   * @memberof EmbeddedChild
   */
  setInitialFormValues() {}
}
