import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "./shared.service";
import { SubthemeOwnershipService } from "./subtheme-ownership.service";
import { UserValidatorService } from "./user-validator.service";
import { Member } from "../models/member";
import { first, map } from "rxjs/operators";
import { Subject } from "rxjs";

@Injectable()
export class PracticeChangeService {
  refreshDashboardDataTrigger$ = new Subject<void>();

  constructor(
    private userValidatorService: UserValidatorService,
    private router: Router,
    private subThemeOwnershipService: SubthemeOwnershipService,
    private sharedService: SharedService,
  ) { }

  onMemberSubmit(memberSelect: Member) {
    this.userValidatorService.currentUser$.pipe(first(), map((user) => {
      if (user._embedded) {
        const currentUser = user;
        const membersList = user._embedded.members;
        const selMemberIndex = membersList.findIndex(member => member.id === memberSelect.id);
        const currentRights = this.userValidatorService.checkUsersRights(
          currentUser,
          membersList[selMemberIndex],
        );

        this.subThemeOwnershipService
          .getAll(undefined, undefined, undefined, [
            { name: 'teamId', value: currentRights.currentTeamId },
          ])
          .subscribe((ownerships) => {
            const ownershipByIds = {};
            for (const ownership of ownerships.payload) {
              if (
                ownership._embedded.subTheme &&
                !ownershipByIds[ownership._embedded.subTheme.id]
              ) {
                ownershipByIds[ownership._embedded.subTheme.id] = ownership;
              }
            }

            this.sharedService.newSubhtemeOwnershipList(ownershipByIds);

            const subThemeOwnership =
              this.userValidatorService.checkSubThemesOwnership(
                membersList[selMemberIndex],
                ownerships.payload,
              );

            currentRights.subThemeOwnership = subThemeOwnership;

            this.userValidatorService.newUserRights(currentRights);

            localStorage.setItem('userRights', JSON.stringify(currentRights));

            if (
              currentRights.currentMember &&
              currentRights.currentMember.teamRole.permissions
            ) {

              this.router.url === '/home' ? this.refreshDashboardDataTrigger$.next() : this.router.navigateByUrl('home');
            }
          });
      }
    })).subscribe();
  }
}
