/** @format */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { malResetPasswordPageComponent, malAuthService } from '@iq/ng-auth';

/**
 *  Reset password page
 *
 * @export
 * @class KmsResetPasswordPageComponent
 * @extends {malResetPasswordPageComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'kms-auth-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class KmsResetPasswordPageComponent
  extends malResetPasswordPageComponent
  implements OnInit
{
  /**
   * Creates an instance of KmsResetPasswordPageComponent.
   *
   * @param         {Router}                    _router
   * @param         {malAuthService}            _auth
   * @param         {ActivatedRoute}            _rout
   * @memberof      KmsResetPasswordPageComponent
   */
  constructor(
    private _router: Router,
    private _auth: malAuthService,
    private _rout: ActivatedRoute,
  ) {
    super(_router, _auth, _rout);
  }
}
