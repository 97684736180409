/** @format */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { UserValidatorService } from '../services/user-validator.service';
import { UserRights } from '../models/user-rights';
import { SharedService } from '../services/shared.service';
import { SubthemeOwnershipService } from '../services/subtheme-ownership.service';
import { CacheUpdateService } from '../services/cache-update.service';

/**
 *  The guard that checks if the TeamId is selected.
 *
 * @export
 * @class           TeamIdGuard
 * @implements      {CanActivate}
 */
@Injectable()
export class TeamIdGuard implements CanActivate {
  /**
   *  The current user rights
   *
   * @type            {UserRights}
   * @memberof        TeamIdGuard
   */
  userRights: UserRights;

  /**
   * Creates an instance of TeamIdGuard.
   *
   * @param {UserValidatorService}  userValidatorService    The instance of uservValidatorService
   * @param {SharedService} sharedService
   * @param {SubthemeOwnershipService} subThemeOwnershipService
   * @param {CacheUpdateService} cacheUpdateService
   * @param {Router}                router                  The instance of Router object.
   * @memberof TeamIdGuard
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private sharedService: SharedService,
    private subThemeOwnershipService: SubthemeOwnershipService,
    private cacheUpdateService: CacheUpdateService,
    private router: Router,
  ) {}

  /**
   *  Interface that a class can implement to be a guard deciding if a route can be activated
   *
   * @param           {ActivatedRouteSnapshot}    route   The instance of ActivatedRouteSnapshot
   * @param           {RouterStateSnapshot}       state   The instance of RouterStateSnapshot
   * @returns         {boolean}
   * @memberof        TeamIdGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (localStorage.getItem('userRights')) {
      this.userRights = JSON.parse(localStorage.getItem('userRights'));
      if (
        this.userRights !==
        this.userValidatorService.currentUserRights.getValue()
      ) {
        this.userValidatorService.newUserRights(this.userRights);
      }
    } else {
      this.userRights = {
        organisationAdmin: false,
        isCurrentTeamAdmin: false,
        currentTeamId: '',
        currentTeamName: '',
      };
    }

    if (this.userRights.currentTeamId) {
      this.cacheUpdateService.updateTeamMembersList();
      this.cacheUpdateService.updateTeamRolesList();
      this.cacheUpdateService.updateYearlyPlannedSubjects();
      this.cacheUpdateService.updateFilesCategories();
      this.cacheUpdateService.updateThemesList();

      this.subThemeOwnershipService
        .getAll(undefined, undefined, undefined, [
          { name: 'teamId', value: this.userRights.currentTeamId },
        ])
        .subscribe((ownerships) => {
          const ownershipByIds = {};
          for (const ownership of ownerships.payload) {
            if (
              ownership._embedded.subTheme &&
              !ownershipByIds[ownership._embedded.subTheme.id]
            ) {
              ownershipByIds[ownership._embedded.subTheme.id] = ownership;
            }
          }
          this.sharedService.newSubhtemeOwnershipList(ownershipByIds);
        });

      return true;
    } else {
      const currentUser = this.userValidatorService.currentUser.getValue();
      this.userRights.organisationAdmin = currentUser.organisationAdmin;

      if (currentUser._embedded) {
        if (currentUser._embedded.members.length === 1) {
          this.userRights.currentMember = currentUser._embedded.members[0];
          this.userRights.currentTeamId =
            currentUser._embedded.members[0].team.id;
          this.userRights.currentTeamName =
            currentUser._embedded.members[0].team.name;
          this.userRights.isCurrentTeamAdmin =
            currentUser._embedded.members[0].teamAdmin;

          localStorage.setItem('userRights', JSON.stringify(this.userRights));
          this.userValidatorService.newUserRights(this.userRights);

          this.subThemeOwnershipService
            .getAll(undefined, undefined, undefined, [
              { name: 'teamId', value: this.userRights.currentTeamId },
            ])
            .subscribe((ownerships) => {
              const ownershipByIds = {};
              for (const ownership of ownerships.payload) {
                if (
                  ownership._embedded.subTheme &&
                  !ownershipByIds[ownership._embedded.subTheme.id]
                ) {
                  ownershipByIds[ownership._embedded.subTheme.id] = ownership;
                }
              }
              this.sharedService.newSubhtemeOwnershipList(ownershipByIds);
              const subThemeOwnership =
                this.userValidatorService.checkSubThemesOwnership(
                  currentUser._embedded.members[0],
                  ownerships.payload,
                );
              this.userRights.subThemeOwnership = subThemeOwnership;
              localStorage.setItem(
                'userRights',
                JSON.stringify(this.userRights),
              );
              this.userValidatorService.newUserRights(this.userRights);
            });

          this.cacheUpdateService.updateTeamMembersList();
          this.cacheUpdateService.updateTeamRolesList();
          this.cacheUpdateService.updateYearlyPlannedSubjects();
          this.cacheUpdateService.updateFilesCategories();
          this.cacheUpdateService.updateThemesList();

          return true;
        } else {
          if (this.userRights.organisationAdmin) {
            this.router.navigate(['team-choose'], {
              queryParams: { returnUrl: state.url },
            });
          } else {
            this.router.navigate(['team-choose'], {
              queryParams: { returnUrl: state.url },
            });
          }

          return false;
        }
      }
    }
  }
}
