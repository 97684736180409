/** @format */

import { DefaultPmrItem } from './pmr-item-default';
import { PMRImprovement } from './pmr-improvement';
import { Attachment } from './attachment';

/**
 *  The default model for PMR Item
 *
 * @export
 * @class PMRItem
 * @extends {DefaultPmrItem}
 */
export class PMRItem extends DefaultPmrItem {
  /**
   *  The list of attachment ids attached to the pmr-item
   *
   * @type            {string[]}
   * @memberof        PMRItem
   */
  attachmentIds: string[];

  checkbox: boolean;

  /**
   *  True/false if the pmr item contains improvements copied from previous pmr
   *
   * @type {boolean}
   * @memberof PMRItem
   */
  containsCopiedImprovements: boolean;

  /**
   *  The remarks from CO
   *
   * @type     {string}
   * @memberof PMRItem
   */
  coRemark: string;

  /**
   *  The conclusion of PMR item
   *  ['0', '1', '2', '3'],
   *
   * @type {boolean}
   * @memberof PMRItem
   */
  conclusion: number;

  /**
   *  True/false if the pmrItem has been copied from the previous PMR
   *
   * @type            {boolean}
   * @memberof        PMRItem
   */
  copiedFromPrevious: boolean;

  /**
   *  The count of attached improvements
   *
   * @type {number}
   * @memberof PMRItem
   */
  improvementCount: number;

  /**
   *  The list of improvement ids attached to the pmr-item
   *
   * @type {string[]}
   * @memberof PMRItem
   */
  improvementIds: string[];

  /**
   *  The list of new linked attachments to the pmr-item
   *
   * @type {string[]}
   * @memberof PMRItem
   */
  newLinkedAttachmentIds: string[];

  /**
   *  The list of new unlikend attachments from the pmr-item
   *
   * @type {string[]}
   * @memberof PMRItem
   */
  newUnLinkedAttachmentIds: string[];

  /**
   *  The id of connected PMR
   *
   * @type            {string}
   * @memberof        PMRItem
   */
  pmrId: string;

  /**
   *  The status of PMR item
   *  ['0', '1', '2', '3'],
   *
   * @param           {number}
   * @memberof        PMRItem
   */
  status: number;

  /**
   *  The _embedded objects
   *
   * @memberof        PMRItem
   */
  _embedded: {
    /**
     *  The list of pmr improvements
     *
     * @type {PMRImprovement[]}
     */
    pmrimprovement: PMRImprovement[];

    /**
     *  The list of attachments
     *
     * @type {Attachment[]}
     */
    attachment: Attachment[];
  };
}

export const PMRITEM_STATUSES = [
  'Concept',
  'Review CO',
  'Review gereed',
  'Afgerond',
];

export const PMRITEM_CONCLUSIONS = [
  'n.v.t.',
  'Goed (G)',
  'Onvoldoende (O)',
  'Voldoende (V)',
];
