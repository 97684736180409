<!-- @format -->

<div
  fxLayout="row"
  fxLayout.cmb="column"
  fxLayoutAlign="start none"
  class="flex-score-item"
>
  <div
    class="scores-container"
    fxFlex="45%"
    fxFlex.cmb="100%"
  >
    <div class="review-question">
      {{ scoreItem.reviewQuestion }}
    </div>
    <div class="smiley-rating-container">
      <form [formGroup]="newScoreGroup">
        <div>
          <ng-container *ngIf="!currentUserRights.organisationAdmin">
            <div
              *ngFor="let rate of ratingScores | slice: 1"
              class="smiley-radio-container"
              (mouseenter)="onSmileyHover(rate, $event)"
              (mouseleave)="onSmileyLeave(rate, $event)"
            >
              <input
                type="radio"
                [value]="rate.value"
                formControlName="score"
              />
              <div class="smiley-radio smiley-{{ rate.value }}"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="currentUserRights.organisationAdmin">
            <div
              *ngFor="let rate of ratingScores | slice: 1"
              class="smiley-radio-container"
              (mouseenter)="onSmileyHover(rate, $event)"
              (mouseleave)="onSmileyLeave(rate, $event)"
            >
              <input
                type="radio"
                [value]="rate.value"
                formControlName="score"
                disabled
              />
              <div class="smiley-radio smiley-{{ rate.value }}"></div>
            </div>
          </ng-container>
        </div>

        <div class="smiley-rating-underline"></div>
        <div
          #smileyDesc
          class="smiley-description"
        >
          <div class="score-description-label">
            {{ currentDescription.label }}
          </div>
          <div class="score-description">
            {{ currentDescription.description }}
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="score-item-content change-on-mobile"
    fxFlex="55%"
    fxFlex.cmb="100%"
  >
    <div
      class="score-item-description-header"
      i18n="score-item-list-item description-header"
    >
      <mat-icon
        mat-list-icon
        svgIcon="info"
      ></mat-icon>
      {{ index + 1 }}. Toelichting {{ scoreItem.name }}
    </div>
    <ul>
      <li *ngIf="scoreItem.explanation">{{ scoreItem.explanation }}</li>
      <li *ngIf="scoreItem.measuringInstrument">
        {{ scoreItem.measuringInstrument }}
      </li>
    </ul>
    <div class="score-update-container">
      <form [formGroup]="newScoreGroup">
        <mat-form-field appearance="fill">
          <mat-label>Motivatie</mat-label>
          <textarea
            matInput
            formControlName="motivation"
            [readonly]="currentUserRights.organisationAdmin"
          ></textarea>
        </mat-form-field>
      </form>
      <div
        class="button-row"
        *ngIf="!currentUserRights.organisationAdmin"
      >
        <button
          mat-flat-button
          class="score-update-button"
          (click)="onCancel()"
          i18n="cancel button @@buttonCancel"
        >
          Annuleren
        </button>
        <button
          *ngIf="!formSubmitted"
          mat-flat-button
          class="score-update-button"
          (click)="onScoreSave()"
          [disabled]="
            !formChanged ||
            newScoreGroup.invalid ||
            currentUserRights.organisationAdmin
          "
          i18n="save button @@buttonSave"
        >
          Opslaan
        </button>
        <button
          *ngIf="formSubmitted"
          mat-flat-button
          class="score-update-button submitted"
          i18n="saved button @@buttonSaved"
        >
          <mat-icon>check</mat-icon> Opgeslagen
        </button>
      </div>
    </div>
  </div>
</div>
