/** @format */

import { Component, OnInit } from '@angular/core';

/**
 *  Main component for managing the Users
 *
 * @export
 * @class         UsersManagementComponent
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss'],
})
export class UsersManagementComponent implements OnInit {
  /**
   * Creates an instance of UsersManagementComponent.
   *
   * @memberof  UsersManagementComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof  UsersManagementComponent
   */
  ngOnInit() {}
}
