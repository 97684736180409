<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-improvement-analysis dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe analyse verbetermaatregel
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-improvement-analysis dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen analyse verbetermaatregel
</h2>

<mat-dialog-content>
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Onderwerp</mat-label>
      <input
        type="text"
        matInput
        formControlName="title"
        required
      />
      <mat-error [hidden]="!formErrors.title">{{ formErrors.title }}</mat-error>
    </mat-form-field>
  </form>

  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Oorzaak trend(s) klachten</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMinRows="5"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>

  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="add-edit-improvement-dialog expansion-panel-title"
        >
          Verbetermaatregelen
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-improvements-list-embedded
        [listData]="improvementsList"
        [formDisabled]="!(formIsNew || data.canUpdate)"
        (itemLinked)="onItemLink($event)"
        (itemUnlinked)="onItemUnlink($event)"
      >
      </app-improvements-list-embedded>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
