<!-- @format -->

<div class="flex-rows filters-container">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngIf="crudR"
  >
    <mat-expansion-panel-header class="filters-header">
      <mat-panel-title
        class="filters-title"
        i18n="improvements-list expansion-panel-title"
      >
        Filter
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div [formGroup]="filterForm">
        <div class="flex-rows">
          <mat-form-field
            appearance="fill"
            [ngClass]="{
              'constraint-violation': filterFormErrors.notValidDates
            }"
          >
            <mat-label>Melddatum begin</mat-label>
            <input
              type="text"
              matInput
              formControlName="beginDate"
              [matDatepicker]="startDate"
            />
            <mat-datepicker #startDate></mat-datepicker>
            <mat-error [hidden]="!filterFormErrors.beginDate">{{
              filterFormErrors.beginDate
            }}</mat-error>
            <mat-icon (click)="startDate.open()">calendar_month</mat-icon>
          </mat-form-field>

          <mat-form-field
            appearance="fill"
            [ngClass]="{
              'constraint-violation': filterFormErrors.notValidDates
            }"
          >
            <mat-label>Melddatum eind</mat-label>
            <input
              type="text"
              matInput
              formControlName="endDate"
              [matDatepicker]="endDate"
            />
            <mat-datepicker #endDate></mat-datepicker>
            <mat-error [hidden]="!filterFormErrors.endDate">{{
              filterFormErrors.endDate
            }}</mat-error>
            <mat-icon (click)="endDate.open()">calendar_month</mat-icon>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Bewaker</mat-label>
            <mat-select formControlName="guardId">
              <mat-option value="">---</mat-option>
              <mat-option
                *ngFor="
                  let guard of sharedService.currentTeamMembersList$ | async
                "
                [value]="guard.id"
              >
                {{ guard.memberName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Uitvoerder</mat-label>
            <mat-select formControlName="executionerId">
              <mat-option value="">---</mat-option>
              <mat-option
                *ngFor="
                  let exe of sharedService.currentTeamMembersList$ | async
                "
                [value]="exe.id"
              >
                {{ exe.memberName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex-rows">
          <mat-form-field appearance="fill">
            <mat-label>Effectief</mat-label>
            <mat-select formControlName="effective">
              <mat-option value="">---</mat-option>
              <mat-option value="0">Nee</mat-option>
              <mat-option value="1">Ja</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select
              formControlName="status"
              [compareWith]="compareFn"
            >
              <mat-option value="">---</mat-option>
              <mat-option
                *ngFor="let status of improvementStatuses; let i = index"
                [value]="status.order"
              >
                {{ status.statusName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Status opvolging</mat-label>
            <mat-select
              formControlName="responseStatus"
              [compareWith]="compareFn"
            >
              <mat-option value="">---</mat-option>
              <mat-option
                *ngFor="
                  let rstatus of improvementResponseStatuses;
                  let j = index
                "
                [value]="j"
              >
                {{ rstatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Dienst</mat-label>
            <mat-select formControlName="themeId">
              <mat-option value="">---</mat-option>
              <mat-option
                *ngFor="let service of sharedService.currentThemesList$ | async"
                [value]="service.id"
              >
                {{ service.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Thema</mat-label>
            <mat-select
              formControlName="subThemeId"
              *ngIf="themesListWithIds"
            >
              <mat-option value="">---</mat-option>
              <mat-option
                *ngFor="
                  let subTheme of themesListWithIds[
                    filterForm?.get('themeId')?.value
                  ]?._embedded?.subThemes;
                  let i = index
                "
                [value]="subTheme.id"
              >
                {{ subTheme?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex-rows">
          <mat-form-field appearance="fill">
            <mat-label>Titel</mat-label>
            <input
              type="text"
              matInput
              formControlName="subject"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Beschrijving</mat-label>
            <input
              type="text"
              matInput
              formControlName="description"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="flex-rows">
        <mat-error [hidden]="!filterFormErrors.notValidDates">
          {{ filterFormErrors.notValidDates }}</mat-error
        >

        <button
          mat-flat-button
          color="accent"
          class="no-grow"
          matBadge="8"
          matBadgePosition="after"
          matBadgeColor="accent"
          [disabled]="!filterForm.valid"
          (click)="onFiltersSubmit()"
        >
          Toepassen
        </button>

        <button
          mat-flat-button
          type="button"
          color="warn"
          class="no-grow"
          matBadge="8"
          matBadgePosition="after"
          matBadgeColor="accent"
          (click)="onFiltersClear()"
        >
          Reset filter
        </button>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <button
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="incidents-list button add-new-incident"
    *ngIf="crudC"
  >
    <mat-icon>add</mat-icon>
    Nieuwe verbetermaatregel
  </button>
</div>

<div
  class="improvements-list-panel kms-list"
  *ngIf="crudR"
>
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="subject">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        >Titel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.subject }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="practice">
      <mat-header-cell
        class="override-cell-width"
        *matHeaderCellDef
        i18n="improvements-list list-header"
        >Praktijk</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="override-cell-width"
      >
        {{ row.content[0]?.value?.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        class="override-cell-width"
        *matHeaderCellDef
        i18n="improvements-list list-header"
        >Beschrijving</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="override-cell-width"
      >
        {{ row.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="effective">
      <mat-header-cell
        class="override-cell-width"
        *matHeaderCellDef
        i18n="improvements-list list-header"
        >Effectief</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        class="override-cell-width"
      >
        <mat-checkbox
          disabled
          [checked]="row.efficient"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="guard">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        class="role-cell"
        >Bewaker
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row._embedded?.guard?.memberName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="executioners">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        class="role-cell"
        >Uitvoerders
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="executioners"
      >
        <ng-container *ngIf="row.executionerMembers">
          <div *ngFor="let executioner of row.executionerMembers">
            <mat-icon *ngIf="executioner.evaluationDone">done</mat-icon>
            <mat-icon *ngIf="!executioner.evaluationDone">clear</mat-icon>
            <span>{{ executioner.memberName }}</span>
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="implementedOn">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        class="role-cell"
        >Implementatiedatum
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.implementedOn | date: 'dd-MM-yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="plannedOn">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        class="role-cell"
        >Evaluatiedatum
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.plannedOn | date: 'dd-MM-yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="evaluationDone">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        class="role-cell"
        >Evaluatie voldaan
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="status-icon"
      >
        <mat-icon *ngIf="row.completedOn">done</mat-icon>
        <mat-icon *ngIf="!row.completedOn">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="responseStatus">
      <mat-header-cell
        *matHeaderCellDef
        i18n="improvements-list list-header"
        class="role-cell"
        >Status opvolging
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-chip-list
          selectable="false"
          *ngIf="row.responseStatus || row.responseStatus === 0"
        >
          <mat-chip
            color="{{
              ['accent', 'warn', 'accent', 'warn'][row.responseStatus]
            }}"
            defaultColor="primary"
            disableRipple="true"
            selected="true"
            value="{{ row.responseStatus }}"
            class="improvement-response-status improvement-response-status-{{
              row.responseStatus
            }}"
          >
            {{ improvementResponseStatuses[row.responseStatus] }}
          </mat-chip>
        </mat-chip-list>
        <p *ngIf="!row.responseStatus && row.responseStatus !== 0">---</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell
        [ngClass]="{ 'override-cell-width': isReviewPage }"
        *matHeaderCellDef
        i18n="improvements-list list-header"
        >Status</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'override-cell-width': isReviewPage }"
      >
        <mat-chip-list
          selectable="false"
          *ngIf="row.status || row.status === 0"
        >
          <mat-chip
            disableRipple="true"
            value="{{ row.status }}"
            class="is-status improvement-status improvement-status-{{
              row.status
            }}"
          >
            {{ getImprovementStatus(row.status) }}
          </mat-chip>
        </mat-chip-list>
        <p *ngIf="!row.status && row.status !== 0">---</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
      >
        <button
          mat-icon-button
          color="warn"
          (click)="onDeleteIntent(row, $event, deleteDialog)"
          *ngIf="crudD && row.status !== 1"
        >
          <mat-icon>unpublished</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          (click)="onEditIntent(row, $event)"
          [ngClass]="{ disabled: tableInEditMode.isEditMode }"
          *ngIf="crudR"
        >
          <mat-icon>create</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="
        isReviewPage ? reviewPagecolumnsToDisplay : columnsToDisplay
      "
    ></mat-header-row>
    <mat-row
      *matRowDef="
        let row;
        columns: isReviewPage ? reviewPagecolumnsToDisplay : columnsToDisplay;
        let i = index
      "
      [id]="row.id"
      [ngClass]="{
        'has-error': row.hasError,
        'edit-mode': row.editMode,
        'cancelled': row.status === 1
      }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
