/** @format */

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  HostBinding,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { User } from '../../models/user';
import { Member } from '../../models/member';
import { UserRights } from '../../models/user-rights';
import { Router } from '@angular/router';
import { UserValidatorService } from '../../services/user-validator.service';
import { malCApp } from '@iq/ng-core';
import { Subscription } from 'rxjs';
import { MenuTogglerService } from '../../services/menu-toggler.service';
import { MatSidenav } from '@angular/material/sidenav';

/**
 *  The main component of the application for authenticated users.
 *
 * @export
 * @class       MainContainerComponent
 * @extends     {malCApp}
 * @implements  {OnInit}
 * @implements  {OnDestroy}
 */
@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
})
export class MainContainerComponent
  extends malCApp
  implements OnInit, OnDestroy {
  readonly topNavManuItems = [
    {
      icon: 'action_key',
      link: '/graphs',
      title: 'Monitor',
    },
    {
      icon: 'trending_up',
      link: '/pmr',
      permission: 'READ_PMR',
      title: 'Periodieke Management Review',
    },
    {
      icon: 'edit_calendar',
      link: '/year-planning',
      permission: 'READ_YEARPLAN',
      title: 'Jaarplan',
    },
    // for the future development
    {
      icon: 'manage_accounts',
      link: '/users',
      permission: 'READ_TEAM',
      title: 'Gebruikers',
    },
  ];

  /**
   *  The list of items to display in the menu for the user WITH org admin rights.
   *
   * @memberof    MainContainerComponent
   */
  orgAdminMenuItems = [];

  /**
   *  The list of items to display in the menu for the user WITH team admin rights.
   *
   * @memberof    MainContainerComponent
   */
  teamAdminMenuItems = [];

  /**
   *  The currentUser.
   *
   * @memberof    MainContainerComponent
   */
  currentUser: User;

  /**
   *  The subscription of currentUser
   *
   * @type        {Subscription}
   * @memberof    MainContainerComponent
   */
  currentUserSubscription: Subscription;

  /**
   *  The list of members that user is
   *
   * @memberof MainContainerComponent
   */
  membersList: Array<Member>;

  /**
   *  The list of items to display in the menu for the user WITHOUT admin rights.
   *
   * @memberof MainContainerComponent
   */
  memberMenuItems = [];

  /**
   *  The final list of items to display in the menu
   *
   * @memberof MainContainerComponent
   */
  menuItems: Array<any>;

  /**
   *  The currentUser.
   *
   * @memberof MainContainerComponent
   */
  selectedTeamId: string;

  /**
   *  The userRights object
   *
   * @memberof MainContainerComponent
   */
  userRights: UserRights;

  isGraphLinkVisible = false;

  /**
   *  The subscription of userRights
   *
   * @type        {Subscription}
   * @memberof    MainContainerComponent
   */
  userRightsSubscription: Subscription;

  /**
   *  Settings of the userPanel in the topmenu
   *
   * @memberof MainContainerComponent
   */
  userPanelSettings = {
    prefixMsg: '',
    user: '',
    links: `
    [ {"path": "/auth/login",
        "data": {
          "group": "user",
          "level": 0,
          "name": "Uitloggen",
          "safeLevel": 0
        }
      },
      {"path": "/team-choose",
        "data": {
          "group": "user",
          "level": 0,
          "name": "Change team",
          "safeLevel": 0
        }
      }
    ]`,
  };
  isOpen = false;

  filteredTopNavButtons = [];

  /**
   * Creates an instance of MainContainerComponent.
   *
   * @param     {router}        Router     Activated router.
   * @memberof  MainContainerComponent
   */
  constructor(
    private router: Router,
    private userValidatorService: UserValidatorService,
    private ref: ChangeDetectorRef,
    private menuTogglerService: MenuTogglerService,
    private elRef: ElementRef,
  ) {
    super();
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof MainContainerComponent
   */
  ngOnInit() {
    if (localStorage.getItem('userRights')) {
      this.userRights = JSON.parse(localStorage.getItem('userRights'));
      const userPermissions =
        this.userRights.currentMember.teamRole.permissions;
      this.filteredTopNavButtons = this.topNavManuItems.filter(
        (topNavMenuItem) =>
          (topNavMenuItem.permission &&
            userPermissions.includes(topNavMenuItem.permission)) ||
          !topNavMenuItem.permission,
      );

      this.userPanelSettings.user = this.userRights.currentMember.memberName;
      this.onRightsChange(this.userRights);

      const allowedRoles = [
        'MT_MEMBER',
        'QUALITY_MANAGER',
        'TEAM_ADMIN',
        'ORG_ADMIN',
        'ORG_COACH',
        'TEAM_GROUP_ADMIN',
      ];
      this.isGraphLinkVisible = allowedRoles.includes(
        this.userRights.currentMember.teamRole.name,
      );
    }

    // Subscription of the current user rights
    this.userRightsSubscription =
      this.userValidatorService.currentUserRights$.subscribe((userrights) => {
        this.userRights = userrights;
        this.userPanelSettings.user = this.userRights.currentMember.memberName;

        this.onRightsChange(this.userRights);
        this.ref.detectChanges();
      });

    //  Set initial values of rights and user and create menuItems in the onRightsChange function
    //
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    //  Subscribe currentUser to detect changes
    this.currentUserSubscription =
      this.userValidatorService.currentUser$.subscribe((user) => {
        this.currentUser = user;
        this.ref.detectChanges();
      });

    this.menuTogglerService.change.subscribe((isOpen) => {
      this.elRef.nativeElement
        .querySelector('.mat-sidenav-content .mat-button > div')
        .click();

      this.elRef.nativeElement
        .querySelector('.team-groups-toogler > mat-icon')
        .click();
      this.isOpen = isOpen;
    });
  }

  /**
   *  Responds to the user member select intent.
   *
   * @param       {Member}        member    The selected member
   * @memberof    MainContainerComponent
   */
  onRightsChange(newRights: UserRights) {
    this.selectedTeamId = newRights.currentTeamId;

    //  Array.from creates new instance from exsisting array
    //  With that we prevent splicing the this.router.config Array
    //
    this.menuItems = $.extend(true, [], this.router.config);

    //  The name of groups that menu items are filtered by
    const groupName = 'kms';
    const noRightsGroupName = 'kms-noread';

    //  Change the group name of the items that have no read rights
    this.menuItems.forEach((route, index) => {
      this.checkReadRights(newRights, route, groupName, noRightsGroupName);

      if (route.children) {
        route.children.forEach((child, ind) => {
          this.checkReadRights(newRights, child, groupName, noRightsGroupName);
        });
      }
    });


    this.userPanelSettings.links = `
    [ {"path": "/auth/login",
        "data": {
          "group": "user",
          "level": 0,
          "name": "Uitloggen",
          "safeLevel": 0
        }
      },
      {"path": "/team-choose",
        "data": {
          "group": "user",
          "level": 0,
          "name": "Praktijk: ${newRights.currentTeamName}",
          "safeLevel": 0
        }
      }
    ]`;
  }

  /**
   *  Check if the current user has a read rights to the specific route
   *
   * @param {UserRights} newRights
   * @param {*}          route
   * @param {string}     groupName
   * @param {string}     noRightsGroupName
   * @memberof MainContainerComponent
   */
  checkReadRights(
    newRights: UserRights,
    route: any,
    groupName: string,
    noRightsGroupName: string,
  ) {
    if (
      route.data &&
      (route.data.group === groupName || route.data.group === noRightsGroupName)
    ) {
      let groupDef = noRightsGroupName;

      if (route.data.readRights && route.data.readRights.length) {
        for (const item of route.data.readRights) {
          if (
            newRights.currentMember &&
            newRights.currentMember.teamRole.permissions
          ) {
            if (
              newRights.currentMember.teamRole.permissions.indexOf(item) > -1
            ) {
              groupDef = groupName;

              break;
            }
          }
        }
      } else {
        groupDef = groupName;
      }

      route.data.group = groupDef;
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof    MainContainerComponent
   */
  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    this.userRightsSubscription.unsubscribe();
  }

  goToHandbook(): void {
    window.open('https://handboek.inkwala.nl/', '_blank');
  }
}
