<!-- @format -->

<h1
  class="mat-h1"
  i18n="dashboard page-title"
>
  Monitor
</h1>

<div class="main-content change-on-mobile">
  <router-outlet></router-outlet>
</div>
