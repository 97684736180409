<!-- @format -->

<mat-accordion
  class="flex-columns mobile-match"
  displayMode="flat"
  multi
  *ngIf="dashboardCounts"
  fxLayout="row"
  fxLayout.cmb="column"
  fxLayoutAlign="start none"
>
  <ng-container *ngFor="let category of dashboardCategories; let ind = index">
    <mat-expansion-panel
      class="dashboard-columns-container mat-elevation-z0"
      *ngIf="checkPermissions(category)"
      fxFlex="100%"
      fxFlex.cmb="100%"
      [expanded]="!mobileQuery.matches"
      [hideToggle]="!mobileQuery.matches"
      [disabled]="!mobileQuery.matches"
    >
      <mat-expansion-panel-header
        class="dashboard-column-header"
        [collapsedHeight]="mobileQuery.matches ? '40px' : '50px'"
        [expandedHeight]="mobileQuery.matches ? '40px' : '50px'"
      >
        {{ category.label }}
      </mat-expansion-panel-header>
      <div class="dashboard-column-content">
        <ng-container *ngIf="category.cards">
          <ng-container *ngFor="let card of category.cards">
            <app-dashboard-single-card
              *ngIf="checkPermissions(card)"
              [cardInfo]="card"
            ></app-dashboard-single-card>
          </ng-container>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
