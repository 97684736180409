/** @format */

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Task } from '../../models/task';
import { MatDialog } from '@angular/material/dialog';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import * as moment from 'moment';

/**
 *  Display the group of tasks
 *
 * @export
 * @class TasksGroupContainerComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-tasks-group-container',
  templateUrl: './tasks-group-container.component.html',
  styleUrls: ['./tasks-group-container.component.scss'],
})
export class TasksGroupContainerComponent
  implements OnInit, OnChanges, OnDestroy
{
  /**
   *  The min date of the period in the group.
   *
   * @type                    {string}
   * @memberof                TasksGroupContainerComponent
   */
  @Input() fromDate: string;

  /**
   *  The max date of the period in the group.
   *
   * @type                    {string}
   * @memberof                TasksGroupContainerComponent
   */
  @Input() toDate: string;

  /**
   *  The list of tasks to show.
   *
   * @type                    {Task[]}
   * @memberof                TasksGroupContainerComponent
   */
  @Input() tasksList: Task[];

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                TasksGroupContainerComponent
   */
  currentUserRights: UserRights;

  /**
   *  The list of cyclic tasks to show
   *
   * @type                    {Task[]}
   * @memberof                TasksGroupContainerComponent
   */
  cyclicTasksList: Task[];

  /**
   *  The list of planned tasks to show.
   *
   * @type                    {Task[]}
   * @memberof                TasksGroupContainerComponent
   */
  plannedTasksList: Task[];

  /**
   * Creates an instance of TasksGroupContainerComponent.
   *
   * @memberof TasksGroupContainerComponent
   */
  constructor(
    private userValidatorService: UserValidatorService,
    private ref: ChangeDetectorRef,
  ) {}

  /**
   * Respond when Angular (re)sets data-bound input properties.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   *
   * @memberof TasksGroupContainerComponent
   */
  ngOnChanges() {
    this.getTasks();

    this.ref.detectChanges();
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof TasksGroupContainerComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.getTasks();
  }

  /**
   *  Get the list of member's tasks
   *
   * @memberof TasksGroupContainerComponent
   */
  getTasks() {
    this.cyclicTasksList = [];
    this.plannedTasksList = [];

    for (const task of this.tasksList) {
      //  Daily tasks - show the late tasks, or planned for today or in the past
      if (this.toDate === this.fromDate) {
        if (task.plannedOn && moment(task.plannedOn) <= moment(this.toDate)) {
          this.plannedTasksList.push(task);
        } else if (
          !task.plannedOn &&
          moment(task.periodEndDate) <= moment(this.toDate)
        ) {
          this.cyclicTasksList.push(task);
        }
      } else if (!this.toDate) {
        // The toDate of the frequency does not exist. Should show all the tasks

        // The rest of the tasks, show if they are planned for that period or are still in the range of start-end date
        if (task.plannedOn && moment(task.plannedOn) > moment(this.fromDate)) {
          this.plannedTasksList.push(task);
        } else if (
          !task.plannedOn &&
          moment(task.periodEndDate) > moment(this.fromDate)
        ) {
          this.cyclicTasksList.push(task);
        }
      } else {
        // The rest of the tasks, show if they are planned for that period or are still in the range of start-end date
        if (
          task.plannedOn &&
          moment(task.plannedOn) <= moment(this.toDate) &&
          moment(task.plannedOn) > moment(this.fromDate)
        ) {
          this.plannedTasksList.push(task);
        } else if (
          !task.plannedOn &&
          moment(task.periodEndDate) <= moment(this.toDate) &&
          moment(task.periodEndDate) > moment(this.fromDate)
        ) {
          this.cyclicTasksList.push(task);
        }
      }
    }
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof TasksGroupContainerComponen
   */
  ngOnDestroy() {
    this.ref.detach();
  }
}
