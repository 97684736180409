/** @format */

import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ImprovementGroup } from '../../models/improvement-group';
import { MatDialog } from '@angular/material/dialog';
import { ImprGroupDetailsComponent } from '../../dialogs/impr-group-details/impr-group-details.component';
import { Theme } from '../../models/theme';
import { SubTheme } from '../../models/subtheme';
import { AddBestPracticeComponent } from '../../dialogs/add-best-practice/add-best-practice.component';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';

/**
 *  The improvement group component
 *
 * @export
 * @class ImprovementGroupComponent
 */
@Component({
  selector: 'app-improvement-group',
  templateUrl: './improvement-group.component.html',
  styleUrls: ['./improvement-group.component.scss'],
})
export class ImprovementGroupComponent implements OnInit {
  /**
   *  The improvement group value sent by parent component.
   *
   * @type            {ImprovementGroup}
   * @memberof        ImprovementGroupComponent
   */
  @Input() improvementGroup: ImprovementGroup;

  /**
   *  The theme value sent by parent component.
   *
   * @type            {ImprovementGroup}
   * @memberof        ImprovementGroupComponent
   */
  @Input() theme: Theme;

  /**
   *  The list of subThemes within a team
   *
   * @memberof        ImprovementGroupComponent
   */
  @Input() subThemesList: SubTheme[];

  /**
   *  The groupChanged output sent to the parent element
   *
   * @memberof ImprovementGroupComponent
   */
  @Output() groupChanged = new EventEmitter<{ changed: boolean }>();

  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                ImprovementGroupComponent
   */
  currentUserRights: UserRights;

  /**
   * Creates an instance of ImprovementGroupComponent.
   *
   * @param      {MatDialog}                 dialog  the MatDialog instance
   * @memberof   ImprovementGroupComponent
   */
  constructor(
    private dialog: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprovementGroupComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
  }

  /**
   *  Respond to the selection intent on the group.
   *
   * @memberof  ImprovementGroupComponent
   */
  onGroupSelect() {
    const dialogRef = this.dialog.open(ImprGroupDetailsComponent, {
      data: {
        improvementGroup: this.improvementGroup,
        theme: this.theme,
        subThemesList: this.subThemesList,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'impr-group-details-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.groupChanged.emit(results);
      }
    });
  }

  /**
   *  Respond to the user add best practice intent.
   *
   * @param           {Event}             event
   * @returns
   * @memberof        ImprovementGroupComponent
   */
  addToBestPractice(event: Event) {
    event.stopImmediatePropagation();

    if (
      this.improvementGroup.hasBestPractice ||
      this.currentUserRights.organisationAdmin
    ) {
      return;
    }

    const dialogRefBestPractice = this.dialog.open(AddBestPracticeComponent, {
      data: { improvementGroup: this.improvementGroup, theme: this.theme },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'impr-group-details-dialog',
    });

    dialogRefBestPractice.afterClosed().subscribe((results) => {
      if (results.changed) {
        this.groupChanged.emit(results);
      }
    });
  }
}
