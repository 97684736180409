/** @format */

import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { navTabAnimation } from '../../functions/nav-tabs-animation';
import { RouterOutlet } from '@angular/router';

/**
 *  Main container for managing agendas
 *
 * @export
 * @class AgendaComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  animations: [navTabAnimation],
})
export class AgendaComponent implements OnInit {
  /**
   *  The index of selected tab.
   *
   * @memberof        AgendaComponent
   */
  selectedTabInd = 0;

  /**
   *  Creates an instance of AgendaComponent.
   *
   * @memberof AgendaComponent
   */
  constructor() {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof AgendaComponent
   */
  ngOnInit() {}

  /**
   *  Respond when the selected tab changed
   *
   * @param       {MatTabChangeEvent} [event]
   * @memberof    AgendaComponent
   */
  tabSelectionChanged(event?: MatTabChangeEvent) {
    this.selectedTabInd = event.index;
  }

  /**
   * Check the animation name in the route data
   *
   * @param    {RouterOutlet} outlet
   * @returns
   * @memberof AgendaComponent
   */
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
