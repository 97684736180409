/** @format */

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';
import { PMRItem } from '../models/pmr-item';
import { catchError } from 'rxjs/operators';

const apiPath = environment.apiPath;

/**
 *  Service for managing the default pmr items.
 *
 * @export
 * @class         PmrItemsService
 * @extends       {AbstractRestService<PMRItem>}
 */
@Injectable()
export class PmrItemsService extends AbstractRestService<PMRItem> {
  /**
   * Creates an instance of PmrItemsService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      PmrItemsService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}pmr_items`, 'pmr_items');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<PMRItem>}
   * @memberof        PmrItemsService
   */
  protected mapToModel(json: any): PagedResult<PMRItem> {
    //  If the PMRItem array doesn't exist, create an empty PMRItem object
    let payload = [];

    if (json._embedded) {
      payload = <PMRItem[]>json._embedded.defaultPMRitems;
    }

    return new PagedResult<PMRItem>(payload, <PageSetting>json.page);
  }

  /**
   *  get single improvement with the teamId param
   *
   * @param       {string}      aModelId  The id of the improvement
   * @param       {string}      teamId    The id of the team
   * @returns
   * @memberof    PmrItemsService
   */
  getSingleItem(aModelId: string, teamId: string): Observable<PMRItem> {
    return this.http
      .get<PMRItem>(`${this.actionUrl}/${aModelId}?teamId=${teamId}`)
      .pipe(catchError((err) => _throw(err)));
  }

  /**
   *  Put PMRItem with the teamId param
   *
   * @param       {BaseModel}   aModel  The posted model
   * @param       {string}      teamId  The id of the team
   * @returns
   * @memberof    PmrItemsService
   */
  put(aModel: any): Observable<PMRItem> {
    return this.http
      .put<PMRItem>(
        `${this.actionUrl}/${aModel.id}?team-id=${aModel.teamId}`,
        aModel,
      )
      .pipe(catchError((err) => _throw(err)));
  }
}
