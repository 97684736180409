<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-incident-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe agenda toevoegen
</h2>
<h2
  class="title-text"
  i18n="add-edit-incident-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen agenda
</h2>

<mat-dialog-content>
  <div class="agenda-name">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Naam</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          required
        />
        <mat-error [hidden]="!formErrors.name">{{ formErrors.name }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="agenda-description">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Omschrijving</mat-label>
        <textarea
          matInput
          formControlName="description"
          matTextareaAutosize
          matAutosizeMinRows="5"
          resizeToFitContent
        ></textarea>
      </mat-form-field>
    </form>
  </div>

  <mat-accordion
    displayMode="flat"
    multi
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Agendapunten </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="subjects">
        <div class="children-expansion-container">
          <mat-accordion
            displayMode="flat"
            multi
            cdkDropList
            (cdkDropListDropped)="drop($event)"
          >
            <mat-expansion-panel
              class="mat-elevation-z0 draggable-items insertable-item"
              #expPanel
              *ngFor="let subject of subjectsList; let ind = index"
              [ngClass]="{
                'is-new': subject.temporaryFields?.isNew,
                'removed': subject.temporaryFields?.removed,
                'has-error': subject.temporaryFields?.invalid
              }"
              [disabled]="subject.temporaryFields?.removed"
              [expanded]="subject.temporaryFields?.expanded"
              cdkDrag
              [cdkDragDisabled]="
                subjectsList.length < 2 ||
                subject.temporaryFields?.isNew ||
                expPanel.expanded
              "
              [cdkDragData]="subject"
              (opened)="subject.expanded = true"
              (closed)="subject.expanded = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon *ngIf="subject.temporaryFields?.invalid"
                    >error</mat-icon
                  >

                  <span>{{
                    subject.name
                      ? subject.name
                      : 'Nieuwe agendapunt ' + (ind + 1)
                  }}</span>

                  <div class="cud-icons">
                    <button
                      mat-icon-button
                      color="accent"
                      *ngIf="subject.temporaryFields?.removed"
                      (click)="onChildItemRestore(subject, 0, $event, ind)"
                    >
                      <mat-icon>restore_from_trash</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      color="warn"
                      *ngIf="
                        !subject.temporaryFields?.removed ||
                        subject.temporaryFields?.isNew
                      "
                      (click)="
                        onChildItemDelete(subject, 0, $event, expPanel, ind)
                      "
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <app-agenda-subject
                  [editedItem]="subject"
                  (itemChanged)="onChildItemChange($event, 0, ind, expPanel)"
                >
                </app-agenda-subject>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="flex-rows">
          <button
            mat-flat-button
            color="accent"
            class="no-grow"
            (click)="onChildItemAdd($event, 0)"
          >
            <mat-icon>forms_add_on</mat-icon>
            Agendapunt toevoegen
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup?.invalid"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel(true)"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="
      !(formChanged || childChanged) || newFormGroup?.invalid || childInvalid
    "
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
