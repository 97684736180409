/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { SubTheme } from '../../models/subtheme';
import { ImprovementItem } from '../../models/improvement_item';
import { Task } from '../../models/task';

import * as moment from 'moment';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';

/**
 *  Show all the tasks in the selected SubTheme.
 *
 * @export
 * @class DashboardTasksContainerComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-dashboard-tasks-container',
  templateUrl: './dashboard-tasks-container.component.html',
  styleUrls: ['./dashboard-tasks-container.component.scss'],
})
export class DashboardTasksContainerComponent implements OnInit {
  /**
   *  The task to show
   *
   * @type                    {SubTheme}
   * @memberof                DashboardTasksContainerComponent
   */
  @Input() subTheme: SubTheme;

  /**
   *  The list of improvement items
   *
   * @type                    {ImprovementItem[]}
   * @memberof                DashboardTasksContainerComponent
   */
  @Input() improvementItems: ImprovementItem[];

  /**
   *  The list of tasks
   *
   * @type                    {Task[]}
   * @memberof                DashboardTasksContainerComponent
   */
  @Input() tasks: Task[];

  /**
   *  The list of delayed tasks
   *
   * @type                    {Task[]}
   * @memberof                DashboardTasksContainerComponent
   */
  @Input() delayedTasks: Task[];

  /**
   *  The total number of tasks
   *
   * @type                    {number}
   * @memberof                DashboardTasksContainerComponent
   */
  @Input() tasksCount: number;

  /**
   *  Current date
   *
   * @memberof                DashboardTasksContainerComponent
   */
  today = moment().format('YYYY-MM-DD');

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              DashboardTasksContainerComponent
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the user is current subTheme owner
   *
   * @memberof DashboardTasksContainerComponent
   */
  currentSubThemeOwner = false;

  /**
   * Creates an instance of DashboardTasksContainerComponent.
   *
   * @memberof DashboardTasksContainerComponent
   */
  constructor(private userValidatorService: UserValidatorService) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof DashboardTasksContainerComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.subTheme && this.currentUserRights.subThemeOwnership.length > 0) {
      for (const subtheme of this.currentUserRights.subThemeOwnership) {
        if (subtheme.id === this.subTheme.id) {
          this.currentSubThemeOwner = true;
        }
      }
    }
  }
}
