/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { PageSetting } from '@iq/ng-core';
import { LampService } from '../../services/lamp.service';

/**
 * The overview container.
 *
 * @export
 * @class       OverviewComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  /**
   *  The subscription of the score items list.
   *
   * @type                {Subscription}
   * @memberof            OverviewComponent
   */
  subThemesListSubs: Subscription;

  /**
   * Creates an instance of OverviewComponent.
   *
   * @memberof OverviewComponent
   */
  constructor(
    private lampService: LampService,
    private sharedService: SharedService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof OverviewComponent
   */
  ngOnInit() {
    this.subThemesListSubs = this.lampService
      .getSubThemesMatrix(undefined, undefined, new PageSetting(1000, 0, 0, 0))
      .subscribe((subthemes) => {
        this.sharedService.newSubThemesList(subthemes);
      });
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof OverviewComponent
   */
  ngOnDestroy() {
    this.subThemesListSubs.unsubscribe();
  }
}
