/**
 * Default model for the BestPracticeLike
 *
 * @format
 * @export
 * @class BestPracticeLike
 */

export class BestPracticeLike {
  /**
   *  The endpoint to the API
   *
   * @memberof        BestPracticeLike
   */
  actionUrl = 'bestpractices-likes';

  /**
   *  The internal id of the BestPracticeLike
   *
   * @type            {string}
   * @memberof        BestPracticeLike
   */
  id: string;

  /**
   *  The id of bestPractice
   *
   * @type            {string}
   * @memberof        BestPracticeLike
   */
  bestPracticeId: string;
}
