/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarviqMatTableInlineCrudComponent, PageSetting } from '@iq/ng-core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WarningDeleteDialogComponent } from '../../dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { MeetingsService } from '../../services/meetings.service';
import { Meeting, MEETING_STATUS } from '../../models/meeting';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';

import * as moment from 'moment';
import { AddEditAgendaMeetingComponent } from '../../dialogs/add-edit-agenda-meeting/add-edit-agenda-meeting.component';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

/**
 *  Display an overview of agenda meetings.
 *
 * @export
 * @class       AgendaMeetingsListComponent
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-agenda-meetings-list',
  templateUrl: './agenda-meetings-list.component.html',
  styleUrls: ['./agenda-meetings-list.component.scss'],
})
export class AgendaMeetingsListComponent
  extends MarviqMatTableInlineCrudComponent<Meeting>
  implements OnInit, OnDestroy
{
  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                AgendaMeetingsListComponent
   */
  columnsToDisplay = ['beginTime', 'title', 'status', 'noteExist', 'CUD'];

  /**
   *  The current user rights.
   *
   * @type                  {UserRights}
   * @memberof              AgendaMeetingsListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The default values of the new Form.
   *
   * @memberof                AgendaMeetingsListComponent
   */
  emptyFormValues = {
    beginTime: '',
    consultationTypeId: [null],
    consultationTypeName: [''],
    endTime: '',
    meetingMembers: [[]],
    note: [''],
    ownerMemberId: '',
    ownerMemberName: '',
    status: 0,
    subjects: [[]],
    taskTemplateIds: [null],
    title: ['', Validators.required],
    teamId: '',

    beginDate: [moment().format('YYYY-MM-DD'), Validators.required],
    beginHour: [
      moment().add(1, 'h').minutes(0).format('HH:mm'),
      Validators.required,
    ],
    endHour: [
      moment().add(3, 'h').minutes(0).format('HH:mm'),
      Validators.required,
    ],
    duration: [moment().hours(2).minutes(0).format('HH:mm')],
  };

  /**
   *  The object of form validation errors.
   *
   * @memberof                TeamsListComponent
   */
  formErrors = {
    title: '',
    beginDate: '',
    notValidDates: '',
    endDate: '',
  };

  /**
   *  The filters form
   *
   * @type                    {FormGroup}
   * @memberof                AgendaMeetingsListComponent
   */
  filterForm: UntypedFormGroup;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof                AgendaMeetingsListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The list of meeting statuses
   *
   * @memberof AgendaMeetingsListComponent
   */
  meetingStatuses = MEETING_STATUS;

  /**
   * The pager info received from the HTTP request
   *
   * @memberof                AgendaMeetingsListComponent
   */
  usersPageSetting: PageSetting = new PageSetting(25, 0, 0, 0);

  /**
   * The form validation messages
   *
   * @memberof AgendaMeetingsListComponent
   */
  validationMessages = {
    beginDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    endDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    notValidDates: 'Begindatum kan niet later zijn dan de einddatum',
  };

  /**
   * Apply user rights
   *
   * @memberof AgendaMeetingsListComponent
   */
  public crudC = false;
  public crudR = false;
  public crudU = false;
  public crudD = false;

  /**
   *  True/false if the user can update only it's own meetings
   *
   * @memberof AgendaMeetingsListComponent
   */
  crudUpdateOwn = false;

  /**
   *  Creates an instance of AgendaMeetingsListComponent.
   *
   * @param    {MeetingsService}      agendaMeetingsService
   * @param    {FormBuilder}          formBuilder
   * @param    {MatDialog}            dg
   * @param    {UserValidatorService} userValidatorService
   * @memberof AgendaMeetingsListComponent
   */
  constructor(
    private agendaMeetingsService: MeetingsService,
    private formBuilder: UntypedFormBuilder,
    private dg: MatDialog,
    private userValidatorService: UserValidatorService,
  ) {
    super(agendaMeetingsService, formBuilder, dg);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof      AgendaMeetingsListComponent
   */
  ngOnInit() {
    this.sortValues = { beginTime: 'desc' };

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    if (this.currentUserRights.currentTeamId) {
      this.emptyFormValues.teamId = this.currentUserRights.currentTeamId;

      this.queryParams.push({
        name: 'teamId',
        value: this.currentUserRights.currentTeamId,
      });
    }

    if (this.currentUserRights.currentMember) {
      this.emptyFormValues.ownerMemberId =
        this.currentUserRights.currentMember.id;
      this.emptyFormValues.ownerMemberName =
        this.currentUserRights.currentMember.memberName;
    }

    this.dialogParameters.data.message = `Weet je zeker dat je dit overleg wilt annuleren?`;

    this.checkPermissions();

    if (this.crudR) {
      this.createFilterForm();
      this.getRows();
    }
  }

  /**
   *  Validate if the endDate is earlier than startDate
   *
   * @param     {FormGroup}                     group
   * @returns
   * @memberof  AgendaMeetingsListComponent
   */
  checkDates(group: UntypedFormGroup) {
    if (
      group.controls.endDate.value &&
      group.controls.endDate.value < group.controls.beginDate.value
    ) {
      return { notValidDates: true };
    }
  }

  /**
   *  Check the current user permissions
   *
   * @memberof AgendaMeetingsListComponent
   */
  checkPermissions() {
    const perm = this.currentUserRights.currentMember.teamRole;

    if (perm.permissions) {
      // READ_AGENDA
      // DELETE_AGENDA
      // CREATE_AGENDA
      // UPDATE_AGENDA
      // READ_ALL_AGENDA
      // DELETE_ALL_AGENDA
      // UPDATE_ALL_AGENDA
      if (
        perm.permissions.indexOf('READ_AGENDA') > -1 ||
        perm.permissions.indexOf('READ_ALL_AGENDA') > -1
      ) {
        this.crudR = true;
      }
      if (
        perm.permissions.indexOf('DELETE_AGENDA') > -1 ||
        perm.permissions.indexOf('DELETE_ALL_AGENDA') > -1
      ) {
        this.crudD = true;
      }
      if (perm.permissions.indexOf('CREATE_AGENDA') > -1) {
        this.crudC = true;
      }
      if (perm.permissions.indexOf('UPDATE_AGENDA') > -1) {
        this.crudUpdateOwn = true;
      }

      if (perm.permissions.indexOf('UPDATE_ALL_AGENDA') > -1) {
        this.crudU = true;
      }
    }
  }

  /**
   *  Check if the current user is invited/attended meeting
   *
   * @param {Meeting} row
   * @returns
   * @memberof AgendaMeetingsListComponent
   */
  checkAttenderRights(row: Meeting) {
    if (row && row.meetingMembers && row.meetingMembers.length) {
      for (const member of row.meetingMembers) {
        if (member.memberId === this.currentUserRights.currentMember.id) {
          if (member.invited || member.attended || member.notesEnabled) {
            return true;
          }
        }
      }
    }
  }

  /**
   * Create the form for the filtering
   *
   * @memberof AgendaMeetingsListComponent
   */
  createFilterForm() {
    this.filterForm = this.formBuilder.group(
      {
        title: '',
        beginDate: '',
        endDate: '',
      },
      { validator: this.checkDates },
    );

    this.filterForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((changes) => {
        for (const field of Object.keys(this.formErrors)) {
          const control = this.filterForm.get(field);
          this.formErrors[field] = '';

          if (control && !control.valid) {
            const messages = this.validationMessages[field];

            for (const key of Object.keys(control.errors)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }

          if (this.filterForm.errors && this.filterForm.errors[field]) {
            this.formErrors[field] += this.validationMessages[field] + ' ';
          }

          if (this.filterForm.valid && field !== 'notValidDates') {
            if (field === 'beginDate' && changes[field] instanceof moment) {
              const dateField = changes[field].format('YYYY-MM-DD HH:mm');
              this.filterValues[field] = dateField;
            } else if (
              field === 'endDate' &&
              changes[field] instanceof moment
            ) {
              const endDateField = changes[field]
                .hours(23)
                .minutes(59)
                .format('YYYY-MM-DD HH:mm');
              this.filterValues[field] = endDateField;
            } else {
              this.filterValues[field] = changes[field];
            }
          }
        }

        if (this.filterForm.valid) {
          this.usersPageSetting.number = 0;
          this.paginator.firstPage();
          this.getRows();
        }
      });
  }

  /**
   *  Respond to the user editing a single item.
   *
   * @param       {*}                         row       The current row in the data tabl
   * @param       {Event}                     [event]   The (`click`) event signalling the user's intent.
   * @memberof    AgendaMeetingsListComponent
   */
  onEditIntent(row?: any, event?: Event, fullscreen?: boolean) {
    let isNew = true;
    let formValues = <any>{};

    if (row) {
      isNew = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }

    if (!row) {
      formValues = this.emptyFormValues;
    } else {
      const beginTime: any = moment(
        row.beginTime ? row.beginTime : moment(),
        'YYYY-MM-DD HH:mm',
      );
      const endTime: any = moment(
        row.endTime ? row.endTime : row.beginTime,
        'YYYY-MM-DD HH:mm',
      );

      const durationSet = moment.duration(endTime - beginTime);
      const duration = moment()
        .hours(durationSet.hours())
        .minutes(durationSet.minutes())
        .format('HH:mm');

      const editedRowFormValues = {
        id: [row.id],
        beginTime: row.beginTime,
        consultationTypeId: [
          row.consultationTypeId ? row.consultationTypeId : null,
        ],
        consultationTypeName: [
          row.consultationTypeName ? row.consultationTypeName : '',
        ],
        endTime: row.endTime,
        meetingMembers: [row.meetingMembers ? row.meetingMembers : []],
        note: [row.note ? row.note : ''],
        ownerMemberId: row.ownerMemberId,
        ownerMemberName: row.ownerMemberName,
        status: row.status ? row.status : 0,
        subjects: [row.subjects ? row.subjects : []],
        title: [row.title ? row.title : '', Validators.required],
        taskTemplateIds: [row.taskTemplateIds ? row.taskTemplateIds : null],
        teamId: row.teamId ? row.teamId : this.currentUserRights.currentTeamId,

        beginDate: [
          moment(beginTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
          Validators.required,
        ],
        beginHour: [
          moment(beginTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
          Validators.required,
        ],
        endHour: [
          moment(endTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
          Validators.required,
        ],
        duration: [duration],
      };

      formValues = editedRowFormValues;
    }

    const dialogRef = this.dg.open(AddEditAgendaMeetingComponent, {
      data: {
        formValues: formValues,
        isNew: isNew,
        element: row,
        updateRights: { full: this.crudU, own: this.crudUpdateOwn },
        fullscreen: fullscreen,
      },
      disableClose: true,
      height: '900px',
      maxHeight: 'calc(100vh - 120px)',
      width: '1000px',
      panelClass: 'primary-dialog',
    });

    dialogRef.afterClosed().subscribe((results) => {
      if (results && results.changed) {
        this.getRows();
      }
    });
  }

  /**
   * Respond to the clear intent on filters
   *
   * @memberof AgendaMeetingsListComponent
   */
  onFiltersClear() {
    this.filterForm.reset({
      title: '',
      beginDate: '',
      endDate: '',
    });
  }

  /**
   *  Open the notes in the full screen mode
   *
   * @param {*} row
   * @memberof AgendaMeetingsListComponent
   */
  openNotes(row) {
    this.onEditIntent(row, undefined, true);
  }

  /**
   *  Update the MatTable after success post/put/delete request.
   *
   * @param         row                 The current row in the data table
   * @param         results             The API response after success request
   * @memberof      AgendaMeetingsListComponent
   */
  updateTable(row: any, results: any, method?: string) {
    const rowIndex = this.dataSource.data.indexOf(row);

    if (method === 'delete') {
      row.status = 1;
    } else {
      this.dataSource.data[rowIndex] = results;
      if (method === 'post') {
        this.usersPageSetting.totalElements += 1;
      }
    }

    this.tableInEditMode = { isEditMode: false, row: {} };

    //  This is a tricky solution to refresh the material table
    //
    this.dataSource.filter = '';

    delete this.newFormGroup;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof        AgendaMeetingsListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
