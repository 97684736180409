/** @format */

import { Component, OnInit, Inject } from '@angular/core';
import { AddEditAbstractDialog } from '../add-edit-abstract-dialog';
import { TasksTemplate } from '../../models/tasks-template';
import { TasksTemplateService } from '../../services/tasks-template.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { UserValidatorService } from '../../services/user-validator.service';

import * as moment from 'moment';

/**
 *  Add/edit tasks templates dialog
 *
 * @export
 * @class         AddEditTasksTemplateComponent
 * @extends       {AddEditAbstractDialog<TasksTemplate>}
 * @implements    {OnInit}
 */
@Component({
  selector: 'app-add-edit-tasks-template',
  templateUrl: './add-edit-tasks-template.component.html',
  styleUrls: ['./add-edit-tasks-template.component.scss'],
})
export class AddEditTasksTemplateComponent
  extends AddEditAbstractDialog<TasksTemplate>
  implements OnInit
{
  /**
   *  Current date
   *
   * @memberof                AddEditTasksTemplateComponent
   */
  today = moment().format('YYYY-MM-DD');

  /**
   *  The object of form validation errors.
   *
   * @memberof                TeamsListComponent
   */
  formErrors = {
    description: '',
    title: '',
    notValidDates: '',
    frequency: '',
    startDate: '',
    teams: '',
    endDate: '',
    subTheme: '',
    critical: '',
  };

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                TeamsListComponent
   */
  validationMessages = {
    title: {
      required: 'Voer a.u.b. een titel in.',
    },
    frequency: {
      required: 'Voer a.u.b. een frequentie in.',
    },
    startDate: {
      required: 'Voer a.u.b. een begindatum in.',
      matDatepickerParse: 'De waarde is niet correct',
    },
    teams: {
      required: 'Voer a.u.b. een team in.',
    },
    endDate: {
      matDatepickerParse: 'De waarde is niet correct',
    },
    notValidDates: 'Startdatum kan niet later zijn dan de einddatum',
  };

  /**
   *  Creates an instance of AddEditTasksTemplateComponent.
   *
   * @param     {MatDialogRef<AddEditTasksTemplateComponent>}     dialogRefA            The instance of MatDialogRef
   * @param     {*}                                               data                  The data passed from the parent
   * @param     {TasksTemplateService}                            tasksTemplatesService
   * @param     {UserValidatorService}                            userValidatorService
   * @param     {FormBuilder}                                     fbA
   * @param     {SharedService}                                   sharedService         The instance of Shared Service
   * @memberof  AddEditTasksTemplateComponent
   */
  constructor(
    public dialogRefA: MatDialogRef<AddEditTasksTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tasksTemplatesService: TasksTemplateService,
    private userValidatorService: UserValidatorService,
    private fbA: UntypedFormBuilder,
    public sharedService: SharedService,
  ) {
    super(dialogRefA, data, tasksTemplatesService, fbA);
  }

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof        AddEditUserComponent
   */
  ngOnInit() {
    this.initialFormValues = this.data.formValues;
    this.formIsNew = this.data.isNew;

    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.createForm(this.initialFormValues);
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof AddEditTasksTemplateComponent
   */
  createForm(data?: any): void {
    this.newFormGroup = this.fbA.group(data, { validator: this.checkDates });

    this.newFormGroup.valueChanges.subscribe((changes) =>
      this.onFormValueChanged(changes),
    );

    this.onFormValueChanged();
  }

  /**
   *  Validate if the endDate is earlier than startDate
   *
   * @param     {FormGroup}                     group
   * @returns
   * @memberof  AddEditTasksTemplateComponent
   */
  checkDates(group: UntypedFormGroup) {
    if (
      group.controls.endDate.value &&
      group.controls.endDate.value < group.controls.startDate.value
    ) {
      return { notValidDates: true };
    }
  }

  /**
   *  Respond to the user submit intent of the form
   *
   * @memberof    AddEditTasksTemplateComponent
   */
  onSubmit() {
    if (this.newFormGroup.get('startDate').value) {
      const startDate = moment(this.newFormGroup.get('startDate').value).format(
        'YYYY-MM-DD',
      );
      this.newFormGroup.patchValue({ startDate: startDate });
    }

    if (this.newFormGroup.get('endDate').value) {
      const endDate = moment(this.newFormGroup.get('endDate').value).format(
        'YYYY-MM-DD',
      );
      this.newFormGroup.patchValue({ endDate: endDate });
    }

    if (this.formIsNew) {
      this.tasksTemplatesService.post(this.newFormGroup.value).subscribe(
        () => {
          this.formSubmitted = true;
          this.dialogStateChanged = true;
          this.formIsNew = false;

          this.dialogRef.close({ changed: this.dialogStateChanged });
        },

        () => {
          this.formSubmitted = false;
        },
      );
    } else {
      this.tasksTemplatesService.put(this.newFormGroup.value).subscribe(
        () => {
          this.formSubmitted = true;
          this.dialogStateChanged = true;

          this.dialogRef.close({ changed: this.dialogStateChanged });
        },

        () => {
          this.formSubmitted = false;
        },
      );
    }
  }
}
