
/** @format */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-user-dropdown',
  templateUrl: './nav-user-dropdown.component.html',
  styleUrls: ['./nav-user-dropdown.component.scss'],
})
export class NavUserDropdownComponent {
  @Input() userName: string;

  menuOpened = false;

  triggerMenu(): void {
    this.menuOpened = !this.menuOpened;
  }
}
