<!-- @format -->

<h1
  class="mat-h1"
  i18n="incidents page-title"
>
  Meldingen
</h1>

<div class="main-content">
  <nav
    mat-tab-nav-bar
    mat-stretch-tabs
    [disablePagination]="true"
    [tabPanel]="tabPanelIncidents"
  >
    <a
      mat-tab-link
      routerLink="incidents-overview"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      >Overzicht meldingen</a
    >

    <a
      mat-tab-link
      *ngIf="allowedToSee"
      routerLink="incidents-analysis"
      routerLinkActive
      #rlb="routerLinkActive"
      [active]="rlb.isActive"
      >Analyse meldingen</a
    >
  </nav>

  <mat-tab-nav-panel
    #tabPanelIncidents
    [@routeAnimations]="prepareRoute(outlet)"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-tab-nav-panel>
</div>
