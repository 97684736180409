<!-- @format -->

<mat-accordion
  displayMode="flat"
  multi="true"
  *ngIf="subthemesList"
>
  <app-score-items-list
    *ngFor="let subtheme of subthemesList"
    [subthemeItem]="subtheme"
  ></app-score-items-list>
</mat-accordion>
