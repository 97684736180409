/** @format */

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MeetingMember } from '../../models/meeting-member';
import { SharedService } from '../../services/shared.service';
import { Member } from '../../models/member';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';

/**
 *  Show the list of meeting members
 *
 * @export
 * @class       MeetingMembersListComponent
 * @extends     {MarviqMatTableComponent<Meeting>}
 * @implements  {OnInit}
 */
@Component({
  selector: 'app-meeting-members-list',
  templateUrl: './meeting-members-list.component.html',
  styleUrls: ['./meeting-members-list.component.scss'],
})
export class MeetingMembersListComponent implements OnInit, OnDestroy {
  /**
   *  The list of meeting members sent from the parent component
   *
   * @type {MeetingMember[]}
   * @memberof MeetingMembersListComponent
   */
  @Input() meetingMembers: MeetingMember[];

  /**
   *  The id of the meeting
   *
   * @type {string}
   * @memberof MeetingMembersListComponent
   */
  @Input() meetingId: string;

  /**
   * True/false if the current user has an update rights
   *
   * @type {boolean}
   * @memberof MeetingMembersListComponent
   */
  @Input() updateRights: boolean;

  /**
   *  Bind the info to the parent when the itemChange
   *
   * @memberof MeetingMembersListComponent
   */
  @Output() membersChanged = new EventEmitter<boolean>();

  /**
   *  The initial list of attended members
   *
   * @memberof MeetingMembersListComponent
   */
  attendedMembersList = [];

  /**
   *  Selection of attended meeting members;
   *
   * @memberof MeetingMembersListComponent
   */
  attendedSelection = new SelectionModel<MeetingMember>(true, []);

  /**
   *  The list of columns that will be displayed in the MatTable
   *
   * @memberof                MeetingMembersListComponent
   */
  columnsToDisplay = ['memberName', 'invited', 'attended', 'notesEnabled'];

  /**
   *  The rights of the current user
   *
   * @type {UserRights}
   * @memberof MeetingMembersListComponent
   */
  currentUserRights: UserRights;

  /**
   *  The Angular Material data source of the table component
   *
   * @memberof MeetingMembersListComponent
   */
  dataSource = new MatTableDataSource();

  /**
   *  The initial list of invited members
   *
   * @memberof MeetingMembersListComponent
   */
  invitedMembersList = [];

  /**
   *  Selection of invited meeting members;
   *
   * @memberof MeetingMembersListComponent
   */
  invitedSelection = new SelectionModel<MeetingMember>(true, []);

  /**
   *  The clone of the meeting members list
   *
   * @type {MeetingMember[]}
   * @memberof MeetingMembersListComponent
   */
  meetingMembersClone: MeetingMember[];

  /**
   *  The initial list of members with enabled notes
   *
   * @memberof MeetingMembersListComponent
   */
  notesEnabledMembersList = [];

  /**
   *  Selection of notes enabled meeting members;
   *
   * @memberof MeetingMembersListComponent
   */
  notesEnabledMembersSelection = new SelectionModel<MeetingMember>(true, []);

  /**
   *  The subscription of the service
   *
   * @type {Subscription}
   * @memberof MeetingMembersListComponent
   */
  serviceSubscription: Subscription;

  /**
   *  The list of team members
   *
   * @type {Member[]}
   * @memberof MeetingMembersListComponent
   */
  teamMembers: Member[];

  constructor(
    private sharedService: SharedService,
    private userValidatorService: UserValidatorService,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof MeetingMembersListComponent
   */
  ngOnInit() {
    this.dataSource.data = this.meetingMembers;
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();

    this.meetingMembersClone = this.meetingMembers
      ? this.meetingMembers.slice()
      : [];

    if (this.meetingMembers && this.meetingMembers.length) {
      for (const meetingmember of this.meetingMembers) {
        if (meetingmember.invited) {
          this.invitedMembersList.push(meetingmember);
        }

        if (meetingmember.attended) {
          this.attendedMembersList.push(meetingmember);
        }

        if (meetingmember.notesEnabled) {
          this.notesEnabledMembersList.push(meetingmember);
        }
      }

      this.invitedSelection = new SelectionModel<MeetingMember>(
        true,
        this.invitedMembersList,
      );
      this.attendedSelection = new SelectionModel<MeetingMember>(
        true,
        this.attendedMembersList,
      );
      this.notesEnabledMembersSelection = new SelectionModel<MeetingMember>(
        true,
        this.notesEnabledMembersList,
      );
    }

    this.getMembers();
  }

  /**
   *  Get list of team members
   *
   * @memberof MeetingMembersListComponent
   */
  getMembers() {
    this.serviceSubscription =
      this.sharedService.currentTeamMembersList.subscribe((memberslist) => {
        this.teamMembers = memberslist;

        if (!this.meetingMembers) {
          this.meetingMembers = [];
        }

        let formChanged = false;

        for (const member of this.teamMembers) {
          const newMeetingMember: MeetingMember = {
            memberId: member.id,
            memberName: member.memberName,
            attended: false,
            invited: false,
            notesEnabled: false,
          };

          if (this.meetingId) {
            newMeetingMember.meetingId = this.meetingId;
          }

          let exsisting = false;

          for (const meetingmember of this.meetingMembersClone) {
            if (meetingmember.memberId === member.id) {
              exsisting = true;

              break;
            }
          }

          if (!exsisting) {
            if (
              !this.meetingId &&
              member.id === this.currentUserRights.currentMember.id
            ) {
              newMeetingMember.notesEnabled = true;
              newMeetingMember.invited = true;
              this.meetingMembers.push(newMeetingMember);

              this.notesEnabledMembersSelection.toggle(newMeetingMember);
              this.invitedSelection.toggle(newMeetingMember);
            } else {
              this.meetingMembers.push(newMeetingMember);
            }

            formChanged = true;
          }
        }

        this.dataSource.data = this.meetingMembers;

        this.membersChanged.emit(formChanged);
      });
  }

  /**
   *  Respond to the change of the selection model
   *
   * @param    {MatSelectChange}               event
   * @param    {SelectionModel<MeetingMember>} selection
   * @param    {*}                             row
   * @param    {string}                        field
   * @memberof MeetingMembersListComponent
   */
  onSelectionChange(
    event: MatSelectChange,
    selection: SelectionModel<MeetingMember>,
    row: any,
    field: string,
  ) {
    if (selection && row) {
      selection.toggle(row);
    }

    if (field) {
      row[field] = !row[field];

      if (field === 'attended' && row[field] && !row['notesEnabled']) {
        row['notesEnabled'] = true;
        this.notesEnabledMembersSelection.toggle(row);
      }
    }

    this.membersChanged.emit(true);
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/compo
   * nent.
   *
   * @memberof MeetingMembersListComponent
   */
  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
