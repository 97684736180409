<!-- @format -->

<mat-tab-group
  mat-stretch-tabs
  *ngIf="themesList"
  [selectedIndex]="selectedTabInd"
  class="themes-tiles"
>
  <ng-container
    *ngIf="selectedTeamGroup && selectedTeamGroup.selected[0]?.name"
  >
    <mat-tab *ngFor="let theme of themesList; let i = index">
      <ng-template mat-tab-label>
        <div
          (click)="tabSelectionChanged($event, theme)"
          class="label-text"
          [ngClass]="{ selected: selectedThemes.isSelected(theme) }"
        >
          <mat-icon *ngIf="selectedThemes.isSelected(theme)"
            >check_box</mat-icon
          >
          <mat-icon *ngIf="!selectedThemes.isSelected(theme)"
            >check_box_outline_blank</mat-icon
          >
          <div class="theme-name">{{ theme.name }}</div>
        </div>
      </ng-template>
    </mat-tab>
  </ng-container>
</mat-tab-group>
<div
  class="overview-content"
  [ngClass]="{ 'themes-header-on': selectedTeamGroup?.selected[0]?.name }"
>
  <app-overview-content *ngIf="themesList"></app-overview-content>
</div>
