<!-- @format -->

<h4
  class="mat-subheading-1"
  i18n="team-groups-list list-title"
>
  Praktijkgroepen in de organisatie
</h4>

<div class="flex-rows filters-container">
  <div [formGroup]="filterForm">
    <mat-form-field
      appearance="fill"
      class="team-group-filter"
    >
      <mat-label>Zoek praktijkgroep</mat-label>
      <input
        type="text"
        matInput
        formControlName="name"
      />
    </mat-form-field>
  </div>

  <button
    *ngIf="!isUserACoCoach"
    mat-flat-button
    color="accent"
    class="no-grow"
    (click)="onEditIntent(undefined, $event)"
    i18n="team-groups-list button add-new-team-group"
  >
    <mat-icon>add</mat-icon>
    Nieuwe praktijkgroep
  </button>
</div>

<div class="team-groups-list-panel kms-list">
  <mat-table
    [dataSource]="dataSource"
    matSort
  >
    <ng-container matColumnDef="index">
      <mat-header-cell
        *matHeaderCellDef
        class="index-cell"
        i18n="team-groups-list list-header"
        >No.1</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row; let i = index"
        class="index-cell"
      >
        {{ i + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        i18n="team-groups-list list-header"
        >Naam
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="coach">
      <mat-header-cell
        *matHeaderCellDef
        i18n="team-groups-list list-header"
        >CO Coach
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngFor="let admin of row.admins">
          {{ admin.firstName }} {{ admin.lastName }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="teamsCount">
      <mat-header-cell
        *matHeaderCellDef
        i18n="team-groups-list list-header"
        >Aantal praktijken</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row._embedded ? row._embedded.teams.length : 0 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CUD">
      <mat-header-cell
        *matHeaderCellDef
        class="cud-icons"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="cud-icons"
      >
        <ng-container>
          <button
            *ngIf="!isUserACoCoach"
            mat-icon-button
            color="accent"
            (click)="onEditIntent(row, $event)"
            [ngClass]="{ disabled: tableInEditMode.isEditMode }"
          >
            <mat-icon>create</mat-icon>
          </button>
          <button
            *ngIf="!isUserACoCoach"
            mat-icon-button
            color="warn"
            (click)="onDeleteIntent(row, $event, deleteDialog)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsToDisplay; let i = index"
      [ngClass]="{
        'selected': selection.isSelected(row),
        'has-error': row.hasError,
        'edit-mode': row.editMode
      }"
      (click)="onRowClick(row)"
    ></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="usersPageSetting.size"
    [length]="usersPageSetting.totalElements"
    (page)="pageRows($event)"
  ></mat-paginator>
</div>
