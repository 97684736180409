/** @format */

export enum CHART_COLORS {
  CENTER = '#4C3584',
  COMPLETED = '#08C493',
  NOT_COMPLETED = '#F98D2B',
  EMPTY = '#D3D3D3',
  UNKNOWN = '#D3D3D3'
}

export enum CHART_TYPES {
  QUALITY = 'Kwaliteit',
  INNOVATION = 'Innovatie',
  COMPLIANCY = 'Compliancy',
  COMPLIANCY_NEXT_YEAR = 'Compliancy',
  BUSINESS_OPERATIONS = 'Bedrijfsvoering'
}

// entend with other types later
export type CriteriaCheck = 'FAILED' | 'PASSED' | 'UNKNOWN';

interface CommonData {
  name: string;
  criteriaCheck: CriteriaCheck;
  id: string;
  shortname: string;
  improvementId: string | null;
}

export interface Question extends CommonData {
  indicator: string;
  documentationUrl?: string;
}

export interface Dimension extends CommonData {
  questions: Question[];
}

export interface RawGraphData {
  criteriaCheck: CriteriaCheck;
  organisationId: string;
  organisationResultStatus: number;
  year: number;
  dimensions: Dimension[];
}

export interface SingleGraphData {
  labels: string[];
  parents: string[];
  colors: CHART_COLORS[];
  textTemplate: string[];
}

export interface GraphData {
  innovationGraph: SingleGraphData;
  qualityGraph: SingleGraphData;
  compliancyGraph: SingleGraphData;
  compliancyGraphNextYear: SingleGraphData;
  businessOperationsGraph: SingleGraphData;
}

export interface RawAllGraphsData {
  innovationGraph: RawGraphData;
  qualityGraph: RawGraphData;
  compliancyGraph: RawGraphData;
  // compliancyGraphNextYear: SingleGraphData;
  compliancyGraphNextYear: RawGraphData;
  businessOperationsGraph: RawGraphData;
}
