<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-file-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuw bestand
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-file-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen bestand
</h2>

<mat-dialog-content>
  <form class="file-container">
    <!--accept=".pdf, image/jpeg, .jpeg, image/png, .png, image/pjpeg, .jpg, .txt"-->
    <mat-label>Bestand</mat-label>
    <input
      type="file"
      *ngIf="formIsNew"
      [disabled]="!formIsNew"
      (change)="onFileChange($event.target.files[0])"
      required
    />
    <mat-error [hidden]="!fileError">{{ fileError }}</mat-error>
    <h3
      class="mat-subheading-2 doc-name"
      *ngIf="!formIsNew"
    >
      {{ newFormGroup?.get('docName')?.value }}
      <a
        class="download-link"
        #downloadLinkForm
        disabled
      ></a>
    </h3>
  </form>

  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Bestandsnaam</mat-label>
      <input
        type="text"
        matInput
        formControlName="title"
        required
      />
      <mat-error [hidden]="!formErrors.title">{{ formErrors.title }}</mat-error>
    </mat-form-field>
  </form>

  <form [formGroup]="newFormGroup">
    <div
      class="hidden-slide"
      *ngIf="data.allowHidden"
    >
      <mat-slide-toggle
        [checked]="!newFormGroup?.get('isHidden')?.value"
        (change)="onToggleChange($event)"
      >
        Bestand naar bestandsbibliotheek
      </mat-slide-toggle>
    </div>
    <mat-form-field
      appearance="fill"
      *ngIf="!data.allowHidden || !newFormGroup?.get('isHidden')?.value"
    >
      <mat-label>Bestandscategorieën</mat-label>
      <mat-select formControlName="category">
        <mat-option value="">--</mat-option>
        <mat-option
          *ngFor="let category of sharedService.filesCategories$ | async"
          [value]="category.name"
        >
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Omschrijving</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMinRows="5"
        resizeToFitContent
      ></textarea>
    </mat-form-field>
  </form>
  <form [formGroup]="newFormGroup">
    <mat-form-field appearance="fill">
      <mat-label>Zichtbaar voor</mat-label>
      <mat-select
        formControlName="allowedMemberIds"
        multiple
        required
      >
        <mat-option
          *ngFor="let member of sharedService.currentTeamMembersList$ | async"
          [value]="member.id"
        >
          {{ member.memberName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions
  [matTooltip]="tooltipMessage"
  matTooltipClass="errors-list-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!newFormGroup.invalid"
>
  <button
    mat-flat-button
    color="primary"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit()"
    [disabled]="!formChanged || newFormGroup.invalid || fileError !== ''"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
