/** @format */

import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { ImprovementGroup, PRIO } from '../../models/improvement-group';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UserRights } from '../../models/user-rights';
import { UserValidatorService } from '../../services/user-validator.service';
import { SubTheme } from '../../models/subtheme';
import { ImprovementGroupService } from '../../services/improvement-group.service';
import { ImprovementItem } from '../../models/improvement_item';
import { WarningDeleteDialogComponent } from '../warning-delete-dialog/warning-delete-dialog.component';

/**
 *  The improvement group details dialog component
 *
 * @export
 * @class         ImprGroupDetailsComponent
 * @implements    {OnInit}
 * @implements    {OnDestroy}
 */
@Component({
  selector: 'app-impr-group-details',
  templateUrl: './impr-group-details.component.html',
  styleUrls: ['./impr-group-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImprGroupDetailsComponent implements OnInit, OnDestroy {
  /**
   *  Rights of the current user
   *
   * @type                    {UserRights}
   * @memberof                ImprGroupDetailsComponent
   */
  currentUserRights: UserRights;

  /**
   *  True/false if the dialog state has been changed
   *  If yes the add/put/ delete/ request was successfully sent
   *
   * @memberof                ImprGroupDetailsComponent
   */
  dialogStateChanged = false;

  /**
   *  The dialog component assigned to the delete request.
   *
   * @memberof              UsersListComponent
   */
  deleteDialog = WarningDeleteDialogComponent;

  /**
   *  The data passed to the dialog.
   *
   * @memberof                ImprGroupDetailsComponent
   */
  delDialogParameters = <any>{
    panelClass: 'delete-dialog',
    minHeight: 'auto',
    maxWidth: '800px',
    disableClose: true,
    data: {
      message: `Weet je zeker dat je deze verbetergroep wilt verwijderen?`,
    },
  };

  /**
   *  The default values of the new Form.
   *
   * @memberof                ImprGroupDetailsComponent
   */
  emptyFormValues = {
    bottleneck: ['', Validators.required],
    consequence: ['', Validators.required],
    prio: ['A'],
    reason: ['', Validators.required],
    status: [0],
    scoreItemId: ['', Validators.required],
    teamId: [''],
  };

  /**
   *  The object of form validation errors.
   *
   * @memberof                ImprGroupDetailsComponent
   */
  formErrors = { bottleneck: '', consequence: '', reason: '', scoreItemId: '' };

  /**
   *  True/false if the group is New.
   *
   * @memberof                ImprGroupDetailsComponent
   */
  groupIsNew = false;

  /**
   *  The current improvementGrouo
   *
   * @type      {ImprovementGroup}
   * @memberof  ImprGroupDetailsComponent
   */
  improvementGroup: ImprovementGroup;

  /**
   *  The form group storing the improvementGroup
   *
   * @type                    {FormGroup}
   * @memberof                ImprGroupDetailsComponent
   */
  imprGroupForm: UntypedFormGroup;

  /**
   *  List of values for the Prio field.
   *
   * @memberof            ImprGroupDetailsComponent
   */
  improvementGroupPrio = PRIO;

  /**
   *  The initial values of the form that will be compared to the current
   *
   * @memberof                ImprGroupDetailsComponent
   */
  initialFormValues;

  /**
   *  The value of currently selected scoreItem.
   *
   * @memberof ImprGroupDetailsComponent
   */
  selectedScoreItemName = '';

  /**
   *  The expansion panel step.
   *
   * @memberof                ImprGroupDetailsComponent
   */
  step = 0;

  /**
   *  The list of Subthemes within a theme.
   *
   * @type                    {SubTheme[]}
   * @memberof                ImprGroupDetailsComponent
   */
  subThemesList: SubTheme[];

  /**
   *  True/false if the score form is changed.
   *
   * @memberof                ImprGroupDetailsComponent
   */
  formChanged = false;

  /**
   *  True/false if the form is successfully submited
   *
   * @memberof                ImprGroupDetailsComponent
   */
  formSubmitted = false;

  /**
   *  Form validation messages that will be shown in case of error
   *
   * @memberof                ImprGroupDetailsComponent
   */
  validationMessages = {
    bottleneck: {
      required: 'Voer a.u.b. een knelpunt in.',
    },
    consequence: {
      required: 'Voer a.u.b. een gevolg in.',
    },
    reason: {
      required: 'Voer a.u.b. een oorzaak in.',
    },
    scoreItemId: {
      required: 'Voer a.u.b. een score item in.',
    },
  };

  /**
   *  Creates an instance of ImprGroupDetailsComponent.
   *
   * @param         {MatDialogRef<ImprGroupDetailsComponent>}  dialogRef                The MatDialogRef instance
   * @param         {MatDialog}                                delDialog                The MatDialog instance
   * @param         {FormBuilder}                              fb                       the formBuilder instance
   * @param         {UserValidatorService}                     userValidatorService     service that handles the user validation logic
   * @param         {ImprovementGroupService}                  improvementGroupService  the service that handles improvement group requests
   * @param         {ChangeDetectorRef}                        ref                      the instance of ChangeDetectorRef
   * @param         {*}                                        data                     The data passed through the dialog
   * @memberof      ImprGroupDetailsComponent
   */
  constructor(
    public dialogRef: MatDialogRef<ImprGroupDetailsComponent>,
    public delDialog: MatDialog,
    private fb: UntypedFormBuilder,
    private userValidatorService: UserValidatorService,
    private improvementGroupService: ImprovementGroupService,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  /**
   * Initialize the component after Angular first displays the data-bound properties
   * and sets the component's input properties.
   * Called once, after the first ngOnChanges().
   *
   * @memberof ImprGroupDetailsComponent
   */
  ngOnInit() {
    this.currentUserRights =
      this.userValidatorService.currentUserRights.getValue();
    this.emptyFormValues.teamId = [this.currentUserRights.currentTeamId];

    if (this.data.subThemesList) {
      this.subThemesList = this.data.subThemesList;
    }

    if (!this.data.improvementGroup) {
      this.groupIsNew = true;
      this.initialFormValues = this.emptyFormValues;
    } else {
      this.improvementGroup = this.data.improvementGroup;

      this.selectedScoreItemName = this.improvementGroup.scoreItemName;
      this.initialFormValues = {
        id: [this.improvementGroup.id],
        bottleneck: [this.improvementGroup.bottleneck, Validators.required],
        consequence: [this.improvementGroup.consequence, Validators.required],
        prio: [this.improvementGroup.prio],
        reason: [this.improvementGroup.reason, Validators.required],
        status: [this.improvementGroup.status],
        scoreItemId: [this.improvementGroup.scoreItemId, Validators.required],
        teamId: [this.improvementGroup.teamId],
      };
    }

    this.createForm(this.initialFormValues);
  }

  /**
   *  Respond to the remove of the child component.
   *
   * @param     {number}                  index
   * @memberof  ImprGroupDetailsComponent
   */
  afterItemRemove(index: number) {
    this.improvementGroup._embedded.improvementItems.splice(index, 1);
  }

  /**
   *  Creates the form instance with initial values.
   *
   * @memberof ImprGroupDetailsComponent
   */
  createForm(data?: any): void {
    this.imprGroupForm = this.fb.group(data);

    this.imprGroupForm.valueChanges.subscribe((changes) =>
      this.onFormValueChanged(changes),
    );

    this.onFormValueChanged();
  }

  /**
   *  Check if the DOM element overflows
   *
   * @param    {*}      el              DOM element
   * @returns
   * @memberof ImprGroupDetailsComponent
   */
  isOverflown(el: any) {
    return el.scrollWidth > el.clientWidth;
  }

  /**
   *  Respond to the close intent on the improvement group dialog.
   *
   * @memberof ImprGroupDetailsComponent
   */
  onDetailsClose() {
    this.dialogRef.close({ changed: this.dialogStateChanged });
  }

  /**
   *  Respond to the change of the child component.
   *
   * @memberof ImprGroupDetailsComponent
   */
  onItemChange() {
    this.dialogStateChanged = true;
  }

  /**
   *  Responds to the add intent in the child component.
   *
   * @param       {ImprovementItem}           data
   * @memberof    ImprGroupDetailsComponent
   */
  onItemAdd(data: ImprovementItem) {
    this.dialogStateChanged = true;

    if (this.improvementGroup._embedded) {
      this.improvementGroup._embedded.improvementItems.push(data);
    } else {
      this.improvementGroup._embedded = { improvementItems: [data] };
    }
  }

  /**
   *  Respond to the change of the form value.
   *
   * @param       {*}                       [data]
   * @returns
   * @memberof    ImprGroupDetailsComponent
   */
  onFormValueChanged(data?: any) {
    if (!this.imprGroupForm) {
      return;
    }

    const form = this.imprGroupForm;

    for (const field of Object.keys(this.formErrors)) {
      this.formErrors[field] = ''; //  Clear all of messages
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }

    this.formChanged = false;

    for (const field of Object.keys(this.imprGroupForm.value)) {
      if (
        this.imprGroupForm.value[field] !== this.initialFormValues[field][0]
      ) {
        this.formChanged = true;
        this.formSubmitted = false;

        break;
      }
    }

    this.ref.detectChanges();
  }

  /**
   *  Respond to the delete intent of the group.
   *
   * @returns
   * @memberof ImprGroupDetailsComponent
   */
  onGroupDelete() {
    if (this.groupIsNew) {
      return;
    }

    const currentTeamId =
      this.userValidatorService.currentUserRights.getValue().currentTeamId;

    this.delDialogParameters.data.name = this.improvementGroup.scoreItemName;

    const deleteDialogRef = this.delDialog.open(
      this.deleteDialog,
      this.delDialogParameters,
    );
    deleteDialogRef.afterClosed().subscribe((results) => {
      if (!results) {
        return;
      }

      this.improvementGroupService
        .deleteGroup(this.imprGroupForm.value, currentTeamId)
        .subscribe(
          (resp) => {
            this.dialogStateChanged = true;
            this.dialogRef.close({ changed: this.dialogStateChanged });
          },
          (err) => {},
        );
    });
  }

  /**
   *  Respond to the group save intent.
   *
   * @memberof ImprGroupDetailsComponent
   */
  onGroupSave() {
    if (this.groupIsNew) {
      this.improvementGroupService.post(this.imprGroupForm.value).subscribe(
        (newgroup) => {
          this.improvementGroup = newgroup;
          this.formSubmitted = true;
          this.groupIsNew = false;
          this.dialogStateChanged = true;

          this.ref.detectChanges();
        },

        (err) => {
          this.formSubmitted = false;
        },
      );
    } else {
      this.improvementGroupService.put(this.imprGroupForm.value).subscribe(
        (updatedgroup) => {
          this.improvementGroup = updatedgroup;
          this.formSubmitted = true;
          this.dialogStateChanged = true;

          this.ref.detectChanges();
        },

        (err) => {},
      );
    }
  }

  /**
   *  Set the step of the expansion panel.
   *
   * @param     {number}                    index
   * @memberof  ImprGroupDetailsComponent
   */
  setStep(index: number) {
    this.step = index;
  }

  /**
   *  Set the next step of the expansion panel.
   *
   * @memberof ImprGroupDetailsComponent
   */
  nextStep() {
    this.step++;
  }

  /**
   *  Set the previous step of the expansion panel.
   *
   * @memberof ImprGroupDetailsComponent
   */
  prevStep() {
    this.step--;
  }

  /**
   *  Cleanup just before Angular destroys the directive/component.
   *  Called just before Angular destroys the directive/component.
   *
   * @memberof          ImprGroupDetailsComponent
   */
  ngOnDestroy() {
    this.ref.detach();
  }
}
