
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Member } from '../../models/member';
import { YearPlanningService } from '../../services/year-planning.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UserValidatorService } from '../../services/user-validator.service';
import { UserRights } from '../../models/user-rights';

type YearConfig = { label: string; value: number };

@Component({
  selector: 'app-year-planning-dropdown',
  templateUrl: './year-planning-dropdown.component.html',
  styleUrls: ['./year-planning-dropdown.component.scss'],
})
export class YearPlanningDropdownComponent implements OnInit, OnDestroy {
  @Input() practiceName: string;
  @Output() chosenMember = new EventEmitter<Member>();

  #destroySubject$ = new Subject<void>();

  yearPlanningService = inject(YearPlanningService);
  #userValidatorService = inject(UserValidatorService);

  currentUserRights: UserRights;
  isUserPracticeOwnerOrQualityManager: boolean;

  menuOpened = false;

  listOfYears: YearConfig[] = [];
  showCurrentYearLabel = false;

  ngOnInit(): void {
    this.#watchNextYearDataPresent();

    this.currentUserRights =
      this.#userValidatorService.currentUserRights.getValue();

    this.isUserPracticeOwnerOrQualityManager =
      this.currentUserRights.currentMember.teamRole.name === 'TEAM_ADMIN' ||
      this.currentUserRights.currentMember.teamRole.name === 'QUALITY_MANAGER';
  }

  ngOnDestroy(): void {
    this.#destroySubject$.next();
    this.#destroySubject$.complete();
  }

  #watchNextYearDataPresent(): void {
    this.yearPlanningService.nextYearDataPresent$.pipe(tap(val => {
      if (val && this.listOfYears.every(year => year.value !== val)) {
        this.listOfYears.unshift({ label: val.toString(), value: val });
        this.listOfYears.sort((a, b) => b.value - a.value);
      }

      // probably we need switchMap / mergeMap or something similar in the future instead of setTimeout
      setTimeout(() => {
        if (this.listOfYears.some(year => year.value === this.yearPlanningService.currentlyChosenYear$.getValue())) {
          this.showCurrentYearLabel = true;
        } else {
          this.showCurrentYearLabel = false;
        }
      }, 0)

    }), takeUntil(this.#destroySubject$)).subscribe();
  }

  triggerMenu(): void {
    this.menuOpened = !this.menuOpened;
  }

  selectYear(year: YearConfig): void {
    this.yearPlanningService.currentlyChosenYear$.next(year.value)
    this.triggerMenu();
  }

  createNewYearsPlan(): void {
    this.yearPlanningService.createNextYearPlan();
    this.triggerMenu();
  }
}
