/** @format */

import { Injectable } from '@angular/core';
import { AbstractRestService, PagedResult, PageSetting } from '@iq/ng-core';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DashboardCounts } from '../models/dashboard-counts';
import { catchError } from 'rxjs/operators';

const apiPath = environment.apiPath;

/**
 *  Service for managing the dashboard counts
 *
 * @export
 * @class     DashboardService
 * @extends   {AbstractRestService<DashboardCounts>}
 */
@Injectable()
export class DashboardService extends AbstractRestService<DashboardCounts> {
  /**
   * Creates an instance of DashboardService.
   *
   * @param         {HttpClient}            http        The http client
   * @memberof      DashboardService
   */
  constructor(http: HttpClient) {
    super(http, `${apiPath}dashboard`, 'dashboard');
  }

  /**
   *  Map the results to the model.
   *
   * @protected
   * @param           {*}                       json
   * @returns         {PagedResult<DashboardCounts}
   * @memberof        DashboardService
   */
  protected mapToModel(json: any): PagedResult<DashboardCounts> {
    let payload = <any>{};

    if (json) {
      payload = <DashboardCounts[]>json;
    }

    return new PagedResult<DashboardCounts>(payload, <PageSetting>json.page);
  }

  /**
   *  Get the counts for the specific team and member
   *
   * @param         {string} teamId
   * @returns       {Observable<DashboardCounts>}
   * @memberof      DashboardService
   */
  getDashboardCounts(teamId: string): Observable<DashboardCounts> {
    return this.http
      .get<DashboardCounts>(`${this.actionUrl}/${teamId}`)
      .pipe(catchError((err) => _throw(err)));
  }
}
